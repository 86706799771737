import React from 'react';
import { Link } from 'react-router-dom';
import './main_footer.scss'
import * as routes from '../constants';
import backgroundDetails from '../images/background_details.svg';

class MainFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  render() {
    return (
      <footer className="main-footer">

        {!this.props.collapse &&
          <div className="main-footer__content">

            <div className="main-footer__wrapper">

              <div className="main-footer__column-group--1">

                <Link
                  className="main-footer__logo"
                  to={routes.MAIN_PATH}
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Footer - Logo', {
                        'event_category' : 'Click',
                        'event_label' : 'FYD logo'
                      });
                    }
                  }}
                >

                  <div className="main-footer__logo__text-wrapper">

                    <p className="main-footer__logo__text">FYD CLUB</p>

                  </div>

                </Link>

                <p className="main-footer__description">

                  Desde 2017, a FYD Club foi criada com o propósito de ajudar os
                  alunos atingir os seus objetivos, com uma metodologia única e
                  inovadora na prática de atividade física.

                </p>

                <ul className="main-footer__social-links-container">

                  <li
                    className="main-footer__social-link-wrapper"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Footer - Facebook', {
                          'event_category' : 'Click',
                          'event_label' : 'Facebook'
                        });
                      }
                    }}
                  >
                    <a
                      className="main-footer__social-link"
                      href="https://www.facebook.com/fyd.centrotreinamento"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>

                  <li
                    className="main-footer__social-link-wrapper"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Footer - Instagram', {
                          'event_category' : 'Click',
                          'event_label' : 'Instagram'
                        });
                      }
                    }}
                  >
                    <a
                      className="main-footer__social-link"
                      href="https://www.instagram.com/fydclub/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>

                  <li
                    className="main-footer__social-link-wrapper"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Footer - Messenger', {
                          'event_category' : 'Click',
                          'event_label' : 'Messenger'
                        });
                      }
                    }}
                  >
                    <a
                      className="main-footer__social-link"
                      href="https://m.me/fyd.centrotreinamento"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-messenger"></i>
                    </a>
                  </li>

                </ul>

              </div>

              <div className="main-footer__column-group--2">

                <p className="main-footer__sub-title">Contato</p>

                <address className="main-footer__address">

                  <p className="main-footer__address__item">

                    <i className="fas fa-home main-footer__address__item__icon"></i>
                    <span className="main-footer__address__item__text">Av. Comendador Alfredo Maffei, 3927, Jardim Nossa Sra. Aparecida, São Carlos, SP</span>

                  </p>

                  {/* <p className="main-footer__address__item">

                    <i className="fas fa-clock main-footer__address__item__icon"></i>
                    <span className="main-footer__address__item__text">Seg - Sexta - 9:00 às 17:00</span>

                  </p> */}

                  <p className="main-footer__address__item">

                    <i className="fas fa-envelope main-footer__address__item__icon"></i>
                    <a
                      href="mailto:contato@fydclub.com.br"
                      className="main-footer__address__item__text--clickable"
                      onClick={() => {
                        if (process.env.NODE_ENV === 'production') {
                          window.gtag('event', 'Footer - email', {
                            'event_category' : 'Click',
                            'event_label' : 'contato@fydclub.com.br'
                          });
                        }
                      }}
                    >

                      contato@fydclub.com.br

                    </a>

                  </p>

                </address>

              </div>

              <div className="main-footer__column-group--3">

                <p className="main-footer__label">
                  {(this.state.screenWidth > 870 || this.state.screenWidth <= 550) ? 'Gostaria de abrir sua própria unidade FYD? Saiba mais no link abaixo:' : 'Gostaria de abrir sua própria unidade FYD? Saiba mais no link ao lado:'}
                </p>

                <a
                  href="https://fydclub.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="main-footer__link-button"
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Footer - Botão seja um franqueado', {
                        'event_category' : 'Click',
                        'event_label' : 'Seja um franqueado'
                      });
                    }
                  }}
                >
                  Seja um franqueado
                </a>

              </div>

            </div>

          </div>
        }

        <p className="main-footer__copyright">

          Copyrights © {(new Date()).getFullYear()} Todos os direitos reservados

        </p>

        {!this.props.collapse &&
          <div className="main-footer__images-container">

            <div className="main-footer__red-filter"></div>

            <img
              className="main-footer__main-image"
              src={backgroundDetails}
              alt=""
            />

          </div>
        }

      </footer>
    );
  }
}

export default MainFooter
