const DEFAULT_ERROR_MESSAGE = `Infelizmente estamos com problemas em nosso serviço. ` +
                              `Certifique-se de que esteja conectado na internet e, ` +
                              `por favor, tente novamente mais tarde.`;

const DEFAULT_UNKNOWN_ERROR_MESSAGE = `Erro não documentado. Por favor, entre em contato com ` +
                                      `o suporte para que isto seja corrigido`;

const TRAINING_EXECUTION_METHOD_FIXED_REPS = 'Repetições fechadas';
const TRAINING_EXECUTION_METHOD_PIRAMIDAL = 'Piramidal';
const TRAINING_EXECUTION_METHOD_QRP = 'QRP';

const FYD_NOTIFICATION_EMAIL = 'contato@notification.fydcentrodetreinamento.com.br';

const STUDENT_APP_SUBDOMAIN = 'aluno';

const DEFAULT_UNIT_TYPE = 1;

const SERVICE_FUNCIONAL = 'Cross FYD';
const SERVICE_PILATES = 'Fyd Pilates';
const SERVICE_GYM = 'Musculação';
const SERVICE_BIKE = 'Fyd Bike';
const SERVICE_CARDIO = 'Cardio';
const SERVICE_CORE = 'Core';
const SERVICE_BOOTCAMP = 'FYD Run Bootcamp';

const CUSTOMER_FEEDBACK_TYPE_DOUBT_ID = 4;

const APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID = 1;
const APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID = 2;

export {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_UNKNOWN_ERROR_MESSAGE,
  TRAINING_EXECUTION_METHOD_FIXED_REPS,
  TRAINING_EXECUTION_METHOD_PIRAMIDAL,
  TRAINING_EXECUTION_METHOD_QRP,
  FYD_NOTIFICATION_EMAIL,
  STUDENT_APP_SUBDOMAIN,
  DEFAULT_UNIT_TYPE,
  SERVICE_FUNCIONAL,
  SERVICE_PILATES,
  SERVICE_GYM,
  SERVICE_BIKE,
  SERVICE_CARDIO,
  SERVICE_CORE,
  SERVICE_BOOTCAMP,
  CUSTOMER_FEEDBACK_TYPE_DOUBT_ID,
  APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID,
  APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID
};


const STATE_API = '/request_state';
const STUDENT_BY_HASH_API = '/aluno/';
const CONTRACT_BY_HASH_API = '/contrato/';

const PHYSICAL_EVALUATION_AVAILABLE_PERIODS_GET_API = '/avaliacao-fisica/datas-disponiveis/';
const FOOD_PRESCRIPTION_AVAILABLE_PERIODS_BY_HASH = '/avaliacao-nutricional/datas-disponiveis/';
const SCHEDULE_SERVICE_APPOINTMENT_POST_API = '/agendar-atendimento/';
const CANCEL_SERVICE_APPOINTMENT_POST_API = '/cancelar-atendimento/';


const TRAINING_CALENDAR_GET_API = '/periodizacao/proximos-treinos/';
const POLL_CONFIRMATION_POST_API = '/confirm-poll-vote/';

const UNSUBSCRIBE_EMAIL_FROM_MARKETING_POST_API = '/unsubscribe-email-from-marketing-list/';

const TRAINING_CALENDAR_DEFAULT_GET_API = '/periodizacao/padrao';

const SERVICE_PLANS_GET_API = '/planos';
const TRAINING_TIMES_GET_API = '/horarios';
const EXPERIMENTAL_CLASS_GET_API = '/aula-experimental/disponibilidade';
const EXPERIMENTAL_CLASS_POST_API = '/aula-experimental';

const STUDENT_DIET_SAMPLE_GET_BY_HASH = '/nutricao/historico-de-alimentacao/';
const STUDENT_DIET_SAMPLE_POST_BY_HASH = '/nutricao/historico-de-alimentacao/';

const FOOD_INGREDIENT_GET_BY_HASH = '/nutricao/ingrediente/{id}/';

const FEEDBACK_UNIT_IDENTIFIER_GET_API = '/canal-de-comunicacao/identificador-de-unidade/';
const CUSTOMER_FEEDBACK_POST_API = '/canal-de-comunicacao/identificador-de-unidade/';

const CLIENT_LEAD_POST_API = '/requisicao-de-contato';

export {
  STATE_API,
  STUDENT_BY_HASH_API,
  CONTRACT_BY_HASH_API,
  PHYSICAL_EVALUATION_AVAILABLE_PERIODS_GET_API,
  SCHEDULE_SERVICE_APPOINTMENT_POST_API,
  CANCEL_SERVICE_APPOINTMENT_POST_API,
  TRAINING_CALENDAR_GET_API,
  POLL_CONFIRMATION_POST_API,
  UNSUBSCRIBE_EMAIL_FROM_MARKETING_POST_API,
  SERVICE_PLANS_GET_API,
  TRAINING_TIMES_GET_API,
  EXPERIMENTAL_CLASS_GET_API,
  EXPERIMENTAL_CLASS_POST_API,
  TRAINING_CALENDAR_DEFAULT_GET_API,
  STUDENT_DIET_SAMPLE_GET_BY_HASH,
  STUDENT_DIET_SAMPLE_POST_BY_HASH,
  FOOD_INGREDIENT_GET_BY_HASH,
  FEEDBACK_UNIT_IDENTIFIER_GET_API,
  CUSTOMER_FEEDBACK_POST_API,
  FOOD_PRESCRIPTION_AVAILABLE_PERIODS_BY_HASH,
  CLIENT_LEAD_POST_API
};


const MAIN_PATH = '/';
const EXPERIMENTAL_CLASS_PATH = '/aula-experimental';
const SERVICES_PATH = '/planos-fyd';
const WORKING_HOURS_PATH = '/horarios-de-funcionamento';
const THANK_YOU_PATH = '/obrigado';
const BLANK_PATH = '/blank';

const TRAINING_PERIOD_PATH = '/periodizacao';

export {
  MAIN_PATH,
  EXPERIMENTAL_CLASS_PATH,
  SERVICES_PATH,
  WORKING_HOURS_PATH,
  THANK_YOU_PATH,
  TRAINING_PERIOD_PATH,
  BLANK_PATH
};
