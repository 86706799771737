import React from 'react';
import './checkbox_question.scss';

class CheckboxQuestion extends React.Component {
  onClickOption() {

  }

  getOptions() {
    return this.props.options.map((option, position) => {
      let hasIcon = false;

      if(option.icon) {
        hasIcon = true;
      }

      return (
        <React.Fragment key={`${this.props.name}${position}`}>

          <input
            type="checkbox"
            id={`${this.props.name}${position}`}
            name={this.props.name}
            value={option.text}
            onChange={this.props.handleInputChange}
            className="checkbox-question__input"
            checked={this.props.value.indexOf(option.text) >= 0}
          />

          <label htmlFor={`${this.props.name}${position}`} className="checkbox-question__label">

            {hasIcon &&
              option.icon
            }
            <span>{option.text}</span>

          </label>

        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <React.Fragment>

        <p className="checkbox-question__title">{this.props.title}</p>

        <div className="checkbox-question__options-container">

          {this.getOptions()}

        </div>

      </React.Fragment>
    );
  }
}

export default CheckboxQuestion
