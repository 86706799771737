import React from 'react';
import { PoseGroup } from 'react-pose';
import PreLoader from '../utils/preloader';
import './overlay_window.scss';
import {FadeContainer, PopUpContainer} from '../utils/pose_containers';

class OverlayWindow extends React.Component {
  render() {
    return (
      <PoseGroup flipMove={false}>

        {this.props.visible &&
          <FadeContainer key="overlay_window">

            <aside className="overlay-window">

              <PopUpContainer className={`overlay-window__container ${this.props.className}`}>

                {this.props.loading ?
                  (
                    <PoseGroup>
                      <FadeContainer key="preloader">
                        <PreLoader local={true} />
                      </FadeContainer>
                    </PoseGroup>
                  ):
                  this.props.children
                }

                {this.props.actions ?
                  this.props.actions:
                  null
                }

              </PopUpContainer>

            </aside>

          </FadeContainer>
        }

      </PoseGroup>
    );
  }
}

export default OverlayWindow;
