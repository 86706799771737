import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import RadioQuestion from '../radio_question';
import CheckboxQuestion from '../checkbox_question';
import TextQuestion from '../text_question';
import TextAreaQuestion from '../text_area_question';
import DateQuestion from '../date_question';
import SelectQuestion from '../select_question';
import DiscQuestion from './disc_question';
import './user_registration_questions.scss';
import disc_example from '../../images/disc_example.gif'

const BRASIL_STATES = [
  { value: 'AC', text: 'Acre' },
  { value: 'AL', text: 'Alagoas' },
  { value: 'AP', text: 'Amapá' },
  { value: 'AM', text: 'Amazonas' },
  { value: 'BA', text: 'Bahia' },
  { value: 'CE', text: 'Ceará' },
  { value: 'DF', text: 'Distrito Federal' },
  { value: 'ES', text: 'Espirito Santo' },
  { value: 'GO', text: 'Goiás' },
  { value: 'MA', text: 'Maranhão' },
  { value: 'MS', text: 'Mato Grosso do Sul' },
  { value: 'MT', text: 'Mato Grosso' },
  { value: 'MG', text: 'Minas Gerais' },
  { value: 'PA', text: 'Pará' },
  { value: 'PB', text: 'Paraíba' },
  { value: 'PR', text: 'Paraná' },
  { value: 'PE', text: 'Pernambuco' },
  { value: 'PI', text: 'Piauí' },
  { value: 'RJ', text: 'Rio de Janeiro' },
  { value: 'RN', text: 'Rio Grande do Norte' },
  { value: 'RS', text: 'Rio Grande do Sul' },
  { value: 'RO', text: 'Rondônia' },
  { value: 'RR', text: 'Roraima' },
  { value: 'SC', text: 'Santa Catarina' },
  { value: 'SP', text: 'São Paulo' },
  { value: 'SE', text: 'Sergipe' },
  { value: 'TO', text: 'Tocantins' }
];

const HiddenContainer = posed.div({
  preEnter: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

function QuestionGroup(props) {
  return (
    <div className="user-registration-questions__question-group">
      <h2 className="user-registration-questions__question-group__group-title">{props.title}</h2>

      {props.children}

    </div>
  );
}

function Question1(props) {
  return (
    <React.Fragment>

      <TextQuestion
        title="Nome completo"
        name="name"
        placeholder="Informe o seu nome completo"
        maxLength="128"
        handleInputChange={props.handleInputChange}
        value={props.name}
      />

      <TextQuestion
        title="E-mail (Para recebimento de avisos importantes)"
        name="email"
        placeholder="Informe o seu e-mail"
        maxLength="128"
        handleInputChange={props.handleInputChange}
        value={props.email}
        disabled={props.email_is_validated === true}
      />

      <TextQuestion
        title="Telefone de contato"
        name="phone"
        placeholder="Informe o seu telefone/Whatsapp com DDD"
        maxLength="16"
        handleInputChange={props.handleInputChange}
        value={props.phone}
      />

      <RadioQuestion
        title="Gênero:"
        options={[
          {text:'Masculino', icon: <i className="fas fa-mars user-registration-questions__icon"></i>},
          {text:'Feminino', icon: <i className="fas fa-mars user-registration-questions__icon"></i>},
          // {text:'Prefiro não informar', icon: <i className="fas fa-mars user-registration-questions__icon"></i>},
        ]}
        handleInputChange={props.handleInputChange}
        name="gender"
        value={props.gender}
      />

    </React.Fragment>
  );
}

function Question2(props) {
  return (
    <React.Fragment>

      <div className="user-registration-questions__row-container">

        <TextQuestion
          title="CPF"
          name="cpf"
          placeholder="Informe o seu CPF"
          maxLength="14"
          handleInputChange={props.handleInputChange}
          value={props.cpf}
        />

        <TextQuestion
          title="RG"
          name="rg"
          placeholder="Informe o seu RG"
          maxLength="16"
          handleInputChange={props.handleInputChange}
          value={props.rg}
        />

      </div>

      <div className="user-registration-questions__row-container">

        <TextQuestion
          title="Profissão"
          name="profession"
          placeholder="Informe a sua profissão"
          maxLength="32"
          handleInputChange={props.handleInputChange}
          value={props.profession}
        />

        <TextQuestion
          title="Estado civil"
          name="marital_status"
          placeholder="Informe o seu estado civil"
          maxLength="32"
          handleInputChange={props.handleInputChange}
          value={props.marital_status}
        />

      </div>

      <div className="user-registration-questions__row-container">

        <TextQuestion
          title="Nacionalidade"
          name="nationality"
          placeholder="Informe a sua nacionalidade"
          maxLength="32"
          handleInputChange={props.handleInputChange}
          value={props.nationality}
        />

        <DateQuestion
          title="Data de nascimento"
          name="birthdate"
          handleInputChange={props.handleInputChange}
          value={props.birthdate}
        />

      </div>

    </React.Fragment>
  );
}

function Question3(props) {
  return (
    <TextQuestion
      title="Contato de emergência"
      name="at_emergency"
      placeholder="Nome e telefone do contato de emergência"
      maxLength="64"
      handleInputChange={props.handleInputChange}
      value={props.at_emergency}
    />
  );
}

function Question4(props) {
  return (
    <QuestionGroup
      title={
        <React.Fragment>
          <span className="site-red-color">DADOS DE</span> ENDEREÇO
        </React.Fragment>
      }
    >

      <div className="user-registration-questions__row-container">

        <TextQuestion
          title="Rua/Avenida"
          name="street"
          placeholder="Digite o nome da rua/avenida"
          maxLength="128"
          handleInputChange={props.handleInputChange}
          value={props.street}
        />

        <TextQuestion
          title="Número"
          name="number"
          placeholder="Digite o número"
          maxLength="16"
          handleInputChange={props.handleInputChange}
          value={props.number}
          isShort={true}
        />

      </div>

      <TextQuestion
        title="Complemento"
        name="complement"
        placeholder="Digite o complemento do endereço"
        maxLength="128"
        handleInputChange={props.handleInputChange}
        value={props.complement}
      />

      <div className="user-registration-questions__row-container">

        <TextQuestion
          title="Bairro"
          name="neighborhood"
          placeholder="Digite o nome do bairro"
          maxLength="64"
          handleInputChange={props.handleInputChange}
          value={props.neighborhood}
        />

        <TextQuestion
          title="CEP"
          name="cep"
          placeholder="Digite o CEP"
          maxLength="9"
          handleInputChange={props.handleInputChange}
          value={props.cep}
          isShort={true}
        />

      </div>

      <div className="user-registration-questions__row-container">

        <TextQuestion
          title="Cidade"
          name="city"
          placeholder="Digite o nome da cidade"
          maxLength="32"
          handleInputChange={props.handleInputChange}
          value={props.city}
        />

        <SelectQuestion
          title="Estado"
          name="state"
          handleInputChange={props.handleInputChange}
          value={props.state}
          options={BRASIL_STATES}
          defaultOptionText="Selecione o estado"
        />

        {/* <TextQuestion
          title="Estado"
          name="state"
          placeholder="Digite a sigla do estado"
          maxLength="32"
          handleInputChange={props.handleInputChange}
          value={props.state}
          isShort={true}
        /> */}

      </div>

    </QuestionGroup>
  );
}

function Question5(props) {
  return (
    <CheckboxQuestion
      title="Como ficou sabendo sobre nós? (multipla escolha)"
      options={[
        {text:'Amigos'},
        {text:'Mora perto'},
        {text:'Panfleto'},
        {text:'Fachada (passando em frente)'},
        {text:'Indicação do professor'},
        {text:'Facebook'},
        {text:'Instagram'},
        {text:'YouTube'},
        {text:'Google'},
        {text:'Outros'},
      ]}
      handleInputChange={props.handleInputChange}
      name="met_from"
      value={props.met_from}
    />
  );
}

function Question6(props) {
  return (
    <RadioQuestion
      title={props.title}
      options={[
        {text:'Ganho de massa muscular (hipertrofia)'},
        {text:'Emagrecimento'},
        {text:'Performance'},
        {text:'Qualidade de vida'}
      ]}
      handleInputChange={props.handleInputChange}
      name="principal_objetivo"
      value={props.principal_objetivo}
    />
  );
}

function Question7(props) {
  return (
    <TextAreaQuestion
      title="Alguma observação principal (que o professor precisaria saber)? Como limitações, cirurgias, doenças entre outros."
      name="note"
      placeholder="Digite a observação"
      maxLength="256"
      rows="4"
      handleInputChange={props.handleInputChange}
      value={props.note}
    />
  );
}



function Question8(props) {
  const showExtra = props.praticava_atividade === 'Sim';

  return (
    <React.Fragment>

      <PoseGroup>

        <RadioQuestion
          title={props.titleA}
          options={[
            {text:'Sim'},
            {text:'Não'},
          ]}
          handleInputChange={props.handleInputChange}
          name="praticava_atividade"
          key="praticava_atividade"
          value={props.praticava_atividade}
        />

        <HiddenContainer
          className="hidden-container"
          key="tempo_que_praticava_atividade"
        >

          {showExtra &&
            <RadioQuestion
              title={props.titleB}
              options={[
                {text:'0 à 6 meses'},
                {text:'6 meses à 1 ano'},
                {text:'1 à 2 anos'},
                {text:'2 à 3 anos'},
                {text:'3 anos ou mais'},
              ]}
              handleInputChange={props.handleInputChange}
              name="tempo_que_praticava_atividade"
              value={props.tempo_que_praticava_atividade}
            />
          }

        </HiddenContainer>

      </PoseGroup>

    </React.Fragment>
  );
}

function Question9(props) {
  return (
    <React.Fragment>

      <RadioQuestion
        title={props.titleA}
        options={[
          {text:'Menos de 5 horas'},
          {text:'Entre 5 e 8 horas'},
          {text:'Mais de 8 horas'},
        ]}
        handleInputChange={props.handleInputChange}
        name="horas_de_sono"
        value={props.horas_de_sono}
      />

      <RadioQuestion
        title={props.titleB}
        options={[
          {text:'Sim'},
          {text:'Não'},
          {text:'Às vezes'},
        ]}
        handleInputChange={props.handleInputChange}
        name="sono_suficiente"
        value={props.sono_suficiente}
      />

    </React.Fragment>
  );
}

function Question10(props) {
  const showExtra = (props.fuma === 'Sim' || props.fuma === 'Às vezes');

  return (
    <React.Fragment>

      <PoseGroup>

        <RadioQuestion
          title={props.titleA}
          options={[
            {text:'Sim'},
            {text:'Não'},
            {text:'Às vezes'},
          ]}
          handleInputChange={props.handleInputChange}
          name="fuma"
          key="fuma"
          value={props.fuma}
        />

        <HiddenContainer
          className="hidden-container"
          key="fuma_ha_quanto_tempo"
        >

          {showExtra &&
            <RadioQuestion
              title={props.titleB}
              options={[
                {text:'0 à 1 ano'},
                {text:'1 à 3 anos'},
                {text:'3 à 5 anos'},
                {text:'5 anos ou mais'},
              ]}
              handleInputChange={props.handleInputChange}
              name="fuma_ha_quanto_tempo"
              value={props.fuma_ha_quanto_tempo}
            />
          }

        </HiddenContainer>

      </PoseGroup>

    </React.Fragment>
  );
}

function Question11(props) {
  return (
    <RadioQuestion
      title={props.title}
      options={[
        {text:'Sim, frequentemente (fins de semana)'},
        {text:'Sim, ocasionalmente'},
        {text:'Sim, raramente'},
        {text:'Não'},
      ]}
      handleInputChange={props.handleInputChange}
      name="ingere_alcool"
      value={props.ingere_alcool}
    />
  );
}

function Question12(props) {
  return (
    <QuestionGroup
      title={
        <React.Fragment>
          <span className="site-red-color">PERGUNTAS RÁPIDAS</span> (RESPONDER SIM OU NÃO)
        </React.Fragment>
      }
    >

      <RadioQuestion
        title={props.titleA}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="alteracao_pressao"
        value={props.alteracao_pressao}
      />

      <RadioQuestion
        title={props.titleB}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="anemia"
        value={props.anemia}
      />

      <RadioQuestion
        title={props.titleC}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="ansiedade"
        value={props.ansiedade}
      />

      <RadioQuestion
        title={props.titleD}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="crise_respiratoria"
        value={props.crise_respiratoria}
      />

      <RadioQuestion
        title={props.titleE}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="colesterol_elevado"
        value={props.colesterol_elevado}
      />

      <RadioQuestion
        title={props.titleF}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="depressao"
        value={props.depressao}
      />

      <RadioQuestion
        title={props.titleG}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="sono_irregular"
        value={props.sono_irregular}
      />

      <RadioQuestion
        title={props.titleH}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="diabetes"
        value={props.diabetes}
      />

      <RadioQuestion
        title={props.titleI}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="gastrite"
        value={props.gastrite}
      />

      <RadioQuestion
        title={props.titleJ}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="insonia"
        value={props.insonia}
      />

      <RadioQuestion
        title={props.titleK}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="tensao_ombros_pescoco"
        value={props.tensao_ombros_pescoco}
      />

      <RadioQuestion
        title={props.titleL}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="tireoide"
        value={props.tireoide}
      />

      <RadioQuestion
        title={props.titleM}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="tonturas"
        value={props.tonturas}
      />

      <RadioQuestion
        title={props.titleN}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="varizes"
        value={props.varizes}
      />

      <RadioQuestion
        title={props.titleO}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="taquicardia"
        value={props.taquicardia}
      />

      <RadioQuestion
        title={props.titleP}
        options={[
          {text:'Sim'},
          {text:'Não'},
        ]}
        handleInputChange={props.handleInputChange}
        name="outros_problemas_saude"
        value={props.outros_problemas_saude}
      />

    </QuestionGroup>
  );
}

function Question13(props) {
  const showExtra = props.usa_medicamento === 'Sim';

  return (
    <React.Fragment>

      <PoseGroup>

        <RadioQuestion
          title={props.titleA}
          options={[
            {text:'Sim'},
            {text:'Não'},
          ]}
          handleInputChange={props.handleInputChange}
          name="usa_medicamento"
          key="usa_medicamento"
          value={props.usa_medicamento}
        />

        <HiddenContainer
          className="hidden-container"
          key="fuma_ha_quanto_tempo"
        >

          {showExtra &&
            <React.Fragment>

              <TextAreaQuestion
                title={props.titleB}
                name="medicamentos_usados"
                placeholder="Digite o nome dos medicamentos"
                maxLength="256"
                rows="4"
                handleInputChange={props.handleInputChange}
                value={props.medicamentos_usados}
              />

              <RadioQuestion
                title={props.titleC}
                options={[
                  {text:'0 à 1 ano'},
                  {text:'1 à 3 anos'},
                  {text:'3 à 5 anos'},
                  {text:'5 anos ou mais'},
                ]}
                handleInputChange={props.handleInputChange}
                name="quanto_tempo_usa_medicamento"
                value={props.quanto_tempo_usa_medicamento}
              />

            </React.Fragment>
          }

        </HiddenContainer>

      </PoseGroup>

    </React.Fragment>
  );
}

function Question14(props) {
  const showExtra = props.fez_cirurgia === 'Sim';

  return (
    <React.Fragment>

      <PoseGroup>

        <RadioQuestion
          title={props.titleA}
          options={[
            {text:'Sim'},
            {text:'Não'},
          ]}
          handleInputChange={props.handleInputChange}
          name="fez_cirurgia"
          key="fez_cirurgia"
          value={props.fez_cirurgia}
        />

        <HiddenContainer
          className="hidden-container"
          key="fuma_ha_quanto_tempo"
        >

          {showExtra &&
            <React.Fragment>

              <TextAreaQuestion
                title={props.titleB}
                name="cirurgia_realizada"
                placeholder="Mencione a cirurgia realizada"
                maxLength="256"
                rows="4"
                handleInputChange={props.handleInputChange}
                value={props.cirurgia_realizada}
              />

              <RadioQuestion
                title={props.titleC}
                options={[
                  {text:'0 à 1 ano'},
                  {text:'1 à 3 anos'},
                  {text:'3 à 5 anos'},
                  {text:'5 anos ou mais'},
                ]}
                handleInputChange={props.handleInputChange}
                name="ha_quanto_tempo_fez_cirurgia"
                value={props.ha_quanto_tempo_fez_cirurgia}
              />

            </React.Fragment>
          }

        </HiddenContainer>

      </PoseGroup>

    </React.Fragment>
  );
}

class Question15 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minDiscRow: 1,
      disc_entries: [
        {
          id: 1,
          name: "Líder",
          type: "d"
        },
        {
          id: 2,
          name: "Influenciável",
          type: "i"
        },
        {
          id: 3,
          name: "Estável",
          type: "s"
        },
        {
          id: 4,
          name: "Cauteloso",
          type: "c"
        },
        {
          id: 5,
          name: "Confiante",
          type: "d"
        },
        {
          id: 6,
          name: "Otimista",
          type: "i"
        },
        {
          id: 7,
          name: "Ponderado",
          type: "s"
        },
        {
          id: 8,
          name: "Racional",
          type: "c"
        },
        {
          id: 9,
          name: "Atrevido",
          type: "d"
        },
        {
          id: 10,
          name: "Entusiasmado",
          type: "i"
        },
        {
          id: 11,
          name: "Previsível",
          type: "s"
        },
        {
          id: 12,
          name: "Lógico",
          type: "c"
        },
        {
          id: 13,
          name: "Decidido",
          type: "d"
        },
        {
          id: 14,
          name: "Aberto",
          type: "i"
        },
        {
          id: 15,
          name: "Paciente",
          type: "s"
        },
        {
          id: 16,
          name: "Analítico",
          type: "c"
        },
        {
          id: 17,
          name: "Destemido",
          type: "d"
        },
        {
          id: 18,
          name: "Impulsivo",
          type: "i"
        },
        {
          id: 19,
          name: "Equilibrado",
          type: "s"
        },
        {
          id: 20,
          name: "Preciso",
          type: "c"
        },
        {
          id: 21,
          name: "Incansável",
          type: "d"
        },
        {
          id: 22,
          name: "Emocional",
          type: "i"
        },
        {
          id: 23,
          name: "Cuidadoso",
          type: "s"
        },
        {
          id: 24,
          name: "Questionador",
          type: "c"
        },
        {
          id: 25,
          name: "Competitiva",
          type: "d"
        },
        {
          id: 26,
          name: "Convincente",
          type: "i"
        },
        {
          id: 27,
          name: "Flexível",
          type: "s"
        },
        {
          id: 28,
          name: "Curioso",
          type: "c"
        },
        {
          id: 29,
          name: "Persistente",
          type: "d"
        },
        {
          id: 30,
          name: "Falante",
          type: "i"
        },
        {
          id: 31,
          name: "Modesto",
          type: "s"
        },
        {
          id: 32,
          name: "Cortez, Delicado",
          type: "c"
        },
        {
          id: 33,
          name: "Iniciativa",
          type: "d"
        },
        {
          id: 34,
          name: "Sedutor",
          type: "i"
        },
        {
          id: 35,
          name: "Fácil",
          type: "s"
        },
        {
          id: 36,
          name: "Consistente",
          type: "c"
        },
        {
          id: 37,
          name: "Forte",
          type: "d"
        },
        {
          id: 38,
          name: "Sensível",
          type: "i"
        },
        {
          id: 39,
          name: "Sincero",
          type: "s"
        },
        {
          id: 40,
          name: "Perfeccionista",
          type: "c"
        },
      ]
    };

    this.discMap = new Map(this.state.disc_entries.map((entry) => [entry.id, entry]));
  }

  onChange(id, value) {
    const updatedEntry = {...this.discMap.get(id), value: value};

    this.discMap.set(id, updatedEntry);

    let minDiscRow = Math.floor((id - 1) / 4) + 2;

    if(minDiscRow < this.state.minDiscRow || value !== 1) {
      minDiscRow = this.state.minDiscRow;
    }

    const newDiscEntries = [...this.discMap.values()];

    this.setState({
      minDiscRow: minDiscRow,
      disc_entries: newDiscEntries
    });

    this.props.onChange(newDiscEntries, !this.state.disc_entries.some((entry) => !entry.value));
  }

  getDiscQuestions() {
    const discQuestions = [];

    let i = 0;

    let maxIndex = (this.state.minDiscRow * 4);

    if(maxIndex > this.state.disc_entries.length) {
      maxIndex = this.state.disc_entries.length;
    }

    for(i = 0; i < maxIndex; i += 4) {
      const entriesGroup = this.state.disc_entries.slice(i, i+4);

      discQuestions.push(
        <HiddenContainer
          className="hidden-container"
          key={`disc_group_${Math.floor(i / 4) + 1}`}
        >

          <DiscQuestion
            options={entriesGroup}
            onChange={this.onChange.bind(this)}
          />

        </HiddenContainer>
      );
    }

    return discQuestions;
  }

  render() {
    return (
      <QuestionGroup
        title={
          <React.Fragment>
            <span className="site-red-color">QUEM É VOCÊ?</span> (SIGA SEUS INSTINTOS)
          </React.Fragment>
        }
      >
        <div className="user-registration-questions__disc-example-header">

          <h4 className="user-registration-questions__disc-example-header__title">
            Exemplo
          </h4>

          <img
            className="user-registration-questions__disc-example-header__image"
            src={disc_example}
            alt="Gif ilustrando como responder ao formulário DISC."
          />

          <p className="user-registration-questions__disc-example-header__description">
            Nesse caso a característica mais forte seria
            {' '}<span style={{color: "white"}}>Líder(4)</span>;
            {' '}<span style={{color: "white"}}>Influenciável(3)</span>;
            {' '}<span style={{color: "white"}}>Estável(2)</span>;
            {' '}<span style={{color: "white"}}>Cauteloso(1)</span>;
            {' '}Responda todos os grupos. Cada grupo pode ser refeita
            quantas vezes desejar.
          </p>

        </div>

        <PoseGroup>

          {this.getDiscQuestions()}

        </PoseGroup>

      </QuestionGroup>
    );
  }
}

function Question16(props) {
  return (
    <RadioQuestion
      title={props.title}
      options={[
        {text:'1'},
        {text:'2'},
        {text:'3'},
        {text:'4'},
        {text:'5'},
        {text:'6'},
        {text:'7'},
      ]}
      handleInputChange={props.handleInputChange}
      name="week_frequency"
      value={props.week_frequency}
    />
  );
}

export {
  Question1,
  Question2,
  Question3,
  Question4,
  Question5,
  Question6,
  Question7,
  Question8,
  Question9,
  Question10,
  Question11,
  Question12,
  Question13,
  Question14,
  Question15,
  Question16,
  BRASIL_STATES
};
