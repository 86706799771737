import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import './form_content.scss';
import '../colors.scss';

const QuestionContainer = posed.div({
  preEnter: {
    opacity: 0,
    x: 100,
    y: 0,
  },
  reversePreEnter: {
    opacity: 0,
    x: -100,
    y: 0,
  },
  enter: {
    opacity: 1,
    x: 0,
    y: 0,
    delay: 100,
  },
  exit: {
    applyAtStart: { position: 'absolute' },
    opacity: 0,
    x: -100,
    y: 0,
  },
  reverseExit: {
    applyAtStart: { position: 'absolute' },
    opacity: 0,
    x: 100,
    y: 0,
  }
});

class FormContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finishClicked: false
    };

    this.lastQuestionId = props.questionsId;
  }

  async onFinishClick() {
    this.setState({
      finishClicked: true
    });

    let finishedSucessfully = await this.props.onFinishClick();

    if (finishedSucessfully === false) {
      this.setState({
        finishClicked: false
      });
    }
  }

  render() {
    const foward = this.lastQuestionId <= this.props.questionsId;

    this.lastQuestionId = this.props.questionsId;

    return (
      <div className="form-content__wrapper">

        <section className="form-content__container">

          <h2 className="form-content__title">{this.props.contentTitle}</h2>

          {this.props.contentText !== null &&
            <p className={`form-content__text ${this.props.contentTextAdditionalClass}`}>{this.props.contentText}</p>
          }

          <div className="form-content__question-wrapper">

            <PoseGroup
              preEnterPose={foward ? 'preEnter' : 'reversePreEnter'}
              exitPose={foward ? 'exit' : 'reverseExit'}
              flipMove={false}
            >

              <QuestionContainer className="form-content__question-container" key={`form_content_${this.props.questionsId}`}>

                {this.props.questions}

              </QuestionContainer>

            </PoseGroup>

          </div>

        </section>

        {this.props.showControllers &&
          <aside className="form-content__controller-container">

            {this.props.questionsId > 0 &&
              <button className="form-content__controller-button" onClick={() => this.props.onPreviousClick()}>
                <i className="fas fa-caret-left form-content__controller-icon"></i>
                <span>Anterior</span>
              </button>
            }

            <div></div>

            {this.props.questionsId < this.props.maxId &&
              <button className="form-content__controller-button" disabled={!this.props.allowNext} onClick={() => this.props.onNextClick()}>
                <span>Próximo</span>
                <i className="fas fa-caret-right form-content__controller-icon"></i>
              </button>
            }

            {this.props.questionsId === this.props.maxId &&
              <button
                className={this.props.finishButtonIsIdentical ? 'form-content__controller-button' : 'form-content__controller-finish-button'}
                disabled={!this.props.allowNext || this.state.finishClicked} 
                onClick={() => this.onFinishClick()}
              >
                <span>{this.state.finishClicked ? this.props.uploadingText || 'Enviando...' :  this.props.finishText || 'Enviar Respostas'}</span>
                {this.props.finishButtonIsIdentical &&
                  <i className="fas fa-caret-right form-content__controller-icon"></i>
                }
              </button>
            }

          </aside>
        }

      </div>
    );
  }
}

export default FormContent
