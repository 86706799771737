import React from 'react';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router'
import MainHeader from '../../components/main_header';
import MainFooter from '../../components/main_footer';
import LoadingIcon from '../../components/loading_icon';
import {RedText} from '../../components/default_elements';
import posed from 'react-pose';
// import FormView from '../form_view';
import FormContent from '../form_content';
// import PreLoader from '../../utils/preloader';
import {STATE_API, CONTRACT_BY_HASH_API, DEFAULT_UNIT_TYPE} from '../../constants';
import './accept_contract.scss';

const FadeContainer = posed.div({
  preEnter: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

function getAsLocalDateString(isoDateString) {
  const date = new Date(
    parseInt(isoDateString.slice(0, 4)),
    parseInt(isoDateString.slice(5, 7)) - 1,
    isoDateString.slice(8, 10)
  );

  return date.toLocaleDateString();
}

class AcceptContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      apiFailed: false,
      errorMessage: "",
      contractAccpeted: false,
      service_plan: "",
      contract_download_link: "",
      screenWidth: window.innerWidth,
    };

    this.stateToken = null;
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async componentDidMount() {
    this.onCloseWindow = (event) => {
      event.preventDefault();

      event.returnValue = 'Você ainda não aceitou o contrato. Tem certeza que deseja sair?';
    };

    window.addEventListener("beforeunload", this.onCloseWindow);
    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);

    let stateToken = await this.getStateToken();

    let errorMessage = 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.';

    if(stateToken) {
      const headers = new Headers({
        'state': stateToken
      });

      try {
        let response = await fetch(`${CONTRACT_BY_HASH_API}${this.props.match.params.userHash}`, {
          method: "GET",
          headers: headers,
          cache: 'no-store',
          credentials: 'same-origin'
        });

        if(!response.ok) {
          throw await response.json();
          // throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
        }

        let responseData = await response.json();

        if(!responseData) {
          throw Error('No contract to be accepted.');
        }

        this.setState({
          loadingData: false,
          contract_download_link: responseData.contract_download_link,
          data: responseData
        });

        window.scrollTo(0, document.body.scrollHeight);

        return responseData;
      }
      catch(error) {
        if(error.code) {
          switch (error.code) {
            case 203:
              errorMessage = 'URL inválida. Certifique-se de utilizar o link enviado no email. Qualquer dúvida não hesite em nos contactar.';

              break;
            default:
          }
        }
      }
    }

    this.setState({
      loadingData: false,
      apiFailed: true,
      errorMessage
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onCloseWindow);
    window.removeEventListener("resize", this.resizeListener);
  }

  async getStateToken() {
    try {
      let response = await fetch(STATE_API, {cache: 'no-store', credentials: 'same-origin'});

      if(!response.ok) {
        throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
      }

      let responseData = await response.json();

      this.stateToken = responseData.state;

      return this.stateToken;
    }
    catch(error) {
      console.log(error);
    }
  }

  async onFinishClick() {
    const data = {
        contract_id: this.state.data.id,
        "accepted": true
    };

    let stateToken = await this.getStateToken();

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken,
      });

      const body = JSON.stringify(data);

      try {
        let response = await fetch(`${CONTRACT_BY_HASH_API}${this.props.match.params.userHash}`, {
          method: "PATCH",
          headers: headers,
          body: body,
          cache: 'no-store',
          credentials: 'same-origin'
        });

        if(!response.ok) {
          throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
        }

        let responseData = await response.json();

        if(responseData.redirect_to) {
          this.setState({
            redirect_to: responseData.redirect_to
          });
        }

        return;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      apiFailed: true,
    });
  }

  getPaymentMethods() {
    if(this.state.data.payments) {
      return this.state.data.payments.map((payment) => payment.payment_method.name).join(', ');
    }

    return null;
  }

  getPaymentDates() {
    if(this.state.data.payments) {
      const transactions = this.state.data.payments.map((payment) => payment.transactions)
        .reduce((acc, element) => acc.concat(element), [])
        .sort((a, b) => a.effective_date > b.effective_date);

      return transactions.map((transaction) => {
        return (
          <tr key={`transaction_id_${transaction.id}`}>
              <td className="accept_contract__contract__table__cell">{getAsLocalDateString(transaction.effective_date)}</td>
              <td className="accept_contract__contract__table__cell">{transaction.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
          </tr>
        );
      });
    }

    return null;
  }

  getRestrictions() {
    const serviceRestrictions = [];

    const weekdayMap = [
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
      'Domingo',
    ];

    for(const service of Object.keys(this.state.data.restriction_map)) {
      if(!this.state.data.restriction_map[service].training_times) {
        continue;
      }

      const restrictionsText = [];

      for(let i=0; i < weekdayMap.length; ++i) {
        let timesText = `- ${weekdayMap[i]}: `;
        const times = this.state.data.restriction_map[service].training_times.filter((entry) => entry.day_id ===i).map(entry => entry.time);

        if(times.length <= 0) {
          continue;
        }

        for(let j=0; j < times.length; ++j) {
          if(j === 0) {
            timesText += times[j];
          }
          else if(j < times.length - 1) {
            timesText += `, ${times[j]}`;
          }
          else {
            timesText += ` e ${times[j]}`;
          }
        }

        restrictionsText.push(
          <p key={`accept_contract:service_restriction:${service}:weekday:${i}`}>
            {timesText}
          </p>
        );
      }

      serviceRestrictions.push(
        <div
          key={`accept_contract:service_restriction:${service}`}
          className="accept_contract__contract__section-content"
        >

          {service}:
          {restrictionsText}

        </div>
      );
    }

    if(serviceRestrictions.length <= 0) {
      return null;
    }

    return (
      <React.Fragment>

        <div className="accept_contract__contract__section-content">

          O Contratante poderá realizar as atividades segundo o
          plano contratado, nos dias e horários abaixo descriminados:

        </div>

        {serviceRestrictions}

      </React.Fragment>
    );
  }

  getUnitCNPJ() {
    let number = this.state.data.unit.cnpj;

    number = number.replace(/(\d{2})(\d)/,"$1.$2");
    number = number.replace(/(\d{3})(\d)/,"$1.$2");
    number = number.replace(/(\d{3})(\d)/,"$1/$2");
    number = number.replace(/(\d{4})(\d{2})$/,"$1-$2");

    return number;
  }

  getUnitCEP(input) {
    let number = this.state.data.unit.cep;

    number = number.replace(/(\d{5})(\d+)/,"$1-$2");

    return number;
  }

  getContract() {
    if(this.state.data.unit.type_id === DEFAULT_UNIT_TYPE) {
      return (<article className="accept_contract__contract">

        <header className="accept_contract__contract__header">

          <h2 className="accept_contract__contract__header-text">

            CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE ACADEMIA DE TREINAMENTO FUNCIONAL E OUTRAS ATIVIDADES FÍSICAS

          </h2>

        </header>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CONTRATANTE</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry--double">

                <span className="accept_contract__contract__field-label">Nome:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.name}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Nacionalidade:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.nationality}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Estado civil:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.marital_status}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Profissão:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.profession || 'NÃO ESPECIFICADO'}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Data de Nascimento:</span>

                <span className="accept_contract__contract__field-text">{getAsLocalDateString(this.state.data.student.birthdate)}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">RG:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.rg}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">CPF:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.cpf}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Telefone:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.phone}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry--double">

                <span className="accept_contract__contract__field-label">Endereço:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.street}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Nº:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.number}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Bairro:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.neighborhood}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Complemento:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.complement}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Cidade:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.city}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry--double">

                <span className="accept_contract__contract__field-label">Data da matrícula:</span>

                <span className="accept_contract__contract__field-text">{getAsLocalDateString(this.state.data.effective_date)}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Validade do Contrato:</span>

                <span className="accept_contract__contract__field-text">{getAsLocalDateString(this.state.data.expires_at)}</span>

              </p>

            </div>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CONTRATADA</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            {this.state.data.unit.business_name}, pessoa jurídica de direito
            privado, inscrita no CNPJ: {this.getUnitCNPJ()}, com sede
            à {this.state.data.unit.street}; {this.state.data.unit.number}; {this.state.data.unit.neighborhood};
            CEP: {this.getUnitCEP()}, nesta cidade de {this.state.data.unit.city}/{this.state.data.unit.state_code}

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DO OBJETO DO CONTRATO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            Este contrato tem como OBJETO o uso da academia de treinamento
            físico, de posse direta da CONTRATADA, pelo CONTRATANTE, para
            realização da atividade física conforme plano de
            treinamento contratado e abaixo relacionado:

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">PLANO VIGENTE</span>

            <span className="accept_contract__contract__section-header-attribute">{this.state.data.service_plan.name}</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__field-entry--lose">

              <span className="accept_contract__contract__field-label">Descrição:</span>

              <span className="accept_contract__contract__field-text">{this.state.data.service_plan.description}</span>

            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">FORMAS DE PAGAMENTO</span>

            <span className="accept_contract__contract__section-header-attribute">{this.getPaymentMethods()}</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <table className="accept_contract__contract__table">

              <thead className="accept_contract__contract__table__header">

                <tr>

                    <th
                      className="accept_contract__contract__table__header-cell--centralized"
                      colSpan="2"
                    >

                      DATA DOS PAGAMENTOS

                    </th>

                </tr>

                <tr>

                    <th className="accept_contract__contract__table__header-cell">Data</th>
                    <th className="accept_contract__contract__table__header-cell">Valor</th>

                </tr>

              </thead>

              <tbody className="accept_contract__contract__table__body">

                {this.getPaymentDates()}

              </tbody>

            </table>

          </div>

          <div className="accept_contract__contract__section-content">

            *Os pagamentos realizados na modalidade de recorrência
            deverão obedecer os valores e periodicidade do
            plano contratado. Em caso de impossibilidade de
            pagamento no cartão cadastrado na recorrência,
            o cartão deverá ser imediatamente substituido pelo
            CONTRATANTE ou substituída a forma de
            pagamento, sob pena de rescisão contratual
            e aplicação de multa, além das medidas administrativas
            (inclusão de nome nos órgãos de proteção ao crédito –
            SCPC/SERASA) e Judiciais.

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">FUNCIONAMENTO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              A {this.state.data.unit.business_name} funcionará de segunda
              à sexta-feira das 06:00 às 10:00, das
              12:00 às 13:00 e das 16:00 às 21:00, e aos sábados
              das 9:00 às 11:00.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Nos dias de feriados federais, estaduais ou municipais
              a CONTRATADA reserva-se no direito do não funcionamento,
              sendo que, nos dias em que não houver expediente,
              o CONTRATANTE será avisado com antecedência mínima de
              5 (cinco) dias, através de e-mails, cartas,
              redes sociais, avisos afixados no quadro de avisos
              ou notificações no interior da academia, podendo não
              funcionar em dias anteriores ou posteriores aos feriados
              (emendas ou dia ponte).
            </p>

            <p className="accept_contract__contract__section-paragraph">
              A CONTRATADA se reserva no direito de realizar
              rescesso de final de ano, compreendido entre os
              dias 20/12 a 05/01.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              O CONTRATANTE poderá frequentar as instalações
              da CONTRATADA nos dias e horários que melhor
              lhe convier, respeitando sempre os dias e horários
              de funcionamento, o plano contratado e a
              disponibilidade de vagas nos horários escolhidos.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DO ACESSO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            Somente será permitida a entrada nas dependências
            da academia para a realização de treinamento se o
            CONTRATANTE estiver adimplente com suas obrigações e
            com planos vigentes.

          </div>

          <div className="accept_contract__contract__section-content">

            O CONTRATANTE deverá realizar seu checkin por ordem de
            chegada antes do início da aula, independentemente do
            meio oferecido pela CONTRATADA (mobile ou
            presencialmente).

          </div>

          <div className="accept_contract__contract__section-content">

            A CONTRATADA é responsável pela indicação da
            quantidade de vagas disponíveis para cada aula ou
            treinamento em cada dia e horário, as quais poderão
            sofrer alteração a qualquer tempo.

          </div>

          <div className="accept_contract__contract__section-content">

            O CONTRATANTE deverá respeitar o número máximo de
            aluno por aula, o qual será preenchido por
            ordem de checkin.

          </div>

          <div className="accept_contract__contract__section-content">

            Fica determinado que, uma vez considerada a lotação
            máxima na aula ou treinamento, o CONTRATANTE apenas
            poderá participar daquela atividade se houver
            desistência de outro aluno CONTRATANTE e
            consequentemente liberação de nova vaga, respeitado,
            sempre, a ordem do checkin.

          </div>

          {this.state.data.restriction_map &&
            this.getRestrictions()
          }

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">MULTA POR ATRASO NOS PLANOS</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              O CONTRATANTE estará sujeito à multa de 2% sobre o
              valor da mensalidade e juros de 1% ao mês, pro rata die,
              se não cumprir com os pagamentos na data e forma de
              vencimento.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Facultará à CONTRATADA conceder 3 dias
              de carência em caso de atraso. A carência conferida em
              um mês não gera direito automático ao CONTRATANTE,
              por se tratar de mera liberalidade da CONTRATADA.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Ocorrendo a contratação de novo plano, o CONTRATANTE deverá
              pagar as multas e juros decorrentes da inadimplência do
              contrato anterior, alterando a data de matrícula e
              vencimento das parcelas para a data de pagamento
              do novo plano.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">PROIBIÇÕES</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              Fica proibido o abaixo descrito dentro das dependências
              da CONTRATADA:
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Uso de substâncias ilegais dentro da academia;
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Treinar de jeans e sapato ou qualquer vestimenta
              inadequada a prática de atividade física;
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Comercialização ou publicidade de qualquer tipo de
              produto no interior da academia;
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Levar qualquer pessoa para orientação de treinamento
              sem autorização prévia e por escrito da CONTRATADA;
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Deixar qualquer tipo de objeto guardado com algum
              funcionário da academia ou em suas dependências sem que seja
              no local específico para tal finalidade (armários com
                uso de cadeado);
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Execução de qualquer exercício sem a prescrição dos
              professores credenciados na CONTRATADA;
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Prática de subida e descida da escada do espaço
              com finalidade de exercício;
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - O uso dos chuveiros dos vestiários é privativo dos
              alunos da academia;
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Por motivo de segurança não é permitido a permanência
              de crianças nas dependências da academia.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DAS OBRIGAÇÕES</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              O CONTRATANTE fica obrigado conforme abaixo descrito:
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Uso de garrafa de água e toalha;
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Uso de cadeado nos armários não sendo de
              responsabilidade da academia perda e roubo de objetos na
              falta deste;
            </p>

            <p className="accept_contract__contract__section-paragraph">
              - Entrega de atestado médico (na falta deste o
              CONTRATANTE se responsabiliza por sua
              declaração de aptidão física).
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DA MATRÍCULA E DO PAGAMENTO DAS MENSALIDADES OU PLANOS</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              A matrícula deverá ser feita na secretaria da CONTRATADA,
              no valor de R$69,90 (sessenta e nove reais e noventa centavos),
              e devendo o CONTRATANTE apresentar: RG, CPF; CNH ou
              outro documento com foto de validade em todo território
              nacional.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Quando o CONTRATANTE for menor de idade, deverá ser
              assistido pelos pais ou responsáveis legais que
              deverão trazer o contrato assinado e se responsabilizarão
              pelas obrigações assumidas, apresentando os
              mesmos documentos solicitados para o CONTRATANTE
              principal.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Os pagamentos deverão ser feitos pelo CONTRATANTE junto
              à Secretaria da CONTRATADA ou no local por ela indicado.
              Na hipótese do CONTRATANTE deixar de pagar as mensalidades,
              ou as parcelas correspondentes ao plano contratado,
              será impedido de frequentar as aulas e demais
              atividades até que quite suas obrigações financeiras,
              sob pena de rescisão contratual e multa.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Os contratos renovados no prazo inferior a 30 (trinta)
              dias do vencimento não sofrerão alteração de valores,
              respeitado o prazo de 1 (um) ano. Após o período de 1
              (um) ano da primeira contratação ou assinatura deste
              instrumento, poderá ser reajustado os valores para novas
              contratações.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              O CONTRATANTE devidamente matriculado e sem inadimplência
              de nenhuma espécie poderá adquirir produtos FYD FOOD e FYD Store,
              se houver disponibilidade, e pagar mediante boleto
              bancário, que será enviado por e-mail, com vencimento
              no quinto dia útil do mês subsequente à compra.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Em caso de inadimplência poderá a CONTRATADA suspender as
              vendas mediante boleto bancário ao CONTRATANTE, cancelar o
              contrato de prestação de serviço contratado
              (treinamento funcional, pilates etc.) e abater o
              débito do valor de devolução, se houver, além de tomar
              as medidas jurídicas cabíveis.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Os produtos e serviços contratados sob condições especiais,
              assim consideradas as promoções e campanhas, cujos valores
              possuem regramento próprio e diferenciado do regular, as
              regras contidas na campanha veiculada deverão ser cumpridas e
              passam a integrar o contrato do CONTRATANTE, quando contratada.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DAS FÉRIAS E DO TRANCAMENTO DA MATRÍCULA POR MOTIVOS DE DOENÇAS</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              O CONTRATANTE poderá realizar trancamento do plano no
              caso de doença que impossibilite de realizar as
              atividades físicas mediante a entrega do atestado médico
              na data do pedido de trancamento, não podendo ser
              posterior a entrega do mesmo.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Não será permitido trancamento retroativo ou adição
              de dias nos planos em hipótese alguma, sendo obrigação
              do CONTRATANTE avisar imediatamente pessoalmente ou
              mediante seu procurador e certificando do pedido
              via e-mail {this.state.data.unit.contact_email} à academia mediante a
              apresentação de atestado médico.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Em nenhuma hipótese será concedido tempo de férias,
              trancamento ou transferência ao CONTRATANTE, devendo o
              CONTRATANTE respeitar o período de recesso de fim de
              ano da CONTRATADA compreendido entre os dias
              20/12 a 05/01.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Nos casos de afastamento por doença, problema de saúde,
              a CONTRATADA deverá ser informada imediatamente,
              sendo de obrigação do CONTRATANTE a apresentação do
              atestado médico indicando o motivo do afastamento e o
              período fora de atividade.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              No caso de doenças infectocontagiosas, procedimentos
              cirúrgicos, doenças cardíacas, o CONTRATANTE deverá
              obrigatoriamente apresentar laudo do seu médico
              afirmando ter condições para a prática de exercícios
              físicos, sob pena de ter rescindido o contrato por
              parte do CONTRATANTE.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Na hipótese de afastamento para tratamento médico
              superior ao período de 3 (três) meses, a CONTRATADA
              reserva-se no direito de rescindir o contrato nos padrões
              estabelecidos pelo contrato.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DA TRANSFERÊNCIA DE PLANOS PARA TERCEIROS</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              A transferência do plano contratado poderá ser feita a
              terceiros, contudo, somete nos créditos restantes,
              (meses faltantes para cumprir o plano) e por uma única
              vez durante o plano contratado, e ainda, com autorização
              por escrito do CONTRATANTE cedente para o CONTRATANTE
              cessionário, dependendo por último da aprovação do
              espaço {this.state.data.unit.business_name}, ora CONTRATADA.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Não será concedida transferência de período de bonificação,
              trancamento, planos bolsistas e planos concedidos por
              promoções e campanhas a terceiros.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Na hipótese da rescisão do contrato, o novo CONTRATANTE
              ficará obrigado ao pagamento da taxa de transferência
              de 20% do valor do plano transferido, mais o valor da
              matrícula de R$ 69,90, caso este não seja aluno
              matriculado da academia.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DA RESCISÃO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              O presente contrato poderá ser rescindido por qualquer
              das partes, mediante comunicação expressa à parte
              contrária.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              A CONTRATADA poderá rescindir o contrato no caso da
              direção do espaço {this.state.data.unit.business_name} julgar
              incompatível a frequência do CONTRATANTE nas
              instalações da mesma, na hipótese de comportamento
              abusivo, ou fora dos critérios de ética e bons costumes,
              devendo a CONTRATADA devolver os valores já pagos e os
              que ainda faltam realizar, que estejam pendentes.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Na hipótese de rescisão por parte do CONTRATANTE fica
              estipulado que, somente será permitida a rescisão com aviso
              prévio de no mínimo 30 (trinta) dias, devendo o aviso se dar pessoalmente
              dentros dos horários de funcionamento da academia através do preenchimento
              e assinatura de um formulário de rescisão sendo que, na
              devolução dos valores pagos (caso houver) será cobrado
              uma multa de 15% do valor do plano refente aos meses
              não utilizados segundo fórmula abaixo. Fica determinado também que alguns
              planos oferecidos na academia podem implicar ainda na cobrança de multa adicional,
              e que estes, quando houver, estarão especificados na descrição do plano contratado.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Segue a fórmula de exemplo:
            </p>

            <p className="accept_contract__contract__section-paragraph">
              P: Valor total pago do plano contratado (menos o valor da matrícula);<br/>
              R: Quantidade de meses restantes não utilizados do plano contratado;<br/>
              C: Valor mensal do plano contratado;<br/>
              T: Duração total em meses do plano contratado;<br/>
              A: Multa adicional do plano (se aplicável);<br/>
              TOTAL A RECEBER: P - C.(T - 85%.R) - A
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Se o valor do cálculo, conforme fórmula acima, for
              positivo, caberá à CONTRATADA pagar ao CONTRATANTE
              referido valor como devolução. No entanto, se o resultado
              do cálculo for negativo, o CONTRATANTE deverá pagar o
              montante como forma de multa, não tendo nenhum valor a
              receber.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Fica estipulado que a contratada tem o prazo de 10 dias
              úteis para o cálculo da rescisão e o prazo de 30 dias
              corridos para a devolução do valor restante.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              A devolução será realizada de acordo com o a forma de
              pagamento efetuado pelo CONTRANTE, podendo essa ser
              feita mês a mês ou integralmente, sendo de faculdade
              da CONTRATADA escolher o melhor meio de devolução.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DAS CONDIÇÕES GERAIS</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              Fica considerado o e-mail como meio oficial de comunicação
              entre CONTRATANTE e CONTRATADO, sendo de responsabilidade
              de cada uma das partes mantê-lo atualizado e informar
              sobre sua alteração, se o caso.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Uma vez realizada comunicação via e-mail (meio oficial)
              será considerada válida e recebida a mensagem pela parte
              contrária, salvo prova em contrário.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              O e-mail da CONTRATANTE será: {this.state.data.student.email}; e o
              e-mail da CONTRATADA será: {this.state.data.unit.contact_email}
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DA LEI GERAL DE PROTEÇÃO DE DADOS</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              A CONTRATADA coleta, processa, transfere e protege seus dados
              conforme nossa Política de Privacidade e de acordo com as leis
              de proteção de dados aplicáveis. O CONTRATANTE não é obrigado
              a fornecer informações pessoais e qualquer informação coletada
              será fornecida com consentimento explícito concedido por meio
              do aceite neste contrato. Caso o CONTRATANTE não aceite
              fornecer seus dados pessoais, algumas funcionalidades dos
              serviços ou aplicativos poderão ser prejudicadas ou as próprias
              funcionalidades principais dos serviços poderão ficar impedidas
              de serem fornecidas.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Considerando a Lei n. 13.709/2018 e o Artigo 5º da Constituição
              Federal, o CONTRATANTE ora signatário, autoriza expressamente a
              coleta dos dados tais como, nome, data de nascimento e/ou idade,
              sexo, nacionalidade, estado civil, peso, altura, medidas
              corporais, força, doenças, estado de saúde, alimentação,
              perfil psicológico, resistência, número e cópia dos documentos
              de identificação, CPF, RG, CTPS, habilitações, função/cargo,
              matrícula, remuneração, local de trabalho, fotografia, dados de
              familiares e/ou dependentes, endereço de residência, e-mail,
              telefones para contato e repasse dos dados para a devida
              execução do presente termo firmado com a CONTRATADA perante
              os Órgãos Públicos, incluindo os Judiciais e Administrativos,
              Bancos, Seguradoras e Planos de Saúde, Instituições de Ensino
              e/ou Aprendizados, Empresa de Transporte, Empresas Terceirizadas,
              Parceiras e Convênio da empresa CONTRATADA em pacote de
              benefícios, em território nacional e estrangeiro, visando o
              fiel cumprimento das obrigações de natureza empresarial,
              previdenciária, tributária, cível e para o bom e fiel
              cumprimento da prestação de serviços contratada.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Em caso de revogação da autorização e/ou alteração dos dados,
              o CONTRATANTE deverá efetuar a comunicação por escrito,
              expressamente e informando quais os dados autorizados devem
              ser excluídos e/ou quais foram alterados.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              As partes acordam que, os dados não descritos nas cláusulas
              acima e fornecidos ao longo do Contrato serão autorizados
              expressamente através de Termo de Autorização integrando os
              Termos firmados no presente Contrato, ou por meio de aditivo
              contratual.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">DO FORO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              Para dirimir quaisquer questões oriundas do presente
              instrumento, as partes elegem o {this.state.data.unit.district_forum},
              em detrimento de qualquer outro por
              mais benefício que seja.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              E por estarem justas e contratadas, assinam
              eletronicamente o presente instrumento, mediante o
              aceite, após a leitura e concordância com seus termos.
            </p>

          </div>

        </section>

      </article>);
    }

    return (
      <article className="accept_contract__contract">

        <header className="accept_contract__contract__header">

          <h2 className="accept_contract__contract__header-text">

            CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE PERSONAL TRAINING

          </h2>

        </header>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CONTRATANTE</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry--double">

                <span className="accept_contract__contract__field-label">Nome:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.name}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Nacionalidade:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.nationality}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Estado civil:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.marital_status}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Profissão:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.profession || 'NÃO ESPECIFICADO'}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Data de Nascimento:</span>

                <span className="accept_contract__contract__field-text">{getAsLocalDateString(this.state.data.student.birthdate)}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">RG:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.rg}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">CPF:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.cpf}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Telefone:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.phone}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry--double">

                <span className="accept_contract__contract__field-label">Endereço:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.street}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Nº:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.number}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Bairro:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.neighborhood}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Complemento:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.complement}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Cidade:</span>

                <span className="accept_contract__contract__field-text">{this.state.data.student.address.city}</span>

              </p>

            </div>

            <div className="accept_contract__contract__field-container">

              <p className="accept_contract__contract__field-entry--double">

                <span className="accept_contract__contract__field-label">Data da matrícula:</span>

                <span className="accept_contract__contract__field-text">{getAsLocalDateString(this.state.data.effective_date)}</span>

              </p>

              <p className="accept_contract__contract__field-entry">

                <span className="accept_contract__contract__field-label">Validade do Contrato:</span>

                <span className="accept_contract__contract__field-text">{getAsLocalDateString(this.state.data.expires_at)}</span>

              </p>

            </div>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CONTRATADO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            {(this.state.data.unit.cnpj === null || this.state.data.unit.cnpj.length === 11) ?
              (`${this.state.data.unit.user.name}, pessoa física, ${this.state.data.unit.user.marital_status}, ` +
               `${this.state.data.unit.user.profession}, inscrito no RG sob o número ${this.state.data.unit.user.rg}, ` +
               `no CPF sob o número ${this.state.data.unit.user.cpf}, e no órgão de Classe CREF sob o número ${this.state.data.unit.cref}, ` +
               `residente e domiciliado à ${this.state.data.unit.user.address.street}, número ${this.state.data.unit.user.address.number}, ` +
               `bairro ${this.state.data.unit.user.address.neighborhood}, cidade de ${this.state.data.unit.user.address.city}/` +
               `${this.state.data.unit.user.address.state}, CEP ${this.state.data.unit.user.address.cep}.`) :
              (`${this.state.data.unit.business_name}, pessoa jurídica de direito privado, inscrita no CNPJ sob o número ` +
               `${this.getUnitCNPJ()}, sediada à ${this.state.data.unit.street}, número ${this.state.data.unit.number}, ` +
               `bairro ${this.state.data.unit.neighborhood}, cidade de ${this.state.data.unit.city}/${this.state.data.unit.state_code}, ` +
               `CEP ${this.state.data.unit.cep}, neste ato representada pelo seu sócio ` +
               `${this.state.data.unit.user.name}, ${this.state.data.unit.user.marital_status}, ` +
                `${this.state.data.unit.user.profession}, inscrito no RG sob o número ${this.state.data.unit.user.rg}, ` +
                `no CPF sob o número ${this.state.data.unit.user.cpf}, e no órgão de Classe CREF sob o número ${this.state.data.unit.cref}, ` +
                `residente e domiciliado à ${this.state.data.unit.user.address.street}, número ${this.state.data.unit.user.address.number}, ` +
                `bairro ${this.state.data.unit.user.address.neighborhood}, cidade de ${this.state.data.unit.user.address.city}/` +
                `${this.state.data.unit.user.address.state}, CEP ${this.state.data.unit.user.address.cep}.`)
            }

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA PRIMEIRA – DO OBJETO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              O presente contrato tem por objeto a prestação de serviços de Personal Training pelo CONTRATADO ao CONTRATANTE.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Parágrafo Primeiro – O CONTRATADO prestará ao CONTRATANTE os serviços referentes ao plano contratado, conforme a seguir especificado.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">PLANO VIGENTE</span>

            <span className="accept_contract__contract__section-header-attribute">{this.state.data.service_plan.name}</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__field-entry--lose">

              <span className="accept_contract__contract__field-label">Descrição:</span>

              <span className="accept_contract__contract__field-text">{this.state.data.service_plan.description}</span>

            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA SEGUNDA – DAS CONDIÇÕES DE EXECUÇÃO DOS SERVIÇOS</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              A prestação de serviços de Personal Training ocorrerá nos dias e horários acordados semanalmente entre as partes.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA TERCEIRA – DA REMUNERAÇÃO</span>

          </h3>

          <div className="accept_contract__contract__section-content">
            {`O CONTRATANTE pagará ao CONTRATADO o valor de ${this.state.data.total_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} referente ao plano contratado, da seguinte forma:`}
          </div>

          <div className="accept_contract__contract__section-content">

            <table className="accept_contract__contract__table">

              <thead className="accept_contract__contract__table__header">

                <tr>

                    <th
                      className="accept_contract__contract__table__header-cell--centralized"
                      colSpan="2"
                    >

                      DATA DOS PAGAMENTOS

                    </th>

                </tr>

                <tr>

                    <th className="accept_contract__contract__table__header-cell">Data</th>
                    <th className="accept_contract__contract__table__header-cell">Valor</th>

                </tr>

              </thead>

              <tbody className="accept_contract__contract__table__body">

                {this.getPaymentDates()}

              </tbody>

            </table>

          </div>

          <div className="accept_contract__contract__section-content">
            Parágrafo Primeiro – No caso de atraso de pagamentos, a CONTRATANTE incorrerá,
            automaticamente, em mora, arcado com multa de 2% (dois por cento), e juros de 1%
            (um por cento) ao mês pro rata die, facultando ao CONTRATADO a rescisão do
            contrato nos termos da Cláusula que trata da Rescisão Contratual, sem prejuízo
            da cobrança integral imediatamente.
          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA QUARTA – DO REAJUSTE DA REMUNERAÇÃO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              A remuneração dos serviços de Personal Training prestados pelo CONTRATADO ao
              CONTRATANTE será reajustado a cada período de 1 (um) ano, contando a partir
              da data de assinatura deste termo.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Parágrafo único – O reajusta implicará em novo valor por aula e consequentemente
              adaptará o plano contratado proporcionalmente.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA QUINTA – DAS FALTAS</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              Em caso de falta injustificada pelo CONTRATANTE, o CONTRATADO considerará a aula
              como realizada, sem prejuízo de sua remuneração, devido ao tempo à disposição. Em
              caso de falta do CONTRATADO, por qualquer motivo, o CONTRATANTE terá direito à
              remarcação em novo dia e horário combinado de comum acordo ou à devolução ou abatimento
              do valor correspondente à hora aula, a critério de escolha do CONTRATADO.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA SEXTA – DA VIGÊNCIA</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              O presente contrato é firmado pelo prazo de 1 (um) ano, a partir da data
              de assinatura deste contrato de prestação de serviços.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Parágrafo Único – O contrato poderá ser renovado ou alterado por meio de
              aditivo a qualquer tempo, bastando aceite de comum acordo por ambas as partes.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA SÉTIMA – DA RESCISÃO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              Este contrato poderá ser rescindido por qualquer uma das partes, mediante
              notificação à outra, por escrito, com antecedência mínima de 15 (quinze) dias.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Parágrafo Primeiro – Em caso de rescisão por parte do CONTRATANTE, será
              devida a multa de rescisão contratual no importe de 20% (vinte por cento)
              sobre o valor em aberto do contrato.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              <strong>Exemplo:</strong> Em um contrato de 12 meses, se o cancelamento
              ocorrer no 8 mês, será devida a multa de 20% sobre o valor referente aos
              4 meses restantes.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Parágrafo Segundo – O contrato será automaticamente rescindido, sem o
              pagamento de multa por rescisão contratual caso o CONTRATADO seja impossibilitado
              de continuar a prestação de serviços de Personal Training de modo temporário
              ou definitivo no mercado, com a devida suspensão ou cancelamento da sua
              inscrição no órgão de classe.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Parágrafo Terceiro – O contrato será encerrado sem o pagamento de multa por
              rescisão contratual em caso de o CONTRATANTE, por motivos médicos, mediante
              apresentação de atestado de saúde, não puder dar continuidade aos treinos.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Parágrafo Quarto – O contrato poderá ser encerrado pelo CONTRATADO, sem o pagamento
              de multa de rescisão, se, por motivo fundamento e expressamente apresentado ao
              CONTRATANTE, o CONTRATADO identificar que a continuidade da prestação de serviços
              puder causar riscos à saúde e/ou integridade física do CONTRATANTE.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Parágrafo Quinto – O contrato poderá ser rescindido em caso de violação de qualquer
              uma das cláusulas deste contrato, pela parte prejudicada, mediante denúncia imediata,
              sem prejuízo de eventual indenização de multa por rescisão contratual.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA OITAVA – DA AVALIAÇÃO FÍSICA</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              O CONTRATANTE se obriga a passar por uma avaliação física a ser realizada pelo
              CONTRATANTE ou outro profissional que este indicar e a responder todos os
              questionários de modo fidedigno e ainda a apresentar documentos médicos, se
              solicitados, sob pena de incorrer em infração contratual e aos prejuízos
              materiais e/ou morais a que der causa.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA NONA – DA LEI GERAL DE PROTEÇÃO DE DADOS</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              A CONTRATADA coleta, processa, transfere e protege seus dados
              conforme nossa Política de Privacidade e de acordo com as leis
              de proteção de dados aplicáveis. O CONTRATANTE não é obrigado
              a fornecer informações pessoais e qualquer informação coletada
              será fornecida com consentimento explícito concedido por meio
              do aceite neste contrato. Caso o CONTRATANTE não aceite
              fornecer seus dados pessoais, algumas funcionalidades dos
              serviços ou aplicativos poderão ser prejudicadas ou as próprias
              funcionalidades principais dos serviços poderão ficar impedidas
              de serem fornecidas.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Considerando a Lei n. 13.709/2018 e o Artigo 5º da Constituição
              Federal, o CONTRATANTE ora signatário, autoriza expressamente a
              coleta dos dados tais como, nome, data de nascimento e/ou idade,
              sexo, nacionalidade, estado civil, peso, altura, medidas
              corporais, força, doenças, estado de saúde, alimentação,
              perfil psicológico, resistência, número e cópia dos documentos
              de identificação, CPF, RG, CTPS, habilitações, função/cargo,
              matrícula, remuneração, local de trabalho, fotografia, dados de
              familiares e/ou dependentes, endereço de residência, e-mail,
              telefones para contato e repasse dos dados para a devida
              execução do presente termo firmado com a CONTRATADA perante
              os Órgãos Públicos, incluindo os Judiciais e Administrativos,
              Bancos, Seguradoras e Planos de Saúde, Instituições de Ensino
              e/ou Aprendizados, Empresa de Transporte, Empresas Terceirizadas,
              Parceiras e Convênio da empresa CONTRATADA em pacote de
              benefícios, em território nacional e estrangeiro, visando o
              fiel cumprimento das obrigações de natureza empresarial,
              previdenciária, tributária, cível e para o bom e fiel
              cumprimento da prestação de serviços contratada.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              Em caso de revogação da autorização e/ou alteração dos dados,
              o CONTRATANTE deverá efetuar a comunicação por escrito,
              expressamente e informando quais os dados autorizados devem
              ser excluídos e/ou quais foram alterados.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              As partes acordam que, os dados não descritos nas cláusulas
              acima e fornecidos ao longo do Contrato serão autorizados
              expressamente através de Termo de Autorização integrando os
              Termos firmados no presente Contrato, ou por meio de aditivo
              contratual.
            </p>

          </div>

        </section>

        <section className="accept_contract__contract__section-container">

          <h3 className="accept_contract__contract__section-header">

            <span className="accept_contract__contract__section-header-text">CLÁUSULA DÉCIMA – DO FORO DE ELEIÇÃO</span>

          </h3>

          <div className="accept_contract__contract__section-content">

            <p className="accept_contract__contract__section-paragraph">
              Para dirimir quaisquer questões oriundas do presente
              instrumento, as partes elegem o {this.state.data.unit.district_forum},
              em detrimento de qualquer outro por
              mais benefício que seja.
            </p>

            <p className="accept_contract__contract__section-paragraph">
              E por estarem justas e contratadas, assinam
              eletronicamente o presente instrumento, mediante o
              aceite, após a leitura e concordância com seus termos.
            </p>

          </div>

        </section>

      </article>
    );
  }

  render() {
    if(this.state.redirect_to) {
      return (
        <Redirect push to={this.state.redirect_to} />
      );
    }

    return (
      <React.Fragment>

        <Helmet>
          <title>Contratação de serviço - FYD Club</title>
        </Helmet>

        <MainHeader
          location={this.props.location}
          collapse={true}
        />

        <div className="accept_contract__wrapper">

          <section className="accept_contract">

            <div className="accept_contract__content">

              {this.state.loadingData ?
                <LoadingIcon />:
                <FormContent
                  contentTitle={
                    this.state.apiFailed ?

                    <FadeContainer key="falha_de_api">

                      <RedText>OOOPS!</RedText>

                    </FadeContainer> :

                    <React.Fragment>
                      <RedText>TERMOS DE ACEITE</RedText>
                    </React.Fragment>
                  }
                  contentText={this.state.apiFailed ?
                    this.state.errorMessage :
                    null}
                  contentTextAdditionalClass="accept_contract__instruction-text"
                  questionsId={0}
                  questions={
                    this.state.apiFailed ?

                    null :

                    <React.Fragment>

                      <a
                        className="accept_contract__download-button"
                        href={this.state.contract_download_link}
                        rel="noopener noreferrer"
                        target="_blank"
                        download
                      >

                        <i className="fas fa-file-download accept_contract__download-button__icon"></i> {this.state.screenWidth > 430 ? 'Clique aqui para baixar o contrato na versão PDF.' : 'Baixar PDF do contrato'}

                      </a>

                      <div className="accept_contract__contract-container">

                        {this.getContract()}

                      </div>

                      <div className="accept_contract__accept-input-container">

                        <input
                          type="checkbox"
                          id="accept_contract_checkbox"
                          name="accept_contract_checkbox"
                          onChange={(event) => {this.setState({contractAccpeted: event.target.checked})}}
                          className="accept_contract__accept-input"
                          checked={this.state.contractAccpeted}
                        />

                        <label htmlFor="accept_contract_checkbox" className="accept_contract__accept-input-label">

                          Concordo com os termos do contrato e confirmo que meus dados apresentados estão corretos

                        </label>

                      </div>

                    </React.Fragment>
                  }
                  onFinishClick={() => this.onFinishClick()}
                  maxId={0}
                  showControllers={!this.state.apiFailed}
                  allowNext={this.state.contractAccpeted}
                  finishText="Confirmar"
                  uploadingText="Confirmando..."
                />
              }

            </div>

          </section>

          <MainFooter
            collapse={true}
          />

        </div>

      </React.Fragment>
    );

    // if(this.state.loadingData) {
    //   return (
    //     <PoseGroup>
    //       <FadeContainer key="preloader">
    //         <PreLoader />
    //       </FadeContainer>
    //     </PoseGroup>
    //   );
    // }
    // else {
    //   return this.state.redirect_to ? (
    //     <Redirect to={this.state.redirect_to} />
    //   ):
    //   (
    //     <FormView
    //       title="Contrato de serviço"
    //       contentTitle={
    //         this.state.apiFailed ?
    //
    //         <FadeContainer key="falha_de_api">
    //
    //           <span className="site-red-color">OOOPS!</span>
    //
    //         </FadeContainer> :
    //
    //         <React.Fragment>
    //           {/* <span className="site-red-color">TERMOS DE ACEITE:</span> {this.state.data.service_plan.name.toUpperCase()} */}
    //           <span className="site-red-color">TERMOS DE ACEITE</span>
    //         </React.Fragment>
    //       }
    //       contentText={this.state.apiFailed ?
    //         `Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.` :
    //         null} //(<span>PARA CONCLUIR LEIA O TERMO DE ACEITE ABAIXO, <span className="accept_contract__highlighted-text">CLIQUE NO BOTÃO</span> "CONCORDO COM OS TERMOS DE ACEITE" LOCALIZADO NO <span className="accept_contract__highlighted-text">FINAL DA PÁGINA</span> E EM SEGUIDA CLIQUE EM <span className="accept_contract__highlighted-text">CONFIRMAR</span>.</span>)
    //       contentTextAdditionalClass="accept_contract__instruction-text"
    //       questionsId={0}
    //       questions={
    //         this.state.apiFailed ?
    //
    //         null :
    //
    //         <React.Fragment>
    //
    //           <a
    //             className="accept_contract__download-button"
    //             href={this.state.contract_download_link}
    //             rel="noopener noreferrer"
    //             target="_blank"
    //             download
    //           >
    //
    //             <i className="fas fa-file-download accept_contract__download-button__icon"></i> {this.state.screenWidth > 430 ? 'Clique aqui para baixar o contrato na versão PDF.' : 'Baixar PDF do contrato'}
    //
    //           </a>
    //
    //           <div className="accept_contract__contract-container">
    //
    //             {this.getContract()}
    //
    //           </div>
    //
    //           <div className="accept_contract__accept-input-container">
    //
    //             <input
    //               type="checkbox"
    //               id="accept_contract_checkbox"
    //               name="accept_contract_checkbox"
    //               onChange={(event) => {this.setState({contractAccpeted: event.target.checked})}}
    //               className="accept_contract__accept-input"
    //               checked={this.state.contractAccpeted}
    //             />
    //
    //             <label htmlFor="accept_contract_checkbox" className="accept_contract__accept-input-label">
    //
    //               Concordo com os termos do contrato e confirmo que meus dados apresentados estão corretos
    //
    //             </label>
    //
    //           </div>
    //
    //         </React.Fragment>
    //       }
    //       onFinishClick={() => this.onFinishClick()}
    //       maxId={0}
    //       showControllers={!this.state.apiFailed}
    //       allowNext={this.state.contractAccpeted}
    //       finishText="Confirmar"
    //       uploadingText="Confirmando..."
    //     />
    //   );
    // }
  }
}

export default AcceptContract
