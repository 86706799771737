import React from 'react';
import './default_section.scss';

function HorizontalRule(props) {
  return (
    <hr className="horizontal-rule"></hr>
  );
}

export {HorizontalRule};

function DefaultSubSectionTitle(props) {
  return (
    <h4 className={`default-sub-section-title ${props.className}`}>

      {props.icon}
      <span className="default-sub-section-title__text">{props.text}</span>

    </h4>
  );
}

export {DefaultSubSectionTitle};

function DefaultSection(props) {
  return (
    <section className={`default-section ${props.className}`}>

      <div className="default-section__header">

        <h3 className="default-section__header__text">{props.title}</h3>

      </div>

      <div className="default-section__content-wrapper">

        {props.children}

      </div>

    </section>
  );
}

export default DefaultSection;
