import React from 'react';
import './radio_question.scss';

class RadioQuestion extends React.Component {
  onClickOption() {

  }

  getOptions() {
    return this.props.options.map((option, position) => {
      let hasIcon = false;

      if(option.icon) {
        hasIcon = true;
      }

      return (
        <React.Fragment key={`${this.props.name}${position}`}>

          <input
            type="radio"
            id={`${this.props.name}${position}`}
            name={this.props.name}
            value={option.text}
            onChange={this.props.handleInputChange}
            className="radio-question__input"
            checked={this.props.value === option.text}
          />

          <label htmlFor={`${this.props.name}${position}`} className="radio-question__label">

            {hasIcon &&
              option.icon
            }
            <span>{option.text}</span>

          </label>

        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <div className="radio-question__warpper">

        <p className="radio-question__title">{this.props.title}</p>

        <div className={`radio-question__options-container${this.props.options.length > 5 && !this.props.forceNoWrap ? '--wrappable' : ''}`}>

          {this.getOptions()}

        </div>

      </div>
    );
  }
}

export default RadioQuestion
