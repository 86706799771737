import React from 'react';
import './popup_window.scss'

class PopupWindow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth
    };

    this.contentRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getContent() {
    if(!this.props.visible) {
      return null;
    }

    return this.props.children;
  }

  render() {
    return (
      <aside
        ref={this.wrapperRef}
        className={`popup-window${(this.props.visible && this.props.children) ? '' : '--hidden'}`}
      >

        <div
          ref={this.contentRef}
          className="popup-window__content"
        >

          {this.getContent()}

        </div>

      </aside>
    );
  }
}

export default PopupWindow
