import React from 'react';
import LoadingIcon from './loading_icon';
import './youtube_player.scss';

class YoutubePlayer extends React.Component {
  static apiLoaded = false;
  static onLoadCallbacks = [];
  static playerCount = 0;

  static loadYoutubeAPI() {
    if (!window.YT) {
      window.onYouTubeIframeAPIReady = () => {
        YoutubePlayer.apiLoaded = true;

        YoutubePlayer.onLoadCallbacks.forEach((entry) => entry());
        YoutubePlayer.onLoadCallbacks = [];
      }

      const loadScript = document.createElement('script');
      loadScript.src = "https://www.youtube.com/iframe_api";

      document.getElementsByTagName("head")[0].appendChild(loadScript);
    }
    else {
      YoutubePlayer.apiLoaded = true;

      YoutubePlayer.onLoadCallbacks.forEach((entry) => entry());
      YoutubePlayer.onLoadCallbacks = [];
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      containerSize: null,
      screenWidth: window.innerWidth,
      youtubeApiLoaded: YoutubePlayer.apiLoaded
    };

    if (!YoutubePlayer.apiLoaded) {
      YoutubePlayer.onLoadCallbacks.push(() => this.setState({youtubeApiLoaded: true}));
    }

    this.container = null;
    this.player = null;
    this.playerId = null;
    this.videoEnded = false;

    this.containerRef = (element) => {
      this.container = element;

      if(element) {
        this.setState({containerSize: [element.clientWidth, element.clientHeight]});
      }
    };

    this.videoRef = (element) => {
      if(element && this.props.videoId && this.state.containerSize) {
        this.player = new window.YT.Player(this.playerId, {
          videoId: this.props.videoId,
          width: this.state.containerSize[0],
          height: this.state.containerSize[1],
          playerVars: {
            playsinline: 1,
            controls: 0,
            enablejsapi: 1,
            origin: window.location.origin
          },
          events: {
            'onStateChange': (event) => {
              if (event.data === window.YT.PlayerState.ENDED && !this.videoEnded) {
                this.videoEnded = true;

                if (this.props.onVideoEnd) {
                  this.props.onVideoEnd();
                }
              }
              else if (event.data === window.YT.PlayerState.PLAYING) {
                requestAnimationFrame(this.trackVideoTime.bind(this));
              }
            }
          }
        });
      }
    };
  }

  trackVideoTime() {
    let keepTracking = false;

    if (this.player !== null) {

      const playerState = this.player.getPlayerState();

      if (playerState === window.YT.PlayerState.PLAYING) {
        keepTracking = true;

        if (this.props.onVideoProgress) {
          this.props.onVideoProgress(this.player.getCurrentTime() / this.player.getDuration());
        }
      }
    }

    if (keepTracking) {
      requestAnimationFrame(this.trackVideoTime.bind(this));
    }
  }

  async componentDidMount() {
    YoutubePlayer.playerCount += 1;
    this.playerId = `youtube:player:${YoutubePlayer.playerCount}`;

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    const update = {screenWidth: window.innerWidth};

    if(this.container !== null) {
      update.containerSize = [this.container.clientWidth, this.container.clientHeight];

      if (this.player !== null) {
        this.player.setSize(this.container.clientWidth, this.container.clientHeight);
      }
    }

    this.setState(update);
  }

  getVideo() {
    if(this.state.containerSize !== null) {
      return (
        <div
          id={this.playerId}
          ref={this.videoRef}
        ></div>
      );
    }

    return null;
  }

  render() {
    if (!this.state.youtubeApiLoaded) {
      if (this.props.hideLoadingElement) {
        return null;
      }

      return (<LoadingIcon />);
    }

    return (
      <div
        ref={this.containerRef}
        className={`youtube-player__container ${this.props.className || ''}`}
      >

        {this.getVideo()}

      </div>
    );
  }
}

YoutubePlayer.loadYoutubeAPI();

export default YoutubePlayer
