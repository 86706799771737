import React from 'react';
import { PoseGroup } from 'react-pose';
import './confirmation_window.scss';
import {FadeContainer, PopUpContainer, VerticalTwistContainer, VerticalContainer} from '../utils/pose_containers';

class ConfirmationWindow extends React.Component {
  getIcon() {
    if(this.props.loading) {
      return (
        <VerticalTwistContainer key="confirm_window_confirm_icon">
          <i className="fas fa-spinner confirmation-window__loading-icon"></i>
        </VerticalTwistContainer>
      );
    }
    else if(this.props.useErrorIcon) {
      return (
        <VerticalTwistContainer key="confirm_window_error_icon">
          {this.props.errorIcon ?
            this.props.errorIcon:
            <i className="fas fa-times confirmation-window__error-icon"></i>
          }
        </VerticalTwistContainer>
      );
    }

    return (
      <VerticalTwistContainer key="confirm_window_alert_icon">
        <i className="fas fa-exclamation-circle confirmation-window__alert-icon"></i>
      </VerticalTwistContainer>
    );
  }

  render() {
    return (
      <PoseGroup flipMove={false}>

        {this.props.visible &&
          <FadeContainer key="confirmation_window">

            <aside className="confirmation-window">

              <PopUpContainer className="confirmation-window__container">

                <div className="confirmation-window__icon-wrapper">

                  <PoseGroup>

                    {this.getIcon()}

                  </PoseGroup>

                </div>

                <h2 className={`confirmation-window__title${this.props.loading ? '--pulsing' : ''}`}>

                  {this.props.title}

                </h2>

                <p className="confirmation-window__description">

                  {this.props.description}

                </p>

                <PoseGroup flipMove={false}>

                  {!this.props.loading &&
                    <VerticalContainer
                      key="confirmation_window__buttons"
                      className="confirmation-window__controls-wrapper"
                    >

                      {!this.props.hideCancelButton &&
                        <button
                          className="confirmation-window__cancel-button"
                          onClick={() => this.props.onCancel()}
                        >

                          {this.props.cancelText || 'Cancelar'}

                        </button>
                      }

                      {!this.props.hideConfirmButton &&
                        <button
                          className="confirmation-window__confirm-button"
                          onClick={() => this.props.onConfirm()}
                        >

                          {this.props.confirmText || 'Confirmar'}

                        </button>
                      }

                    </VerticalContainer>
                  }

                </PoseGroup>

              </PopUpContainer>

            </aside>

          </FadeContainer>
        }

      </PoseGroup>
    );
  }
}

export default ConfirmationWindow;
