import React from 'react';
import './text_area_question.scss';

class TextAreaQuestion extends React.Component {

  render() {
    return (
      <React.Fragment>

        <div className={`text-area-question__wrapper${this.props.isShort ? '--short' : ''}`}>

          <label
            className="text-area-question__title"
            htmlFor={this.props.name}
          >
            {this.props.title}
          </label>

          <textarea
            className="text-area-question__input"
            wrap="soft"
            id={this.props.name}
            name={this.props.name}
            placeholder={this.props.placeholder}
            maxLength={this.props.maxLength}
            onChange={this.props.handleInputChange}
            value={this.props.value}
            rows={this.props.rows}
          />

        </div>

      </React.Fragment>
    );
  }
}

export default TextAreaQuestion
