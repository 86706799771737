import React from 'react';
import YoutubePlayer from '../components/youtube_player';
import './promotion.scss';
import * as routes from '../constants';
import { getLocalDateIsoString, getAsLocalDate } from '../utils/functions';

// import promotionImage1_370 from '../images/promotions/plano_trimestral_fyd_nutri_370.jpg';
// import promotionImage1_750 from '../images/promotions/plano_trimestral_fyd_nutri_750.jpg';
// import promotionImage1_940 from '../images/promotions/plano_trimestral_fyd_nutri_940.jpg';

// import promotionImage1_370 from '../images/promotions/fyd_nutri_launch_370.jpg';
// import promotionImage1_750 from '../images/promotions/fyd_nutri_launch_750.jpg';
// import promotionImage1_940 from '../images/promotions/fyd_nutri_launch_940.jpg';

// import promotionImage1_370 from '../images/promotions/black_november/2022/black_november_1_370.png';
// import promotionImage1_750 from '../images/promotions/black_november/2022/black_november_1_750.png';
// import promotionImage1_940 from '../images/promotions/black_november/2022/black_november_1_940.png';

// import promotionImage2_370 from '../images/promotions/black_november/2022/black_november_2_370.png';
// import promotionImage2_750 from '../images/promotions/black_november/2022/black_november_2_750.png';
// import promotionImage2_940 from '../images/promotions/black_november/2022/black_november_2_940.png';

// import promotionImage3_370 from '../images/promotions/black_november/2022/black_november_3_370.png';
// import promotionImage3_750 from '../images/promotions/black_november/2022/black_november_3_750.png';
// import promotionImage3_940 from '../images/promotions/black_november/2022/black_november_3_940.png';

// import promotionImage4_370 from '../images/promotions/black_november/2022/black_november_4_370.png';
// import promotionImage4_750 from '../images/promotions/black_november/2022/black_november_4_750.png';
// import promotionImage4_940 from '../images/promotions/black_november/2022/black_november_4_940.png';

// import promotionImage5_370 from '../images/promotions/black_november/2022/black_november_5_370.png';
// import promotionImage5_750 from '../images/promotions/black_november/2022/black_november_5_750.png';
// import promotionImage5_940 from '../images/promotions/black_november/2022/black_november_5_940.png';

// import blackNovemeber2023_370 from '../images/promotions/black_november/2023/black_november_370.gif';
// import blackNovemeber2023_750 from '../images/promotions/black_november/2023/black_november_750.gif';
// import blackNovemeber2023_940 from '../images/promotions/black_november/2023/black_november_940.gif';

// import blackNovemeberExtended2023_370 from '../images/promotions/black_november/2023/black_november_extended_370.gif';
// import blackNovemeberExtended2023_750 from '../images/promotions/black_november/2023/black_november_extended_750.gif';
// import blackNovemeberExtended2023_940 from '../images/promotions/black_november/2023/black_november_extended_940.gif';

import fyd_challenge_1_370 from '../images/promotions/fyd_desafio_1_370.jpg';
import fyd_challenge_1_750 from '../images/promotions/fyd_desafio_1_750.jpg';
import fyd_challenge_1_940 from '../images/promotions/fyd_desafio_1_940.jpg';

class Promotion extends React.Component {
  constructor(props) {
    super(props);

    const todayDate = new Date();

    this.scheduledPromotions = [
      // {
      //   promotionKey: 'fyd_nutri_combo',
      //   image_alt: 'Imagem apresentando um combo de serviços da FYD com a orientação nutricional inclusa',
      //   analytics_click_event_title: 'Divulgação de serviço',
      //   analytics_click_event_label: 'Combo com orientação nutricional',
      //   externalLink: 'https://fydcentrodetreinamento.com.br/aula-experimental',
      //   startDate: '2022-10-02',
      //   endDate: '2022-10-31',
      //   image_370: promotionImage1_370,
      //   image_750: promotionImage1_750,
      //   image_940: promotionImage1_940,
      // },
      // {
      //   promotionKey: 'black_novemeber_1',
      //   image_alt: 'A promoção de Black Friday da FYD começa antes no dia 21/11.',
      //   analytics_click_event_title: 'Black november - all locked',
      //   analytics_click_event_label: 'Divulgação da Black november',
      //   // externalLink: 'https://fydcentrodetreinamento.com.br/planos-fyd',
      //   startDate: '2022-11-17',
      //   endDate: '2022-11-20',
      //   image_370: promotionImage1_370,
      //   image_750: promotionImage1_750,
      //   image_940: promotionImage1_940,
      // },
      // {
      //   promotionKey: 'black_novemeber_2',
      //   image_alt: 'Venha conhecer nossa primeira promoção de Black Friday com o plano Combo Full, que dá acesso à todos os serviços da academia.',
      //   analytics_click_event_title: 'Black november - cadeado 1',
      //   analytics_click_event_label: 'Promoção Combo Full',
      //   // externalLink: 'https://fydcentrodetreinamento.com.br/planos-fyd',
      //   startDate: '2022-11-21',
      //   endDate: '2022-11-21',
      //   image_370: promotionImage2_370,
      //   image_750: promotionImage2_750,
      //   image_940: promotionImage2_940,
      // },
      // {
      //   promotionKey: 'black_novemeber_3',
      //   image_alt: 'Nossa segunda promoção de Black Friday é no plano Combo Funcional, para você que é amante de Cross e quer tirar o máximo de aproveitamento de nossos serviços.',
      //   analytics_click_event_title: 'Black november - cadeado 3',
      //   analytics_click_event_label: 'Promoção Combo Funcional',
      //   // externalLink: 'https://fydcentrodetreinamento.com.br/planos-fyd',
      //   startDate: '2022-11-22',
      //   endDate: '2022-11-22',
      //   image_370: promotionImage3_370,
      //   image_750: promotionImage3_750,
      //   image_940: promotionImage3_940,
      // },
      // {
      //   promotionKey: 'black_novemeber_4',
      //   image_alt: 'Mais uma promoção de Black Friday, desta vez no plano Combo Musculação, que conta com um conjunto completo de serviços e cronograma de treino personalizado para você alcançar seus objetivos de forma rápida e eficiente.',
      //   analytics_click_event_title: 'Black november - cadeado 4',
      //   analytics_click_event_label: 'Promoção Combo Musculação',
      //   // externalLink: 'https://fydcentrodetreinamento.com.br/planos-fyd',
      //   startDate: '2022-11-23',
      //   endDate: '2022-11-23',
      //   image_370: promotionImage4_370,
      //   image_750: promotionImage4_750,
      //   image_940: promotionImage4_940,
      // },
      // {
      //   promotionKey: 'black_novemeber_5',
      //   image_alt: 'Nossa última promoção de Black Friday é no plano Combo Básico, um plano econômico mas que conta com a mesma qualidade FYD para você não ter desculpa para se mexer e entrar em forma.',
      //   analytics_click_event_title: 'Black november - cadeado 5',
      //   analytics_click_event_label: 'Promoção Combo Básico',
      //   // externalLink: 'https://fydcentrodetreinamento.com.br/planos-fyd',
      //   startDate: '2022-11-24',
      //   endDate: '2022-12-31',
      //   image_370: promotionImage5_370,
      //   image_750: promotionImage5_750,
      //   image_940: promotionImage5_940,
      // },
      // {
      //   promotionKey: 'black_novemeber_5',
      //   image_alt: 'Promoção de Black Friday 2023 da FYD',
      //   analytics_click_event_title: 'Black Friday 2023',
      //   analytics_click_event_label: 'Promoção Black Friday 2023',
      //   externalLink: 'https://fydcentrodetreinamento.com.br/planos-fyd',
      //   startDate: '2023-11-08',
      //   endDate: '2023-11-28',
      //   image_370: blackNovemeber2023_370,
      //   image_750: blackNovemeber2023_750,
      //   image_940: blackNovemeber2023_940,
      // },
      // {
      //   promotionKey: 'black_novemeber_6',
      //   image_alt: 'Promoção de Black Friday 2023 extendida da FYD',
      //   analytics_click_event_title: 'Black Friday 2023 - Extended',
      //   analytics_click_event_label: 'Promoção Black Friday 2023 extendida',
      //   externalLink: 'https://fydcentrodetreinamento.com.br/planos-fyd',
      //   startDate: '2023-11-29',
      //   endDate: '2023-12-31',
      //   image_370: blackNovemeberExtended2023_370,
      //   image_750: blackNovemeberExtended2023_750,
      //   image_940: blackNovemeberExtended2023_940,
      // },
      {
        promotionKey: 'fyd_desafio_1',
        image_alt: 'Divulgação sobre o primeiro desafio FYD',
        analytics_click_event_title: 'Primeiro desafio FYD',
        analytics_click_event_label: 'Primeiro desafio FYD',
        externalLink: 'https://abrir.link/fHAdH',
        startDate: '2024-07-01',
        endDate: '2024-07-14',
        image_370: fyd_challenge_1_370,
        image_750: fyd_challenge_1_750,
        image_940: fyd_challenge_1_940,
      },
      // {
      //   promotionKey: 'black_novemeber_2023',
      //   // image_alt: 'Vídeo explicativo sobre a promoção de Black Friday 2023 da FYD',
      //   // analytics_click_event_title: 'Black Friday 2023',
      //   // analytics_click_event_label: 'Promoção Black Friday 2023',
      //   // externalLink: 'https://fydcentrodetreinamento.com.br/planos-fyd',
      //   startDate: '2023-11-08',
      //   endDate: '2023-12-01',
      //   youtubeVideoId: 'ROastoYetYs'
      // },
    ];

    this.activePromotion = null;

    this.today = getLocalDateIsoString(todayDate);

    for (const entry of this.scheduledPromotions) {
        if (this.today >= entry.startDate && this.today <= entry.endDate) {
          this.activePromotion = entry;
          break;
        }
    }

    let lastView = null;

    if (this.activePromotion !== null && window.localStorage) {
      this.PROMOTION_KEY = `PROMOTION:${this.activePromotion.promotionKey}:view_date`;

      lastView = localStorage.getItem(this.PROMOTION_KEY);
    }

    let hasAlreadyViewed = false;

    if (lastView !== null) {
      const lastViewDate = getAsLocalDate(lastView);
      const timeDiff = Math.abs(todayDate.getTime() - lastViewDate.getTime());
      let daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (daysPassed > 1) {
        hasAlreadyViewed = false;
      }
      else {
        hasAlreadyViewed = true;
      }
    }

    this.state = {
      visible: this.activePromotion !== null && !hasAlreadyViewed && window.localStorage,
      imageLoaded: this.activePromotion !== null && typeof this.activePromotion.youtubeVideoId !== 'undefined' && this.activePromotion.youtubeVideoId !== null,
      imageLoadTime: null,
      screenWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  onClosePromotion() {
    if (window.localStorage) {
      localStorage.setItem(this.PROMOTION_KEY, this.today);
    }

    this.setState({visible: false})
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  render() {
    if (!this.state.visible) {
      return null;
    }

    if (!this.state.imageLoaded) {
      if (typeof this.activePromotion.youtubeVideoId !== 'undefined' && this.activePromotion.youtubeVideoId !== null) {
        return (
          <YoutubePlayer
            className="promotion__video--hidden"
            videoId={this.activePromotion.youtubeVideoId}
            hideLoadingElement={true}
          />
        );
      }

      return (
        <img
          key="promotion_image"
          className="promotion__image--hidden"
          src={this.activePromotion.image_940}
          onLoad={() => {
            this.setState({
              imageLoaded: true,
              imageLoadTime: Date.now()
            })
          }}
          srcSet={`${this.activePromotion.image_370} 370w,
                   ${this.activePromotion.image_750} 750w,
                   ${this.activePromotion.image_940} 940w`}
          sizes="(max-width: 470px) 370px, (max-width: 870px) 750px, 940px"
          alt={this.activePromotion.image_alt}
        />
      );
    }

    return (
      <aside className="promotion">

        <div className="promotion__background"></div>

        <div className="promotion__wrapper">

          <button
            className="promotion__close-button"
            onClick={() => this.onClosePromotion()}
          >

            <i className="fas fa-times"></i>

          </button>

          <div className="promotion__animation-rect--top"></div>
          <div className="promotion__animation-decoration--top"></div>
          <div className="promotion__animation-decoration--bottom"></div>
          <p className="promotion__animation-text">Promoção!</p>
          <div className="promotion__animation-rect--bottom"></div>

          {(typeof this.activePromotion.youtubeVideoId !== 'undefined' && this.activePromotion.youtubeVideoId !== null) ? (
            <YoutubePlayer
              key="promotion_video"
              className="promotion__video"
              videoId={this.activePromotion.youtubeVideoId}
              hideLoadingElement={true}
            />
          ) : (
            <img
              key="promotion_image"
              className="promotion__image"
              src={this.activePromotion.image_940}
              onLoad={() => this.setState({imageLoaded: true})}
              onClick={() => {
                if (this.state.imageLoaded && (Date.now() - this.state.imageLoadTime) > 4500) {
                  if (process.env.NODE_ENV === 'production') {
                    window.gtag('event', this.activePromotion.analytics_click_event_title, {
                      'event_category' : 'Click',
                      'event_label' : this.activePromotion.analytics_click_event_label
                    });
                  }

                  if (this.activePromotion.externalLink) {
                    window.open(this.activePromotion.externalLink, '_blank');
                  }
                  else {
                    this.setState({visible: false});

                    this.props.history.push(routes.SERVICES_PATH);
                  }
                }
              }}
              srcSet={`${this.activePromotion.image_370} 370w,
                      ${this.activePromotion.image_750} 750w,
                      ${this.activePromotion.image_940} 940w`}
              sizes="(max-width: 470px) 370px, (max-width: 870px) 750px, 940px"
              alt={this.activePromotion.image_alt}
            />
          )}

        </div>

      </aside>
    );
  }
}

export default Promotion
