import React from 'react';
import './select_question.scss';

class SelectQuestion extends React.Component {
  getSelectOptions(options) {
    let hasOption = options.some((entry) => entry.value === this.props.value);

    let optionList = options.map((option) => {
      return (
        <option key={`select_question_option:${this.props.name}:${option.value}`} value={option.value}>{option.text}</option>
      );
    });

    if (!hasOption) {
      optionList = [
        <option key={`select_question_option:${this.props.name}:default_value`} value="">{this.props.defaultOptionText}</option>,
        ...optionList
      ];
    }

    return optionList;
  }

  render() {
    return (
      <React.Fragment>

        <div className="select-question__wrapper">

          <label
            className="select-question__title"
            htmlFor={this.props.name}
          >
            {this.props.title}
          </label>

          <select
            className="select-question__input"
            id={this.props.name}
            name={this.props.name}
            onChange={this.props.handleInputChange}
            value={this.props.value || ""}
          >
              {this.getSelectOptions(this.props.options)}
          </select>

        </div>

      </React.Fragment>
    );
  }
}

export default SelectQuestion
