import React from 'react';
import {Helmet} from "react-helmet";
import MainHeader from '../../components/main_header';
import MainFooter from '../../components/main_footer';
import LoadingIcon from '../../components/loading_icon';
import {RedText} from '../../components/default_elements';
import posed from 'react-pose';
// import FormView from '../../forms/form_view';
// import PreLoader from '../../utils/preloader';
import {UNSUBSCRIBE_EMAIL_FROM_MARKETING_POST_API} from '../../constants';
import {postModel} from '../../utils/functions';
import './unsubscribe_email.scss';

const FadeContainer = posed.div({
  preEnter: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

class UnsubscribeEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      apiFailed: false
    };
  }

  async componentDidMount() {
    const data = {}

    try {
      let response = await postModel(`${UNSUBSCRIBE_EMAIL_FROM_MARKETING_POST_API}${this.props.match.params.userHash}`, data, true);

      if(response) {
        this.setState({
          loadingData: false
        });
      }
      else {
        this.setState({
          loadingData: false,
          apiFailed: true
        });
      }
    }
    catch(errors) {
      this.setState({
        loadingData: false,
        apiFailed: true
      });
    }
  }

  getContentTitle() {
    if(this.state.apiFailed) {
      return (
        <FadeContainer key="falha_de_api">

          <RedText>OOOPS!</RedText>

        </FadeContainer>
      );
    }
    else if (this.state.loadingData) {
      return (
        <RedText>Removendo email</RedText>
      );
    }

    return (
      <RedText>Email removido</RedText>
    );
  }

  getContentText() {
    if(this.state.apiFailed) {
      return 'Infelizmente estamos com problemas temporários ' +
             'em nosso serviço. Por favor, tente novamente mais tarde.';
    }

    return '';
  }

  getWarningMessage() {
    let message = 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.';

    return (
      <div className="unsubscribe-email__warning-message">

        <i className="fas fa-exclamation unsubscribe-email__warning-message__icon"></i>
        <p className="unsubscribe-email__warning-message__text">{message}</p>

      </div>
    );
  }

  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>Remoção de email da lista de divulgação da FYD Club</title>
        </Helmet>

        <MainHeader
          location={this.props.location}
          collapse={true}
        />

        <div className="unsubscribe-email__wrapper">

          <section className="unsubscribe-email">

            <div className="unsubscribe-email__content">

              <h1 className="unsubscribe-email__title">{this.getContentTitle()}</h1>

              {this.state.loadingData ?
                <LoadingIcon />:
                this.state.apiFailed ?
                  this.getWarningMessage():
                  <div className="unsubscribe-email__message">

                    Email removido de nossa lista de divulgação com sucesso!

                  </div>
              }

            </div>

          </section>

          <MainFooter
            collapse={true}
          />

        </div>

      </React.Fragment>
    );
  }
}

export default UnsubscribeEmail
