import React from 'react';
import './text_question.scss';

class TextQuestion extends React.Component {

  render() {
    return (
      <React.Fragment>

        <div className={`text-question__wrapper${this.props.isShort ? '--short' : ''}`}>

          <label
            className="text-question__title"
            htmlFor={this.props.name}
          >
            {this.props.title}
          </label>

          <input
            className="text-question__input"
            id={this.props.name}
            name={this.props.name}
            type="text"
            placeholder={this.props.placeholder}
            maxLength={this.props.maxLength}
            onChange={this.props.handleInputChange}
            value={this.props.value}
            disabled={this.props.disabled}
          />

        </div>

      </React.Fragment>
    );
  }
}

export default TextQuestion
