import React from 'react';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router'
import MainHeader from '../../components/main_header';
import MainFooter from '../../components/main_footer';
import LoadingIcon from '../../components/loading_icon';
import SmallLoadingIcon from '../../components/small_loading_icon';
import {RedText} from '../../components/default_elements';
import posed from 'react-pose';
import FormContent from '../form_content';
import {STUDENT_DIET_SAMPLE_GET_BY_HASH,
        FOOD_INGREDIENT_GET_BY_HASH,
        STUDENT_DIET_SAMPLE_POST_BY_HASH,
        THANK_YOU_PATH} from '../../constants';
import {getModel, getModels, postModel} from '../../utils/functions';
import DefaultInput, {HalfWrapper, SelectOption, DummyInput} from '../../utils/default_input';
import WarningMessage from '../../utils/warning_message';
import ConfirmationWindow from '../../utils/confirmation_window';
import DefaultMenuButton from '../../components/default_menu_button';
import OverlayWindow from '../../components/overlay_window';
import {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import DropdownMenu from '../../components/dropdown_menu';
import './diet_sample.scss';


const FadeContainer = posed.div({
  preEnter: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

class DietSample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      apiFailed: false,
      food_prescription: {
        food_recipe_associations: [],
        description: ""
      },
      meal_periods: [],
      food_ingredients: [],
      food_allergens: [],
      user_allergens: [],
      newRecipeAssociation: null,
      ingredientSelectionText: "",
      ingredientNameInputRef: null,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      recipeAssociationToRemove: null,
      showTutorialOverlay: true,
      tutorialVideoContainerSize: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };

    this.accentRegex = new RegExp(/\p{Diacritic}/, 'gu');
    this.basicIngredientDataMap = new Map();

    this.setIngredientNameInputRef = (element) => {
      this.setState({ingredientNameInputRef: element});
    };

    this.recipeCounter = 0;
    this.ingredientAssociationCounter = 0;

    this.tutorialVideoContainer = null;

    this.tutorialOverlayContainerRef = (element) => {
      this.tutorialVideoContainer = element;

      if(element) {
        this.setState({tutorialVideoContainerSize: [element.clientWidth, element.clientHeight]});
      }
    };
  }

  async componentDidMount() {
    this.getDietSample();

    this.onCloseWindow = (event) => {
      event.preventDefault();

      const textMessage = 'Alterações ainda não foram salvas. Para salvá-las, clique em "Salvar histórico no final da página."';

      event.returnValue = textMessage;
      return textMessage;
    };

    window.addEventListener("beforeunload", this.onCloseWindow);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onCloseWindow);
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    const update = {screenWidth: window.innerWidth};

    if(this.tutorialVideoContainer !== null) {
      update.tutorialVideoContainerSize = [this.tutorialVideoContainer.clientWidth, this.tutorialVideoContainer.clientHeight];
    }

    this.setState(update);
  }

  async getDietSample() {
    this.setState({
      loadingData: true,
    });

    const update = {loadingData: false};

    const response = await getModels(`${STUDENT_DIET_SAMPLE_GET_BY_HASH}${this.props.match.params.userHash}`);

    if(response) {
      update.meal_periods = response.meal_periods;
      update.food_ingredients = response.food_ingredients;
      update.food_allergens = response.food_allergens;
      update.user_allergens = update.food_allergens.filter((entry) => response.student_food_allergen_ids.includes(entry.id)).map((entry) => ({...entry}));

      update.food_allergens.sort((a, b) => a.name.localeCompare(b.name));
      update.meal_periods.sort((a, b) => {
        if (a.start_time !== null && b.start_time !== null) {
          const result = a.start_time.localeCompare(b.start_time);

          if (result !== 0) {
            return result;
          }

          return a.name.localeCompare(b.name);
        }
        else if (a.start_time !== null && b.start_time === null) {
          return -1;
        }
        else if (a.start_time === null && b.start_time !== null) {
          return 1;
        }
        else {
          return a.name.localeCompare(b.name);
        }
      });
      update.food_ingredients.sort((a, b) => a.name.localeCompare(b.name));

      if (response.food_prescription) {
        const filteredFoodRecipeAssociations = response.food_prescription.food_recipe_associations.filter((recipeAssociation) => update.meal_periods.some((entry) => entry.id === recipeAssociation.meal_period_id));

        update.food_prescription = {
          description: response.food_prescription.description,
          food_recipe_associations: filteredFoodRecipeAssociations.map((recipeAssociation) => {
            const meal_period = update.meal_periods.find((entry) => entry.id === recipeAssociation.meal_period_id);

            const newAssociation = {
              id: recipeAssociation.id,
              key: `meal_period:${meal_period.id}:recipe_association:${this.recipeCounter}`,
              meal_period_id: meal_period.id,
              meal_period: meal_period,
              priority: recipeAssociation.priority,
              weekdays: recipeAssociation.weekdays, // binary math: mon=1, tue=2, wed=4, thu=8, fri=16, sat=32, sun=64
              isNew: true,
              basic_ingredient_associations: recipeAssociation.food_recipe.basic_ingredient_associations.map((basicAssociation) => {
                const food_ingredient = basicAssociation.food_ingredient_source_association.food_ingredient;

                this.basicIngredientDataMap.set(food_ingredient.id, food_ingredient);

                const newEntry = {
                  id: basicAssociation.id,
                  key: `${this.ingredientAssociationCounter}_basic`,
                  food_ingredient_source_association: food_ingredient.source_associations.find((entry) => {
                    return entry.id === basicAssociation.food_ingredient_source_association.id;
                  }),
                  food_ingredient_measurement_association: food_ingredient.food_measurement_associations.find((entry) => {
                    return entry.id === basicAssociation.food_ingredient_measurement_association_id;
                  }),
                  order: basicAssociation.order,
                  quantity: basicAssociation.quantity,
                  isBasic: true,
                  loaded: true,
                  data: food_ingredient
                };

                this.ingredientAssociationCounter += 1;

                return newEntry;
              })
            };

            newAssociation.basic_ingredient_associations.sort((a, b) => b.order - a.order);

            this.recipeCounter += 1;

            return newAssociation;
          })
        };

        update.food_prescription.food_recipe_associations.sort((a, b) => a.priority - b.priority);
      }
    }

    this.setState(update);
  }

  autoSetMeasurementAssociation(ingredient) {
    let filteredAssociations = ingredient.data.food_measurement_associations.filter((association) => association.processing_method_id === ingredient.food_ingredient_source_association.processing_method_id);

    if (ingredient.data.default_measurement_source_id !== null) {
      filteredAssociations = filteredAssociations.filter((association) => association.food_info_source_id === ingredient.data.default_measurement_source_id);
    }

    if (filteredAssociations.length === 1) {
      ingredient.food_ingredient_measurement_association = filteredAssociations[0];
    }
    else if (filteredAssociations.length > 1 && ingredient.food_ingredient_source_association.default_measurement_id) {
      let measurement_association = filteredAssociations.find((association) => {
        return association.food_measurement_id === ingredient.food_ingredient_source_association.default_measurement_id;
      });

      if (measurement_association) {
        ingredient.food_ingredient_measurement_association = measurement_association;
      }
    }
  }

  async loadBasicIngredient(ingredient) {
    let food_ingredient;

    if (this.basicIngredientDataMap.has(ingredient.data.id)) {
      food_ingredient = this.basicIngredientDataMap.get(ingredient.data.id);
    }
    else {
      food_ingredient = await getModel(`${FOOD_INGREDIENT_GET_BY_HASH.replace('{id}', ingredient.data.id)}${this.props.match.params.userHash}`);

      if(food_ingredient) {
        this.basicIngredientDataMap.set(ingredient.data.id, food_ingredient);
      }
    }

    if(food_ingredient) {
      const newRecipeAssociation = {...this.state.newRecipeAssociation};

      ingredient.data = food_ingredient;
      ingredient.loaded = true;

      let filteredAssociations = ingredient.data.source_associations;

      if (ingredient.data.default_nutrient_source_id !== null) {
        filteredAssociations = ingredient.data.source_associations.filter((association) => association.food_info_source_id === ingredient.data.default_nutrient_source_id);
      }

      if (filteredAssociations.length === 1) {
        ingredient.food_ingredient_source_association = filteredAssociations[0];

        this.autoSetMeasurementAssociation(ingredient);
      }

      this.setState({newRecipeAssociation});
    }
  }

  async onFinishClick() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
    });

    const food_recipe_associations = this.state.food_prescription.food_recipe_associations.map((recipe_association) => {
      const recipe_association_data = {
        name: null,
        priority: recipe_association.priority,
        weekdays: recipe_association.weekdays,
        meal_period_id: recipe_association.meal_period_id,
        basic_ingredient_associations: recipe_association.basic_ingredient_associations.map((basic_association) => {
          const basic_association_data = {
            name: null,
            order: basic_association.order,
            quantity: basic_association.quantity,
            food_ingredient_source_association_id: basic_association.food_ingredient_source_association.id,
            food_ingredient_measurement_association_id: basic_association.food_ingredient_measurement_association.id,
            recipe_preparation_step_id: null
          };

          if (basic_association.id !== null) {
            basic_association_data.id = basic_association.id;
          }

          return basic_association_data;
        }),
      };

      if (recipe_association.id !== null) {
        recipe_association_data.id = recipe_association.id;
      }

      return recipe_association_data;
    });

    const data = {
      food_recipe_associations,
      description: this.state.food_prescription.description,
      student_food_allergen_ids: this.state.user_allergens.map((entry) => entry.id)
    };

    try {
      if(await postModel(`${STUDENT_DIET_SAMPLE_POST_BY_HASH}${this.props.match.params.userHash}`, data) == null) {
        this.setState({
          apiFailed: true
        });
      }
      else {
        this.setState({
          redirect_to: THANK_YOU_PATH
        });
      }
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              // for(let parameter of error.parameters) {
              //   switch (parameter.name) {
              //     case 'scheduled_time':
              //       warningMessages.push(
              //         'Infelizmente o dia e horário selecionado acabou ' +
              //         'de ser agendado por outro aluno. Por favor, ' +
              //         'selecione outro dia e horário'
              //       );
              //       highlights.push('scheduled_date');
              //       highlights.push('scheduled_time');
              //
              //       break;
              //     default:
              //   }
              // }

              break;

              default:
          }
        }

        this.setState({
          highlights: highlights,
          showWarningMessage: true,
          warningMessage: `${warningMessages.join('; ')}.`,
        });
      }
    }
  }

  isHighlighted(propertyName) {
    return this.state.highlights.includes(propertyName);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'ingredientSelectionText') {
      this.setState({ingredientSelectionText: value});
    }
    else {
      const food_prescription = {...this.state.food_prescription, [name]: value};

      this.setState({food_prescription});
    }
  }

  onAddIngredient(ingredient) {
    const newRecipeAssociation = {
      ...this.state.newRecipeAssociation
    };

    const newEntry = {
      id: null,
      key: `${this.ingredientAssociationCounter}_basic`,
      food_ingredient_source_association: null,
      food_ingredient_measurement_association: null,
      order: newRecipeAssociation.basic_ingredient_associations.length + 1,
      quantity: null,
      isBasic: true,
      loaded: false,
      data: ingredient
    };

    this.ingredientAssociationCounter += 1;

    newRecipeAssociation.basic_ingredient_associations = [
      newEntry,
      ...newRecipeAssociation.basic_ingredient_associations
    ];

    newRecipeAssociation.basic_ingredient_associations.sort((a, b) => b.order - a.order);

    this.loadBasicIngredient(newEntry);

    this.setState({
      newRecipeAssociation,
      ingredientSelectionText: ""
    });
  }

  getContentTitle() {
    if(this.state.apiFailed) {
      return (
        <FadeContainer key="falha_de_api">

          <RedText>OOOPS!</RedText>

        </FadeContainer>
      );
    }

    return (
      <React.Fragment>

        HISTÓRICO DE <RedText>ALIMENTAÇÃO</RedText>

      </React.Fragment>
    );
  }

  getContentText() {
    if(this.state.apiFailed) {
      return 'Infelizmente estamos com problemas temporários ' +
             'em nosso serviço. Por favor, tente novamente mais tarde.';
    }

    // <RedText>BLABLA</RedText>

    return (
      <React.Fragment>

        Para que nossa equipe possa atendê-lo da melhor forma possível, necessitamos que você nos informe sobre como se encontra sua alimentação hoje.
        Quais alimentos você normalmente ingere durante suas refeições ao longo dos dias e em quais quantidades. Não precisa ser exato, mas procure
        cadastrar valores que representem bem a média do que você consome normalmente.

        E como deve ser feito esse cadastro? Assista ao nosso vídeo explicativo clicando <button className="diet-sample__tutorial-video-button" onClick={() => this.setState({showTutorialOverlay: true})}>aqui</button>.

      </React.Fragment>
    );
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'confirmInProgress';
    }
    else if(this.state.confirmFailed) {
      return 'confirmFailed';
    }

    return 'Deseja remover esta refeição?';
  }

  async onAcceptConfirmation() {
    if (this.state.recipeAssociationToRemove !== null) {
      const food_prescription = {
        ...this.state.food_prescription,
        food_recipe_associations: [...this.state.food_prescription.food_recipe_associations.filter((entry) => entry.key !== this.state.recipeAssociationToRemove.key)]
      };

      food_prescription.food_recipe_associations.filter((entry) => entry.meal_period.id === this.state.recipeAssociationToRemove.meal_period.id).forEach((entry, index) => {
        entry.priority = index + 1;
      });

      this.setState({
        food_prescription,
        recipeAssociationToRemove: null
      });
    }
  }

  onCancelConfirmation() {
    if(this.state.confirmFailed) {
      window.location.reload();

      return;
    }

    this.setState({
      recipeAssociationToRemove: null
    });
  }

  onEditRecipeAssociation(association) {
    const newRecipeAssociation = {...association};
    newRecipeAssociation.basic_ingredient_associations = newRecipeAssociation.basic_ingredient_associations.map((ingredient) => {
      return {...ingredient};
    });
    newRecipeAssociation.isNew = false;

    this.setState({newRecipeAssociation});
  }

  onRemoveRecipeAssociation(association) {
    this.setState({recipeAssociationToRemove: association});
  }

  onUpdateRecipeAssociationWeekday(association, weekdayIndex) {
    const food_prescription = {
      ...this.state.food_prescription,
      food_recipe_associations: [...this.state.food_prescription.food_recipe_associations]
    };

    const weekdayValue = Math.pow(2, weekdayIndex);

    if ((association.weekdays & weekdayValue) > 0) {
      association.weekdays = association.weekdays ^ weekdayValue;
    }
    else {
      association.weekdays = association.weekdays | weekdayValue;
    }

    this.setState({food_prescription});
  }

  getRecipeAssociations(mealPeriod) {
    const filteredAssociations = this.state.food_prescription.food_recipe_associations.filter((association) => association.meal_period.id === mealPeriod.id);

    const weekdays = [
      'segunda',
      'terça',
      'quarta',
      'quinta',
      'sexta',
      'sábado',
      'domingo',
    ];

    return filteredAssociations.map((association) => {
      const weekdayInputs = weekdays.map((weekday, index) => {
        const checked = association.weekdays & Math.pow(2, index);

        return (
          <div
            key={`${association.key}:weekday_enabled:${index}`}
            className={`diet-sample__weekday${!checked ? '--disabled': ''}`}
          >

            <p className="diet-sample__weekday__text">

              {weekday}

            </p>

            <button
              className="diet-sample__weekday__select-button"
              onClick={() => this.onUpdateRecipeAssociationWeekday(association, index)}
            >

              {(checked > 0) &&
                <i className="fas fa-check"></i>
              }

            </button>

          </div>
        );
      });

      return (
        <div
          className="diet-sample__recipe-association"
          key={association.key}
        >

          <p
            className="diet-sample__recipe-association__detail"
          >

            <i className="fas fa-clipboard-list"></i>

          </p>

          <div className="diet-sample__recipe-association__content-wrapper">

            <p className="diet-sample__recipe-association__content">{`Refeição ${association.priority}`}</p>

            <ul className="diet-sample__recipe-association__ingredient-list">
              {association.basic_ingredient_associations.map((entry) => (
                <li
                  className="diet-sample__recipe-association__ingredient-list__item"
                  key={entry.key}
                >

                  {entry.data.name}

                </li>
              ))}
            </ul>

            <p className="diet-sample__recipe-association__sub-title">Consome geralmente nos dias:</p>

            <div className="diet-sample__recipe-association__weekdays">

              {weekdayInputs}

            </div>

          </div>

          <button
            className="diet-sample__edit-recipe-association-button"
            onClick={() => this.onEditRecipeAssociation(association)}
          >

            <i className="far fa-edit"></i>

          </button>

          <button
            className="diet-sample__delete-recipe-association-button"
            onClick={() => this.onRemoveRecipeAssociation(association)}
          >

            <i className="far fa-trash-alt"></i>

          </button>

        </div>
      );
    });
  }

  onEnterNewRecipeAssociation(mealPeriod) {
    const filteredAssociations = this.state.food_prescription.food_recipe_associations.filter((association) => association.meal_period.id === mealPeriod.id);

    this.recipeCounter += 1;

    this.setState({
      newRecipeAssociation: {
        id: null,
        key: `meal_period:${mealPeriod.id}:recipe_association:${this.recipeCounter}`,
        meal_period_id: mealPeriod.id,
        meal_period: mealPeriod,
        priority: filteredAssociations.length + 1,
        weekdays: 127, // binary math: mon=1, tue=2, wed=4, thu=8, fri=16, sat=32, sun=64
        isNew: true,
        basic_ingredient_associations:[]
      }
    });
  }

  onApplyRecipeAssociation() {
    const food_prescription = {...this.state.food_prescription};

    const newRecipeAssociation = {...this.state.newRecipeAssociation};

    if (!this.state.newRecipeAssociation.isNew) {
      food_prescription.food_recipe_associations = food_prescription.food_recipe_associations.filter((entry) => entry.key !== newRecipeAssociation.key);
    }

    newRecipeAssociation.isNew = false;
    food_prescription.food_recipe_associations.push(newRecipeAssociation);

    food_prescription.food_recipe_associations.sort((a, b) => a.priority - b.priority);

    this.setState({
      food_prescription,
      newRecipeAssociation: null,
      ingredientSelectionText: ""
    });
  }

  onSelectIngredientSourceAssociation(ingredient, associationId) {
    const newRecipeAssociation = {...this.state.newRecipeAssociation};

    ingredient.food_ingredient_source_association = ingredient.data.source_associations.find((association) => association.id === associationId);

    ingredient.food_ingredient_measurement_association = null;
    this.autoSetMeasurementAssociation(ingredient);

    this.setState({newRecipeAssociation});
  }

  onUpdateIngredientQuantity(ingredient, quantity) {
    const newRecipeAssociation = {...this.state.newRecipeAssociation};

    ingredient.quantity = quantity;

    this.setState({newRecipeAssociation});
  }

  onSelectIngredientMeasurementAssociation(ingredient, associationId) {
    const newRecipeAssociation = {...this.state.newRecipeAssociation};

    ingredient.food_ingredient_measurement_association = ingredient.data.food_measurement_associations.find((association) => association.id === associationId);

    this.setState({newRecipeAssociation});
  }

  onRemoveIngredient(ingredient) {
    const newRecipeAssociation = {...this.state.newRecipeAssociation};
    newRecipeAssociation.basic_ingredient_associations = [...newRecipeAssociation.basic_ingredient_associations.filter((entry) => entry.key !== ingredient.key)];

    newRecipeAssociation.basic_ingredient_associations.forEach((entry, index) => {
      entry.order = newRecipeAssociation.basic_ingredient_associations.length - index;
    });

    this.setState({newRecipeAssociation});
  }

  getProcessingMethodOptions(ingredient) {
    let filteredAssociations = ingredient.data.source_associations;

    if (ingredient.data.default_nutrient_source_id !== null) {
      filteredAssociations = ingredient.data.source_associations.filter((association) => association.food_info_source_id === ingredient.data.default_nutrient_source_id);
    }

    if (ingredient.food_ingredient_source_association !== null) {
      return [
        ...filteredAssociations.map((source_association) => SelectOption(source_association.id, source_association.processing_method ? source_association.processing_method.name : 'Não se aplica'))
      ];
    }
    else {
      return [
        SelectOption('', 'Selecione um método de processamento'),
        ...filteredAssociations.map((source_association) => SelectOption(source_association.id, source_association.processing_method ? source_association.processing_method.name : 'Não se aplica'))
      ];
    }
  }

  getMeasurementOptions(ingredient) {
    let filteredMeasurementAssociations = ingredient.data.food_measurement_associations.filter((association) => association.processing_method_id === ingredient.food_ingredient_source_association.processing_method_id);

    if (ingredient.data.default_measurement_source_id !== null) {
      filteredMeasurementAssociations = filteredMeasurementAssociations.filter((association) => association.food_info_source_id === ingredient.data.default_measurement_source_id);
    }

    if (ingredient.food_ingredient_measurement_association === null) {
      return [
        SelectOption('', 'Selecione uma medida'),
        ...filteredMeasurementAssociations.map((association) => SelectOption(association.id, `${association.food_measurement.name} (${association.weight_reference}${association.weight_reference_unit.shortname})`))
      ];
    }

    return [
      ...filteredMeasurementAssociations.map((association) => SelectOption(association.id, `${association.food_measurement.name} (${association.weight_reference}${association.weight_reference_unit.shortname})`))
    ];
  }

  getIngredientInputs(ingredient) {
    if (!ingredient.loaded) {
      return (
        <SmallLoadingIcon />
      );
    }

    if (ingredient.isBasic) {
      let filteredAssociations = ingredient.data.source_associations;

      if (ingredient.data.default_nutrient_source_id !== null) {
        filteredAssociations = ingredient.data.source_associations.filter((association) => association.food_info_source_id === ingredient.data.default_nutrient_source_id);
      }

      return (
        <HalfWrapper className="diet-sample__ingredient__inputs-container">

          {filteredAssociations.length > 1 ? (
            <DefaultInput
              inputClassName="diet-sample__overlay__input"
              name={`ingredient:${ingredient.key}:food_ingredient_source_association_id`}
              isHighlighted={!ingredient.food_ingredient_source_association}
              labelClassName="diet-sample__overlay__input-label"
              label="Processamento:"
              type="select"
              handleInputChange={(event) => this.onSelectIngredientSourceAssociation(ingredient, parseInt(event.target.value))}
              value={ingredient.food_ingredient_source_association ? ingredient.food_ingredient_source_association.id : ''}
              options={this.getProcessingMethodOptions(ingredient)}
            />
          ) : this.state.screenWidth >= 680 ? (<DummyInput />) : null}

          {ingredient.food_ingredient_source_association !== null &&
            <React.Fragment>

              <DefaultInput
                inputClassName="diet-sample__overlay__input"
                name={`ingredient:${ingredient.key}:quantity`}
                isHighlighted={!ingredient.quantity}
                labelClassName="diet-sample__overlay__input-label"
                labelMessage="Apenas números"
                label="Quantidade"
                type="number"
                placeholder="-"
                step="0.01"
                min="0.00"
                handleInputChange={(event) => this.onUpdateIngredientQuantity(ingredient, parseFloat(event.target.value) || null)}
                value={ingredient.quantity || ''}
                autoComplete="off"
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                inputClassName="diet-sample__overlay__input"
                name={`ingredient:${ingredient.key}:food_ingredient_measurement_association_id`}
                isHighlighted={!ingredient.food_ingredient_measurement_association}
                labelClassName="diet-sample__overlay__input-label"
                label="Medida:"
                type="select"
                handleInputChange={(event) => this.onSelectIngredientMeasurementAssociation(ingredient, parseInt(event.target.value))}
                value={ingredient.food_ingredient_measurement_association !== null ? ingredient.food_ingredient_measurement_association.id : ''}
                options={this.getMeasurementOptions(ingredient)}
              />

            </React.Fragment>
          }

        </HalfWrapper>
      );
    }
  }

  getIngredients() {
    return this.state.newRecipeAssociation.basic_ingredient_associations.map((ingredient) => {
      return (
        <div
          className="diet-sample__ingredient"
          key={ingredient.key}
        >

          <p
            className="diet-sample__ingredient__detail"
          >

            <i className="fas fa-carrot"></i>

          </p>

          <div className="diet-sample__ingredient__content-wrapper">

            <p className="diet-sample__ingredient__content"> {ingredient.data.name} </p>

            {this.getIngredientInputs(ingredient)}

          </div>

          <button
            className="diet-sample__delete-ingredient-button"
            onClick={() => this.onRemoveIngredient(ingredient)}
          >

            <i className="far fa-trash-alt"></i>

          </button>

        </div>
      );
    });
  }

  getMealPeriods() {
    return this.state.meal_periods.map((period) => {
      let timePeriod = '';

      if (period.start_time !== null && period.end_time !== null) {
        timePeriod = ` (${period.start_time} - ${period.end_time})`;
      }
      else if (period.start_time !== null && period.end_time === null) {
        timePeriod = ` (a partir de ${period.start_time})`;
      }
      else if (period.start_time === null && period.end_time !== null) {
        timePeriod = ` (até ${period.end_time})`;
      }

      return (
        <React.Fragment key={`meal_period:${period.id}`}>

          <DefaultSubSectionTitle
            className="diet-sample__sub-section-title"
            icon={<i className="fas fa-clock"></i>}
            text={`${period.name}${timePeriod}`}
          />

          {period.description !== null &&
            <p className="diet-sample__meal-period__description">{period.description}</p>
          }

          <div className="diet-sample__meal-period-container">

            {this.getRecipeAssociations(period)}

            <button
              className="diet-sample__add-food-recipe-button"
              onClick={() => this.onEnterNewRecipeAssociation(period)}
            >

              <i className="fas fa-plus diet-sample__add-food-recipe-button__icon"></i>
              Adicionar refeição

            </button>

          </div>

        </React.Fragment>
      );
    });
  }

  getIngredientSelectList() {
    if (this.state.ingredientSelectionText.length <= 2) {
      return null;
    }

    const filterText = this.state.ingredientSelectionText.normalize("NFD").replace(this.accentRegex, "").toLowerCase();

    const filteredIngredients = this.state.food_ingredients.filter((entry) => entry.name.normalize("NFD").replace(this.accentRegex, "").toLowerCase().startsWith(filterText));

    return filteredIngredients;
  }

  ingredientInputsAreValid() {
    if (this.state.newRecipeAssociation === null) {
      return false;
    }

    return this.state.newRecipeAssociation.basic_ingredient_associations.every((entry) => {
             let entryIsValid = entry.quantity !== null && entry.quantity > 0;

             if (entry.isBasic) {
               entryIsValid = entryIsValid &&
                              entry.food_ingredient_source_association != null &&
                              entry.food_ingredient_measurement_association != null;
             }

             return entryIsValid;
           });
  }

  onSetFoodAllergen(allergen, include) {
    let user_allergens = this.state.user_allergens.map((entry) => ({...entry}));

    user_allergens = user_allergens.filter((entry) => entry.id !== allergen.id);

    if (include) {
      user_allergens.push({...allergen});
    }

    this.setState({user_allergens});
  }

  getFoodAllergens() {
    return this.state.food_allergens.map((allergen) => {
      return (
        <DefaultInput
          key={`food_allergen:${allergen.id}`}
          className="diet-sample__food-allergens__input"
          name={`food_allergen:${allergen.id}`}
          labelClassName="diet-sample__food-allergens__input-label"
          label={allergen.name}
          type="toggle"
          activeText="Sim"
          inactiveText="Não"
          neutralInactiveColor={true}
          handleInputChange={(event) => this.onSetFoodAllergen(allergen, event.target.checked)}
          value={this.state.user_allergens.some((entry) => entry.id === allergen.id)}
        />
      );
    });
  }

  render() {
    if(this.state.redirect_to) {
      return (
        <Redirect push to={this.state.redirect_to} />
      );
    }

    return (
      <React.Fragment>

        <Helmet>
          <title>Histórico alimentar - FYD Club</title>
        </Helmet>

        <OverlayWindow
          className="diet-sample__overlay"
          visible={this.state.newRecipeAssociation != null}
          actions={(
            <div className="diet-sample__overlay__action-container">

              <DefaultMenuButton
                className="diet-sample__overlay__action-button"
                onClick={() => this.setState({
                  newRecipeAssociation: null,
                  ingredientSelectionText: ""
                })}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="diet-sample__overlay__action-button"
                onClick={() => this.onApplyRecipeAssociation()}
                text={(this.state.newRecipeAssociation !== null && this.state.newRecipeAssociation.id !== null) ? 'Atualizar' : 'Adicionar'}
                color="green"
                disabled={(this.state.newRecipeAssociation !== null && this.state.newRecipeAssociation.id === null && this.state.newRecipeAssociation.basic_ingredient_associations.length <= 0) || !this.ingredientInputsAreValid()}
              />

            </div>
          )}
        >

          <header className="diet-sample__overlay__header">

            <h3 className="diet-sample__overlay__header__title">
              {`${this.state.newRecipeAssociation !== null ? this.state.newRecipeAssociation.meal_period.name : ''}: adicionar refeição`}
            </h3>

          </header>

          <hr className="diet-sample__horizontal-rule" />

          <div className="diet-sample__overlay__content">

            <DefaultInput
              inputClassName="diet-sample__overlay__input"
              name="ingredientSelectionText"
              labelClassName="diet-sample__overlay__input-label"
              label="Selecione os alimentos:"
              labelMessage="Digite as 3 primeiras letras para visualizar a lista"
              type="text"
              placeholder="Procure pelos alimentos"
              value={this.state.ingredientSelectionText}
              handleInputChange={(event) => this.handleInputChange(event)}
              componentRef={this.setIngredientNameInputRef}
              autoComplete="off"
            />

            <DropdownMenu
              element={this.state.ingredientNameInputRef}
              visible={this.state.ingredientSelectionText.length > 2}
              showWhenSelected={true}
              list={this.getIngredientSelectList()}
              onSelectItem={(ingredient) => this.onAddIngredient(ingredient)}
            />

            <HorizontalRule />

            {(this.state.newRecipeAssociation !== null && this.state.newRecipeAssociation.basic_ingredient_associations.length > 0) &&
              <React.Fragment>

                <DefaultSubSectionTitle
                  icon={<i className="fas fa-carrot"></i>}
                  text="Ingredientes selecionados"
                />

                <div className="diet-sample__ingredients-container">

                  {this.getIngredients()}

                </div>

              </React.Fragment>
            }

          </div>

        </OverlayWindow>

        <MainHeader
          location={this.props.location}
          collapse={true}
        />

        <div className="diet-sample__wrapper">

          <section className="diet-sample">

            <div className="diet-sample__content">

              {this.state.loadingData ?
                <LoadingIcon />:
                <FormContent
                  contentTitle={this.getContentTitle()}
                  contentText={this.getContentText()}
                  questionsId={0}
                  questions={
                    <div className="diet-sample__main-wrapper">

                      <div className="diet-sample__warning-container">

                        <WarningMessage
                          message={this.state.warningMessage}
                          onClose={() => {this.setState({highlights: [], showWarningMessage: false})}}
                          visible={this.state.showWarningMessage}
                        />

                      </div>

                      {this.getMealPeriods()}

                      <DefaultSubSectionTitle
                        className="diet-sample__sub-section-title"
                        icon={<i className="fas fa-exclamation-circle"></i>}
                        text="Observações"
                      />


                      <DefaultInput
                        className="diet-sample__description"
                        inputClassName="diet-sample__description__input"
                        name="description"
                        label="Caso tenha alguma observação importante para nos orientar na montagem de sua dieta, por favor, descreva abaixo. Não deixe também de nos informar os horários que pretende treinar/exercitar. Exemplos: Detesto abobrinha; Almoço no trabalho e tenho pouco tempo para a refeição; Trabalho em turno noturno e preciso jantar bem; Procuro me exercitar por volta das 8:00 da manhã ou 20:00 da noite."
                        type="textarea"
                        placeholder="Digite suas observações importantes para a montagem de sua dieta"
                        rows="3"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.food_prescription.description || ''}
                      />

                      <DefaultSubSectionTitle
                        className="diet-sample__sub-section-title"
                        icon={<i className="fas fa-plus-square"></i>}
                        text="Possui alergia à algum alimento?"
                      />

                      <div className="diet-sample__food-allergens">

                        <h3 className="diet-sample__food-allergens__instructions">
                          Selecione os tipos de alimento que possui alergia:
                        </h3>

                        <div className="diet-sample__food-allergens__list">

                          {this.getFoodAllergens()}

                        </div>

                      </div>

                    </div>
                  }
                  onFinishClick={() => this.onFinishClick()}
                  maxId={0}
                  showControllers={!this.state.apiFailed}
                  allowNext={this.state.food_prescription.food_recipe_associations.length > 0}
                  finishText="Salvar histórico"
                  uploadingText="Salvando..."
                />
              }

            </div>

          </section>

          <MainFooter
            collapse={true}
          />

        </div>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : 'Esta remoção será confirmada somente na atualização de seu histórico ao clicar em "Salvar histórico".'}
          confirmText="Remover"
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.recipeAssociationToRemove !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
          errorIcon={<i className="fas fa-check diet-sample__exam-canceld-icon"></i>}
        />

        <OverlayWindow
          className="diet-sample__overlay--fill-height"
          visible={this.state.showTutorialOverlay}
          actions={(
            <div className="diet-sample__overlay__action-container">

              <DefaultMenuButton
                className="diet-sample__overlay__action-button"
                onClick={() => this.setState({showTutorialOverlay: false})}
                text="Fechar"
                color="black"
              />

            </div>
          )}
        >

          <header className="diet-sample__overlay__header">

            <h3 className="diet-sample__overlay__header__title">
              Vídeo explicativo para preenchimento
            </h3>

          </header>

          <hr className="diet-sample__overlay__horizontal-rule" />

          <div
            ref={this.tutorialOverlayContainerRef}
            className="diet-sample__overlay__reference-container"
          >

            {this.state.tutorialVideoContainerSize !== null &&
              <iframe
                key="diet-sample:tutorial_video"
                className="diet-sample__tutorial-video"
                title="Vídeo explicativo para preenchimento de histórico de alimentação"
                width={this.state.tutorialVideoContainerSize[0]}
                height={this.state.tutorialVideoContainerSize[1]}
                src="https://www.youtube.com/embed/7Sp4sqeG0hs"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen={true}
              ></iframe>
            }

          </div>

        </OverlayWindow>

      </React.Fragment>
    );
  }
}

export default DietSample
