import React from 'react';
import './default_input.scss';

function HalfWrapper(props) {
  return (
    <div className={`half-wrapper ${props.className}`}>

      {props.children}

    </div>
  );
}

export {HalfWrapper};

function SelectOption(value, text=null) {
  return {
    value: value,
    text: text || value,
  };
}

function DummyInput(props) {
  return (
    <div className={`dummy-input ${props.className}`}> </div>
  );
}

export {DummyInput};

export {SelectOption};

function getSelectOptions(options) {
  return options.map((option) => {
    return (
      <option key={`default_input_select_option_${option.value}`} value={option.value}>{option.text}</option>
    );
  });
}

function DefaultInput(props) {
  if(props.type === 'toggle') {
    return (
      <div
        className={`default-input${props.isHorizontal ? '--horizontal': ''} ${props.className || ''}`}
        ref={props.componentRef}
      >

        {props.label ?
          <h4
            className={`default-input__label${props.invertLabel ? '--inverted' : ''} ${props.labelClassName || ''}`}
            htmlFor={`default_input_${props.name}`}
          >
            {props.label}
          </h4> :
          null
        }

        <div className={`default-input__input-wrapper${props.invertLabel ? '--inverted' : ''}`}>

          <label>

            <input
              className="default-input__toggle-input"
              id={`default_input_${props.name}`}
              name={props.name}
              aria-hidden="false"
              type="checkbox"
              checked={props.value}
              onChange={props.handleInputChange}
            />
            <span
              className={`default-input__toggle-visual${props.neutralInactiveColor ? '--inactive-neutral' : ''}`}
              aria-hidden="true"
              inactive-text={props.inactiveText}
              active-text={props.activeText}
              toggle-width="20em"
            >

              <span></span>

            </span>

          </label>

        </div>

      </div>
    );
  }

  return (
    <div
      className={`default-input${props.isHorizontal ? '--horizontal': ''} ${props.className || ''}`}
      ref={props.componentRef}
    >

      {props.label ?
        <label
          className={`default-input__label${props.invertLabel ? '--inverted' : ''} ${props.labelClassName || ''}`}
          htmlFor={`default_input_${props.name}`}
        >
          {props.label}

          {props.labelMessage ?
            <span className="default-input__label__warning-message">
              {props.labelMessage}
            </span>:
            null
          }
        </label> :
        null
      }

      <div className={`default-input__input-wrapper${props.invertLabel ? '--inverted' : ''}`}>

        {props.type === 'textarea' ?
          <textarea
            className={`default-input__text-area-input${props.isHighlighted ? '--highlighted' : ''} ${props.inputClassName || ''}`}
            wrap="soft"
            id={`default_input_${props.name}`}
            name={props.name}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            onChange={props.handleInputChange}
            value={props.value}
            rows={props.rows}
            onKeyDown={props.onKeyDown}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}
          /> :
          props.type === 'select' ?
          <select
            className={`default-input__select-input${props.isHighlighted ? '--highlighted' : ''} ${props.inputClassName || ''}`}
            id={`default_input_${props.name}`}
            name={props.name}
            onChange={props.handleInputChange}
            value={props.value}
            disabled={props.disabled}
          >
            {getSelectOptions(props.options)}
          </select> :
          <input
            className={`default-input__input${props.isHighlighted ? '--highlighted' : ''} ${props.inputClassName || ''}`}
            id={`default_input_${props.name}`}
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            min={props.min}
            max={props.max}
            step={props.step}
            onChange={props.handleInputChange}
            value={props.value}
            autoComplete={props.autoComplete}
            onKeyDown={props.onKeyDown}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}
            checked={props.type === 'checkbox' && props.value}
            list={props.dataListId}
          />
        }
        {(props.dataListId && props.dataListOptions) &&
          <datalist id={props.dataListId}>

            {getSelectOptions(props.dataListOptions)}

          </datalist>
        }

        {props.prefix ?
          <span className="default-input__input-prefix">{props.prefix}</span> :
          null
        }

        {props.suffix ?
          <span className="default-input__input-suffix">{props.suffix}</span> :
          null
        }

      </div>

    </div>
  );
}

export default DefaultInput;
