import React from 'react';
import './date_question.scss';

class DateQuestion extends React.Component {

  render() {
    return (
      <React.Fragment>

        <div className="date-question__wrapper">

          <label
            className="date-question__title"
            htmlFor={this.props.name}
          >
            {this.props.title}
          </label>

          <input
            className="date-question__input"
            id={this.props.name}
            name={this.props.name}
            type="date"
            onChange={this.props.handleInputChange}
            value={this.props.value}
          />

        </div>

      </React.Fragment>
    );
  }
}

export default DateQuestion
