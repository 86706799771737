import posed from 'react-pose';
import './pose_containers.scss';

const FadeContainer = posed.div({
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

export {
  FadeContainer
};

const DelatedFadeContainer = posed.div({
  enter: {
    opacity: 1,
    delay: 150,
    transition: { duration: 300 }
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 }
  }
});

export {
  DelatedFadeContainer
};

const PopUpContainer = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 100,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 }
    }
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 100 }
  }
});

export {
  PopUpContainer
};

const VerticalTwistContainer = posed.div({
  enter: {
    scaleY: 1,
    opacity: 1,
    delay: 100,
  },
  exit: {
    scaleY: 0,
    opacity: 0,
    transition: { duration: 100 }
  }
});

export {
  VerticalTwistContainer
};

const VerticalContainer = posed.div({
  enter: {
    height: 'auto',
    opacity: 1,
  },
  exit: {
    height: 0,
    opacity: 0,
  }
});

export {
  VerticalContainer
};

const HorizontalContainer = posed.div({
  enter: {
    width: 'auto',
    opacity: 1,
  },
  exit: {
    width: 0,
    opacity: 0,
  }
});

export {
  HorizontalContainer
};

const VerticalAccordionContainer = posed.div({
  verticalClosed: {
    height: 0
  },
  verticalOpen: {
    height: 'auto'
  }
});

export {
  VerticalAccordionContainer
};

const HorizontalAccordionContainer = posed.div({
  horizontalClosed: { width: 0 },
  horizontalOpen: { width: 'auto' }
});

export {
  HorizontalAccordionContainer
};

const DiagonalAccordionContainer = posed.div({
  diagonalClosed: {
    width: 0,
    height: 0
  },
  diagonalOpen: {
    width: 'auto' ,
    height: 'auto'
  }
});

export {
  DiagonalAccordionContainer
};
