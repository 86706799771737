import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import LoadingIcon from '../components/loading_icon';
import ChatOverlayButton from '../components/chat_overlay_button';
import YoutubePlayer from '../components/youtube_player';
import './services.scss';
import {RedText} from '../components/default_elements';
import * as routes from '../constants';
import {getModels, getCurrencyText, getLocalDateIsoString} from '../utils/functions';


const DEFAULT_RED_COLOR = '#ff3d53';


class Services extends React.Component {
  constructor(props) {
    super(props);

    this.LOCK_KEY = `PRICE_PAGE:METHODOLOGY_VIDEO_WATCHED`;

    let priceVisibilityControlLocked = true;

    if (window.localStorage) {
      priceVisibilityControlLocked = localStorage.getItem(this.LOCK_KEY) !== 'true';
    }

    const today = getLocalDateIsoString(new Date());

    const promotionStartDate = '2022-09-01';
    // const promotionEndDate = '2021-11-30';

    this.state = {
      enablePromotion: today >= promotionStartDate, //&& today <= promotionEndDate
      showPrices: false,
      priceVisibilityControlLocked,
      priceVisibilityControlHighlighted: false,
      loadingData: true,
      apiFailed: false,
      services: [],
      showTimeRestrictions: null,
      screenWidth: window.innerWidth,
    };

    this.restrictionOrigin = null;

    this.restrictionWindowRef = React.createRef();
    this.restrictionWindowWrapperRef = React.createRef();
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', 'G-8YRPC28XN5', {'page_path': this.props.location.pathname});
    }

    const services = await getModels(routes.SERVICE_PLANS_GET_API);

    if(services === null) {
      this.setState({
        loadingData: false,
        apiFailed: true,
      });

      return;
    }

    this.setState({
      loadingData: false,
      apiFailed: false,
      services: services,
    });

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  hasTimeRestricion(serviceEntry) {
    if(serviceEntry.restriction_map) {
      return Object.values(serviceEntry.restriction_map).some((entry) => !entry.enable_all && entry.time_ids.length > 0);
    }

    return false;
  }

  onDisplayRestriction(event, serviceEntry) {
    this.restrictionOrigin = event.target;
    this.setState({showTimeRestrictions: serviceEntry});
  }

  onCloseRestriction() {
    this.restrictionOrigin = null;
    this.setState({showTimeRestrictions: null});
  }

  getServicePriceText(value, period) {
    const months = Math.ceil(period / 30);
    const price = Math.round(100 * value / months) / 100;

    return getCurrencyText(price);
  }

  getServices() {
    let groupOrderMap = new Map();
    let groupNames = new Set();
    let serviceNames = new Set();
    const entryMap = new Map();
    const comboServices = [];
    const otherServices = [];

    const OTHERS_KEY = 'Outros serviços';
    const COMBO_KEY = 'Combo';

    for(const service of this.state.services) {
      let nameKey;
      let groupOrder = 1;
      let hasGroupName = false;

      if(service.service_group !== null) {
        nameKey = service.service_group.name;
        groupOrder = service.service_group.order;
        hasGroupName = true;
      }
      else if(service.target_services.length <= 0) {
        otherServices.push(service);
        continue;
      }
      else if(service.target_services.length === 1) {
        nameKey = service.target_services[0];
      }
      else {
        comboServices.push(service);
        continue;
      }

      if(hasGroupName) {
        groupNames.add(nameKey);
        groupOrderMap.set(nameKey, groupOrder);
      }
      else {
        serviceNames.add(nameKey);
      }

      if(!entryMap.has(nameKey)) {
        entryMap.set(nameKey, []);
      }

      entryMap.get(nameKey).push(service);
    }

    serviceNames = [...serviceNames];
    serviceNames.sort();
    groupNames = [...groupNames].filter((name => !serviceNames.includes(name)));
    groupNames.sort((a, b) => groupOrderMap.get(a) - groupOrderMap.get(b));

    const response = [];

    if(groupNames.length > 0) {
      for(const name of groupNames) {
        const services = entryMap.get(name);

        services.sort((a, b) => {
          if(a.value !== b.value) {
            const aPrice = a.value / a.period;
            const bPrice = b.value / b.period;

            return aPrice - bPrice;
          }

          return a.name.localeCompare(b.name);
        });

        response.push(
          <article
            className="services__category"
            key={`services:category:${name}`}
          >

            <h2 className="services__category__title">{name}</h2>

            {services[0].service_group.description !== null &&
              <div className="services__group-description">

                <p className="services__group-description__text">
                  {services[0].service_group.description}
                </p>

              </div>
            }

            <ul className="services__category__option-list">

              {services.map((entry) => {
                const months = Math.ceil(entry.period / 30);

                return (
                  <li
                    className="services__category__option"
                    key={`services:option:${entry.id}`}
                  >
                    <div
                      className="services__category__option__background"
                      style={{background: entry.service_group.color || DEFAULT_RED_COLOR}}
                    >
                    </div>

                    <div className={`services__category__option__row${entry.target_services.length > 0 ? '' : '--no-border'}`}>

                      <h3 className="services__category__option__name">{entry.name}</h3>
                      <div className="services__category__option__highlight-container">

                        <div
                          className="services__category__option__highlight-background"
                          style={{background: entry.service_group.color || DEFAULT_RED_COLOR}}
                        >
                        </div>
                        <p className="services__category__option__period">{months} {months > 1 ? 'meses' : 'mês'}</p>
                        <p className="services__category__option__price">
                          {this.getServicePriceText(entry.value, entry.period)} <span className="services__category__option__suffix">/ mês</span>
                        </p>

                      </div>

                    </div>

                    {entry.target_services.length > 0 &&
                      <React.Fragment>

                        {this.hasTimeRestricion(entry) ?
                          <p className="services__category__option__generic-text">
                            Horários restritos
                            <button
                              className="services__category__option__restriction-button"
                              onClick={(event) => this.onDisplayRestriction(event, entry)}
                              style={{color: entry.service_group.color || DEFAULT_RED_COLOR}}
                            >
                              <i className="fas fa-question-circle"></i>
                            </button>
                          </p>:
                          <p className="services__category__option__generic-text">Todos os horários liberados</p>
                        }

                        <p className="services__category__option__generic-text--topic">Serviços inclusos:</p>
                        <div className="services__category__option__combo-items">
                          {entry.target_services.map((name) => (
                            <p
                              className="services__category__option__combo-item"
                              key={`services:option:${entry.id}:${name}`}
                            >
                              {name}
                            </p>
                          ))}
                          {entry.allows_food_prescription &&
                            <p
                              className="services__category__option__combo-item--gold"
                              key={`services:option:${entry.id}:fyd_nutri`}
                            >
                              1 Acompanhamento nutricional
                            </p>
                          }
                        </div>

                      </React.Fragment>
                    }

                  </li>
                );
              })
              }

            </ul>

            {services.length > 0 &&
              <Link
                className="services__category__experimental-link"
                to={routes.EXPERIMENTAL_CLASS_PATH}
                style={{background: services[0].service_group.color || DEFAULT_RED_COLOR}}
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') {
                    window.gtag('event', `${name} - Experimental class button`, {
                      'event_category' : 'Click',
                      'event_label' : `Agende uma aula experimental - ${name}`
                    });
                  }
                }}
              >
                Agende uma aula experimental
              </Link>
            }

          </article>
        );
      }
    }

    if(comboServices.length > 0) {
      comboServices.sort((a, b) => {
        if(a.value !== b.value) {
          const aPrice = a.value / a.period;
          const bPrice = b.value / b.period;

          return aPrice - bPrice;
        }

        return a.name.localeCompare(b.name);
      });

      response.push(
        <article
          className="services__category"
          key="services:category:combo"
        >

          <h2 className="services__category__title">{COMBO_KEY}</h2>

          <ul className="services__category__option-list">

            {comboServices.map((entry) => {
              const months = Math.ceil(entry.period / 30);

              return (
                <li
                  className="services__category__option"
                  key={`services:option:${entry.id}`}
                >

                  <div
                    className="services__category__option__background"
                    style={{background: entry.color || DEFAULT_RED_COLOR}}
                  >
                  </div>

                  <div className="services__category__option__row">

                    <h3 className="services__category__option__name">{entry.name}</h3>
                    <div className="services__category__option__highlight-container">

                      <div
                        className="services__category__option__highlight-background"
                        style={{background: entry.color || DEFAULT_RED_COLOR}}
                      >
                      </div>
                      <p className="services__category__option__period">{months} {months > 1 ? 'meses' : 'mês'}</p>
                      <p className="services__category__option__price">
                        {this.getServicePriceText(entry.value, entry.period)} <span className="services__category__option__suffix">/ mês</span>
                      </p>

                    </div>


                  </div>
                  {this.hasTimeRestricion(entry) ?
                    <p className="services__category__option__generic-text">
                      Horários restritos
                      <button
                        className="services__category__option__restriction-button"
                        onClick={(event) => this.onDisplayRestriction(event, entry)}
                        style={{color: entry.color || DEFAULT_RED_COLOR}}
                      >
                        <i className="fas fa-question-circle"></i>
                      </button>
                    </p>:
                    <p className="services__category__option__generic-text">Todos os horários liberados</p>
                  }
                  <p className="services__category__option__generic-text--topic">Serviços inclusos:</p>
                  <div className="services__category__option__combo-items">
                    {entry.target_services.map((name) => (
                      <p
                        className="services__category__option__combo-item"
                        key={`services:option:${entry.id}:${name}`}
                      >
                        {name}
                      </p>
                    ))}
                    {entry.allows_food_prescription &&
                      <p
                        className="services__category__option__combo-item--gold"
                        key={`services:option:${entry.id}:fyd_nutri`}
                      >
                        1 Acompanhamento nutricional
                      </p>
                    }
                  </div>

                </li>
              );
            })}

          </ul>

          {comboServices.length > 0 &&
            <Link
              className="services__category__experimental-link"
              style={{background: comboServices[0].color || DEFAULT_RED_COLOR}}
              to={routes.EXPERIMENTAL_CLASS_PATH}
              onClick={() => {
                if (process.env.NODE_ENV === 'production') {
                  window.gtag('event', 'Combo - Experimental class button', {
                    'event_category' : 'Click',
                    'event_label' : 'Agende uma aula experimental - Combo'
                  });
                }
              }}
            >
              Agende uma aula experimental
            </Link>
          }

        </article>
      );
    }

    if(serviceNames.length > 0) {
      for(const name of serviceNames) {
        const services = entryMap.get(name);

        services.sort((a, b) => {
          if(a.value !== b.value) {
            const aPrice = a.value / a.period;
            const bPrice = b.value / b.period;

            return aPrice - bPrice;
          }

          return a.name.localeCompare(b.name);
        });

        response.push(
          <article
            className="services__category"
            key={`services:category:${name}`}
          >

            <h2 className="services__category__title">{name}</h2>

            <ul className="services__category__option-list">

              {services.map((entry) => {
                const months = Math.ceil(entry.period / 30);

                return (
                  <li
                    className="services__category__option"
                    key={`services:option:${entry.id}`}
                  >
                    <div
                      className="services__category__option__background"
                      style={{background: entry.color || DEFAULT_RED_COLOR}}
                    >
                    </div>

                    <div className="services__category__option__row">

                      <h3 className="services__category__option__name">{entry.name}</h3>
                      <div className="services__category__option__highlight-container">

                        <div
                          className="services__category__option__highlight-background"
                          style={{background: entry.color || DEFAULT_RED_COLOR}}
                        >
                        </div>
                        <p className="services__category__option__period">{months} {months > 1 ? 'meses' : 'mês'}</p>
                        <p className="services__category__option__price">
                          {this.getServicePriceText(entry.value, entry.period)} <span className="services__category__option__suffix">/ mês</span>
                        </p>

                      </div>

                    </div>

                    {this.hasTimeRestricion(entry) ?
                      <p className="services__category__option__generic-text">
                        Horários restritos
                        <button
                          className="services__category__option__restriction-button"
                          onClick={(event) => this.onDisplayRestriction(event, entry)}
                          style={{color: entry.color || DEFAULT_RED_COLOR}}
                        >
                          <i className="fas fa-question-circle"></i>
                        </button>
                      </p>:
                      <p className="services__category__option__generic-text">Todos os horários liberados</p>
                    }

                  </li>
                );
              })
              }

            </ul>

            {services.length > 0 &&
              <Link
                className="services__category__experimental-link"
                to={`${routes.EXPERIMENTAL_CLASS_PATH}?servico=${name}`}
                style={{background: services[0].color || DEFAULT_RED_COLOR}}
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') {
                    window.gtag('event', `${name} - Experimental class button`, {
                      'event_category' : 'Click',
                      'event_label' : `Agende uma aula experimental - ${name}`
                    });
                  }
                }}
              >
                Agende uma aula experimental
              </Link>
            }

          </article>
        );
      }
    }

    if(otherServices.length > 0) {
      otherServices.sort((a, b) => {
        if(a.value !== b.value) {
          const aPrice = a.value / a.period;
          const bPrice = b.value / b.period;

          return aPrice - bPrice;
        }

        return a.name.localeCompare(b.name);
      });

      response.push(
        <article
          className="services__category"
          key="services:category:others"
        >

          <h2 className="services__category__title">{OTHERS_KEY}</h2>

          <ul className="services__category__option-list">

            {otherServices.map((entry) => {
              const months = Math.ceil(entry.period / 30);

              return (
                <li
                  className="services__category__option"
                  key={`services:option:${entry.id}`}
                >
                  <div
                    className="services__category__option__background"
                    style={{background: entry.color || DEFAULT_RED_COLOR}}
                  >
                  </div>

                  <div className="services__category__option__row--no-border">

                    <h3 className="services__category__option__name">{entry.name}</h3>
                    <div className="services__category__option__highlight-container">

                      <div
                        className="services__category__option__highlight-background"
                        style={{background: entry.color || DEFAULT_RED_COLOR}}
                      >
                      </div>
                      <p className="services__category__option__period">{months} {months > 1 ? 'meses' : 'mês'}</p>
                      <p className="services__category__option__price">
                        {this.getServicePriceText(entry.value, entry.period)} <span className="services__category__option__suffix">/ mês</span>
                      </p>

                    </div>

                  </div>

                </li>
              );
            })}

          </ul>

        </article>
      );
    }

    return response;
  }

  isRestrictionWindowVisible() {
    return !(this.state.showTimeRestrictions === null || !this.restrictionWindowRef.current || !this.restrictionWindowWrapperRef.current || this.restrictionOrigin === null);
  }

  getRestrictionContent() {
    if(!this.isRestrictionWindowVisible()) {
      return null;
    }

    window.requestAnimationFrame(() => {
      const restrictionWindow = this.restrictionWindowRef.current;
      const restrictionWindowWrapper = this.restrictionWindowWrapperRef.current;

      const originRect = this.restrictionOrigin.getBoundingClientRect();
      const contentRect = restrictionWindow.getBoundingClientRect();

      const originPosition = {
        x: (originRect.right + originRect.left)/2,
        y: (originRect.bottom + originRect.top)/2,
      };

      const defaultPosition = {
        x: window.innerWidth/2,
        y: window.innerHeight/2,
      };

      const ANIMATION_PERIOD = 250;

      restrictionWindow.animate([
        {
          transform: `translate(${originPosition.x - defaultPosition.x}px, ${originPosition.y - defaultPosition.y}px) scale(${0.8*originRect.width/contentRect.width})`
        },
        {
          transform: `translate(0, 0)  scale(1)`
        },
      ],
      {
        duration: ANIMATION_PERIOD,
        iterations: 1,
        fill: "both"
      });
      restrictionWindowWrapper.animate([
        {
          opacity: 0
        },
        {
          opacity: 1
        },
      ],
      {
        duration: ANIMATION_PERIOD,
        iterations: 1,
        fill: "both"
      });
    });

    return (
      <React.Fragment>

        <header className="services__time-restrictions__header">

          <h3
            className="services__time-restrictions__header__title"
            style={{color: this.state.showTimeRestrictions.color || DEFAULT_RED_COLOR}}
          >
            {this.state.showTimeRestrictions.name}
          </h3>

          <button
            className="services__time-restrictions__header__close-button"
            style={{background: this.state.showTimeRestrictions.color || DEFAULT_RED_COLOR}}
            onClick={() => this.onCloseRestriction()}
          >
            <i className="fas fa-times"></i>
          </button>

        </header>

        <div className="services__time-restrictions__restrictions-wrapper">

          {this.state.showTimeRestrictions.target_services.map((service) => {
            const restriction_map = this.state.showTimeRestrictions.restriction_map[service];

            if(!restriction_map.enable_all && restriction_map.time_ids.length <= 0) {
              return null
            }

            return (
              <div
                key={`service-plan:${this.state.showTimeRestrictions.id}:${service}:restriction`}
                className="services__time-restrictions__restriction"
              >

                <h4
                  className="services__time-restrictions__restriction__service-title"
                >
                  {service}
                </h4>

                {restriction_map.enable_all ?
                  <p className="services__time-restrictions__restriction__generic-text">Todos os horários liberados</p>:
                  <ul className="services__time-restrictions__restriction__weekday-list">

                    {restriction_map.training_times.map((entry) => {
                      if(entry.times.length <= 0) {
                        return null;
                      }

                      entry.times.sort();

                      return (
                        <li
                          key={`service-plan:${this.state.showTimeRestrictions.id}:${service}:restriction:${entry.weekday}`}
                          className="services__time-restrictions__restriction__weekday"
                        >

                          <p
                            className="services__time-restrictions__restriction__weekday__label"
                            style={{color: this.state.showTimeRestrictions.color || DEFAULT_RED_COLOR}}
                          >
                            {entry.weekday}:
                          </p>
                          <div className="services__time-restrictions__restriction__weekday__times">

                            {entry.times.map((time) => (
                              <p
                                key={`service-plan:${this.state.showTimeRestrictions.id}:${service}:restriction:${entry.weekday}:${time}`}
                                className="services__time-restrictions__restriction__weekday__time"
                                style={{background: this.state.showTimeRestrictions.color || DEFAULT_RED_COLOR}}
                              >
                                {time}
                              </p>
                            ))}

                          </div>

                        </li>
                      );
                    }).filter((entry) => entry !== null)}

                  </ul>
                }

              </div>
            );
          }).filter((entry) => entry !== null)}

        </div>

      </React.Fragment>
    );
  }

  getWarningMessage() {
    let message = 'Não há planos disponíveis no momento. Por favor, entre em contato conosco para mais informações.';

    if(this.state.apiFailed) {
      message = 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.';
    }

    return (
      <div className="services__warning-message">

        <i className="fas fa-exclamation services__warning-message__icon"></i>
        <p className="services__warning-message__text">{message}</p>

      </div>
    );
  }

  getContent() {
    if (!this.state.showPrices) {
      return (
        <div className="services__content">

          <h1 className="services__longer-title">PARA VISUALIZAR {this.state.screenWidth <= 650 ? (<br />) : null}NOSSOS <RedText>PLANOS E PREÇOS</RedText><br/> <RedText>ASSISTA</RedText> AO VÍDEO ABAIXO</h1>

          <YoutubePlayer
            className={`services__video${this.state.priceVisibilityControlHighlighted ? '--hidden' : ''}`}
            videoId="oIqJXr1LTEE"
            onVideoEnd={() => {
              this.setState({
                priceVisibilityControlLocked: false,
                priceVisibilityControlHighlighted: true,
              });
            }}
            onVideoProgress={(currentProgress) => {
              if (currentProgress > 0.925) {
                this.setState({
                  priceVisibilityControlLocked: false
                });
              }
            }}
          />

          <div className={`services__unlock-controls${this.state.priceVisibilityControlLocked ? '--locked' : '--unlocked'}`}>

            <div className="services__unlock-controls__lock-cover">

              <i className="fas fa-lock services__unlock-controls__lock-cover__locked-icon"></i>
              <i className="fas fa-lock-open services__unlock-controls__lock-cover__unlocked-icon"></i>
              <p className="services__unlock-controls__lock-cover__text">Assista ao vídeo até o fim para liberar os botões</p>

            </div>

            <Link
              className="services__unlock-controls__button--silver"
              to={routes.EXPERIMENTAL_CLASS_PATH}
              onClick={() => {
                if (process.env.NODE_ENV === 'production') {
                  window.gtag('event', "Price page - Experimental class button", {
                    'event_category' : 'Click',
                    'event_label' : "Agende uma aula experimental"
                  });
                }

                if (window.localStorage) {
                  localStorage.setItem(this.LOCK_KEY, 'true');
                }
              }}
            >
              <span className="services__unlock-controls__button__text--small">Agendar</span>
              <span className="services__unlock-controls__button__text">aula {this.state.screenWidth <= 500 ? (<br />) : null}experimental</span>
            </Link>

            <button
              className="services__unlock-controls__button--gold"
              onClick={() => {
                if (process.env.NODE_ENV === 'production') {
                  window.gtag('event', "Price page - Unlock content button", {
                    'event_category' : 'Click',
                    'event_label' : "Ver planos e preços"
                  });
                }

                if (!this.state.priceVisibilityControlLocked) {
                  this.setState({showPrices: true});

                  if (window.localStorage) {
                    localStorage.setItem(this.LOCK_KEY, 'true');
                  }
                }
              }}
            >
              <span className="services__unlock-controls__button__text--small">Ver</span>
              <span className="services__unlock-controls__button__text">planos e {this.state.screenWidth <= 500 ? (<br />) : null}preços</span>
            </button>

          </div>

        </div>
      );
    }

    return (
      <div className="services__content">

        <h1 className="services__title">Planos e preços <RedText>FYD CLUB</RedText></h1>

        <div className="services__header-message">

          <i className="fas fa-star services__header-message__icon"></i>
          <div className="services__header-message__text-wrapper">
            <p className="services__header-message__text">
              Como parte de nossa metodologia, todos os nossos planos incluem <strong>avaliações físicas periódicas</strong>.
            </p>
          </div>

        </div>

        {this.state.loadingData ?
          <LoadingIcon />:
          (this.state.apiFailed || this.state.services.length <= 0) ?
            this.getWarningMessage():
            <div className="services__entries-container">

              {this.getServices()}

            </div>
        }

      </div>
    );
  }

  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>Preços e planos principais - FYD Club</title>
          <meta name="description" content="Encontre o plano ideal que se encaixe com seu perfil." />
        </Helmet>

        <MainHeader
          location={this.props.location}
          enablePromotion={this.state.enablePromotion}
          invertPromotionColors={true}
        />

        <div className="services__wrapper">

          <section className={`services${this.state.enablePromotion ? '--promotion-enalbed' : ''}`}>

            {this.getContent()}

          </section>

          <MainFooter />

        </div>

        <aside
          ref={this.restrictionWindowWrapperRef}
          className={`services__time-restrictions${this.isRestrictionWindowVisible() ? '' : '--hidden'}`}
        >

          <div
            ref={this.restrictionWindowRef}
            className="services__time-restrictions__content"
          >

            {this.getRestrictionContent()}

          </div>

        </aside>

        <ChatOverlayButton />

      </React.Fragment>
    );
  }
}

export default Services
