import React from 'react';
import {Helmet} from "react-helmet";
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import ChatOverlayButton from '../components/chat_overlay_button';
import LoadingIcon from '../components/loading_icon';
import './training_hours.scss';
import {RedText} from '../components/default_elements';
import * as routes from '../constants';
import {getModels, getLocalDateIsoString} from '../utils/functions';


class TrainingHours extends React.Component {
  constructor(props) {
    super(props);

    const today = getLocalDateIsoString(new Date());

    const promotionStartDate = '2022-09-01';
    // const promotionEndDate = '2021-11-30';

    this.state = {
      enablePromotion: today >= promotionStartDate, //&& today <= promotionEndDate
      loadingData: true,
      apiFailed: false,
      services: [],
      training_time_map: {},
      class_duration_map: {},
      screenWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', 'G-8YRPC28XN5', {'page_path': this.props.location.pathname});
    }

    const training_times_data = await getModels(routes.TRAINING_TIMES_GET_API);

    if(training_times_data === null) {
      this.setState({
        loadingData: false,
        apiFailed: true,
      });

      return;
    }

    training_times_data.services.sort();

    this.setState({
      loadingData: false,
      apiFailed: false,
      training_time_map: training_times_data.training_time_map,
      class_duration_map: training_times_data.class_duration_map,
      services: training_times_data.services,
    });

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getWarningMessage() {
    let message = 'Horário de treinos ainda não foi definido. Por favor, entre em contato conosco para mais informações.';

    if(this.state.apiFailed) {
      message = 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.';
    }

    return (
      <div className="training-hours__warning-message">

        <i className="fas fa-exclamation training-hours__warning-message__icon"></i>
        <p className="training-hours__warning-message__text">{message}</p>

      </div>
    );
  }

  getServiceHours() {
    const output = [];

    const DAY_ID_ORDER = [0, 1, 2, 3, 4, 5, 6];

    for(const service of this.state.services) {
      let timeRange = null;
      const dayMap = new Map();
      const weekdayMap = new Map();
      const classDuration = (this.state.class_duration_map[service] || 60) / 60;

      for(const entry of this.state.training_time_map[service]) {
        if(!dayMap.has(entry.day_id)) {
          dayMap.set(entry.day_id, []);
          weekdayMap.set(entry.day_id, entry.weekday);
        }

        if(timeRange === null) {
          timeRange = [entry.time, entry.time]
        }
        else if(entry.time > timeRange[1]) {
          timeRange[1] = entry.time;
        }
        else if(entry.time < timeRange[0]) {
          timeRange[0] = entry.time;
        }

        dayMap.get(entry.day_id).push(entry);
      }

      const parsedRange = [
        parseFloat(timeRange[0].slice(0, 2)),
        parseFloat(timeRange[1].slice(0, 2)) + Math.ceil((parseFloat(timeRange[1].slice(3, 5)) / 60) + classDuration)
      ];

      const HOUR_STEP = parsedRange[1] - parsedRange[0] > 12 ? 2 : 1;
      const hourLabels = [];
      const verticalBars = [];

      const maxStep = ((parsedRange[1] - parsedRange[0]) / HOUR_STEP);

      // const widthReference = window.innerWidth * 0.7;
      // const hourSize = widthReference / (parsedRange[1] - parsedRange[0]);

      let hourSize = 3.5; //unit: em
      if(classDuration*hourSize < hourSize) {
        hourSize = 3.5 / classDuration;
      }

      let horizontalSize = 0;

      for(let i=0; i<=maxStep; ++i) {
        let style = {};
        let width = 3.5;

        if(i < maxStep) {
          width = hourSize*HOUR_STEP;
          style.width = `${width}em`;
        }

        horizontalSize += width;

        hourLabels.push(
          <div
            key={`training_hours:hour_label:${i}`}
            className="training-hours__service-entry__hour-label"
            style={style}
          >

            <p className="training-hours__service-entry__hour-label__text">
              {`${(parsedRange[0] + (HOUR_STEP*i)).toString().padStart(2, '0')}:00`}
            </p>

          </div>
        );

        verticalBars.push(
          <div
            key={`training_hours:vertical_bar:${i}`}
            className="training-hours__service-entry__vertical-division"
            style={{left: `${(i*HOUR_STEP)*hourSize}em`,}}
          >
          </div>
        );
      };

      const dayRows = [];

      for(const day_id of DAY_ID_ORDER) {
        if(dayMap.has(day_id)) {
          dayRows.push(
            <div
              key={`training_hours:service_entry:${service}:day_row:${day_id}`}
              className="training-hours__service-entry__day-row"
              style={{width: `${horizontalSize + 3.5}em`}}
            >

              <div className="training-hours__service-entry__day-row__title">

                <h4 className="training-hours__service-entry__day-row__title__text">

                  {weekdayMap.get(day_id)}

                </h4>

              </div>

              <div className="training-hours__service-entry__day-row__days-container">

                {dayMap.get(day_id).map((entry) => {
                  const parsedHour = parseInt(entry.time.slice(0, 2));
                  const parsedMinutes = parseInt(entry.time.slice(3, 5));

                  return (
                    (
                      <div
                        key={`training_hours:time:${entry.id}`}
                        className="training-hours__service-entry__time"
                        style={{
                          left: `${((parsedHour + (parsedMinutes/60)) - parsedRange[0])*hourSize}em`,
                          width: `${hourSize*classDuration}em`
                        }}
                      >

                        <p className="training-hours__service-entry__time__text">
                          {`${parsedHour}:${entry.time.slice(3, 5)}`}
                        </p>

                      </div>
                    )
                  );
                })}

              </div>

            </div>
          );
        }
      }

      output.push(
        <section
          className="training-hours__service-entry"
          key={`training_hours:service_entry:${service}`}
        >

          <h2 className="training-hours__service-entry__title">{service}</h2>

          <article className="training-hours__service-entry__info-container">

            <div className="training-hours__service-entry__header-container">

              <div className="training-hours__service-entry__left-padding"></div>

              <div className="training-hours__service-entry__header-wrapper">

                {hourLabels}

              </div>

            </div>

            <div>

              {dayRows}

            </div>


            <div className="training-hours__service-entry__division-container">

              {verticalBars}

            </div>

          </article>

        </section>
      );
    }

    return output;
  }

  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>Grade de horário de serviços - FYD Club</title>
          <meta name="description" content="Aqui seu treino é com hora marcada e sem superlotação." />
        </Helmet>

        <MainHeader
          location={this.props.location}
          enablePromotion={this.state.enablePromotion}
          invertPromotionColors={true}
        />

        <div className="training-hours__wrapper">

          <section className={`training-hours${this.state.enablePromotion ? '--promotion-enalbed' : ''}`}>

            <div className="training-hours__content">

              <h1 className="training-hours__title">Horários dos <RedText>TREINOS</RedText></h1>

              {this.state.loadingData ?
                <LoadingIcon />:
                (this.state.apiFailed || this.state.services.length <= 0) ?
                  this.getWarningMessage():
                  <div
                    className="training-hours__entries-container"
                  >

                    {this.getServiceHours()}

                  </div>
              }

            </div>

          </section>

          <MainFooter />

        </div>

        <ChatOverlayButton />

      </React.Fragment>
    );
  }
}

export default TrainingHours
