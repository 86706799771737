import React from 'react';
import {Helmet} from "react-helmet";
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import ChatOverlayButton from '../components/chat_overlay_button';
import { Link } from 'react-router-dom';
import {RedText} from '../components/default_elements';
// import { getLocalDateIsoString } from '../utils/functions';
import Promotion from '../components/promotion';
import PhotoGallery, {ImageOption, GalleryResponsiveImage} from '../components/photo_gallery';
import './main_page.scss';

import mainBannerExtraLarge from '../images/fyd_panoramic_extra_large.jpg';
import mainBannerExtraLarge2x from '../images/fyd_panoramic_extra_large_2x.jpg';
import mainBannerLarge from '../images/fyd_panoramic_cropped_large.jpg';
import mainBannerLarge2x from '../images/fyd_panoramic_cropped_large_2x.jpg';
import mainBannerMedium from '../images/fyd_panoramic_cropped_medium.jpg';
import mainBannerMedium2x from '../images/fyd_panoramic_cropped_medium_2x.jpg';
import mainBannerSmall from '../images/fyd_panoramic_cropped_small.jpg';
import mainBannerSmall2x from '../images/fyd_panoramic_cropped_small_2x.jpg';
import mainBannerVerySmall from '../images/fyd_panoramic_cropped_very_small.jpg';
import mainBannerVerySmall2x from '../images/fyd_panoramic_cropped_very_small_2x.jpg';
import mainBannerTiny from '../images/fyd_panoramic_cropped_tiny.jpg';
import mainBannerTiny2x from '../images/fyd_panoramic_cropped_tiny_2x.jpg';
import seriousWomanExtraLarge from '../images/serious_woman_extra_large.png';
import seriousWomanExtraLarge2x from '../images/serious_woman_extra_large_2x.png';
import seriousWomanLarge from '../images/serious_woman_large.png';
import seriousWomanLarge2x from '../images/serious_woman_large_2x.png';
import seriousWomanMedium from '../images/serious_woman_medium.png';
import seriousWomanMedium2x from '../images/serious_woman_medium_2x.png';
import seriousWomanHalvedSmall from '../images/serious_woman_halved_small.png';
import seriousWomanHalvedSmall2x from '../images/serious_woman_halved_small_2x.png';
import seriousWomanHalvedVerySmall from '../images/serious_woman_halved_very_small.png';
import seriousWomanHalvedVerySmall2x from '../images/serious_woman_halved_very_small_2x.png';
import seriousWomanHalvedTiny from '../images/serious_woman_halved_tiny.png';
import seriousWomanHalvedTiny2x from '../images/serious_woman_halved_tiny_2x.png';
import bannerArrow from '../images/banner_arrow.png';
// import decorativeLine1 from '../images/decorative_lines_1.svg';
// import decorativeLine2 from '../images/decorative_lines_2.svg';

import locationBackground_940 from '../images/location_background_940.png';
import locationBackground_850 from '../images/location_background_850.png';
import locationBackground_760 from '../images/location_background_760.png';
import locationBackground_720 from '../images/location_background_720.png';
import locationBackground_530 from '../images/location_background_530.png';
import locationBackground_460 from '../images/location_background_460.png';

import hex_710 from '../images/banner_hex_710.png';
import hex_600 from '../images/banner_hex_600.png';
import hex_460 from '../images/banner_hex_460.png';
import hex_380 from '../images/banner_hex_380.png';

import locationMask_1920 from '../images/location_mask_1920.png';
import locationMask_1700 from '../images/location_mask_1700.png';
import locationMask_1550 from '../images/location_mask_1550.png';
import locationMask_1320 from '../images/location_mask_1320.png';
import locationMask_1200 from '../images/location_mask_1200.png';
import locationMask_1080 from '../images/location_mask_1080.png';
import locationMask_970 from '../images/location_mask_970.png';

import locationMask2_870 from '../images/location_mask2_870.png';
import locationMask2_780 from '../images/location_mask2_780.png';
import locationMask2_600 from '../images/location_mask2_600.png';
import locationMask2_470 from '../images/location_mask2_470.png';
import locationMask2_380 from '../images/location_mask2_380.png';

import articleCover1_694 from '../images/blog/article_1_cover_694.jpg';
import articleCover1_380 from '../images/blog/article_1_cover_380.jpg';
import articleCover1_330 from '../images/blog/article_1_cover_330.jpg';
import articleCover1_330_2x from '../images/blog/article_1_cover_330_2x.jpg';
import articleCover1_290 from '../images/blog/article_1_cover_290.jpg';
import articleCover1_290_2x from '../images/blog/article_1_cover_290_2x.jpg';
import articleCover1_210 from '../images/blog/article_1_cover_210.jpg';
import articleCover1_210_2x from '../images/blog/article_1_cover_210_2x.jpg';

import articleCover2_694 from '../images/blog/article_2_cover_694.jpg';
import articleCover2_380 from '../images/blog/article_2_cover_380.jpg';
import articleCover2_330 from '../images/blog/article_2_cover_330.jpg';
import articleCover2_330_2x from '../images/blog/article_2_cover_330_2x.jpg';
import articleCover2_290 from '../images/blog/article_2_cover_290.jpg';
import articleCover2_290_2x from '../images/blog/article_2_cover_290_2x.jpg';
import articleCover2_210 from '../images/blog/article_2_cover_210.jpg';
import articleCover2_210_2x from '../images/blog/article_2_cover_210_2x.jpg';

import articleCover3_694 from '../images/blog/article_3_cover_694.jpg';
import articleCover3_380 from '../images/blog/article_3_cover_380.jpg';
import articleCover3_330 from '../images/blog/article_3_cover_330.jpg';
import articleCover3_330_2x from '../images/blog/article_3_cover_330_2x.jpg';
import articleCover3_290 from '../images/blog/article_3_cover_290.jpg';
import articleCover3_290_2x from '../images/blog/article_3_cover_290_2x.jpg';
import articleCover3_210 from '../images/blog/article_3_cover_210.jpg';
import articleCover3_210_2x from '../images/blog/article_3_cover_210_2x.jpg';

// import featureFuncional from '../images/feature_funcional.png';
// import featurePilates from '../images/feature_pilates.png';
// import featureAvaliacao from '../images/feature_avaliacao.png';
// import featurePeriodizacao from '../images/feature_periodizacao.png';
// import featureTecnologia from '../images/feature_tecnologia.png';
// import featureHomeTraining from '../images/feature_home_training.png';

import sao_carlos_sp_h_1_380 from '../images/gallery/sao_carlos_sp/horizontal/1_380.jpg';
import sao_carlos_sp_h_1_330 from '../images/gallery/sao_carlos_sp/horizontal/1_330.jpg';
import sao_carlos_sp_h_1_280 from '../images/gallery/sao_carlos_sp/horizontal/1_280.jpg';
import sao_carlos_sp_h_1_200 from '../images/gallery/sao_carlos_sp/horizontal/1_200.jpg';
import sao_carlos_sp_h_1_170 from '../images/gallery/sao_carlos_sp/horizontal/1_170.jpg';
import sao_carlos_sp_h_1_380_2x from '../images/gallery/sao_carlos_sp/horizontal/1_380_2x.jpg';
import sao_carlos_sp_h_1_330_2x from '../images/gallery/sao_carlos_sp/horizontal/1_330_2x.jpg';
import sao_carlos_sp_h_1_280_2x from '../images/gallery/sao_carlos_sp/horizontal/1_280_2x.jpg';
import sao_carlos_sp_h_1_200_2x from '../images/gallery/sao_carlos_sp/horizontal/1_200_2x.jpg';
import sao_carlos_sp_h_1_170_2x from '../images/gallery/sao_carlos_sp/horizontal/1_170_2x.jpg';
import sao_carlos_sp_h_1_800 from '../images/gallery/sao_carlos_sp/horizontal/1_800.jpg';
import sao_carlos_sp_h_1_1000 from '../images/gallery/sao_carlos_sp/horizontal/1_1000.jpg';
import sao_carlos_sp_h_1_1200 from '../images/gallery/sao_carlos_sp/horizontal/1_1200.jpg';
import sao_carlos_sp_h_1_1400 from '../images/gallery/sao_carlos_sp/horizontal/1_1400.jpg';

import sao_carlos_sp_h_2_380 from '../images/gallery/sao_carlos_sp/horizontal/2_380.jpg';
import sao_carlos_sp_h_2_330 from '../images/gallery/sao_carlos_sp/horizontal/2_330.jpg';
import sao_carlos_sp_h_2_280 from '../images/gallery/sao_carlos_sp/horizontal/2_280.jpg';
import sao_carlos_sp_h_2_200 from '../images/gallery/sao_carlos_sp/horizontal/2_200.jpg';
import sao_carlos_sp_h_2_170 from '../images/gallery/sao_carlos_sp/horizontal/2_170.jpg';
import sao_carlos_sp_h_2_380_2x from '../images/gallery/sao_carlos_sp/horizontal/2_380_2x.jpg';
import sao_carlos_sp_h_2_330_2x from '../images/gallery/sao_carlos_sp/horizontal/2_330_2x.jpg';
import sao_carlos_sp_h_2_280_2x from '../images/gallery/sao_carlos_sp/horizontal/2_280_2x.jpg';
import sao_carlos_sp_h_2_200_2x from '../images/gallery/sao_carlos_sp/horizontal/2_200_2x.jpg';
import sao_carlos_sp_h_2_170_2x from '../images/gallery/sao_carlos_sp/horizontal/2_170_2x.jpg';
import sao_carlos_sp_h_2_800 from '../images/gallery/sao_carlos_sp/horizontal/2_800.jpg';
import sao_carlos_sp_h_2_1000 from '../images/gallery/sao_carlos_sp/horizontal/2_1000.jpg';
import sao_carlos_sp_h_2_1200 from '../images/gallery/sao_carlos_sp/horizontal/2_1200.jpg';
import sao_carlos_sp_h_2_1400 from '../images/gallery/sao_carlos_sp/horizontal/2_1400.jpg';

import sao_carlos_sp_h_3_380 from '../images/gallery/sao_carlos_sp/horizontal/3_380.jpg';
import sao_carlos_sp_h_3_330 from '../images/gallery/sao_carlos_sp/horizontal/3_330.jpg';
import sao_carlos_sp_h_3_280 from '../images/gallery/sao_carlos_sp/horizontal/3_280.jpg';
import sao_carlos_sp_h_3_200 from '../images/gallery/sao_carlos_sp/horizontal/3_200.jpg';
import sao_carlos_sp_h_3_170 from '../images/gallery/sao_carlos_sp/horizontal/3_170.jpg';
import sao_carlos_sp_h_3_380_2x from '../images/gallery/sao_carlos_sp/horizontal/3_380_2x.jpg';
import sao_carlos_sp_h_3_330_2x from '../images/gallery/sao_carlos_sp/horizontal/3_330_2x.jpg';
import sao_carlos_sp_h_3_280_2x from '../images/gallery/sao_carlos_sp/horizontal/3_280_2x.jpg';
import sao_carlos_sp_h_3_200_2x from '../images/gallery/sao_carlos_sp/horizontal/3_200_2x.jpg';
import sao_carlos_sp_h_3_170_2x from '../images/gallery/sao_carlos_sp/horizontal/3_170_2x.jpg';
import sao_carlos_sp_h_3_800 from '../images/gallery/sao_carlos_sp/horizontal/3_800.jpg';
import sao_carlos_sp_h_3_1000 from '../images/gallery/sao_carlos_sp/horizontal/3_1000.jpg';
import sao_carlos_sp_h_3_1200 from '../images/gallery/sao_carlos_sp/horizontal/3_1200.jpg';
import sao_carlos_sp_h_3_1400 from '../images/gallery/sao_carlos_sp/horizontal/3_1400.jpg';

// import sao_carlos_sp_h_4_380 from '../images/gallery/sao_carlos_sp/horizontal/4_380.jpg';
// import sao_carlos_sp_h_4_330 from '../images/gallery/sao_carlos_sp/horizontal/4_330.jpg';
// import sao_carlos_sp_h_4_280 from '../images/gallery/sao_carlos_sp/horizontal/4_280.jpg';
// import sao_carlos_sp_h_4_200 from '../images/gallery/sao_carlos_sp/horizontal/4_200.jpg';
// import sao_carlos_sp_h_4_170 from '../images/gallery/sao_carlos_sp/horizontal/4_170.jpg';
// import sao_carlos_sp_h_4_380_2x from '../images/gallery/sao_carlos_sp/horizontal/4_380_2x.jpg';
// import sao_carlos_sp_h_4_330_2x from '../images/gallery/sao_carlos_sp/horizontal/4_330_2x.jpg';
// import sao_carlos_sp_h_4_280_2x from '../images/gallery/sao_carlos_sp/horizontal/4_280_2x.jpg';
// import sao_carlos_sp_h_4_200_2x from '../images/gallery/sao_carlos_sp/horizontal/4_200_2x.jpg';
// import sao_carlos_sp_h_4_170_2x from '../images/gallery/sao_carlos_sp/horizontal/4_170_2x.jpg';
import sao_carlos_sp_h_4_380 from '../images/gallery/sao_carlos_sp/horizontal/4_380.jpg';
import sao_carlos_sp_h_4_330 from '../images/gallery/sao_carlos_sp/horizontal/4_330.jpg';
import sao_carlos_sp_h_4_280 from '../images/gallery/sao_carlos_sp/horizontal/4_280.jpg';
import sao_carlos_sp_h_4_200 from '../images/gallery/sao_carlos_sp/horizontal/4_200.jpg';
import sao_carlos_sp_h_4_170 from '../images/gallery/sao_carlos_sp/horizontal/4_170.jpg';
import sao_carlos_sp_h_4_380_2x from '../images/gallery/sao_carlos_sp/horizontal/4_380_2x.jpg';
import sao_carlos_sp_h_4_330_2x from '../images/gallery/sao_carlos_sp/horizontal/4_330_2x.jpg';
import sao_carlos_sp_h_4_280_2x from '../images/gallery/sao_carlos_sp/horizontal/4_280_2x.jpg';
import sao_carlos_sp_h_4_200_2x from '../images/gallery/sao_carlos_sp/horizontal/4_200_2x.jpg';
import sao_carlos_sp_h_4_170_2x from '../images/gallery/sao_carlos_sp/horizontal/4_170_2x.jpg';
import sao_carlos_sp_h_4_800 from '../images/gallery/sao_carlos_sp/horizontal/4_800.jpg';
import sao_carlos_sp_h_4_1000 from '../images/gallery/sao_carlos_sp/horizontal/4_1000.jpg';
import sao_carlos_sp_h_4_1200 from '../images/gallery/sao_carlos_sp/horizontal/4_1200.jpg';
import sao_carlos_sp_h_4_1400 from '../images/gallery/sao_carlos_sp/horizontal/4_1400.jpg';

import sao_carlos_sp_h_5_380 from '../images/gallery/sao_carlos_sp/horizontal/5_380.jpg';
import sao_carlos_sp_h_5_380_2x from '../images/gallery/sao_carlos_sp/horizontal/5_380_2x.jpg';
import sao_carlos_sp_h_5_330 from '../images/gallery/sao_carlos_sp/horizontal/5_330.jpg';
import sao_carlos_sp_h_5_330_2x from '../images/gallery/sao_carlos_sp/horizontal/5_330_2x.jpg';
import sao_carlos_sp_h_5_280 from '../images/gallery/sao_carlos_sp/horizontal/5_280.jpg';
import sao_carlos_sp_h_5_280_2x from '../images/gallery/sao_carlos_sp/horizontal/5_280_2x.jpg';
import sao_carlos_sp_h_5_280_4x from '../images/gallery/sao_carlos_sp/horizontal/5_280_4x.jpg';
import sao_carlos_sp_h_5_200 from '../images/gallery/sao_carlos_sp/horizontal/5_200.jpg';
import sao_carlos_sp_h_5_200_2x from '../images/gallery/sao_carlos_sp/horizontal/5_200_2x.jpg';
import sao_carlos_sp_h_5_200_4x from '../images/gallery/sao_carlos_sp/horizontal/5_200_4x.jpg';
import sao_carlos_sp_h_5_340 from '../images/gallery/sao_carlos_sp/horizontal/5_340.jpg';
import sao_carlos_sp_h_5_340_2x from '../images/gallery/sao_carlos_sp/horizontal/5_340_2x.jpg';
import sao_carlos_sp_h_5_1200 from '../images/gallery/sao_carlos_sp/horizontal/5_1200.jpg';
import sao_carlos_sp_h_5_1400 from '../images/gallery/sao_carlos_sp/horizontal/5_1400.jpg';

import sao_carlos_sp_h_6_380 from '../images/gallery/sao_carlos_sp/horizontal/6_380.jpg';
import sao_carlos_sp_h_6_330 from '../images/gallery/sao_carlos_sp/horizontal/6_330.jpg';
import sao_carlos_sp_h_6_280 from '../images/gallery/sao_carlos_sp/horizontal/6_280.jpg';
import sao_carlos_sp_h_6_200 from '../images/gallery/sao_carlos_sp/horizontal/6_200.jpg';
import sao_carlos_sp_h_6_170 from '../images/gallery/sao_carlos_sp/horizontal/6_170.jpg';
import sao_carlos_sp_h_6_380_2x from '../images/gallery/sao_carlos_sp/horizontal/6_380_2x.jpg';
import sao_carlos_sp_h_6_330_2x from '../images/gallery/sao_carlos_sp/horizontal/6_330_2x.jpg';
import sao_carlos_sp_h_6_280_2x from '../images/gallery/sao_carlos_sp/horizontal/6_280_2x.jpg';
import sao_carlos_sp_h_6_200_2x from '../images/gallery/sao_carlos_sp/horizontal/6_200_2x.jpg';
import sao_carlos_sp_h_6_170_2x from '../images/gallery/sao_carlos_sp/horizontal/6_170_2x.jpg';
import sao_carlos_sp_h_6_800 from '../images/gallery/sao_carlos_sp/horizontal/6_800.jpg';
import sao_carlos_sp_h_6_1000 from '../images/gallery/sao_carlos_sp/horizontal/6_1000.jpg';
import sao_carlos_sp_h_6_1200 from '../images/gallery/sao_carlos_sp/horizontal/6_1200.jpg';
import sao_carlos_sp_h_6_1400 from '../images/gallery/sao_carlos_sp/horizontal/6_1400.jpg';

import sao_carlos_sp_h_7_380 from '../images/gallery/sao_carlos_sp/horizontal/7_380.jpg';
import sao_carlos_sp_h_7_330 from '../images/gallery/sao_carlos_sp/horizontal/7_330.jpg';
import sao_carlos_sp_h_7_280 from '../images/gallery/sao_carlos_sp/horizontal/7_280.jpg';
import sao_carlos_sp_h_7_200 from '../images/gallery/sao_carlos_sp/horizontal/7_200.jpg';
import sao_carlos_sp_h_7_170 from '../images/gallery/sao_carlos_sp/horizontal/7_170.jpg';
import sao_carlos_sp_h_7_380_2x from '../images/gallery/sao_carlos_sp/horizontal/7_380_2x.jpg';
import sao_carlos_sp_h_7_330_2x from '../images/gallery/sao_carlos_sp/horizontal/7_330_2x.jpg';
import sao_carlos_sp_h_7_280_2x from '../images/gallery/sao_carlos_sp/horizontal/7_280_2x.jpg';
import sao_carlos_sp_h_7_200_2x from '../images/gallery/sao_carlos_sp/horizontal/7_200_2x.jpg';
import sao_carlos_sp_h_7_170_2x from '../images/gallery/sao_carlos_sp/horizontal/7_170_2x.jpg';
import sao_carlos_sp_h_7_800 from '../images/gallery/sao_carlos_sp/horizontal/7_800.jpg';
import sao_carlos_sp_h_7_1000 from '../images/gallery/sao_carlos_sp/horizontal/7_1000.jpg';
import sao_carlos_sp_h_7_1200 from '../images/gallery/sao_carlos_sp/horizontal/7_1200.jpg';
import sao_carlos_sp_h_7_1400 from '../images/gallery/sao_carlos_sp/horizontal/7_1400.jpg';

import sao_carlos_sp_h_8_380 from '../images/gallery/sao_carlos_sp/horizontal/8_380.jpg';
import sao_carlos_sp_h_8_380_2x from '../images/gallery/sao_carlos_sp/horizontal/8_380_2x.jpg';
import sao_carlos_sp_h_8_330 from '../images/gallery/sao_carlos_sp/horizontal/8_330.jpg';
import sao_carlos_sp_h_8_330_2x from '../images/gallery/sao_carlos_sp/horizontal/8_330_2x.jpg';
import sao_carlos_sp_h_8_280 from '../images/gallery/sao_carlos_sp/horizontal/8_280.jpg';
import sao_carlos_sp_h_8_280_2x from '../images/gallery/sao_carlos_sp/horizontal/8_280_2x.jpg';
import sao_carlos_sp_h_8_280_4x from '../images/gallery/sao_carlos_sp/horizontal/5_280_4x.jpg';
import sao_carlos_sp_h_8_200 from '../images/gallery/sao_carlos_sp/horizontal/8_200.jpg';
import sao_carlos_sp_h_8_200_2x from '../images/gallery/sao_carlos_sp/horizontal/8_200_2x.jpg';
import sao_carlos_sp_h_8_200_4x from '../images/gallery/sao_carlos_sp/horizontal/8_200_4x.jpg';
import sao_carlos_sp_h_8_340 from '../images/gallery/sao_carlos_sp/horizontal/8_340.jpg';
import sao_carlos_sp_h_8_340_2x from '../images/gallery/sao_carlos_sp/horizontal/8_340_2x.jpg';
import sao_carlos_sp_h_8_1200 from '../images/gallery/sao_carlos_sp/horizontal/8_1200.jpg';
import sao_carlos_sp_h_8_1400 from '../images/gallery/sao_carlos_sp/horizontal/8_1400.jpg';

import sao_carlos_sp_h_9_380 from '../images/gallery/sao_carlos_sp/horizontal/9_380.jpg';
import sao_carlos_sp_h_9_330 from '../images/gallery/sao_carlos_sp/horizontal/9_330.jpg';
import sao_carlos_sp_h_9_280 from '../images/gallery/sao_carlos_sp/horizontal/9_280.jpg';
import sao_carlos_sp_h_9_200 from '../images/gallery/sao_carlos_sp/horizontal/9_200.jpg';
import sao_carlos_sp_h_9_170 from '../images/gallery/sao_carlos_sp/horizontal/9_170.jpg';
import sao_carlos_sp_h_9_380_2x from '../images/gallery/sao_carlos_sp/horizontal/9_380_2x.jpg';
import sao_carlos_sp_h_9_330_2x from '../images/gallery/sao_carlos_sp/horizontal/9_330_2x.jpg';
import sao_carlos_sp_h_9_280_2x from '../images/gallery/sao_carlos_sp/horizontal/9_280_2x.jpg';
import sao_carlos_sp_h_9_200_2x from '../images/gallery/sao_carlos_sp/horizontal/9_200_2x.jpg';
import sao_carlos_sp_h_9_170_2x from '../images/gallery/sao_carlos_sp/horizontal/9_170_2x.jpg';
import sao_carlos_sp_h_9_800 from '../images/gallery/sao_carlos_sp/horizontal/9_800.jpg';
import sao_carlos_sp_h_9_1000 from '../images/gallery/sao_carlos_sp/horizontal/9_1000.jpg';
import sao_carlos_sp_h_9_1200 from '../images/gallery/sao_carlos_sp/horizontal/9_1200.jpg';
import sao_carlos_sp_h_9_1400 from '../images/gallery/sao_carlos_sp/horizontal/9_1400.jpg';

import sao_carlos_sp_h_10_380 from '../images/gallery/sao_carlos_sp/horizontal/10_380.jpg';
import sao_carlos_sp_h_10_330 from '../images/gallery/sao_carlos_sp/horizontal/10_330.jpg';
import sao_carlos_sp_h_10_280 from '../images/gallery/sao_carlos_sp/horizontal/10_280.jpg';
import sao_carlos_sp_h_10_200 from '../images/gallery/sao_carlos_sp/horizontal/10_200.jpg';
import sao_carlos_sp_h_10_170 from '../images/gallery/sao_carlos_sp/horizontal/10_170.jpg';
import sao_carlos_sp_h_10_380_2x from '../images/gallery/sao_carlos_sp/horizontal/10_380_2x.jpg';
import sao_carlos_sp_h_10_330_2x from '../images/gallery/sao_carlos_sp/horizontal/10_330_2x.jpg';
import sao_carlos_sp_h_10_280_2x from '../images/gallery/sao_carlos_sp/horizontal/10_280_2x.jpg';
import sao_carlos_sp_h_10_200_2x from '../images/gallery/sao_carlos_sp/horizontal/10_200_2x.jpg';
import sao_carlos_sp_h_10_170_2x from '../images/gallery/sao_carlos_sp/horizontal/10_170_2x.jpg';
import sao_carlos_sp_h_10_800 from '../images/gallery/sao_carlos_sp/horizontal/10_800.jpg';
import sao_carlos_sp_h_10_1000 from '../images/gallery/sao_carlos_sp/horizontal/10_1000.jpg';
import sao_carlos_sp_h_10_1200 from '../images/gallery/sao_carlos_sp/horizontal/10_1200.jpg';
import sao_carlos_sp_h_10_1400 from '../images/gallery/sao_carlos_sp/horizontal/10_1400.jpg';

// import sao_carlos_sp_h_6 from '../images/gallery/sao_carlos_sp/horizontal/6_large.jpg';
// import sao_carlos_sp_h_7 from '../images/gallery/sao_carlos_sp/horizontal/7_large.jpg';
// import sao_carlos_sp_h_8 from '../images/gallery/sao_carlos_sp/horizontal/8_large.jpg';
// import sao_carlos_sp_h_9 from '../images/gallery/sao_carlos_sp/horizontal/9_large.jpg';
// import sao_carlos_sp_h_10 from '../images/gallery/sao_carlos_sp/horizontal/10_large.jpg';
// import sao_carlos_sp_h_11 from '../images/gallery/sao_carlos_sp/horizontal/11_large.jpg';

import sao_carlos_sp_v_1_380 from '../images/gallery/sao_carlos_sp/vertical/1_380.jpg';
import sao_carlos_sp_v_1_330 from '../images/gallery/sao_carlos_sp/vertical/1_330.jpg';
import sao_carlos_sp_v_1_280 from '../images/gallery/sao_carlos_sp/vertical/1_280.jpg';
import sao_carlos_sp_v_1_200 from '../images/gallery/sao_carlos_sp/vertical/1_200.jpg';
import sao_carlos_sp_v_1_170 from '../images/gallery/sao_carlos_sp/vertical/1_170.jpg';
import sao_carlos_sp_v_1_380_2x from '../images/gallery/sao_carlos_sp/vertical/1_380_2x.jpg';
import sao_carlos_sp_v_1_330_2x from '../images/gallery/sao_carlos_sp/vertical/1_330_2x.jpg';
import sao_carlos_sp_v_1_280_2x from '../images/gallery/sao_carlos_sp/vertical/1_280_2x.jpg';
import sao_carlos_sp_v_1_200_2x from '../images/gallery/sao_carlos_sp/vertical/1_200_2x.jpg';
import sao_carlos_sp_v_1_170_2x from '../images/gallery/sao_carlos_sp/vertical/1_170_2x.jpg';

import sao_carlos_sp_v_2_380 from '../images/gallery/sao_carlos_sp/vertical/2_380.jpg';
import sao_carlos_sp_v_2_330 from '../images/gallery/sao_carlos_sp/vertical/2_330.jpg';
import sao_carlos_sp_v_2_280 from '../images/gallery/sao_carlos_sp/vertical/2_280.jpg';
import sao_carlos_sp_v_2_200 from '../images/gallery/sao_carlos_sp/vertical/2_200.jpg';
import sao_carlos_sp_v_2_170 from '../images/gallery/sao_carlos_sp/vertical/2_170.jpg';
import sao_carlos_sp_v_2_380_2x from '../images/gallery/sao_carlos_sp/vertical/2_380_2x.jpg';
import sao_carlos_sp_v_2_330_2x from '../images/gallery/sao_carlos_sp/vertical/2_330_2x.jpg';
import sao_carlos_sp_v_2_280_2x from '../images/gallery/sao_carlos_sp/vertical/2_280_2x.jpg';
import sao_carlos_sp_v_2_200_2x from '../images/gallery/sao_carlos_sp/vertical/2_200_2x.jpg';
import sao_carlos_sp_v_2_170_2x from '../images/gallery/sao_carlos_sp/vertical/2_170_2x.jpg';

import sao_carlos_sp_v_3_380 from '../images/gallery/sao_carlos_sp/vertical/3_380.jpg';
import sao_carlos_sp_v_3_330 from '../images/gallery/sao_carlos_sp/vertical/3_330.jpg';
import sao_carlos_sp_v_3_280 from '../images/gallery/sao_carlos_sp/vertical/3_280.jpg';
import sao_carlos_sp_v_3_200 from '../images/gallery/sao_carlos_sp/vertical/3_200.jpg';
import sao_carlos_sp_v_3_170 from '../images/gallery/sao_carlos_sp/vertical/3_170.jpg';
import sao_carlos_sp_v_3_380_2x from '../images/gallery/sao_carlos_sp/vertical/3_380_2x.jpg';
import sao_carlos_sp_v_3_330_2x from '../images/gallery/sao_carlos_sp/vertical/3_330_2x.jpg';
import sao_carlos_sp_v_3_280_2x from '../images/gallery/sao_carlos_sp/vertical/3_280_2x.jpg';
import sao_carlos_sp_v_3_200_2x from '../images/gallery/sao_carlos_sp/vertical/3_200_2x.jpg';
import sao_carlos_sp_v_3_170_2x from '../images/gallery/sao_carlos_sp/vertical/3_170_2x.jpg';

import sao_carlos_sp_v_4_380 from '../images/gallery/sao_carlos_sp/vertical/4_380.jpg';
import sao_carlos_sp_v_4_330 from '../images/gallery/sao_carlos_sp/vertical/4_330.jpg';
import sao_carlos_sp_v_4_280 from '../images/gallery/sao_carlos_sp/vertical/4_280.jpg';
import sao_carlos_sp_v_4_200 from '../images/gallery/sao_carlos_sp/vertical/4_200.jpg';
import sao_carlos_sp_v_4_170 from '../images/gallery/sao_carlos_sp/vertical/4_170.jpg';
import sao_carlos_sp_v_4_380_2x from '../images/gallery/sao_carlos_sp/vertical/4_380_2x.jpg';
import sao_carlos_sp_v_4_330_2x from '../images/gallery/sao_carlos_sp/vertical/4_330_2x.jpg';
import sao_carlos_sp_v_4_280_2x from '../images/gallery/sao_carlos_sp/vertical/4_280_2x.jpg';
import sao_carlos_sp_v_4_200_2x from '../images/gallery/sao_carlos_sp/vertical/4_200_2x.jpg';
import sao_carlos_sp_v_4_170_2x from '../images/gallery/sao_carlos_sp/vertical/4_170_2x.jpg';

// import sao_carlos_sp_v_3 from '../images/gallery/sao_carlos_sp/vertical/3_large.jpg';
// import sao_carlos_sp_v_4 from '../images/gallery/sao_carlos_sp/vertical/4_large.jpg';
// import sao_carlos_sp_v_5 from '../images/gallery/sao_carlos_sp/vertical/5_large.jpg';
// import sao_carlos_sp_v_6 from '../images/gallery/sao_carlos_sp/vertical/6_large.jpg';
// import sao_carlos_sp_v_7 from '../images/gallery/sao_carlos_sp/vertical/7_large.jpg';

// import hexagon from '../images/hexagon.svg';
import hexagonRed from '../images/hexagon_red.svg';

import slim_300 from '../images/slim_300.png';
import slim_280 from '../images/slim_280.png';
import slim_260 from '../images/slim_260.png';
import slim_230 from '../images/slim_230.png';
import slim_300_2x from '../images/slim_300_2x.png';
import slim_280_2x from '../images/slim_280_2x.png';
import slim_260_2x from '../images/slim_260_2x.png';
import slim_230_2x from '../images/slim_230_2x.png';
import shape_432 from '../images/shape_432.png';
import shape_408 from '../images/shape_408.png';
import shape_380 from '../images/shape_380.png';
import shape_340 from '../images/shape_340.png';
import shape_432_2x from '../images/shape_432_2x.png';
import shape_408_2x from '../images/shape_408_2x.png';
import shape_380_2x from '../images/shape_380_2x.png';
import shape_340_2x from '../images/shape_340_2x.png';
import performance_432 from '../images/performance_432.png';
import performance_408 from '../images/performance_408.png';
import performance_380 from '../images/performance_380.png';
import performance_340 from '../images/performance_340.png';
import performance_432_2x from '../images/performance_432_2x.png';
import performance_408_2x from '../images/performance_408_2x.png';
import performance_380_2x from '../images/performance_380_2x.png';
import performance_340_2x from '../images/performance_340_2x.png';
import life_300 from '../images/life_300.png';
import life_280 from '../images/life_280.png';
import life_260 from '../images/life_260.png';
import life_230 from '../images/life_230.png';
import life_300_2x from '../images/life_300_2x.png';
import life_280_2x from '../images/life_280_2x.png';
import life_260_2x from '../images/life_260_2x.png';
import life_230_2x from '../images/life_230_2x.png';

import general_720 from '../images/general_720.jpg';
import general_660 from '../images/general_660.jpg';
import general_520 from '../images/general_520.jpg';
import general_460 from '../images/general_460.jpg';
import general_380 from '../images/general_380.jpg';
import general_310 from '../images/general_310.jpg';
import general_280 from '../images/general_280.jpg';
import general_720_2x from '../images/general_720_2x.jpg';
import general_660_2x from '../images/general_660_2x.jpg';
import general_520_2x from '../images/general_520_2x.jpg';
import general_460_2x from '../images/general_460_2x.jpg';
import general_380_2x from '../images/general_380_2x.jpg';
import general_310_2x from '../images/general_310_2x.jpg';
import general_280_2x from '../images/general_280_2x.jpg';
import fyd_bike_720 from '../images/fyd_bike_720.jpg';
import fyd_bike_660 from '../images/fyd_bike_660.jpg';
import fyd_bike_520 from '../images/fyd_bike_520.jpg';
import fyd_bike_460 from '../images/fyd_bike_460.jpg';
import fyd_bike_380 from '../images/fyd_bike_380.jpg';
import fyd_bike_310 from '../images/fyd_bike_310.jpg';
import fyd_bike_280 from '../images/fyd_bike_280.jpg';
import fyd_bike_720_2x from '../images/fyd_bike_720_2x.jpg';
import fyd_bike_660_2x from '../images/fyd_bike_660_2x.jpg';
import fyd_bike_520_2x from '../images/fyd_bike_520_2x.jpg';
import fyd_bike_460_2x from '../images/fyd_bike_460_2x.jpg';
import fyd_bike_380_2x from '../images/fyd_bike_380_2x.jpg';
import fyd_bike_310_2x from '../images/fyd_bike_310_2x.jpg';
import fyd_bike_280_2x from '../images/fyd_bike_280_2x.jpg';
import pilates_720 from '../images/pilates_720.jpg';
import pilates_660 from '../images/pilates_660.jpg';
import pilates_520 from '../images/pilates_520.jpg';
import pilates_460 from '../images/pilates_460.jpg';
import pilates_380 from '../images/pilates_380.jpg';
import pilates_310 from '../images/pilates_310.jpg';
import pilates_280 from '../images/pilates_280.jpg';
import pilates_720_2x from '../images/pilates_720_2x.jpg';
import pilates_660_2x from '../images/pilates_660_2x.jpg';
import pilates_520_2x from '../images/pilates_520_2x.jpg';
import pilates_460_2x from '../images/pilates_460_2x.jpg';
import pilates_380_2x from '../images/pilates_380_2x.jpg';
import pilates_310_2x from '../images/pilates_310_2x.jpg';
import pilates_280_2x from '../images/pilates_280_2x.jpg';
import functional_training_720 from '../images/functional_training_720.jpg';
import functional_training_660 from '../images/functional_training_660.jpg';
import functional_training_520 from '../images/functional_training_520.jpg';
import functional_training_460 from '../images/functional_training_460.jpg';
import functional_training_380 from '../images/functional_training_380.jpg';
import functional_training_310 from '../images/functional_training_310.jpg';
import functional_training_280 from '../images/functional_training_280.jpg';
import functional_training_720_2x from '../images/functional_training_720_2x.jpg';
import functional_training_660_2x from '../images/functional_training_660_2x.jpg';
import functional_training_520_2x from '../images/functional_training_520_2x.jpg';
import functional_training_460_2x from '../images/functional_training_460_2x.jpg';
import functional_training_380_2x from '../images/functional_training_380_2x.jpg';
import functional_training_310_2x from '../images/functional_training_310_2x.jpg';
import functional_training_280_2x from '../images/functional_training_280_2x.jpg';
import bootcamp_720 from '../images/bootcamp_720.jpg';
import bootcamp_660 from '../images/bootcamp_660.jpg';
import bootcamp_520 from '../images/bootcamp_520.jpg';
import bootcamp_460 from '../images/bootcamp_460.jpg';
import bootcamp_380 from '../images/bootcamp_380.jpg';
import bootcamp_310 from '../images/bootcamp_310.jpg';
import bootcamp_280 from '../images/bootcamp_280.jpg';
import bootcamp_720_2x from '../images/bootcamp_720_2x.jpg';
import bootcamp_660_2x from '../images/bootcamp_660_2x.jpg';
import bootcamp_520_2x from '../images/bootcamp_520_2x.jpg';
import bootcamp_460_2x from '../images/bootcamp_460_2x.jpg';
import bootcamp_380_2x from '../images/bootcamp_380_2x.jpg';
import bootcamp_310_2x from '../images/bootcamp_310_2x.jpg';
import bootcamp_280_2x from '../images/bootcamp_280_2x.jpg';
import gym_720 from '../images/gym_720.jpg';
import gym_660 from '../images/gym_660.jpg';
import gym_520 from '../images/gym_520.jpg';
import gym_460 from '../images/gym_460.jpg';
import gym_380 from '../images/gym_380.jpg';
import gym_310 from '../images/gym_310.jpg';
import gym_280 from '../images/gym_280.jpg';
import gym_720_2x from '../images/gym_720_2x.jpg';
import gym_660_2x from '../images/gym_660_2x.jpg';
import gym_520_2x from '../images/gym_520_2x.jpg';
import gym_460_2x from '../images/gym_460_2x.jpg';
import gym_380_2x from '../images/gym_380_2x.jpg';
import gym_310_2x from '../images/gym_310_2x.jpg';
import gym_280_2x from '../images/gym_280_2x.jpg';

import follow_up_150 from '../images/follow_up_150.jpg';
import follow_up_125 from '../images/follow_up_125.jpg';
import follow_up_110 from '../images/follow_up_110.jpg';
import follow_up_70 from '../images/follow_up_70.jpg';
import follow_up_150_2x from '../images/follow_up_150_2x.jpg';
import follow_up_125_2x from '../images/follow_up_125_2x.jpg';
import follow_up_110_2x from '../images/follow_up_110_2x.jpg';
import follow_up_70_2x from '../images/follow_up_70_2x.jpg';
import physical_evaluation_150 from '../images/physical_evaluation_150.jpg';
import physical_evaluation_125 from '../images/physical_evaluation_125.jpg';
import physical_evaluation_110 from '../images/physical_evaluation_110.jpg';
import physical_evaluation_70 from '../images/physical_evaluation_70.jpg';
import physical_evaluation_150_2x from '../images/physical_evaluation_150_2x.jpg';
import physical_evaluation_125_2x from '../images/physical_evaluation_125_2x.jpg';
import physical_evaluation_110_2x from '../images/physical_evaluation_110_2x.jpg';
import physical_evaluation_70_2x from '../images/physical_evaluation_70_2x.jpg';
import prescription_150 from '../images/prescription_150.jpg';
import prescription_125 from '../images/prescription_125.jpg';
import prescription_110 from '../images/prescription_110.jpg';
import prescription_70 from '../images/prescription_70.jpg';
import prescription_150_2x from '../images/prescription_150_2x.jpg';
import prescription_125_2x from '../images/prescription_125_2x.jpg';
import prescription_110_2x from '../images/prescription_110_2x.jpg';
import prescription_70_2x from '../images/prescription_70_2x.jpg';

import * as routes from '../constants';

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    // const today = getLocalDateIsoString(new Date());

    // const promotionStartDate = '2022-09-01';
    // const promotionEndDate = '2022-09-30';

    this.state = {
      enablePromotion: false, //today >= promotionStartDate && today <= promotionEndDate
      citySelected: 'São Carlos - SP',
      serviceDescriptionSelected: null,
      serviceDescription: null,
      galleryIsVisible: false,
      currentGalleryIndex: 1,
      screenWidth: window.innerWidth,
    };

    this.currentServiceDescriptionImage = null;
    this.lastServiceDescriptionImage = null;

    this.galleryItems = [
      new GalleryResponsiveImage(
        ImageOption(sao_carlos_sp_v_1_380_2x, 760),
        [
          ImageOption(sao_carlos_sp_v_1_380, 380),
          ImageOption(sao_carlos_sp_v_1_380_2x, 760),
          ImageOption(sao_carlos_sp_v_1_330, 330),
          ImageOption(sao_carlos_sp_v_1_330_2x, 660),
          ImageOption(sao_carlos_sp_v_1_280, 280),
          ImageOption(sao_carlos_sp_v_1_280_2x, 560),
          ImageOption(sao_carlos_sp_v_1_200, 200),
          ImageOption(sao_carlos_sp_v_1_200_2x, 400),
          ImageOption(sao_carlos_sp_v_1_170, 170),
          ImageOption(sao_carlos_sp_v_1_170_2x, 340)
        ],
        'Treino de Cross FYD'),
      new GalleryResponsiveImage(
        ImageOption(sao_carlos_sp_h_1_1400, 1400),
        [
          ImageOption(sao_carlos_sp_h_1_380, 380),
          ImageOption(sao_carlos_sp_h_1_380_2x, 760),
          ImageOption(sao_carlos_sp_h_1_330, 330),
          ImageOption(sao_carlos_sp_h_1_330_2x, 660),
          ImageOption(sao_carlos_sp_h_1_280, 280),
          ImageOption(sao_carlos_sp_h_1_280_2x, 560),
          ImageOption(sao_carlos_sp_h_1_200, 200),
          ImageOption(sao_carlos_sp_h_1_200_2x, 400),
          ImageOption(sao_carlos_sp_h_1_170, 170),
          ImageOption(sao_carlos_sp_h_1_170_2x, 340),
          ImageOption(sao_carlos_sp_h_1_800, 800),
          ImageOption(sao_carlos_sp_h_1_1000, 1000),
          ImageOption(sao_carlos_sp_h_1_1200, 1200),
          ImageOption(sao_carlos_sp_h_1_1400, 1400),
        ],
        'Sala de musculação - Frente'),
      new GalleryResponsiveImage(
        ImageOption(sao_carlos_sp_h_2_1400, 1400),
        [
          ImageOption(sao_carlos_sp_h_2_380, 380),
          ImageOption(sao_carlos_sp_h_2_380_2x, 760),
          ImageOption(sao_carlos_sp_h_2_330, 330),
          ImageOption(sao_carlos_sp_h_2_330_2x, 660),
          ImageOption(sao_carlos_sp_h_2_280, 280),
          ImageOption(sao_carlos_sp_h_2_280_2x, 560),
          ImageOption(sao_carlos_sp_h_2_200, 200),
          ImageOption(sao_carlos_sp_h_2_200_2x, 400),
          ImageOption(sao_carlos_sp_h_2_170, 170),
          ImageOption(sao_carlos_sp_h_2_170_2x, 340),
          ImageOption(sao_carlos_sp_h_2_800, 800),
          ImageOption(sao_carlos_sp_h_2_1000, 1000),
          ImageOption(sao_carlos_sp_h_2_1200, 1200),
          ImageOption(sao_carlos_sp_h_2_1400, 1400),
        ],
        'Sala de musculação - Fundo'),
      new GalleryResponsiveImage(
        ImageOption(sao_carlos_sp_h_5_1400, 1400),
        [
          ImageOption(sao_carlos_sp_h_5_380, 380),
          ImageOption(sao_carlos_sp_h_5_380_2x, 760),
          ImageOption(sao_carlos_sp_h_5_330, 330),
          ImageOption(sao_carlos_sp_h_5_330_2x, 660),
          ImageOption(sao_carlos_sp_h_5_280, 280),
          ImageOption(sao_carlos_sp_h_5_280_2x, 560),
          ImageOption(sao_carlos_sp_h_5_280_4x, 1120),
          ImageOption(sao_carlos_sp_h_5_200, 200),
          ImageOption(sao_carlos_sp_h_5_200_2x, 400),
          ImageOption(sao_carlos_sp_h_5_200_4x, 800),
          ImageOption(sao_carlos_sp_h_5_340, 340),
          ImageOption(sao_carlos_sp_h_5_340_2x, 680),
          ImageOption(sao_carlos_sp_h_5_1200, 1200),
          ImageOption(sao_carlos_sp_h_5_1400, 1400),
        ],
        'Treino de FYD Run Bootcamp'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_v_2_380_2x, 760),
          [
            ImageOption(sao_carlos_sp_v_2_380, 380),
            ImageOption(sao_carlos_sp_v_2_380_2x, 760),
            ImageOption(sao_carlos_sp_v_2_330, 330),
            ImageOption(sao_carlos_sp_v_2_330_2x, 660),
            ImageOption(sao_carlos_sp_v_2_280, 280),
            ImageOption(sao_carlos_sp_v_2_280_2x, 560),
            ImageOption(sao_carlos_sp_v_2_200, 200),
            ImageOption(sao_carlos_sp_v_2_200_2x, 400),
            ImageOption(sao_carlos_sp_v_2_170, 170),
            ImageOption(sao_carlos_sp_v_2_170_2x, 340)
          ],
          'Alunas guerreiras do FYD Run Bootcamp'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_h_4_1400, 1400),
          [
            ImageOption(sao_carlos_sp_h_4_380, 380),
            ImageOption(sao_carlos_sp_h_4_380_2x, 760),
            ImageOption(sao_carlos_sp_h_4_330, 330),
            ImageOption(sao_carlos_sp_h_4_330_2x, 660),
            ImageOption(sao_carlos_sp_h_4_280, 280),
            ImageOption(sao_carlos_sp_h_4_280_2x, 560),
            ImageOption(sao_carlos_sp_h_4_200, 200),
            ImageOption(sao_carlos_sp_h_4_200_2x, 400),
            ImageOption(sao_carlos_sp_h_4_170, 170),
            ImageOption(sao_carlos_sp_h_4_170_2x, 340),
            ImageOption(sao_carlos_sp_h_4_800, 800),
            ImageOption(sao_carlos_sp_h_4_1000, 1000),
            ImageOption(sao_carlos_sp_h_4_1200, 1200),
            ImageOption(sao_carlos_sp_h_4_1400, 1400),
          ],
          'Treino de FYD Bike / Spinning'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_h_3_1400, 1400),
          [
            ImageOption(sao_carlos_sp_h_3_380, 380),
            ImageOption(sao_carlos_sp_h_3_380_2x, 760),
            ImageOption(sao_carlos_sp_h_3_330, 330),
            ImageOption(sao_carlos_sp_h_3_330_2x, 660),
            ImageOption(sao_carlos_sp_h_3_280, 280),
            ImageOption(sao_carlos_sp_h_3_280_2x, 560),
            ImageOption(sao_carlos_sp_h_3_200, 200),
            ImageOption(sao_carlos_sp_h_3_200_2x, 400),
            ImageOption(sao_carlos_sp_h_3_170, 170),
            ImageOption(sao_carlos_sp_h_3_170_2x, 340),
            ImageOption(sao_carlos_sp_h_3_800, 800),
            ImageOption(sao_carlos_sp_h_3_1000, 1000),
            ImageOption(sao_carlos_sp_h_3_1200, 1200),
            ImageOption(sao_carlos_sp_h_3_1400, 1400),
          ],
          'Foto de alunos e professores da FYD'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_v_3_380_2x, 760),
          [
            ImageOption(sao_carlos_sp_v_3_380, 380),
            ImageOption(sao_carlos_sp_v_3_380_2x, 760),
            ImageOption(sao_carlos_sp_v_3_330, 330),
            ImageOption(sao_carlos_sp_v_3_330_2x, 660),
            ImageOption(sao_carlos_sp_v_3_280, 280),
            ImageOption(sao_carlos_sp_v_3_280_2x, 560),
            ImageOption(sao_carlos_sp_v_3_200, 200),
            ImageOption(sao_carlos_sp_v_3_200_2x, 400),
            ImageOption(sao_carlos_sp_v_3_170, 170),
            ImageOption(sao_carlos_sp_v_3_170_2x, 340)
          ],
          'Sala de FYD Bike / Spinning'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_h_6_1400, 1400),
          [
            ImageOption(sao_carlos_sp_h_6_380, 380),
            ImageOption(sao_carlos_sp_h_6_380_2x, 760),
            ImageOption(sao_carlos_sp_h_6_330, 330),
            ImageOption(sao_carlos_sp_h_6_330_2x, 660),
            ImageOption(sao_carlos_sp_h_6_280, 280),
            ImageOption(sao_carlos_sp_h_6_280_2x, 560),
            ImageOption(sao_carlos_sp_h_6_200, 200),
            ImageOption(sao_carlos_sp_h_6_200_2x, 400),
            ImageOption(sao_carlos_sp_h_6_170, 170),
            ImageOption(sao_carlos_sp_h_6_170_2x, 340),
            ImageOption(sao_carlos_sp_h_6_800, 800),
            ImageOption(sao_carlos_sp_h_6_1000, 1000),
            ImageOption(sao_carlos_sp_h_6_1200, 1200),
            ImageOption(sao_carlos_sp_h_6_1400, 1400),
          ],
          'Sala de Pilates'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_h_7_1400, 1400),
          [
            ImageOption(sao_carlos_sp_h_7_380, 380),
            ImageOption(sao_carlos_sp_h_7_380_2x, 760),
            ImageOption(sao_carlos_sp_h_7_330, 330),
            ImageOption(sao_carlos_sp_h_7_330_2x, 660),
            ImageOption(sao_carlos_sp_h_7_280, 280),
            ImageOption(sao_carlos_sp_h_7_280_2x, 560),
            ImageOption(sao_carlos_sp_h_7_200, 200),
            ImageOption(sao_carlos_sp_h_7_200_2x, 400),
            ImageOption(sao_carlos_sp_h_7_170, 170),
            ImageOption(sao_carlos_sp_h_7_170_2x, 340),
            ImageOption(sao_carlos_sp_h_7_800, 800),
            ImageOption(sao_carlos_sp_h_7_1000, 1000),
            ImageOption(sao_carlos_sp_h_7_1200, 1200),
            ImageOption(sao_carlos_sp_h_7_1400, 1400),
          ],
          'Professor demonstrando utilização de aparelho de musculação'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_h_8_1400, 1400),
          [
            ImageOption(sao_carlos_sp_h_8_380, 380),
            ImageOption(sao_carlos_sp_h_8_380_2x, 760),
            ImageOption(sao_carlos_sp_h_8_330, 330),
            ImageOption(sao_carlos_sp_h_8_330_2x, 660),
            ImageOption(sao_carlos_sp_h_8_280, 280),
            ImageOption(sao_carlos_sp_h_8_280_2x, 560),
            ImageOption(sao_carlos_sp_h_8_280_4x, 1120),
            ImageOption(sao_carlos_sp_h_8_200, 200),
            ImageOption(sao_carlos_sp_h_8_200_2x, 400),
            ImageOption(sao_carlos_sp_h_8_200_4x, 800),
            ImageOption(sao_carlos_sp_h_8_340, 340),
            ImageOption(sao_carlos_sp_h_8_340_2x, 680),
            ImageOption(sao_carlos_sp_h_8_1200, 1200),
            ImageOption(sao_carlos_sp_h_8_1400, 1400),
          ],
          'Sala de musculação - Meio'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_v_4_380_2x, 760),
          [
            ImageOption(sao_carlos_sp_v_4_380, 380),
            ImageOption(sao_carlos_sp_v_4_380_2x, 760),
            ImageOption(sao_carlos_sp_v_4_330, 330),
            ImageOption(sao_carlos_sp_v_4_330_2x, 660),
            ImageOption(sao_carlos_sp_v_4_280, 280),
            ImageOption(sao_carlos_sp_v_4_280_2x, 560),
            ImageOption(sao_carlos_sp_v_4_200, 200),
            ImageOption(sao_carlos_sp_v_4_200_2x, 400),
            ImageOption(sao_carlos_sp_v_4_170, 170),
            ImageOption(sao_carlos_sp_v_4_170_2x, 340)
          ],
          'Aluna durante competição do Cross FYD'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_h_9_1400, 1400),
          [
            ImageOption(sao_carlos_sp_h_9_380, 380),
            ImageOption(sao_carlos_sp_h_9_380_2x, 760),
            ImageOption(sao_carlos_sp_h_9_330, 330),
            ImageOption(sao_carlos_sp_h_9_330_2x, 660),
            ImageOption(sao_carlos_sp_h_9_280, 280),
            ImageOption(sao_carlos_sp_h_9_280_2x, 560),
            ImageOption(sao_carlos_sp_h_9_200, 200),
            ImageOption(sao_carlos_sp_h_9_200_2x, 400),
            ImageOption(sao_carlos_sp_h_9_170, 170),
            ImageOption(sao_carlos_sp_h_9_170_2x, 340),
            ImageOption(sao_carlos_sp_h_9_800, 800),
            ImageOption(sao_carlos_sp_h_9_1000, 1000),
            ImageOption(sao_carlos_sp_h_9_1200, 1200),
            ImageOption(sao_carlos_sp_h_9_1400, 1400),
          ],
          'Aluno durante competição do Cross FYD'),
        new GalleryResponsiveImage(
          ImageOption(sao_carlos_sp_h_10_1400, 1400),
          [
            ImageOption(sao_carlos_sp_h_10_380, 380),
            ImageOption(sao_carlos_sp_h_10_380_2x, 760),
            ImageOption(sao_carlos_sp_h_10_330, 330),
            ImageOption(sao_carlos_sp_h_10_330_2x, 660),
            ImageOption(sao_carlos_sp_h_10_280, 280),
            ImageOption(sao_carlos_sp_h_10_280_2x, 560),
            ImageOption(sao_carlos_sp_h_10_200, 200),
            ImageOption(sao_carlos_sp_h_10_200_2x, 400),
            ImageOption(sao_carlos_sp_h_10_170, 170),
            ImageOption(sao_carlos_sp_h_10_170_2x, 340),
            ImageOption(sao_carlos_sp_h_10_800, 800),
            ImageOption(sao_carlos_sp_h_10_1000, 1000),
            ImageOption(sao_carlos_sp_h_10_1200, 1200),
            ImageOption(sao_carlos_sp_h_10_1400, 1400),
          ],
          'Sala de FYD Run Bootcamp')
    ];
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', 'G-8YRPC28XN5', {'page_path': this.props.location.pathname});
    }

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  // getVideoSize() {
  //   if(this.state.screenWidth <= 470) {
  //     return [this.state.screenWidth, (this.state.screenWidth)*0.5625];
  //   }
  //   else if(this.state.screenWidth <= 600) {
  //     return [this.state.screenWidth - 60, (this.state.screenWidth - 60)*0.5625];
  //   }
  //   else if(this.state.screenWidth <= 870) {
  //     return [500, 290];
  //   }
  //   else if(this.state.screenWidth <= 970) {
  //     return [600, 350];
  //   }
  //   else if(this.state.screenWidth <= 1080) {
  //     return [430, 310];
  //   }
  //   else if(this.state.screenWidth <= 1200) {
  //     return [480, 310];
  //   }
  //   else if(this.state.screenWidth <= 1320) {
  //     return [500, 350];
  //   }
  //   else if(this.state.screenWidth <= 1550) {
  //     return [550, 350];
  //   }
  //
  //
  //   return [550, 400];
  // }

  getGalleryCityOptions() {
    const options = [
      'São Carlos - SP',
      // 'Araraquara - SP'
    ];

    return options.map((option, position) => (
      <li
        key={`main_page:city_options:${position}`}
        className="main-page__gallery-section__city-item"
      >

        <button
          className={`main-page__gallery-section__city-button${this.state.citySelected === option ? '--selected' : ''}`}
          onClick={() => this.setState({citySelected: option})}
        >

          {option}

        </button>

      </li>
    ));
  }

  getGalleryPhotos() {
    return [];
  }

  onSelectServiceDescription(service) {
    let serviceDescriptionSelected = null;
    let serviceDescription = null;

    if(process.env.NODE_ENV === 'production') {
      window.gtag('event', `Service description - ${service}`, {
        'event_category' : 'Click',
        'event_label' : service
      });
    }

    switch (service) {
      case 'Cross FYD':
        serviceDescriptionSelected = service;
        serviceDescription = 'Um programa de treinamento com o foco em desenvolver o ' +
                             'condicionamento físico geral, proporcionando a mais ampla ' +
                             'adaptação fisiológica e oferecendo resultados ' +
                             'incríveis treinando apenas 1 hora por dia. Uma atividade ' +
                             'dinâmica voltada para qualquer pessoa, independente de ' +
                             'idade ou nível físico, e que aplica movimentos funcionais ' +
                             'inspirados na ginástica, no levantamento de peso e no atletismo.';
        break;
      case 'Pilates':
        serviceDescriptionSelected = service;
        serviceDescription = 'Um treino de condicionamento postural onde fundimos o Pilates ' +
                             'tradicional (com aparelhos e solo), treinamento funcional e Yoga. ' +
                             'Uma atividade para o corpo e mente, onde trabalhamos tanto para ' +
                             'melhorar sua postura, mobilidade articular e força, quanto sua ' +
                             'consciência corporal, com exercícios de respiração que ajudam a ' +
                             'diminuir o estresse e ansiedade para proporcional melhor qualidade de vida.';
        break;
      case 'Bike':
        serviceDescriptionSelected = service;
        serviceDescription = 'Desafiador, divertido, e seguro, o FYD Bike é um treino de ciclismo ' +
                             'indoor que irá te proporcionar ótimos resultados em pouquíssimo tempo. ' +
                             'Com a aplicação de tecnologias como o de monitoramento cardíaco, ' +
                             'oferecemos treinos de ciclismo elaborados ajustados de forma ' +
                             'personalizada para o seu condicionamento físico. No pelotão FYD ' +
                             'Bike ninguém fica para trás e todos chegam juntos!';
        break;
      case 'Musculação':
        serviceDescriptionSelected = service;
        serviceDescription = 'Uma forma revigorada da musculação tradicional, a FYD Gym é um ' +
                             'treino indicado para pessoas de qualquer idade ou condicionamento ' +
                             'físico que buscam desde o ganho de massa muscular (hipertrofia) ' +
                             'até o emagrecimento ou performance. Com um cronograma personalizado ' +
                             'e dinâmico, trazemos mais eficiência para que você alcance seus ' +
                             'objetivos gastando menos tempo na academia e sem aqueles treinos ' +
                             'repetidos e maçantes.';
        break;
      // case 'Cardio':
      //   serviceDescriptionSelected = service;
      //   serviceDescription = 'Se você procura queimar muitas calorias, emagrecer, melhorar seu ' +
      //                        'condicionamento e resistência física, então o FYD Cardio é para ' +
      //                        'você! Um treino coletivo dinâmico e divertido de alta intensidade ' +
      //                        'que combina movimentos de esportes, como corrida e salto, além de ' +
      //                        'exercícios tradicionais de força para atender desde os alunos ' +
      //                        'iniciantes até os mais energéticos.';
      //   break;
      case 'Bootcamp':
        serviceDescriptionSelected = service;
        serviceDescription = 'Combinação perfeita de CORRIDA + TREINO FUNCIONAL e totalmente adaptável ' +
                             'para todos os níveis de condicionamento, desde o iniciante até ao avançado. ' +
                             'Aliado à tecnologia do monitoramento cardíaco em tempo real, proporcionamos ' +
                             'segurança e ótimos resultados. Um treino animado e com muita música, ' +
                             'este serviço é ideal para aqueles que buscam tanto emagrecimento ' +
                             'quanto fortalecimento e ganho de performance.';
        break;
      // case 'Core':
      //   serviceDescriptionSelected = service;
      //   serviceDescription = 'Um core mais forte torna você melhor em quase tudo que você faz, ' +
      //                        'da vida cotidiana às atividades favoritas. Também indicado para todas ' +
      //                        'as idades e condicionamento físico, o FYD Core é constituído de ' +
      //                        'treinos de curta duração com exercícios que vão fortalecer e ' +
      //                        'definir os músculos das regiões abdominais, lombar, pélvis e ' +
      //                        'quadril.';
      //   break;
      default:
    }

    this.setState({
      serviceDescriptionSelected,
      serviceDescription
    });
  }

  render() {
    // const videoSize = this.getVideoSize();

    return (
      <React.Fragment>

        <PhotoGallery
          visible={this.state.galleryIsVisible}
          items={this.galleryItems}
          currentIndex={this.state.currentGalleryIndex}
          onUpdateIndex={(newIndex) => this.setState({currentGalleryIndex: newIndex})}
          onCloseGallery={() => this.setState({galleryIsVisible: false})}
          enumeratePhotos={true}
        />

        <Helmet>
          <title>FYD - Centro de Treinamento | Cross, Pilates, Musculação, Bike e Run Bootcamp</title>
          <meta name="description" content="Alcance seus objetivos, seja ele emagrecimento, hipertrofia, performance ou qualidade com um serviço eficiente e de alta qualidade. Agende já uma aula experimental e conheça na prática nossos serviços: Cross, Pilates, Musculação, Bike e Run Bootcamp." />
        </Helmet>

        <Promotion
          history={this.props.history}
        />

        <MainHeader
          location={this.props.location}
          enablePromotion={this.state.enablePromotion}
        />

        <main className="main-page">

          <section className={`main-page__main-banner${this.state.enablePromotion ? '--promotion-enalbed' : ''}`}>

            <div className="main-page__main-banner__content">

              <div className="main-page__main-banner__content-wrapper">

                <h1 className="main-page__main-banner__header">Planos a partir de <span className="main-page__main-banner__header__strong-text">R$135,90/mês</span></h1>

                <p className="main-page__main-banner__minor-specifier">*Planos anuais incluem 1 acompanhamento nutricional</p>

                <p className="main-page__main-banner__specifier">Cross, Pilates, Spinning, Musculação e Run Bootcamp</p>
                {/* <p className="main-page__main-banner__specifier">No plano anual de treino funcional</p> */}

                <div className="main-page__main-banner__links-container">

                  <Link
                    className="main-page__main-banner__experimental-class-link"
                    to={routes.EXPERIMENTAL_CLASS_PATH}
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Main banner - Experimental class button', {
                          'event_category' : 'Click',
                          'event_label' : 'Agende uma aula experimental'
                        });
                      }
                    }}
                  >
                    <i className="fas fa-calendar-alt main-page__main-banner__experimental-class-link__icon"></i>
                    {this.state.screenWidth <= 380 ?
                      <React.Fragment>

                        Agende uma aula <br/>experimental

                      </React.Fragment>:
                      "Agende uma aula experimental"
                    }
                  </Link>

                  {/* <Link
                    className="main-page__main-banner__services-link"
                    to={routes.SERVICES_PATH}
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Click', {
                          'event_category' : 'Internal link',
                          'event_label' : 'Conheça os planos e preços FYD Club'
                        });
                      }
                    }}
                  >
                    {this.state.screenWidth <= 380 ?
                      <React.Fragment>

                        Conheça os planos e <br/>preços FYD Club

                      </React.Fragment>:
                      "Conheça os planos e preços FYD Club"
                    }
                  </Link> */}

                </div>

              </div>

            </div>

            <div className="main-page__main-banner__images-container">

              <div className="main-page__main-banner__red-filter"></div>
              <div className="main-page__main-banner__background-line-detail--1"></div>
              <div className="main-page__main-banner__background-line-detail--2"></div>
              <div className="main-page__main-banner__background-line-detail--3"></div>
              <img className="main-page__main-banner__background-arrow--1" src={bannerArrow} alt="" />
              <img className="main-page__main-banner__background-arrow--2" src={bannerArrow} alt="" />
              <img className="main-page__main-banner__background-arrow--3" src={bannerArrow} alt="" />
              <img
                className="main-page__main-banner__background-hex"
                src={hex_710}
                srcSet={`${hex_710} 710w,
                         ${hex_600} 600w,
                         ${hex_460} 460w,
                         ${hex_380} 380w`}
                sizes="(max-width: 600px) 380px,(max-width: 1200px) 460px, (max-width: 1550px) 600px, 710px"
                alt=""
              />
              {this.state.screenWidth <= 870 ?
                <img
                  className="main-page__main-banner__person-image--1"
                  src={seriousWomanHalvedSmall}
                  srcSet={`${seriousWomanHalvedTiny} 395w,
                           ${seriousWomanHalvedTiny2x} 790w,
                           ${seriousWomanHalvedVerySmall} 420w,
                           ${seriousWomanHalvedVerySmall2x} 840w,
                           ${seriousWomanHalvedSmall} 480w,
                           ${seriousWomanHalvedSmall2x} 960w`}
                  sizes="(max-width: 380px) 395px, (max-width: 600px) 420px, 480px"
                  alt=""
                />:
                <img
                  className="main-page__main-banner__person-image--1"
                  src={seriousWomanExtraLarge}
                  srcSet={`${seriousWomanMedium} 450w,
                           ${seriousWomanMedium2x} 900w,
                           ${seriousWomanLarge} 520w,
                           ${seriousWomanLarge2x} 1040w,
                           ${seriousWomanExtraLarge} 610w,
                           ${seriousWomanExtraLarge2x} 1220w`}
                  sizes="(max-width: 1200px) 450px, (max-width: 1550px) 520px, 610px"
                  alt=""
                />
              }
              {this.state.screenWidth <= 870 ?
                <img
                  className="main-page__main-banner__person-image--2"
                  src={seriousWomanHalvedSmall}
                  srcSet={`${seriousWomanHalvedTiny} 395w,
                           ${seriousWomanHalvedTiny2x} 790w,
                           ${seriousWomanHalvedVerySmall} 420w,
                           ${seriousWomanHalvedVerySmall2x} 840w,
                           ${seriousWomanHalvedSmall} 480w,
                           ${seriousWomanHalvedSmall2x} 960w`}
                  sizes="(max-width: 380px) 395px, (max-width: 600px) 420px, 480px"
                  alt=""
                />:
                <img
                  className="main-page__main-banner__person-image--2"
                  src={seriousWomanExtraLarge}
                  srcSet={`${seriousWomanMedium} 450w,
                           ${seriousWomanMedium2x} 900w,
                           ${seriousWomanLarge} 520w,
                           ${seriousWomanLarge2x} 1040w,
                           ${seriousWomanExtraLarge} 610w,
                           ${seriousWomanExtraLarge2x} 1220w`}
                  sizes="(max-width: 1200px) 450px, (max-width: 1550px) 520px, 610px"
                  alt=""
                />
              }
              {this.state.screenWidth <= 1550 ?
                <img
                  className="main-page__main-banner__main-image"
                  src={mainBannerLarge}
                  srcSet={`${mainBannerLarge} 1620w,
                           ${mainBannerLarge2x} 3240w,
                           ${mainBannerMedium} 1418w,
                           ${mainBannerMedium2x} 2835w,
                           ${mainBannerSmall} 1337w,
                           ${mainBannerSmall2x} 2673w,
                           ${mainBannerVerySmall} 1188w,
                           ${mainBannerVerySmall2x} 2376w,
                           ${mainBannerTiny} 1040w,
                           ${mainBannerTiny2x} 2079w`}
                  sizes="(max-width: 600px) 1040px, (max-width: 870px) 1188px, (max-width: 970px) 1337px, (max-width: 1200px) 1418px, 1620px"
                  alt=""
                />:
                <img
                  className="main-page__main-banner__main-image"
                  src={mainBannerExtraLarge}
                  srcSet={`${mainBannerExtraLarge} 2931w,
                           ${mainBannerExtraLarge2x} 5862w`}
                  sizes="2931px"
                  alt=""
                />
              }

            </div>

          </section>

          <section className="main-page__objectives-section">

            <div className="main-page__objectives-section__content">

              <article className="main-page__objectives-section__article">

                <div className="main-page__objectives-section__article__background">

                  <img
                    className="main-page__objectives-section__article__img-1"
                    src={slim_300}
                    srcSet={`${slim_300} 300w,
                             ${slim_300_2x} 600w,
                             ${slim_280} 280w,
                             ${slim_280_2x} 560w,
                             ${slim_260} 260w,
                             ${slim_260_2x} 520w,
                             ${slim_230} 230w,
                             ${slim_230_2x} 460w`}
                    sizes="(max-width: 470px) 260px, (max-width: 870px) 300px, (max-width: 1080px) 230px, (max-width: 1200px) 260px, (max-width: 1320px) 280px, 300px"
                    alt="Mulheres prontas para se exercitarem"
                  />

                </div>

                {/* <img
                  className="main-page__objectives-section__article__hex"
                  src={hexagon}
                  alt=""
                /> */}

                <div className="main-page__objectives-section__article__content-wrapper">

                  <h2 className="main-page__objectives-section__article__title">
                    Para quem quer <strong className="main-page__objectives-section__article__title--strong">emagrecer</strong>
                  </h2>

                  <p className="main-page__objectives-section__article__text">

                    Nós temos o plano perfeito para você que quer entrar em forma e conquistar o corpo que sempre sonhou. Com todo o acompanhamento, avaliações físicas e
                    orientações inclusas em nosso pacote de emagrecimento basta apenas que você aceite o desafio para começar a alcançar seus objetivos.

                  </p>

                </div>

              </article>

              <article className="main-page__objectives-section__article">

                <div className="main-page__objectives-section__article__background--centered">

                  {/* <img
                    className="main-page__objectives-section__article__img-2"
                    src={shape_300}
                    srcSet={`${shape_300} 300w,
                             ${shape_300_2x} 600w,
                             ${shape_280} 280w,
                             ${shape_280_2x} 560w,
                             ${shape_260} 260w,
                             ${shape_260_2x} 520w,
                             ${shape_230} 230w,
                             ${shape_230_2x} 460w`}
                    sizes="(max-width: 470px) 260px, (max-width: 870px) 300px, (max-width: 1080px) 230px, (max-width: 1200px) 260px, (max-width: 1320px) 280px, 300px"
                    alt="Homem musculoso se exercitando com halteres"
                  /> */}

                  <img
                    className="main-page__objectives-section__article__img-2"
                    src={shape_432}
                    srcSet={`${shape_432} 432w,
                             ${shape_432_2x} 864w,
                             ${shape_408} 408w,
                             ${shape_408_2x} 816w,
                             ${shape_380} 380w,
                             ${shape_380_2x} 760w,
                             ${shape_340} 340w,
                             ${shape_340_2x} 680w`}
                    sizes="(max-width: 380px) 380px, (max-width: 870px) 408px, (max-width: 1080px) 340px, (max-width: 1200px) 372px, (max-width: 1550px) 408px, 432px"
                    alt="Homem se exercitando em equipamento de musculação"
                  />

                </div>

                <div className="main-page__objectives-section__article__content-wrapper">

                  <h2 className="main-page__objectives-section__article__title">
                    Para quem quer o <strong className="main-page__objectives-section__article__title--strong"><i>shape</i></strong> perfeito
                  </h2>

                  <p className="main-page__objectives-section__article__text">

                    Temos o plano certo para quem busca a hipertrofia, ganho de força ou massa muscular. Além de oferecer todo o suporte, orientação e acompanhamento, inclusos
                    no plano, aplicamos metodologias de ponta, as mesmas aplicadas em atletas profissionais, para que você alcance seus objetivos de forma segura e eficiente.

                  </p>

                </div>

              </article>

              <article className="main-page__objectives-section__article">

                <div className="main-page__objectives-section__article__background--centered">

                  {/* <img
                    className="main-page__objectives-section__article__img-3"
                    src={performance_300}
                    srcSet={`${performance_300} 300w,
                             ${performance_300_2x} 600w,
                             ${performance_280} 280w,
                             ${performance_280_2x} 560w,
                             ${performance_260} 260w,
                             ${performance_260_2x} 520w,
                             ${performance_230} 230w,
                             ${performance_230_2x} 460w`}
                    sizes="(max-width: 470px) 260px, (max-width: 870px) 300px, (max-width: 1080px) 230px, (max-width: 1200px) 260px, (max-width: 1320px) 280px, 300px"
                    alt="Homem se exercitando com intensidade"
                  /> */}

                  <img
                    className="main-page__objectives-section__article__img-3"
                    src={performance_432}
                    srcSet={`${performance_432} 432w,
                             ${performance_432_2x} 864w,
                             ${performance_408} 408w,
                             ${performance_408_2x} 816w,
                             ${performance_380} 380w,
                             ${performance_380_2x} 760w,
                             ${performance_340} 340w,
                             ${performance_340_2x} 680w`}
                    sizes="(max-width: 380px) 380px, (max-width: 870px) 408px, (max-width: 1080px) 340px, (max-width: 1200px) 372px, (max-width: 1550px) 408px, 432px"
                    alt="Homem se exercitando com intensidade"
                  />

                </div>

                <div className="main-page__objectives-section__article__content-wrapper">

                  <h2 className="main-page__objectives-section__article__title">
                    Para elevar sua <strong className="main-page__objectives-section__article__title--strong">performance</strong>
                  </h2>

                  <p className="main-page__objectives-section__article__text">

                    Estamos prontos para receber todos os atletas, profissionais e amadores, que buscam uma melhora de desempenho. Nosso leque
                    variado de serviços e equipe qualificada nos permite trabalhar individualmente nos pontos principais que você deseja melhorar.

                  </p>

                </div>

              </article>

              <article className="main-page__objectives-section__article">

                <div className="main-page__objectives-section__article__background">

                  <img
                    className="main-page__objectives-section__article__img-4"
                    src={life_300}
                    srcSet={`${life_300} 300w,
                             ${life_300_2x} 600w,
                             ${life_280} 280w,
                             ${life_280_2x} 560w,
                             ${life_260} 260w,
                             ${life_260_2x} 520w,
                             ${life_230} 230w,
                             ${life_230_2x} 460w`}
                    sizes="(max-width: 470px) 260px, (max-width: 870px) 300px, (max-width: 1080px) 230px, (max-width: 1200px) 260px, (max-width: 1320px) 280px, 300px"
                    alt="Homem mais velho fitness"
                  />

                </div>

                <div className="main-page__objectives-section__article__content-wrapper">

                  <h2 className="main-page__objectives-section__article__title">
                    Para quem busca <strong className="main-page__objectives-section__article__title--strong">qualidade de vida</strong>
                  </h2>

                  <p className="main-page__objectives-section__article__text">

                    A atividade física é fundamental na prevenção e combate de diversas doenças que surgem conforme a idade avança e que afetam diretamente nossa sociedade cada vez mais
                    sedentária. Com nosso leque de serviços e metodologias conseguimos atender públicos de todas as idades independentemente de seu condicionamento físico.

                  </p>

                </div>

              </article>

            </div>

          </section>

          <section className="main-page__methodology-section">

            <div className="main-page__methodology-section__content">

              <div className="main-page__methodology-section__illustration--wrapper">

                <div className="main-page__methodology-section__illustration">

                  <div className="main-page__methodology-section__illustration__flow-circle"></div>

                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-1"></i>
                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-2"></i>
                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-3"></i>
                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-4"></i>

                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-5"></i>
                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-6"></i>
                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-7"></i>
                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-8"></i>

                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-9"></i>
                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-10"></i>
                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-11"></i>
                  <i className="fas fa-chevron-right main-page__methodology-section__illustration__flow-12"></i>

                  <div className="main-page__methodology-section__illustration__item-1">

                    <img
                      className="main-page__methodology-section__illustration__item__image-1"
                      src={physical_evaluation_150}
                      srcSet={`${physical_evaluation_150} 150w,
                               ${physical_evaluation_150_2x} 300w,
                               ${physical_evaluation_125} 125w,
                               ${physical_evaluation_125_2x} 250w,
                               ${physical_evaluation_110} 110w,
                               ${physical_evaluation_110_2x} 220w,
                               ${physical_evaluation_70} 70w,
                               ${physical_evaluation_70_2x} 140w`}
                      sizes="(max-width: 870px) 70px, (max-width: 1080px) 110px, (max-width: 1320px) 125px, 150px"
                      alt="Treinador apresentando o resultado da avaliação física para aluna"
                    />
                    <p className="main-page__methodology-section__illustration__item__text">Avaliação<br/> física</p>

                  </div>

                  <div className="main-page__methodology-section__illustration__item-2">

                    <img
                      className="main-page__methodology-section__illustration__item__image-2"
                      src={prescription_150}
                      srcSet={`${prescription_150} 150w,
                               ${prescription_150_2x} 300w,
                               ${prescription_125} 125w,
                               ${prescription_125_2x} 250w,
                               ${prescription_110} 110w,
                               ${prescription_110_2x} 220w,
                               ${prescription_70} 70w,
                               ${prescription_70_2x} 140w`}
                      sizes="(max-width: 870px) 70px, (max-width: 1080px) 110px, (max-width: 1320px) 125px, 150px"
                      alt="Coach orientando treino para aluna"
                    />
                    <p className="main-page__methodology-section__illustration__item__text">Prescrição</p>

                  </div>

                  <div className="main-page__methodology-section__illustration__item-3">

                    <img
                      className="main-page__methodology-section__illustration__item__image-3"
                      src={follow_up_150}
                      srcSet={`${follow_up_150} 150w,
                               ${follow_up_150_2x} 300w,
                               ${follow_up_125} 125w,
                               ${follow_up_125_2x} 250w,
                               ${follow_up_110} 110w,
                               ${follow_up_110_2x} 220w,
                               ${follow_up_70} 70w,
                               ${follow_up_70_2x} 140w`}
                      sizes="(max-width: 870px) 70px, (max-width: 1080px) 110px, (max-width: 1320px) 125px, 150px"
                      alt="Treinadora instruindo aluno na execução de exercício fisico"
                    />
                    <p className="main-page__methodology-section__illustration__item__text">Acompanhamento</p>

                  </div>

                  <p className="main-page__methodology-section__illustration__main-text">Metodologia FYD</p>

                </div>

              </div>

              <article className="main-page__methodology-section__article">

                <div className="main-page__methodology-section__article__title-wrapper">

                  <h2 className="main-page__methodology-section__article__title">Um jeito eficiente de treinar</h2>

                </div>

                <p className="main-page__methodology-section__article__text">

                  Trabalhamos constantemente para lhe oferecer as metodologias mais modernas de treinamento
                  que muitas vezes são acessíveis apenas por atletas profissionais. Uma metodologia integrada e
                  aplicada em todos os serviços que oferecemos para levar o seu treinamento a outro patamar,
                  personalizado para as suas necessidades e com muito mais eficiência e segurança.
                  E a melhor parte é que oferecemos isso sem cobrar um centavo a mais no seu plano.
                  Quer saber mais? Então marque uma aula experimental e conheça na prática um pouco mais de nossa proposta.

                </p>

                <div className="main-page__methodology-section__buttons-wrapper">

                  <Link
                    className="main-page__methodology-section__button"
                    to={routes.EXPERIMENTAL_CLASS_PATH}
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Methodology - Experimental class button', {
                          'event_category' : 'Click',
                          'event_label' : 'Agendar'
                        });
                      }
                    }}
                  >
                    <i className="fas fa-calendar-alt main-page__methodology-section__button__icon"></i>

                    Agendar
                  </Link>

                  <Link
                    className="main-page__methodology-section__button"
                    to={routes.SERVICES_PATH}
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Methodology - Plans & prices button', {
                          'event_category' : 'Click',
                          'event_label' : 'Conheça os planos e preços FYD Club'
                        });
                      }
                    }}
                  >
                    {(this.state.screenWidth > 600 && this.state.screenWidth <= 970) ?
                      <React.Fragment>

                        Conheça nossos <br/>planos

                      </React.Fragment>:
                      "Conheça nossos planos"
                    }
                  </Link>

                </div>


                {/* <button className="main-page__methodology-section__button">

                  <i className="fab fa-youtube main-page__methodology-section__button__icon"></i>

                  Saiba mais

                </button> */}

              </article>

            </div>

          </section>

          <section className="main-page__services-section">

            <div className="main-page__services-section__detail"></div>

            <div className="main-page__services-section__content">

              <h2 className="main-page__services-section__title">Sua próxima atividade está aqui</h2>

              {this.state.screenWidth > 600 ? (
                <div className="main-page__services-section__service-panel">

                  <div className="main-page__services-section__service-panel__row--1">

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Cross FYD')}
                      disabled={this.state.serviceDescriptionSelected === 'Cross FYD'}
                    >

                      Cross FYD

                    </button>

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Pilates')}
                      disabled={this.state.serviceDescriptionSelected === 'Pilates'}
                    >

                      Pilates

                    </button>

                    {this.state.screenWidth > 780 &&
                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Bike')}
                        disabled={this.state.serviceDescriptionSelected === 'Bike'}
                      >

                        Spinning

                      </button>
                    }

                  </div>

                  <div className="main-page__services-section__service-panel__row--2">

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Musculação')}
                      disabled={this.state.serviceDescriptionSelected === 'Musculação'}
                    >

                      Musculação

                    </button>

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Bootcamp')}
                      disabled={this.state.serviceDescriptionSelected === 'Bootcamp'}
                    >

                      Run<br/>Bootcamp

                    </button>

                    {/* {this.state.screenWidth > 780 &&
                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Cardio')}
                        disabled={this.state.serviceDescriptionSelected === 'Cardio'}
                      >

                        Cardio

                      </button>
                    } */}

                  </div>

                  <div className="main-page__services-section__service-panel__row--3">

                    {this.state.screenWidth <= 780 ? (
                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Bike')}
                        disabled={this.state.serviceDescriptionSelected === 'Bike'}
                      >

                        Spinning

                      </button>
                    ) : (
                      <div className="main-page__services-section__fake-service-button">
                      </div>
                    )}

                    {/* {this.state.screenWidth <= 780 &&
                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Cardio')}
                        disabled={this.state.serviceDescriptionSelected === 'Cardio'}
                      >

                        Cardio

                      </button>
                    }

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Core')}
                      disabled={this.state.serviceDescriptionSelected === 'Core'}
                    >

                      Core

                    </button> */}

                  </div>

                </div>
              ): this.state.screenWidth > 470 ? (
                <div className="main-page__services-section__service-panel">

                  <div className="main-page__services-section__service-panel__row--1">

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Cross FYD')}
                      disabled={this.state.serviceDescriptionSelected === 'Cross FYD'}
                    >

                      Cross FYD

                    </button>

                  </div>

                  <div className="main-page__services-section__service-panel__row--2">

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Pilates')}
                      disabled={this.state.serviceDescriptionSelected === 'Pilates'}
                    >

                      Pilates

                    </button>

                  </div>

                  <div className="main-page__services-section__service-panel__row--3">

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Bike')}
                      disabled={this.state.serviceDescriptionSelected === 'Bike'}
                    >

                      Spinning

                    </button>

                  </div>

                  <div className="main-page__services-section__service-panel__row--4">

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Musculação')}
                      disabled={this.state.serviceDescriptionSelected === 'Musculação'}
                    >

                      Musculação

                    </button>

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Bootcamp')}
                      disabled={this.state.serviceDescriptionSelected === 'Bootcamp'}
                    >

                      Run<br/>Bootcamp

                    </button>

                    {/* <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Cardio')}
                      disabled={this.state.serviceDescriptionSelected === 'Cardio'}
                    >

                      Cardio

                    </button>

                    <button
                      className="main-page__services-section__service-button"
                      onClick={() => this.onSelectServiceDescription('Core')}
                      disabled={this.state.serviceDescriptionSelected === 'Core'}
                    >

                      Core

                    </button> */}

                  </div>

                </div>
              ): this.state.screenWidth > 380 ? (
                (
                  <div className="main-page__services-section__service-panel">

                    <div className="main-page__services-section__service-panel__row--1">

                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Bootcamp')}
                        disabled={this.state.serviceDescriptionSelected === 'Bootcamp'}
                      >

                        Run<br/>Bootcamp

                      </button>

                      {/* <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Core')}
                        disabled={this.state.serviceDescriptionSelected === 'Core'}
                      >

                        Core

                      </button> */}

                    </div>

                    <div className="main-page__services-section__service-panel__row--2">

                      <div className="main-page__services-section__service-panel__row">

                        <button
                          className="main-page__services-section__service-button"
                          onClick={() => this.onSelectServiceDescription('Cross FYD')}
                          disabled={this.state.serviceDescriptionSelected === 'Cross FYD'}
                        >

                          Cross FYD

                        </button>

                        <button
                          className="main-page__services-section__service-button"
                          onClick={() => this.onSelectServiceDescription('Pilates')}
                          disabled={this.state.serviceDescriptionSelected === 'Pilates'}
                        >

                          Pilates

                        </button>

                      </div>

                      {/* <div className="main-page__services-section__service-panel__row">

                        <button
                          className="main-page__services-section__service-button"
                          onClick={() => this.onSelectServiceDescription('Cardio')}
                          disabled={this.state.serviceDescriptionSelected === 'Cardio'}
                        >

                          Cardio

                        </button>

                      </div> */}

                      <div className="main-page__services-section__service-panel__row">

                        <button
                          className="main-page__services-section__service-button"
                          onClick={() => this.onSelectServiceDescription('Bike')}
                          disabled={this.state.serviceDescriptionSelected === 'Bike'}
                        >

                          Spinning

                        </button>

                        <button
                          className="main-page__services-section__service-button"
                          onClick={() => this.onSelectServiceDescription('Musculação')}
                          disabled={this.state.serviceDescriptionSelected === 'Musculação'}
                        >

                          Musculação

                        </button>

                      </div>

                    </div>

                  </div>
                )
              ) : (
                (
                  <div className="main-page__services-section__service-panel">

                    <div className="main-page__services-section__service-panel__row--1">

                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Cross FYD')}
                        disabled={this.state.serviceDescriptionSelected === 'Cross FYD'}
                      >

                        Cross FYD

                      </button>

                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Pilates')}
                        disabled={this.state.serviceDescriptionSelected === 'Pilates'}
                      >

                        Pilates

                      </button>

                    </div>

                    <div className="main-page__services-section__service-panel__row--2">

                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Bike')}
                        disabled={this.state.serviceDescriptionSelected === 'Bike'}
                      >

                        Spinning

                      </button>

                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Musculação')}
                        disabled={this.state.serviceDescriptionSelected === 'Musculação'}
                      >

                        Musculação

                      </button>

                    </div>

                    <div className="main-page__services-section__service-panel__row--3">

                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Bootcamp')}
                        disabled={this.state.serviceDescriptionSelected === 'Bootcamp'}
                      >

                        Run<br/>Bootcamp

                      </button>

                      {/* <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Cardio')}
                        disabled={this.state.serviceDescriptionSelected === 'Cardio'}
                      >

                        Cardio

                      </button>

                      <button
                        className="main-page__services-section__service-button"
                        onClick={() => this.onSelectServiceDescription('Core')}
                        disabled={this.state.serviceDescriptionSelected === 'Core'}
                      >

                        Core

                      </button> */}

                    </div>

                  </div>
                )
              )}

              <p className={`main-page__services-section__description${this.state.serviceDescription !== null ? '--selected' : ''}`}>

                {this.state.serviceDescription || 'Selecione a atividade para saber mais'}

              </p>

            </div>

            <div className="main-page__services-section__image-background"></div>
            <div className="main-page__services-section__image-background--inner"></div>
            <div className="main-page__services-section__image-frame">

              <img
                className={`main-page__services-section__service-image${this.state.serviceDescriptionSelected === null ? '' : '--hidden'}`}
                style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "38% center",
                  }}
                src={general_720}
                srcSet={`${general_720} 720w,
                         ${general_720_2x} 1440w,
                         ${general_660} 660w,
                         ${general_660_2x} 1320w,
                         ${general_520} 520w,
                         ${general_520_2x} 1040w,
                         ${general_460} 460w,
                         ${general_460_2x} 920w,
                         ${general_380} 380w,
                         ${general_380_2x} 760w,
                         ${general_310} 310w,
                         ${general_310_2x} 620w,
                         ${general_280} 280w,
                         ${general_280_2x} 560w`}
                sizes="(max-width: 470px) 280px, (max-width: 600px) 310px, (max-width: 780px) 380px, (max-width: 970px) 460px, (max-width: 1200px) 520px, (max-width: 1550px) 660px, 720px"
                alt="Grupo de pessoas se preparando para a atividade física"
              />
              <img
                className={`main-page__services-section__service-image${this.state.serviceDescriptionSelected === 'Cross FYD' ? '' : '--hidden'}`}
                style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "70% center",
                  }}
                  src={functional_training_720}
                  srcSet={`${functional_training_720} 720w,
                           ${functional_training_720_2x} 1440w,
                           ${functional_training_660} 660w,
                           ${functional_training_660_2x} 1320w,
                           ${functional_training_520} 520w,
                           ${functional_training_520_2x} 1040w,
                           ${functional_training_460} 460w,
                           ${functional_training_460_2x} 920w,
                           ${functional_training_380} 380w,
                           ${functional_training_380_2x} 760w,
                           ${functional_training_310} 310w,
                           ${functional_training_310_2x} 620w,
                           ${functional_training_280} 280w,
                           ${functional_training_280_2x} 560w`}
                  sizes="(max-width: 470px) 280px, (max-width: 600px) 310px, (max-width: 780px) 380px, (max-width: 970px) 460px, (max-width: 1200px) 520px, (max-width: 1550px) 660px, 720px"
                alt="Homem levantando barra olímpica com pesos sobre a cabeça"
              />
              <img
                className={`main-page__services-section__service-image${this.state.serviceDescriptionSelected === 'Pilates' ? '' : '--hidden'}`}
                style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "75% center",
                  }}
                  src={pilates_720}
                  srcSet={`${pilates_720} 720w,
                           ${pilates_720_2x} 1440w,
                           ${pilates_660} 660w,
                           ${pilates_660_2x} 1320w,
                           ${pilates_520} 520w,
                           ${pilates_520_2x} 1040w,
                           ${pilates_460} 460w,
                           ${pilates_460_2x} 920w,
                           ${pilates_380} 380w,
                           ${pilates_380_2x} 760w,
                           ${pilates_310} 310w,
                           ${pilates_310_2x} 620w,
                           ${pilates_280} 280w,
                           ${pilates_280_2x} 560w`}
                  sizes="(max-width: 470px) 280px, (max-width: 600px) 310px, (max-width: 780px) 380px, (max-width: 970px) 460px, (max-width: 1200px) 520px, (max-width: 1550px) 660px, 720px"
                alt="Mulher realizando alongamento em aparelho de pilates"
              />
              <img
                className={`main-page__services-section__service-image${this.state.serviceDescriptionSelected === 'Bike' ? '' : '--hidden'}`}
                style={{
                    transformOrigin: "50% 25%",
                    objectPosition: "50% center",
                  }}
                  src={fyd_bike_720}
                  srcSet={`${fyd_bike_720} 720w,
                           ${fyd_bike_720_2x} 1440w,
                           ${fyd_bike_660} 660w,
                           ${fyd_bike_660_2x} 1320w,
                           ${fyd_bike_520} 520w,
                           ${fyd_bike_520_2x} 1040w,
                           ${fyd_bike_460} 460w,
                           ${fyd_bike_460_2x} 920w,
                           ${fyd_bike_380} 380w,
                           ${fyd_bike_380_2x} 760w,
                           ${fyd_bike_310} 310w,
                           ${fyd_bike_310_2x} 620w,
                           ${fyd_bike_280} 280w,
                           ${fyd_bike_280_2x} 560w`}
                  sizes="(max-width: 470px) 280px, (max-width: 600px) 310px, (max-width: 780px) 380px, (max-width: 970px) 460px, (max-width: 1200px) 520px, (max-width: 1550px) 660px, 720px"
                alt="Mulher se exercitando na bicicleta"
              />
              <img
                className={`main-page__services-section__service-image${this.state.serviceDescriptionSelected === 'Musculação' ? '' : '--hidden'}`}
                style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "50% center",
                  }}
                  src={gym_720}
                  srcSet={`${gym_720} 720w,
                           ${gym_720_2x} 1440w,
                           ${gym_660} 660w,
                           ${gym_660_2x} 1320w,
                           ${gym_520} 520w,
                           ${gym_520_2x} 1040w,
                           ${gym_460} 460w,
                           ${gym_460_2x} 920w,
                           ${gym_380} 380w,
                           ${gym_380_2x} 760w,
                           ${gym_310} 310w,
                           ${gym_310_2x} 620w,
                           ${gym_280} 280w,
                           ${gym_280_2x} 560w`}
                  sizes="(max-width: 470px) 280px, (max-width: 600px) 310px, (max-width: 780px) 380px, (max-width: 970px) 460px, (max-width: 1200px) 520px, (max-width: 1550px) 660px, 720px"
                alt="Treinador orientando aluna em exercício"
              />
              {/* <img
                className={`main-page__services-section__service-image${this.state.serviceDescriptionSelected === 'Cardio' ? '' : '--hidden'}`}
                style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "25% center",
                  }}
                  src={cardio_720}
                  srcSet={`${cardio_720} 720w,
                           ${cardio_720_2x} 1440w,
                           ${cardio_660} 660w,
                           ${cardio_660_2x} 1320w,
                           ${cardio_520} 520w,
                           ${cardio_520_2x} 1040w,
                           ${cardio_460} 460w,
                           ${cardio_460_2x} 920w,
                           ${cardio_380} 380w,
                           ${cardio_380_2x} 760w,
                           ${cardio_310} 310w,
                           ${cardio_310_2x} 620w,
                           ${cardio_280} 280w,
                           ${cardio_280_2x} 560w`}
                  sizes="(max-width: 470px) 280px, (max-width: 600px) 310px, (max-width: 780px) 380px, (max-width: 970px) 460px, (max-width: 1200px) 520px, (max-width: 1550px) 660px, 720px"
                alt="Grupo de alunos realizando atividade física"
              /> */}
              <img
                className={`main-page__services-section__service-image${this.state.serviceDescriptionSelected === 'Bootcamp' ? '' : '--hidden'}`}
                style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "center center",
                  }}
                  src={bootcamp_720}
                  srcSet={`${bootcamp_720} 720w,
                           ${bootcamp_720_2x} 1440w,
                           ${bootcamp_660} 660w,
                           ${bootcamp_660_2x} 1320w,
                           ${bootcamp_520} 520w,
                           ${bootcamp_520_2x} 1040w,
                           ${bootcamp_460} 460w,
                           ${bootcamp_460_2x} 920w,
                           ${bootcamp_380} 380w,
                           ${bootcamp_380_2x} 760w,
                           ${bootcamp_310} 310w,
                           ${bootcamp_310_2x} 620w,
                           ${bootcamp_280} 280w,
                           ${bootcamp_280_2x} 560w`}
                  sizes="(max-width: 470px) 280px, (max-width: 600px) 310px, (max-width: 780px) 380px, (max-width: 970px) 460px, (max-width: 1200px) 520px, (max-width: 1550px) 660px, 720px"
                alt="Grupo de alunos realizando atividade física"
              />
              {/* <img
                className={`main-page__services-section__service-image${this.state.serviceDescriptionSelected === 'Core' ? '' : '--hidden'}`}
                style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "21% center",
                  }}
                  src={core_720}
                  srcSet={`${core_720} 720w,
                           ${core_720_2x} 1440w,
                           ${core_660} 660w,
                           ${core_660_2x} 1320w,
                           ${core_520} 520w,
                           ${core_520_2x} 1040w,
                           ${core_460} 460w,
                           ${core_460_2x} 920w,
                           ${core_380} 380w,
                           ${core_380_2x} 760w,
                           ${core_310} 310w,
                           ${core_310_2x} 620w,
                           ${core_280} 280w,
                           ${core_280_2x} 560w`}
                  sizes="(max-width: 470px) 280px, (max-width: 600px) 310px, (max-width: 780px) 380px, (max-width: 970px) 460px, (max-width: 1200px) 520px, (max-width: 1550px) 660px, 720px"
                alt="Alunos realizando exercícios de core"
              /> */}

            </div>

          </section>

          {/* <section className="main-page__video-section">

            <div className="main-page__video-section__content">

              <div className="main-page__video-section__video-container">

                <iframe
                  className="main-page__video-section__video"
                  title="Vídeo descrevendo os serviços da FYD Club"
                  width={videoSize[0]}
                  height={videoSize[1]}
                  src="https://www.youtube.com/embed/a0F52_vwjPQ?modestbranding=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen>
                </iframe>

                <div className="main-page__video-section__decoration-container">

                  <img className="main-page__video-section__decorative-line--1" src={decorativeLine1} alt="" />
                  <img className="main-page__video-section__decorative-line--2" src={decorativeLine2} alt="" />

                </div>


              </div>

              <div className="main-page__video-section__description-container">

                <h2 className="main-page__video-section__title">O local certo <RedText>para o seu treino</RedText></h2>

                <p className="main-page__video-section__semi-title">Treino funcional e Pilates</p>

                <p className="main-page__video-section__paragraph">

                  Para todas as idades e condições a <RedText>FYD CLUB</RedText> é
                  o lugar certo para iniciar o seu treino. Para pessoas que buscam
                  condicionamento físico, qualidade de vida e sair do sedentarismo.
                  Venha conhecer nossos serviços de <RedText>pilates</RedText> e <RedText>cross FYD</RedText>.

                </p>

                <p className="main-page__video-section__paragraph">

                  <RedText>Agende uma aula experimental</RedText> e comece a fazer
                  parte de nossa incrível comunidade.

                </p>

                <a href={routes.EXPERIMENTAL_CLASS_PATH} className="main-page__video-section__experimental-class-link">
                  Agendar aula experimental
                </a>

              </div>

            </div>

          </section> */}

          <img
            className="main-page__location-section__background"
            src={locationBackground_940}
            srcSet={`${locationBackground_940} 940w,
                     ${locationBackground_850} 850w,
                     ${locationBackground_760} 760w,
                     ${locationBackground_720} 720w,
                     ${locationBackground_530} 530w,
                     ${locationBackground_460} 460w`}
            sizes="(max-width: 470px) 460px, (max-width: 870px) 530px, (max-width: 970px) 720px, (max-width: 1080px) 760px, (max-width: 1420px) 850px, 940px"
            alt="Homem ajustando peso de barra olímpica"
          />

          <section className="main-page__location-section">

            <div className="main-page__location-section__images-container">

              {this.state.screenWidth > 870 ?
                <img
                  className="main-page__location-section__mask--1"
                  src={locationMask_1920}
                  srcSet={`${locationMask_1920} 1920w,
                           ${locationMask_1700} 1700w,
                           ${locationMask_1550} 1550w,
                           ${locationMask_1320} 1320w,
                           ${locationMask_1200} 1200w,
                           ${locationMask_1080} 1080w,
                           ${locationMask_970} 970w`}
                  sizes="(max-width: 970px) 970px, (max-width: 1080px) 1080px, (max-width: 1200px) 1200px, (max-width: 1320px) 1320px, (max-width: 1550px) 1550px, (max-width: 1700px) 1700px, 1920px"
                  alt=""
                />:
                <img
                  className="main-page__location-section__mask--2"
                  src={locationMask2_870}
                  srcSet={`${locationMask2_870} 870w,
                           ${locationMask2_780} 780w,
                           ${locationMask2_600} 600w,
                           ${locationMask2_470} 470w,
                           ${locationMask2_380} 380w`}
                  sizes="(max-width: 380px) 380px, (max-width: 470px) 470px, (max-width: 600px) 600px, (max-width: 780px) 780px, 870px"
                  alt=""
                />
              }

            </div>

            <div className="main-page__location-section__wrapper">

              <h2 className="main-page__location-section__title">Como chegar na FYD Club</h2>

              <a
                className="main-page__location-section__link"
                href="https://g.page/fyd-centrotreinamento?share"
                rel="noreferrer noopener"
                target="_blank"
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') {
                    window.gtag('event', 'Google maps button', {
                      'event_category' : 'Click',
                      'event_label' : 'Google maps'
                    });
                  }
                }}
              >
                 <i className="fas fa-map-marked-alt main-page__location-section__link-icon"></i>
                 Google maps
              </a>

            </div>

          </section>

          <section className="main-page__blog-section">

            <div className="main-page__blog-section__wrapper">

              <h2 className="main-page__blog-section__title">Blog <RedText>FYD Club</RedText></h2>

              <h3 className="main-page__blog-section__sub-title">Novidades dicas e curiosidades</h3>

              <div className="main-page__blog-section__articles-container">

                <article className="main-page__blog-section__article">

                  <a
                    className="main-page__blog-section__link"
                    href="https://blog.fydcentrodetreinamento.com.br/2023/11/14/atividade-fisica-e-o-bom-sono/"
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Blog - Atividade física e o bom sono', {
                          'event_category' : 'Click',
                          'event_label' : 'Atividade física e o bom sono'
                        });
                      }
                    }}
                  >

                    <div className="main-page__blog-section__article__image-wrapper--1">

                      <img
                        className="main-page__blog-section__article__image"
                        src={articleCover1_210_2x}
                        srcSet={`${articleCover1_694} 694w,
                                 ${articleCover1_380} 380w,
                                 ${articleCover1_330} 330w,
                                 ${articleCover1_330_2x} 660w,
                                 ${articleCover1_290} 290w,
                                 ${articleCover1_290_2x} 580w,
                                 ${articleCover1_210} 210w,
                                 ${articleCover1_210_2x} 420w`}
                        sizes="(max-width: 380px) 290px, (max-width: 470px) 330px, (max-width: 600px) 210px, (max-width: 870px) 290px, (max-width: 970px) 330px, (max-width: 1080px) 380px, 420px"
                        alt="Capa da publicação falando sobre a atividade física e o bom sono"
                      />

                    </div>

                    <div className="main-page__blog-section__article__text-wrapper--1">

                      <h3 className="main-page__blog-section__article__title">Atividade física e o bom sono</h3>
                      <p className="main-page__blog-section__article__date">Publicado em <time dateTime="2023-11-14">14/11/2023</time></p>

                    </div>

                  </a>

                </article>

                {this.state.screenWidth <= 870 &&
                  <hr className="main-page__blog-section__hr"></hr>
                }

                <article className="main-page__blog-section__article">

                  <a
                    className="main-page__blog-section__link"
                    href="https://blog.fydcentrodetreinamento.com.br/2023/11/13/atividade-fisca-para-idosos/"
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Blog - Atividade físca para Idosos', {
                          'event_category' : 'Click',
                          'event_label' : 'Atividade físca para Idosos'
                        });
                      }
                    }}
                  >

                    <div className="main-page__blog-section__article__image-wrapper--2">

                      <img
                        className="main-page__blog-section__article__image"
                        src={articleCover2_210_2x}
                        srcSet={`${articleCover2_694} 694w,
                                 ${articleCover2_380} 380w,
                                 ${articleCover2_330} 330w,
                                 ${articleCover2_330_2x} 660w,
                                 ${articleCover2_290} 290w,
                                 ${articleCover2_290_2x} 580w,
                                 ${articleCover2_210} 210w,
                                 ${articleCover2_210_2x} 420w`}
                        sizes="(max-width: 380px) 290px, (max-width: 470px) 330px, (max-width: 600px) 210px, (max-width: 870px) 290px, (max-width: 970px) 330px, (max-width: 1080px) 380px, 420px"
                        alt="Capa da publicação falando sobre a atividade físca para Idosos"
                      />

                    </div>

                    <div className="main-page__blog-section__article__text-wrapper--2">

                      <h3 className="main-page__blog-section__article__title">Atividade físca para Idosos</h3>
                      <p className="main-page__blog-section__article__date">Publicado em <time dateTime="2023-11-13">13/11/2023</time></p>

                    </div>

                  </a>

                </article>

                {this.state.screenWidth <= 870 &&
                  <hr className="main-page__blog-section__hr"></hr>
                }

                <article className="main-page__blog-section__article">

                  <a
                    className="main-page__blog-section__link"
                    href="https://blog.fydcentrodetreinamento.com.br/2023/11/09/beneficios-do-pilates/"
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Blog - Benefícios do pilates', {
                          'event_category' : 'Click',
                          'event_label' : 'Benefícios do pilates'
                        });
                      }
                    }}
                  >

                    <div className="main-page__blog-section__article__image-wrapper--3">

                      <img
                        className="main-page__blog-section__article__image"
                        src={articleCover3_210_2x}
                        srcSet={`${articleCover3_694} 694w,
                                 ${articleCover3_380} 380w,
                                 ${articleCover3_330} 330w,
                                 ${articleCover3_330_2x} 660w,
                                 ${articleCover3_290} 290w,
                                 ${articleCover3_290_2x} 580w,
                                 ${articleCover3_210} 210w,
                                 ${articleCover3_210_2x} 420w`}
                        sizes="(max-width: 380px) 290px, (max-width: 470px) 330px, (max-width: 600px) 210px, (max-width: 870px) 290px, (max-width: 970px) 330px, (max-width: 1080px) 380px, 420px"
                        alt="Capa da publicação falando sobre os benefícios do pilates"
                      />

                    </div>

                    <div className="main-page__blog-section__article__text-wrapper--3">

                      <h3 className="main-page__blog-section__article__title">Benefícios do pilates</h3>
                      <p className="main-page__blog-section__article__date">Publicado em <time dateTime="2023-11-09">09/11/2023</time></p>

                    </div>

                  </a>

                </article>

              </div>

            </div>

          </section>

          {/* <section className="main-page__features-section">

            <div className="main-page__features-section__wrapper">

              <h2 className="main-page__features-section__title">Metodologia <RedText>FYD Training</RedText></h2>

              <h3 className="main-page__features-section__sub-title">Tudo o que você precisa para alcançar o seu objetivo</h3>

              <div className="main-page__features-section__list">

                <article className="main-page__features-section__item">

                  <div className="main-page__features-section__item__image-wrapper">

                    <img
                      className="main-page__features-section__item__image"
                      src={featureFuncional}
                      alt="Batimentos cardíacos"
                    />

                  </div>

                  <div className="main-page__features-section__item__content">

                    <h3 className="main-page__features-section__item__title">Funcional</h3>

                    <p className="main-page__features-section__item__text">

                      Treino de alta intensidade, dinâmica de exercicíos variado diariamente e
                      dificuldade ajustada de acordo com a necessidade do aluno.

                    </p>

                  </div>

                </article>

                <article className={`main-page__features-section__item${this.state.screenWidth <= 600 ? '--right' : ''}`}>

                  <div className="main-page__features-section__item__image-wrapper">

                    <img
                      className="main-page__features-section__item__image"
                      src={featurePilates}
                      alt="Corpo equilibrado"
                    />

                  </div>

                  <div className="main-page__features-section__item__content">

                    <h3 className="main-page__features-section__item__title">Pilates</h3>

                    <p className="main-page__features-section__item__text">

                      Exercícios supervisionados para fortalecimento de <i>core</i> (abdominal, lombar e pélvica),
                      respiração e mobilidade com o auxílio de aparelhos.

                    </p>

                  </div>

                </article>

                <article className="main-page__features-section__item">

                  <div className="main-page__features-section__item__image-wrapper">

                    <img
                      className="main-page__features-section__item__image"
                      src={featureAvaliacao}
                      alt="Cronômetro"
                    />

                  </div>

                  <div className="main-page__features-section__item__content">

                    <h3 className="main-page__features-section__item__title">Avaliação física</h3>

                    <p className="main-page__features-section__item__text">

                      Acompanhamento trimestral através de avaliação física para traçar o objetivo de peso
                      e condicionamento do aluno e analisar seu desempenho.

                    </p>

                  </div>

                </article>

                <article className={`main-page__features-section__item${this.state.screenWidth <= 600 ? '--right' : ''}`}>

                  <div className="main-page__features-section__item__image-wrapper">

                    <img
                      className="main-page__features-section__item__image"
                      src={featurePeriodizacao}
                      alt="Esteira em movimento"
                    />

                  </div>

                  <div className="main-page__features-section__item__content">

                    <h3 className="main-page__features-section__item__title">Periodização de treinos</h3>

                    <p className="main-page__features-section__item__text">

                      Nossos treinos são planejados para otimizar o resultado dos alunos mantendo
                      sempre um nivel ideal de segurança para evitar qualquer tipo de lesão inesperada.

                    </p>

                  </div>

                </article>

                <article className="main-page__features-section__item">

                  <div className="main-page__features-section__item__image-wrapper">

                    <img
                      className="main-page__features-section__item__image"
                      src={featureTecnologia}
                      alt="REDEFINIR"
                    />

                  </div>

                  <div className="main-page__features-section__item__content">

                    <h3 className="main-page__features-section__item__title">Acompanhamento personalizado</h3>

                    <p className="main-page__features-section__item__text">

                      Nosso sistema nos permite acompanhar de perto as atividades de nossos alunos e
                      gera indicadores para que possamos orientá-los da melhor forma possível.

                    </p>

                  </div>

                </article>

                <article className={`main-page__features-section__item${this.state.screenWidth <= 600 ? '--right' : ''}`}>

                  <div className="main-page__features-section__item__image-wrapper">

                    <img
                      className="main-page__features-section__item__image"
                      src={featureHomeTraining}
                      alt="REDEFINIR"
                    />

                  </div>

                  <div className="main-page__features-section__item__content">

                    <h3 className="main-page__features-section__item__title">Treinos para casa</h3>

                    <p className="main-page__features-section__item__text">

                      Plataforma de treinos para nossos alunos se exercitarem quando e onde quiserem.

                    </p>

                  </div>

                </article>

              </div>

            </div>

          </section> */}

          <div className="main-page__section-ruler">
            <img
              className="main-page__section-ruler__image"
              src={hexagonRed}
              alt=''
            />
          </div>

          <section className="main-page__gallery-section">

            <div className="main-page__gallery-section__wrapper">

              <h2 className="main-page__gallery-section__title">Galeria <RedText>FYD Club</RedText></h2>

              <div className="main-page__gallery-section__sub-title-wrapper">

                <h3 className="main-page__gallery-section__sub-title">Conheça nossas unidades</h3>

                <p className="main-page__gallery-section__sub-description">(Clique na imagem para abrir a galeria)</p>

              </div>


              <ul className="main-page__gallery-section__cities-list">

                {this.getGalleryCityOptions()}

              </ul>

              <div className="main-page__gallery-section__photos-container--14">

                <div
                  className="main-page__gallery-section__photo--1"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 1
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_v_1_380}
                    srcSet={`${sao_carlos_sp_v_1_380} 380w,
                             ${sao_carlos_sp_v_1_380_2x} 760w,
                             ${sao_carlos_sp_v_1_330} 330w,
                             ${sao_carlos_sp_v_1_330_2x} 660w,
                             ${sao_carlos_sp_v_1_280} 280w,
                             ${sao_carlos_sp_v_1_280_2x} 560w,
                             ${sao_carlos_sp_v_1_200} 200w,
                             ${sao_carlos_sp_v_1_200_2x} 400w,
                             ${sao_carlos_sp_v_1_170} 170w,
                             ${sao_carlos_sp_v_1_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Treino de Cross FYD"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--2"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 2
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_1_380}
                    srcSet={`${sao_carlos_sp_h_1_380} 380w,
                             ${sao_carlos_sp_h_1_380_2x} 760w,
                             ${sao_carlos_sp_h_1_330} 330w,
                             ${sao_carlos_sp_h_1_330_2x} 660w,
                             ${sao_carlos_sp_h_1_280} 280w,
                             ${sao_carlos_sp_h_1_280_2x} 560w,
                             ${sao_carlos_sp_h_1_200} 200w,
                             ${sao_carlos_sp_h_1_200_2x} 400w,
                             ${sao_carlos_sp_h_1_170} 170w,
                             ${sao_carlos_sp_h_1_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Sala de musculação - Frente"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--3"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 3
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_2_380}
                    srcSet={`${sao_carlos_sp_h_2_380} 380w,
                             ${sao_carlos_sp_h_2_380_2x} 760w,
                             ${sao_carlos_sp_h_2_330} 330w,
                             ${sao_carlos_sp_h_2_330_2x} 660w,
                             ${sao_carlos_sp_h_2_280} 280w,
                             ${sao_carlos_sp_h_2_280_2x} 560w,
                             ${sao_carlos_sp_h_2_200} 200w,
                             ${sao_carlos_sp_h_2_200_2x} 400w,
                             ${sao_carlos_sp_h_2_170} 170w,
                             ${sao_carlos_sp_h_2_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Sala de musculação - Fundo"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--4"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 4
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_5_280_2x}
                    srcSet={`${sao_carlos_sp_h_5_380} 380w,
                             ${sao_carlos_sp_h_5_380_2x} 760w,
                             ${sao_carlos_sp_h_5_330} 330w,
                             ${sao_carlos_sp_h_5_330_2x} 660w,
                             ${sao_carlos_sp_h_5_280} 280w,
                             ${sao_carlos_sp_h_5_280_2x} 560w,
                             ${sao_carlos_sp_h_5_280_4x} 1120w,
                             ${sao_carlos_sp_h_5_200} 200w,
                             ${sao_carlos_sp_h_5_200_2x} 400w,
                             ${sao_carlos_sp_h_5_200_4x} 800w,
                             ${sao_carlos_sp_h_5_340} 340w,
                             ${sao_carlos_sp_h_5_340_2x} 680w`}
                    sizes="(max-width: 370px) 340px, (max-width: 440px) 400px, (max-width: 600px) 560px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Treino de FYD Run Bootcamp"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--5"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 5
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_v_2_380}
                    srcSet={`${sao_carlos_sp_v_2_380} 380w,
                             ${sao_carlos_sp_v_2_380_2x} 760w,
                             ${sao_carlos_sp_v_2_330} 330w,
                             ${sao_carlos_sp_v_2_330_2x} 660w,
                             ${sao_carlos_sp_v_2_280} 280w,
                             ${sao_carlos_sp_v_2_280_2x} 560w,
                             ${sao_carlos_sp_v_2_200} 200w,
                             ${sao_carlos_sp_v_2_200_2x} 400w,
                             ${sao_carlos_sp_v_2_170} 170w,
                             ${sao_carlos_sp_v_2_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Alunas guerreiras do FYD Run Bootcamp"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--6"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 6
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_4_380}
                    srcSet={`${sao_carlos_sp_h_4_380} 380w,
                             ${sao_carlos_sp_h_4_380_2x} 760w,
                             ${sao_carlos_sp_h_4_330} 330w,
                             ${sao_carlos_sp_h_4_330_2x} 660w,
                             ${sao_carlos_sp_h_4_280} 280w,
                             ${sao_carlos_sp_h_4_280_2x} 560w,
                             ${sao_carlos_sp_h_4_200} 200w,
                             ${sao_carlos_sp_h_4_200_2x} 400w,
                             ${sao_carlos_sp_h_4_170} 170w,
                             ${sao_carlos_sp_h_4_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Treino de FYD Bike / Spinning"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--7"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 7
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_3_380}
                    srcSet={`${sao_carlos_sp_h_3_380} 380w,
                             ${sao_carlos_sp_h_3_380_2x} 760w,
                             ${sao_carlos_sp_h_3_330} 330w,
                             ${sao_carlos_sp_h_3_330_2x} 660w,
                             ${sao_carlos_sp_h_3_280} 280w,
                             ${sao_carlos_sp_h_3_280_2x} 560w,
                             ${sao_carlos_sp_h_3_200} 200w,
                             ${sao_carlos_sp_h_3_200_2x} 400w,
                             ${sao_carlos_sp_h_3_170} 170w,
                             ${sao_carlos_sp_h_3_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Foto de alunos e professores da FYD"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--8"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 8
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_v_3_380}
                    srcSet={`${sao_carlos_sp_v_3_380} 380w,
                             ${sao_carlos_sp_v_3_380_2x} 760w,
                             ${sao_carlos_sp_v_3_330} 330w,
                             ${sao_carlos_sp_v_3_330_2x} 660w,
                             ${sao_carlos_sp_v_3_280} 280w,
                             ${sao_carlos_sp_v_3_280_2x} 560w,
                             ${sao_carlos_sp_v_3_200} 200w,
                             ${sao_carlos_sp_v_3_200_2x} 400w,
                             ${sao_carlos_sp_v_3_170} 170w,
                             ${sao_carlos_sp_v_3_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Sala de FYD Bike / Spinning"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--9"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 9
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_6_380}
                    srcSet={`${sao_carlos_sp_h_6_380} 380w,
                             ${sao_carlos_sp_h_6_380_2x} 760w,
                             ${sao_carlos_sp_h_6_330} 330w,
                             ${sao_carlos_sp_h_6_330_2x} 660w,
                             ${sao_carlos_sp_h_6_280} 280w,
                             ${sao_carlos_sp_h_6_280_2x} 560w,
                             ${sao_carlos_sp_h_6_200} 200w,
                             ${sao_carlos_sp_h_6_200_2x} 400w,
                             ${sao_carlos_sp_h_6_170} 170w,
                             ${sao_carlos_sp_h_6_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Sala de Pilates"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--10"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 10
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_7_380}
                    srcSet={`${sao_carlos_sp_h_7_380} 380w,
                             ${sao_carlos_sp_h_7_380_2x} 760w,
                             ${sao_carlos_sp_h_7_330} 330w,
                             ${sao_carlos_sp_h_7_330_2x} 660w,
                             ${sao_carlos_sp_h_7_280} 280w,
                             ${sao_carlos_sp_h_7_280_2x} 560w,
                             ${sao_carlos_sp_h_7_200} 200w,
                             ${sao_carlos_sp_h_7_200_2x} 400w,
                             ${sao_carlos_sp_h_7_170} 170w,
                             ${sao_carlos_sp_h_7_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Professor demonstrando utilização de aparelho de musculação"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--11"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 11
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_8_380}
                    srcSet={`${sao_carlos_sp_h_8_380} 380w,
                             ${sao_carlos_sp_h_8_380_2x} 760w,
                             ${sao_carlos_sp_h_8_330} 330w,
                             ${sao_carlos_sp_h_8_330_2x} 660w,
                             ${sao_carlos_sp_h_8_280} 280w,
                             ${sao_carlos_sp_h_8_280_2x} 560w,
                             ${sao_carlos_sp_h_8_280_4x} 1120w,
                             ${sao_carlos_sp_h_8_200} 200w,
                             ${sao_carlos_sp_h_8_200_2x} 400w,
                             ${sao_carlos_sp_h_8_200_4x} 800w,
                             ${sao_carlos_sp_h_8_340} 340w,
                             ${sao_carlos_sp_h_8_340_2x} 680w`}
                    sizes="(max-width: 370px) 340px, (max-width: 440px) 400px, (max-width: 600px) 560px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Sala de musculação - Meio"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--12"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 12
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_v_4_380}
                    srcSet={`${sao_carlos_sp_v_4_380} 380w,
                             ${sao_carlos_sp_v_4_380_2x} 760w,
                             ${sao_carlos_sp_v_4_330} 330w,
                             ${sao_carlos_sp_v_4_330_2x} 660w,
                             ${sao_carlos_sp_v_4_280} 280w,
                             ${sao_carlos_sp_v_4_280_2x} 560w,
                             ${sao_carlos_sp_v_4_200} 200w,
                             ${sao_carlos_sp_v_4_200_2x} 400w,
                             ${sao_carlos_sp_v_4_170} 170w,
                             ${sao_carlos_sp_v_4_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Aluna durante competição do Cross FYD"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--13"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 13
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_9_380}
                    srcSet={`${sao_carlos_sp_h_9_380} 380w,
                             ${sao_carlos_sp_h_9_380_2x} 760w,
                             ${sao_carlos_sp_h_9_330} 330w,
                             ${sao_carlos_sp_h_9_330_2x} 660w,
                             ${sao_carlos_sp_h_9_280} 280w,
                             ${sao_carlos_sp_h_9_280_2x} 560w,
                             ${sao_carlos_sp_h_9_200} 200w,
                             ${sao_carlos_sp_h_9_200_2x} 400w,
                             ${sao_carlos_sp_h_9_170} 170w,
                             ${sao_carlos_sp_h_9_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Aluno durante competição do Cross FYD"
                  />

                </div>

                <div
                  className="main-page__gallery-section__photo--14"
                  onClick={() => this.setState({
                    galleryIsVisible: true,
                    currentGalleryIndex: 14
                  })}
                >

                  <img
                    className="main-page__gallery-section__photo__image"
                    src={sao_carlos_sp_h_10_380}
                    srcSet={`${sao_carlos_sp_h_10_380} 380w,
                             ${sao_carlos_sp_h_10_380_2x} 760w,
                             ${sao_carlos_sp_h_10_330} 330w,
                             ${sao_carlos_sp_h_10_330_2x} 660w,
                             ${sao_carlos_sp_h_10_280} 280w,
                             ${sao_carlos_sp_h_10_280_2x} 560w,
                             ${sao_carlos_sp_h_10_200} 200w,
                             ${sao_carlos_sp_h_10_200_2x} 400w,
                             ${sao_carlos_sp_h_10_170} 170w,
                             ${sao_carlos_sp_h_10_170_2x} 340w`}
                    sizes="(max-width: 370px) 170px, (max-width: 440px) 200px, (max-width: 600px) 280px, (max-width: 660px) 200px, (max-width: 870px) 280px, (max-width: 1080px) 330px, 380px"
                    alt="Sala de FYD Run Bootcamp"
                  />

                </div>



              </div>

            </div>

          </section>

          <MainFooter />

        </main>

        <ChatOverlayButton />

      </React.Fragment>
    );
  }
}

export default MainPage
