import React from 'react';
import {Helmet} from "react-helmet";
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import LoadingIcon from '../components/loading_icon';
import './blank.scss';


class Blank extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>FYD Club</title>
        </Helmet>

        <MainHeader
          location={this.props.location}
        />

        <div className="blank__wrapper">

          <section className="blank">

            <div className="blank__content">

              <LoadingIcon />

            </div>

          </section>

          <MainFooter />

        </div>

      </React.Fragment>
    );
  }
}

export default Blank
