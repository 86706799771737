import React from 'react';
import {Helmet} from "react-helmet";
import MainHeader from '../../components/main_header';
import MainFooter from '../../components/main_footer';
import LoadingIcon from '../../components/loading_icon';
import {RedText} from '../../components/default_elements';
import posed from 'react-pose';
// import FormView from '../../forms/form_view';
// import PreLoader from '../../utils/preloader';
import {POLL_CONFIRMATION_POST_API} from '../../constants';
import {postModel} from '../../utils/functions';
import './poll_confirmation.scss';

const FadeContainer = posed.div({
  preEnter: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

class PollConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      apiFailed: false,
      pollTitle: ''
    };
  }

  async componentDidMount() {
    const data = {}

    try {
      let response = await postModel(`${POLL_CONFIRMATION_POST_API}${this.props.match.params.userHash}`, data, true);

      if(response) {
        this.setState({
          loadingData: false,
          pollTitle: response
        });
      }
      else {
        this.setState({
          loadingData: false,
          apiFailed: true
        });
      }
    }
    catch(errors) {
      this.setState({
        loadingData: false,
        apiFailed: true
      });
    }
  }

  getContentTitle() {
    if(this.state.apiFailed) {
      return (
        <FadeContainer key="falha_de_api">

          <RedText>OOOPS!</RedText>

        </FadeContainer>
      );
    }

    return (
      <RedText>{this.state.pollTitle}</RedText>
    );
  }

  getContentText() {
    if(this.state.apiFailed) {
      return 'Infelizmente estamos com problemas temporários ' +
             'em nosso serviço. Por favor, tente novamente mais tarde.';
    }

    return '';
  }

  getWarningMessage() {
    let message = 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.';

    return (
      <div className="poll-confirmation__warning-message">

        <i className="fas fa-exclamation poll-confirmation__warning-message__icon"></i>
        <p className="poll-confirmation__warning-message__text">{message}</p>

      </div>
    );
  }

  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>Pesquisa com alunos - FYD Club</title>
        </Helmet>

        <MainHeader
          location={this.props.location}
          collapse={true}
        />

        <div className="poll-confirmation__wrapper">

          <section className="poll-confirmation">

            <div className="poll-confirmation__content">

              <h1 className="poll-confirmation__title">{this.getContentTitle()}</h1>

              {this.state.loadingData ?
                <LoadingIcon />:
                this.state.apiFailed ?
                  this.getWarningMessage():
                  <div className="poll-confirmation__message">

                    SUA RESPOSTA FOI SALVA COM SUCESSO. MUITO OBRIGADO!

                  </div>
              }

            </div>

          </section>

          <MainFooter
            collapse={true}
          />

        </div>

      </React.Fragment>
    );

    // if(this.state.loadingData) {
    //   return (
    //     <PoseGroup>
    //       <FadeContainer key="preloader">
    //         <PreLoader />
    //       </FadeContainer>
    //     </PoseGroup>
    //   );
    // }
    // else {
    //   return (
    //     <React.Fragment>
    //
    //       <FormView
    //         title={this.state.pollTitle}
    //         contentTitle={this.getContentTitle()}
    //         contentText={this.getContentText()}
    //         questionsId={0}
    //         // questions={}
    //         // onFinishClick={() => this.onFinishClick()}
    //         maxId={0}
    //         showControllers={false}
    //         allowNext={false}
    //         finishText=""
    //         uploadingText=""
    //       />
    //
    //     </React.Fragment>
    //   );
    // }
  }
}

export default PollConfirmation
