import React from 'react';
import {getGesturePointFromEvent} from '../utils/functions';
import './photo_gallery.scss';

function ImageOption(image_url, size) {
  return {
    image_url,
    size
  };
}

class GalleryResponsiveImage {
  constructor(defaultOption, imageOptions, title) {
    this.defaultOption = defaultOption;
    this.imageOptions = imageOptions;
    this.title = title;

    this.index = null;

    this.containerElement = null;
    this.onLoadImageCallback = null;

    this.containerRef = (element) => {
      this.containerElement = element;

      // if (element && this.onLoadImageCallback !== null && this.index !== null) {
      //   this.onLoadImageCallback(this.index);
      // }
    };
  }

  getImageElement(elementClassName=null) {
    if (!this.defaultOption || !this.imageOptions || !this.title) {
      return null;
    }

    let srcSet = '';
    let sizes = '';

    this.imageOptions.sort((a,b) => a.size - b.size);

    for (const entry of this.imageOptions) {
      if (srcSet.length > 0) {
        srcSet += ', '
      }
      if (sizes.length > 0) {
        sizes += ', '
      }

      srcSet += `${entry.image_url} ${entry.size}w`;
      sizes += `(max-width: ${entry.size*1.1112}px) ${entry.size}px`;
    }

    sizes += `, ${this.defaultOption.size}px`;

    return (
      <div
        key={`photo_gallery:index:${this.index}`}
        className={`photo-gallery__responsive-image__wrapper${elementClassName !== null ? ` ${elementClassName}` : ''}`}
        ref={this.containerRef}
      >

        <img
          className="photo-gallery__responsive-image"
          src={this.defaultOption.image_url}
          srcSet={srcSet}
          sizes={sizes}
          alt={this.title}
          onLoad={() => {
            if (this.onLoadImageCallback !== null) {
              this.onLoadImageCallback(this.index);
            }
          }}
        />

      </div>
    );
  }
}

export {ImageOption, GalleryResponsiveImage};

class PhotoGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      currentContentWidth: '100%',
      currentContentHeight: '80vh'
    };

    if (this.props.items && Array.isArray(this.props.items)) {
      for (const entry of this.props.items) {
        entry.onLoadImageCallback = (index) => {
          if (this.props.currentIndex === index) {
            this.resizeItemContainer(this.props.currentIndex);
          }
        }
      }
    }

    this.touchXStart = null;
    this.touchXEnd = null;
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.resizeItemContainer(this.props.currentIndex);

    this.setState({
      screenWidth: window.innerWidth
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if(prevProps.currentIndex !== this.props.currentIndex) {
  //     this.resizeItemContainer(this.props.currentIndex);
  //   }
  // }

  resizeItemContainer(indexReference) {
    if (this.props.items[indexReference-1].containerElement !== null) {
      this.setState({
        currentContentWidth: `${this.props.items[indexReference-1].containerElement.offsetWidth}px`,
        currentContentHeight: `${this.props.items[indexReference-1].containerElement.offsetHeight}px`
      });
    }
  }

  updateIndex(newIndex) {
    if (newIndex < 1 || newIndex > this.props.items.length) {
      return;
    }

    this.props.onUpdateIndex(newIndex);
    this.resizeItemContainer(newIndex)
  }

  getFooter() {
    if (this.props.enumeratePhotos) {
      return (
        <p className="photo-gallery__carousel__footer__text">
          {this.props.currentIndex} / {this.props.items.length}
        </p>
      );
    }

    return this.props.items.map((entry, index) => (
      <button
        key={`photo_gallery:items_count:index:${index}`}
        className={`photo-gallery__carousel__index-button${(index + 1) === this.props.currentIndex ? '--selected' : ''}`}
        onClick={() => this.updateIndex(index+1)}
        disabled={(index + 1) === this.props.currentIndex}
      >

        <i className="fa-solid fa-circle"></i>

      </button>
    ));
  }

  getCarousel() {
    if (!this.props.items || !Array.isArray(this.props.items) || this.props.currentIndex > this.props.items.length) {
      return null;
    }

    return (
      <div className="photo-gallery__carousel">

        <div className="photo-gallery__carousel__header">

          <h3 className="photo-gallery__carousel__header__title">{this.props.items[this.props.currentIndex - 1].title}</h3>

          <button
            className="photo-gallery__carousel__header__close-button"
            onClick={() => {
              if (this.props.onCloseGallery) {
                this.props.onCloseGallery();
              }
            }}
          >

            <i className="fa-solid fa-xmark"></i>

          </button>

        </div>

        <div className="photo-gallery__carousel__container">

          <button
            className="photo-gallery__carousel__side-button--left"
            disabled={this.props.currentIndex <= 1}
            onClick={() => this.updateIndex(this.props.currentIndex-1)}
          >

            <i className="fa-solid fa-chevron-left"></i>

          </button>

          <div
            className="photo-gallery__carousel__item-container"
            style={{
              width: this.state.currentContentWidth,
              height: this.state.currentContentHeight
            }}
            onTouchStart={(evt) => {
              this.touchXStart = getGesturePointFromEvent(evt).x;
            }}
            onTouchMove={(evt) => {
              if (this.touchXStart === null) {
                return;
              }

              this.touchXEnd = getGesturePointFromEvent(evt).x;
            }}
            onTouchEnd={(evt) => {
              if (this.touchXStart !== null && this.touchXEnd !== null) {
                const diff = this.touchXEnd - this.touchXStart;

                if (Math.abs(diff) > 45) {
                  this.updateIndex(diff < 0 ? this.props.currentIndex+1 : this.props.currentIndex-1)
                }

                this.touchXStart = null;
                this.touchXEnd = null;
              }
            }}
            onTouchCancel={(evt) => {
                this.touchXStart = null;
                this.touchXEnd = null;
            }}
          >

            {this.props.items.map((entry, index) => {
              let elementClassName = '';

              if (this.props.currentIndex > index+1) {
                elementClassName = 'photo-gallery__responsive-image__wrapper--left';
              }
              else if (this.props.currentIndex < index+1) {
                elementClassName = 'photo-gallery__responsive-image__wrapper--right';
              }

              entry.index = index+1;

              return entry.getImageElement(elementClassName);
            })}

          </div>

          <button
            className="photo-gallery__carousel__side-button--right"
            disabled={this.props.currentIndex >= this.props.items.length}
            onClick={() => this.updateIndex(this.props.currentIndex+1)}
          >

            <i className="fa-solid fa-chevron-right"></i>

          </button>

        </div>

        <div className="photo-gallery__carousel__footer">

          {this.getFooter()}

        </div>

      </div>
    );
  }

  render() {
    if (!this.props.visible || !this.props.items || !Array.isArray(this.props.items)) {
      return null;
    }

    return (
      <aside
        className="photo-gallery__main-container"
      >

        {this.getCarousel()}

      </aside>
    );
  }
}

export default PhotoGallery
