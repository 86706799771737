import React from 'react';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router'
import MainHeader from '../../components/main_header';
import MainFooter from '../../components/main_footer';
import LoadingIcon from '../../components/loading_icon';
import {RedText} from '../../components/default_elements';
import FormContent from '../form_content';
import {FEEDBACK_UNIT_IDENTIFIER_GET_API,
        CUSTOMER_FEEDBACK_POST_API,
        THANK_YOU_PATH,
        MAIN_PATH,
        CUSTOMER_FEEDBACK_TYPE_DOUBT_ID,
        DEFAULT_ERROR_MESSAGE} from '../../constants';
import {getModel, postModel} from '../../utils/functions';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../../utils/warning_message';
import DefaultMenuButton from '../../components/default_menu_button';
import './customer_feedback.scss';


class CustomerFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      apiFailed: false,
      unit: null,
      customer_feedback_targets: [],
      customer_feedback_types: [],
      addPersonalInformation: false,
      name: null,
      email: null,
      customer_feedback_type_id: null,
      customer_feedback_type: null,
      customer_feedback_target_id: null,
      description: null,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    this.getUnitData();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getUnitData() {
    this.setState({
      loadingData: true,
    });

    const update = {loadingData: false};

    try {
      const data = await getModel(`${FEEDBACK_UNIT_IDENTIFIER_GET_API}${this.props.match.params.unitIdentifier}`);

      if(data) {
        update.unit = data.unit;
        update.customer_feedback_types = data.customer_feedback_types;
        update.customer_feedback_targets = data.customer_feedback_targets;
      }
      // else {
      //
      // }
    }
    catch(errors) {
      let errorMessage = null;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 208:
              errorMessage = 'Link do canal de comunicação inválido. Por favor, entre em contato com o responsável pela unidade FYD desejada para receber o link correto.';

              break;

              default:
          }
        }

        if (errorMessage !== null) {
          update.showWarningMessage = true;
          update.warningMessage = errorMessage;
        }
        else {
          update.apiFailed = true;
        }
      }
    }

    this.setState(update);
  }

  async onFinishClick() {
    if (this.state.customer_feedback_type === null) {
      return;
    }

    const isDoubtType = this.state.customer_feedback_type.id === CUSTOMER_FEEDBACK_TYPE_DOUBT_ID;

    let warningMessages = [];
    let highlights = [];

    if (isDoubtType || this.state.addPersonalInformation) {
      if (this.state.name === null || this.state.name.length <= 0) {
        warningMessages.push("Nome não preenchido");
        highlights.push('name');
      }

      if (this.state.email === null || this.state.email.length <= 0) {
        warningMessages.push("E-mail não preenchido");
        highlights.push('email');
      }
      else if(this.state.email.match(/.+@.+\..+/) === null) {
        warningMessages.push("Formato de e-mail incorreto");
        highlights.push('email');
      }
    }

    if (this.state.customer_feedback_target_id === null) {
      warningMessages.push("Assunto não selecionado");
      highlights.push('customer_feedback_target_id');
    }

    if (highlights.length > 0) {
      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
      });

      return false;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
    });

    const data = {
      name: null,
      email: null,
      customer_feedback_type_id: parseInt(this.state.customer_feedback_type_id),
      customer_feedback_target_id: parseInt(this.state.customer_feedback_target_id),
      description: this.state.description,
    };

    if (isDoubtType || this.state.addPersonalInformation) {
      data.name = this.state.name;
      data.email = this.state.email;
    }

    try {
      if(await postModel(`${CUSTOMER_FEEDBACK_POST_API}${this.props.match.params.unitIdentifier}`, data) === null) {
        this.setState({
          apiFailed: true
        });
      }
      else {
        this.setState({
          redirect_to: THANK_YOU_PATH
        });
      }
    }
    catch(errors) {
      warningMessages = [DEFAULT_ERROR_MESSAGE];
      highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              // for(let parameter of error.parameters) {
              //   switch (parameter.name) {
              //     case 'scheduled_time':
              //       warningMessages.push(
              //         'Infelizmente o dia e horário selecionado acabou ' +
              //         'de ser agendado por outro aluno. Por favor, ' +
              //         'selecione outro dia e horário'
              //       );
              //       highlights.push('scheduled_date');
              //       highlights.push('scheduled_time');
              //
              //       break;
              //     default:
              //   }
              // }

              break;

              default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}`,
      });

      return false;
    }
  }

  isHighlighted(propertyName) {
    return this.state.highlights.includes(propertyName);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  getContentTitle() {
    if(this.state.apiFailed) {
      return (
        <div className="customer-feedback__title-wrapper">

          <h2 className="customer-feedback__title"><RedText>OOOPS!</RedText></h2>

        </div>
      );
    }

    if (this.state.unit !== null) {
      return (
        <div className="customer-feedback__title-wrapper">

          <h2 className="customer-feedback__title">CANAL DE <RedText>COMUNICAÇÃO</RedText></h2>

          <h3 className="customer-feedback__sub-title">Unidade {this.state.unit.name}</h3>

        </div>
      );
    }

    return (
      <div className="customer-feedback__title-wrapper">

        <h2 className="customer-feedback__title">CANAL DE <RedText>COMUNICAÇÃO</RedText></h2>

      </div>
    );
  }

  getContentText() {
    if(this.state.apiFailed) {
      return 'Infelizmente estamos com problemas temporários ' +
             'em nosso serviço. Por favor, tente novamente mais tarde.';
    }

    if (this.state.unit === null) {
      return null;
    }

    return (
      <React.Fragment>

        Este é um canal aberto e anônimo para que você possa nos deixar qualquer tipo de mensagem que sinta necessidade,
        seja ela uma crítica ou reclamação, uma sugestão, elogios ou até mesmo uma dúvida que tenha. Essas mensagens nos ajudam
        a melhorar os serviços que oferecemos a você, então lhe agradecemos muito pelo seu tempo e sinceridade!

      </React.Fragment>
    );
  }

  getAvailableFeedbackTargets() {
    if (this.state.customer_feedback_target_id === null) {
      return [
        SelectOption('', 'Selecione um assunto'),
        ...this.state.customer_feedback_targets.map((entry) => SelectOption(entry.id, entry.name))
      ];
    }

    return this.state.customer_feedback_targets.map((entry) => SelectOption(entry.id, entry.name));
  }

  getFeedbackTypeSession() {
    if (this.state.unit === null) {
      return null;
    }

    if (this.state.customer_feedback_type === null) {
      const typeOptions = this.state.customer_feedback_types.map((type) => {
        return (
          <DefaultMenuButton
            key={`custom_feedback_type:${type.id}`}
            className="customer-feedback__feedback-type__option-button"
            onClick={() => this.setState({
              customer_feedback_type_id: type.id,
              customer_feedback_type: type
            })}
            text={type.name}
            color="black"
          />
        );
      });

      return (
        <div className="customer-feedback__feedback-type">

          <h3 className="customer-feedback__feedback-type__title">Selecione o tipo de mensagem que deseja nos deixar:</h3>

          <div className="customer-feedback__feedback-type__options-container">

            {typeOptions}

          </div>


        </div>
      );
    }
    else {
      return (
        <div className="customer-feedback__feedback-type--row">

          <h3 className="customer-feedback__feedback-type__title">Tipo de mensagem:</h3>

          <div className="customer-feedback__feedback-type__selected-content">

            <p className="customer-feedback__feedback-type__selected-text">{this.state.customer_feedback_type.name}</p>

            <DefaultMenuButton
              className="customer-feedback__feedback-type__replace-button"
              onClick={() => this.setState({
                customer_feedback_type_id: null,
                customer_feedback_type: null
              })}
              text="Alterar"
              color="black"
            />

          </div>

        </div>
      );
    }
  }

  getFormInputs() {
    if (this.state.customer_feedback_type === null) {
      return null;
    }

    const isDoubtType = this.state.customer_feedback_type.id === CUSTOMER_FEEDBACK_TYPE_DOUBT_ID;

    return (
      <div className="customer-feedback__input-container">

        {isDoubtType ? (
          <p className="customer-feedback__alert-text">

            <i className="fas fa-exclamation customer-feedback__alert-text__icon"></i>
            <span className="customer-feedback__alert-text__text">
              Para dúvidas, os campos nome e email são obrigatórios para que possamos entrar em contato com uma responta.
            </span>

          </p>
        ) : (
          <div className="customer-feedback__personal-information-toggle">

            <input
              className="customer-feedback__personal-information-toggle__input"
              type="checkbox"
              id="addPersonalInformation"
              name="addPersonalInformation"
              onChange={(event) => this.handleInputChange(event)}
              checked={this.state.addPersonalInformation}
            />

            <label htmlFor="addPersonalInformation" className="customer-feedback__personal-information-toggle__label">

              Marque esta opção caso deseje informar sua identidade.

            </label>

          </div>
        )}

        {(isDoubtType || this.state.addPersonalInformation) &&
          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Informe seu nome"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.name || ''}
              autoComplete="name"
            />

            <DefaultInput
              name="email"
              isHighlighted={this.isHighlighted("email")}
              label="E-mail"
              type="email"
              placeholder="Informe seu e-mail"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.email || ''}
              autoComplete="email"
            />

          </HalfWrapper>
        }

        <DefaultInput
          name="customer_feedback_target_id"
          isHighlighted={this.isHighlighted("customer_feedback_target_id")}
          label="Assunto"
          type="select"
          handleInputChange={(event) => this.handleInputChange(event)}
          value={this.state.customer_feedback_target_id || ''}
          options={this.getAvailableFeedbackTargets()}
        />

        <DefaultInput
          className="customer-feedback__description"
          inputClassName="customer-feedback__description__input"
          name="description"
          label="Mensagem"
          type="textarea"
          placeholder="Digite sua mensagem"
          rows="5"
          handleInputChange={(event) => this.handleInputChange(event)}
          value={this.state.description || ''}
        />

      </div>
    );
  }

  inputsAreValid() {
    if (this.state.customer_feedback_type === null) {
      return false;
    }

    return this.state.description !== null && this.state.description.length > 0;
  }

  render() {
    if(this.state.redirect_to) {
      return (
        <Redirect push to={this.state.redirect_to} />
      );
    }

    return (
      <React.Fragment>

        <Helmet>
          <title>Feedback de aluno - FYD Club</title>
        </Helmet>

        <MainHeader
          location={this.props.location}
          collapse={true}
        />

        <div className="customer-feedback__wrapper">

          <section className="customer-feedback">

            <div className="customer-feedback__content">

              {this.state.loadingData ?
                <LoadingIcon />:
                <FormContent
                  contentTitle={this.getContentTitle()}
                  contentText={this.getContentText()}
                  questionsId={0}
                  questions={
                    <div className="customer-feedback__main-wrapper">

                      <div className="customer-feedback__warning-container">

                        <WarningMessage
                          message={this.state.warningMessage}
                          onClose={() => {
                            const update = {
                              highlights: [],
                              showWarningMessage: false
                            };

                            if (this.state.unit === null) {
                              update.redirect_to = MAIN_PATH;
                            }

                            this.setState(update)
                          }}
                          visible={this.state.showWarningMessage}
                        />

                      </div>

                      {this.getFeedbackTypeSession()}

                      {this.getFormInputs()}

                    </div>
                  }
                  onFinishClick={() => this.onFinishClick()}
                  maxId={0}
                  showControllers={!this.state.apiFailed && this.state.unit !== null && this.state.customer_feedback_type !== null}
                  allowNext={this.inputsAreValid()}
                  finishText="Enviar"
                  uploadingText="Enviando..."
                />
              }

            </div>

          </section>

          <MainFooter
            collapse={true}
          />

        </div>

      </React.Fragment>
    );
  }
}

export default CustomerFeedback
