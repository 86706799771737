import React from 'react';
import './disc_question.scss';

const MAX_SCORE = 4;

class DiscQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentScore: MAX_SCORE
    };
  }

  componentDidMount() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  getOptions() {
    return this.props.options.map((option, position) => {
      return (
        <React.Fragment key={`disc_question_${option.id}`}>

          <button
            className={`disc-question__button disc-score-${option.value}`}
            onClick={() => {
              if(option.value && this.state.currentScore >= 1) {
                return;
              }

              option.value = this.state.currentScore;

              if(this.state.currentScore >= 1) {
                this.setState({
                  currentScore: this.state.currentScore - 1
                });
              }
              else {
                for(let entry of this.props.options) {
                  entry.value = null;

                  if(entry.id !== option.id) {
                    this.props.onChange(entry.id, entry.value);
                  }
                }

                option.value = MAX_SCORE;

                this.setState({
                  currentScore: MAX_SCORE - 1
                });
              }

              this.props.onChange(option.id, option.value);
            }}
          >
            <span className="disc-question__button__score">
              {option.value}
            </span>
            <span className="disc-question__button__text">
              {option.name}
            </span>
          </button>

        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <React.Fragment>

        <div className="disc-question__wrapper">

          {this.getOptions()}

        </div>

      </React.Fragment>
    );
  }
}

export default DiscQuestion
