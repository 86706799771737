import React from 'react';
import {Helmet} from "react-helmet";
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import ChatOverlayButton from '../components/chat_overlay_button';
import LoadingIcon from '../components/loading_icon';
import {VerticalAccordionContainer} from '../utils/pose_containers';
import DefaultInput, {HalfWrapper, SelectOption} from '../utils/default_input';
import WarningMessage from '../utils/warning_message';
import {RedText} from '../components/default_elements';
import * as routes from '../constants';
import {SERVICE_FUNCIONAL, SERVICE_PILATES, SERVICE_GYM, SERVICE_BIKE, SERVICE_BOOTCAMP, SERVICE_CARDIO, SERVICE_CORE, DEFAULT_ERROR_MESSAGE} from '../constants';
import {getModels, postModel, getPhoneText, getAsLocalDateString, getLocalDateIsoString} from '../utils/functions';
import './experimental_class.scss';

import hex from '../images/hexagon_red.svg';

import funcional_180 from '../images/funcional2_180.jpg';
import funcional_150 from '../images/funcional2_150.jpg';
import funcional_130 from '../images/funcional2_130.jpg';
import funcional_180_2x from '../images/funcional2_180_2x.jpg';
import funcional_150_2x from '../images/funcional2_150_2x.jpg';
import funcional_130_2x from '../images/funcional2_130_2x.jpg';

import pilates_180 from '../images/pilates2_180.jpg';
import pilates_150 from '../images/pilates2_150.jpg';
import pilates_130 from '../images/pilates2_130.jpg';
import pilates_180_2x from '../images/pilates2_180_2x.jpg';
import pilates_150_2x from '../images/pilates2_150_2x.jpg';
import pilates_130_2x from '../images/pilates2_130_2x.jpg';

import gym_180 from '../images/gym2_180.jpg';
import gym_150 from '../images/gym2_150.jpg';
import gym_130 from '../images/gym2_130.jpg';
import gym_180_2x from '../images/gym2_180_2x.jpg';
import gym_150_2x from '../images/gym2_150_2x.jpg';
import gym_130_2x from '../images/gym2_130_2x.jpg';

import bike_180 from '../images/bike2_180.jpg';
import bike_150 from '../images/bike2_150.jpg';
import bike_130 from '../images/bike2_130.jpg';
import bike_180_2x from '../images/bike2_180_2x.jpg';
import bike_150_2x from '../images/bike2_150_2x.jpg';
import bike_130_2x from '../images/bike2_130_2x.jpg';

import cardio_180 from '../images/cardio2_180.jpg';
import cardio_150 from '../images/cardio2_150.jpg';
import cardio_130 from '../images/cardio2_130.jpg';
import cardio_180_2x from '../images/cardio2_180_2x.jpg';
import cardio_150_2x from '../images/cardio2_150_2x.jpg';
import cardio_130_2x from '../images/cardio2_130_2x.jpg';

import bootcamp_180 from '../images/bootcamp2_180.jpg';
import bootcamp_150 from '../images/bootcamp2_150.jpg';
import bootcamp_130 from '../images/bootcamp2_130.jpg';
import bootcamp_180_2x from '../images/bootcamp2_180_2x.jpg';
import bootcamp_150_2x from '../images/bootcamp2_150_2x.jpg';
import bootcamp_130_2x from '../images/bootcamp2_130_2x.jpg';

import core_180 from '../images/core2_180.jpg';
import core_150 from '../images/core2_150.jpg';
import core_130 from '../images/core2_130.jpg';
import core_180_2x from '../images/core2_180_2x.jpg';
import core_150_2x from '../images/core2_150_2x.jpg';
import core_130_2x from '../images/core2_130_2x.jpg';

class ExperimentalClass extends React.Component {
  constructor(props) {
    super(props);

    const today = getLocalDateIsoString(new Date());

    const promotionStartDate = '2022-09-01';
    // const promotionEndDate = '2022-09-30';

    this.state = {
      enablePromotion: today >= promotionStartDate, //&& today <= promotionEndDate
      loadingData: true,
      submittingData: false,
      registrationCompleted: false,
      apiFailed: false,
      service_map: {},
      services: {},
      serviceSelected: null,
      selectServiceVisible: true,
      formVisible: false,
      experimental_class: {
        name: "",
        email: "",
        phone: "",
        physical_activity: "",
        physical_condition: "",
        permission_to_send_email_marketing: false,
        date: "",
        hour: "",
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      screenWidth: window.innerWidth,
    };

    this.pageExited = false;
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', 'G-8YRPC28XN5', {'page_path': this.props.location.pathname});
    }

    const result = await getModels(routes.EXPERIMENTAL_CLASS_GET_API);

    if(result === null) {
      this.setState({
        loadingData: false,
        apiFailed: true,
      });

      return;
    }

    const service_map = result.service_map;
    const services = result.services.filter((entry) => entry !== SERVICE_CARDIO && entry !== SERVICE_CORE);

    services.sort((a, b) => {
      const aIsAvailable = service_map[a].length > 0;
      const bIsAvailable = service_map[b].length > 0;

      if(aIsAvailable !== bIsAvailable) {
        return bIsAvailable - aIsAvailable;
      }

      return a.localeCompare(b);
    })

    let params = new URLSearchParams(window.location.search);

    const service = params.get('servico');

    const experimental_class = {...this.state.experimental_class};
    let serviceSelected = null;
    let selectServiceVisible = true;
    let formVisible = false;

    if(service && service_map[service]) {
      serviceSelected = service;
      selectServiceVisible = false;
      formVisible = true;

      if(service_map[service].length > 0) {
        experimental_class.date = service_map[service][0].iso_date;
      }
    }

    if (!this.pageExited) {
      this.setState({
        loadingData: false,
        apiFailed: false,
        service_map,
        services,
        serviceSelected,
        selectServiceVisible,
        formVisible,
        experimental_class
      });

      this.resizeListener = () => this.updateSize();
      window.addEventListener("resize", this.resizeListener);
    }
  }

  componentWillUnmount() {
    this.pageExited = true;

    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getWarningMessage() {
    let message = 'O agendamento de aulas experimental não está disponível no momento. Por favor, entre em contato conosco para mais informações.';

    if(this.state.apiFailed) {
      message = 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.';
    }

    return (
      <div className="experimental-class__warning-message">

        <i className="fas fa-exclamation experimental-class__warning-message__icon"></i>
        <p className="experimental-class__warning-message__text">{message}</p>

      </div>
    );
  }

  hasServiceAvailable() {
    for(const service of this.state.services) {
      if(this.state.service_map[service].length > 0) {
        return true;
      }
    }

    return false;
  }

  onSelectService(service) {
    if(this.state.service_map[service] && this.state.service_map[service].length <= 0) {
      return;
    }

    const experimental_class = {
      ...this.state.experimental_class,
      date: this.state.service_map[service][0].iso_date
    };

    if(process.env.NODE_ENV === 'production') {
      window.gtag('event', `Experimental - ${service}`, {
        'event_category' : 'Click',
        'event_label' : service
      });
    }

    this.setState({
      serviceSelected: service,
      selectServiceVisible: false,
      formVisible: true,
      experimental_class
    });
  }

  getServiceImage(service) {
    switch (service) {
      case SERVICE_FUNCIONAL:
        return (
          <img
            className="experimental-class__select-service__option__img"
            src={funcional_180}
            srcSet={`${funcional_180} 180w,
                     ${funcional_180_2x} 360w,
                     ${funcional_150} 150w,
                     ${funcional_150_2x} 300w,
                     ${funcional_130} 130w,
                     ${funcional_130_2x} 260w`}
            sizes="(max-width: 470px) 130px, (max-width: 1080px) 150px, 180px"
            alt="Treinadora instruindo aluno na execução de exercício fisico"
          />
        );
      case SERVICE_PILATES:
        return (
          <img
            className="experimental-class__select-service__option__img"
            src={pilates_180}
            srcSet={`${pilates_180} 180w,
                     ${pilates_180_2x} 360w,
                     ${pilates_150} 150w,
                     ${pilates_150_2x} 300w,
                     ${pilates_130} 130w,
                     ${pilates_130_2x} 260w`}
            sizes="(max-width: 470px) 130px, (max-width: 1080px) 150px, 180px"
            alt="Mulher executando exercícios em aparelho de pilates"
          />
        );
      case SERVICE_GYM:
        return (
          <img
            className="experimental-class__select-service__option__img"
            src={gym_180}
            srcSet={`${gym_180} 180w,
                     ${gym_180_2x} 360w,
                     ${gym_150} 150w,
                     ${gym_150_2x} 300w,
                     ${gym_130} 130w,
                     ${gym_130_2x} 260w`}
            sizes="(max-width: 470px) 130px, (max-width: 1080px) 150px, 180px"
            alt="Treinadora instruindo aluno na execução de exercício fisico"
          />
        );
      case SERVICE_BIKE:
        return (
          <img
            className="experimental-class__select-service__option__img"
            src={bike_180}
            srcSet={`${bike_180} 180w,
                     ${bike_180_2x} 360w,
                     ${bike_150} 150w,
                     ${bike_150_2x} 300w,
                     ${bike_130} 130w,
                     ${bike_130_2x} 260w`}
            sizes="(max-width: 470px) 130px, (max-width: 1080px) 150px, 180px"
            alt="Grupo de alunos se exercitando na bicicleta"
          />
        );
      case SERVICE_CARDIO:
        return (
          <img
            className="experimental-class__select-service__option__img"
            src={cardio_180}
            srcSet={`${cardio_180} 180w,
                     ${cardio_180_2x} 360w,
                     ${cardio_150} 150w,
                     ${cardio_150_2x} 300w,
                     ${cardio_130} 130w,
                     ${cardio_130_2x} 260w`}
            sizes="(max-width: 470px) 130px, (max-width: 1080px) 150px, 180px"
            alt="Grupo de alunos realizando atividade física"
          />
        );
      case SERVICE_BOOTCAMP:
        return (
          <img
            className="experimental-class__select-service__option__img"
            src={bootcamp_180}
            srcSet={`${bootcamp_180} 180w,
                     ${bootcamp_180_2x} 360w,
                     ${bootcamp_150} 150w,
                     ${bootcamp_150_2x} 300w,
                     ${bootcamp_130} 130w,
                     ${bootcamp_130_2x} 260w`}
            sizes="(max-width: 470px) 130px, (max-width: 1080px) 150px, 180px"
            alt="Grupo de alunos realizando atividade física"
          />
        );
      case SERVICE_CORE:
        return (
          <img
            className="experimental-class__select-service__option__img"
            src={core_180}
            srcSet={`${core_180} 180w,
                     ${core_180_2x} 360w,
                     ${core_150} 150w,
                     ${core_150_2x} 300w,
                     ${core_130} 130w,
                     ${core_130_2x} 260w`}
            sizes="(max-width: 470px) 130px, (max-width: 1080px) 150px, 180px"
            alt="Grupo de alunos executando o exercício prancha"
          />
        );
      default:
        return (
          <img
            className="experimental-class__select-service__option__img"
            src={funcional_180}
            srcSet={`${funcional_180} 180w,
                     ${funcional_180_2x} 360w,
                     ${funcional_150} 150w,
                     ${funcional_150_2x} 300w,
                     ${funcional_130} 130w,
                     ${funcional_130_2x} 260w`}
            sizes="(max-width: 470px) 130px, (max-width: 1080px) 150px, 180px"
            alt="Treinadora instruindo aluno na execução de exercício fisico"
          />
        );
    }
  }

  getServiceOptions() {
    return this.state.services.map((service) => {
      const available = this.state.service_map[service].length > 0;

      return (
        <button
          key={`experimental_class:service_option:${service}`}
          className={`experimental-class__select-service__option${this.state.serviceSelected === service ? '--selected' : ''}`}
          onClick={() => this.onSelectService(service)}
          disabled={!available}
        >

          <div className="experimental-class__select-service__option__img-wrapper">

            {/* <div className="experimental-class__select-service__option__img-detail"></div> */}
            {available &&
              <img
                className="experimental-class__select-service__option__img-detail"
                src={hex}
                alt=""
              />
            }

            {this.getServiceImage(service)}

            {!available &&
              <div className="experimental-class__select-service__option__unavailable">
                <p className="experimental-class__select-service__option__unavailable__text">Indisponível</p>
              </div>
            }

          </div>


          <p className="experimental-class__select-service__option__text">

            {service}

          </p>

        </button>
      );
    });
  }

  isHighlighted(propertyName) {
    return this.state.highlights.includes(propertyName);
  }

  getAvailableDates() {
    if(this.state.serviceSelected === null) {
      return [];
    }

    return [
      ...this.state.service_map[this.state.serviceSelected].map((entry) => SelectOption(entry.iso_date, `${entry.label} - ${entry.date}`))
    ];
  }

  getAvailableTimes() {
    if(this.state.serviceSelected === null || this.state.experimental_class.date.length <= 0) {
      return [SelectOption('', 'Selecione um horário')];
    }

    const dateEntry = this.state.service_map[this.state.serviceSelected].find((entry) => entry.iso_date === this.state.experimental_class.date);

    return [
      SelectOption('', 'Selecione um horário'),
      ...dateEntry.hours.map((entry) => SelectOption(entry.label, entry.label))
    ];
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const experimental_class = {
      ...this.state.experimental_class,
      [name]: value
    };

    this.setState({experimental_class});
  }

  validateInputs() {
    const highlights = [];

    if(this.state.experimental_class.name.length <= 0) {
      highlights.push('name');
    }
    if(this.state.experimental_class.email.match(/.+@.+\..+/) === null) {
      highlights.push('email');
    }
    if(this.state.experimental_class.phone.length <= 0) {
      highlights.push('phone');
    }
    if(this.state.experimental_class.date.length <= 0) {
      highlights.push('date');
    }
    if(this.state.experimental_class.hour.length <= 0) {
      highlights.push('hour');
    }

    return highlights;
  }

  inputsAreValid() {
    return this.validateInputs().length <= 0;
  }

  async submitExperimentalClass() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'Experimental class - Form request', {
        'event_category' : 'Request',
        'event_label' : 'Experimental class'
      });
    }

    const highlights = this.validateInputs();

    if(highlights.length > 0) {
      if(highlights.length === 1 && highlights[0] === 'email' && this.state.experimental_class.email.length > 0) {
        this.setState({
          highlights: highlights,
          showWarningMessage: true,
          warningMessage: 'Formato do email incorreto.'
        });
      }
      else {
        this.setState({
          highlights: highlights,
          showWarningMessage: true,
          warningMessage: 'Informe os campos destacados acima.'
        });
      }


      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      submittingData: true
    });

    const data = {
      ...this.state.experimental_class,
      target_service: this.state.serviceSelected
    };

    try {
      if(await postModel(routes.EXPERIMENTAL_CLASS_POST_API, data) === null) {
        this.setState({
          apiFailed: true,
          submittingData: false
        });
      }
      else {
        this.setState({
          submittingData: false,
          registrationCompleted: true
        });

        if (process.env.NODE_ENV === 'production') {
          window.gtag('event', 'Experimental class - Submit confirmation', {
            'event_category' : 'Submit confirmation',
            'event_label' : 'Experimental class'
          });
        }
      }
    }
    catch(errors) {
      let warningMessages = [DEFAULT_ERROR_MESSAGE];
      let highlights = [];

      if(errors instanceof Array) {
        // for(let error of errors) {
        //   switch (error.code) {
        //     case 103:
        //       for(let parameter of error.parameters) {
        //         switch (parameter.name) {
        //           case 'scheduled_time':
        //             warningMessages.push(
        //               'Infelizmente o dia e horário selecionado acabou ' +
        //               'de ser agendado por outro aluno. Por favor, ' +
        //               'selecione outro dia e horário'
        //             );
        //             highlights.push('scheduled_date');
        //             highlights.push('scheduled_time');
        //
        //             break;
        //           default:
        //         }
        //       }
        //
        //       break;
        //     case 106:
        //       for(let parameter of error.parameters) {
        //         switch (parameter.name) {
        //           case 'Has exam scheduled':
        //             warningMessages.push(
        //               'Nosso sistema consta que há um agendamento de exame ' +
        //               'recente ainda em aberto. Por favor, tente reagendar ' +
        //               'seu exame novamente dentro de alguns dias. Em caso de ' +
        //               'qualquer dúvida, por favor, entre em contato com seu ' +
        //               'treinador. Obrigado!'
        //             );
        //
        //             break;
        //           default:
        //         }
        //       }
        //
        //       break;
        //
        //       default:
        //   }
        // }

        this.setState({
          submittingData: false,
          highlights: highlights,
          showWarningMessage: true,
          warningMessage: `${warningMessages.join('; ')}`,
        });
      }
    }
  }

  serviceSelectedIsUnavailable() {
    return this.state.serviceSelected !== null && this.state.service_map[this.state.serviceSelected] && this.state.service_map[this.state.serviceSelected].length <= 0;
  }

  getMainMessage() {
    if(this.state.registrationCompleted) {
      return (
        <p className="experimental-class__main-message__text">
          Sua aula experimental de <RedText>{this.state.serviceSelected.toUpperCase()}</RedText> foi
          agendada com sucesso para o
          dia <RedText>{getAsLocalDateString(this.state.experimental_class.date)}</RedText> às <RedText>{this.state.experimental_class.hour}</RedText>.
          Estamos ansiosos para te conhecer!
        </p>
      );
    }

    return (
      <p className="experimental-class__main-message__text">
        Infelizmente o agendamento de aula experimental do serviço
        de <RedText>{this.state.serviceSelected.toUpperCase()}</RedText> encontra-se
        indisponível no momento. Por favor, entre em contato
        conosco para maiores informações.
      </p>
    );
  }

  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>Agende uma aula experimental - FYD Club</title>
          <meta name="description" content="Teremos prazer em lhe receber e mostrar porque somos diferentes." />
        </Helmet>

        <MainHeader
          location={this.props.location}
          enablePromotion={this.state.enablePromotion}
          invertPromotionColors={true}
        />

        <div className="experimental-class__wrapper">

          <section className={`experimental-class${this.state.enablePromotion ? '--promotion-enalbed' : ''}`}>

            <div className="experimental-class__content">

              {this.state.screenWidth > 380 ? (
                <h1 className="experimental-class__title">Agendar <RedText>AULA EXPERIMENTAL</RedText></h1>
              ) : (
                <h1 className="experimental-class__title">Agendar<br/><RedText>AULA EXPERIMENTAL</RedText></h1>
              )}

              <p className="experimental-class__message">

                Venha conhecer nossos serviços na prática sem nenhum compromisso e dê o primeiro passo para
                começar a alcançar seus objetivos!

              </p>

              {this.state.loadingData || this.state.submittingData ?
                <LoadingIcon
                  text={this.state.submittingData ? 'Agendando aula' : 'Carregando'}
                />:
                (this.state.apiFailed || !this.hasServiceAvailable()) ?
                  this.getWarningMessage():
                  <div
                    className="experimental-class__entries-container"
                  >

                    <section
                      className="experimental-class__select-service"
                    >

                      <header
                        className={`experimental-class__select-service__header${this.state.selectServiceVisible ? '' : '--highlighted'}`}
                        onClick={() => {
                          if (!this.state.registrationCompleted) {
                            this.setState({selectServiceVisible: true});
                          }
                        }}
                        style={this.state.registrationCompleted ? {cursor: 'default'} : null}
                      >

                        {!this.state.selectServiceVisible &&
                          <i className="fas fa-chevron-right experimental-class__select-service__header__icon"></i>
                        }

                        <h2 className={`experimental-class__select-service__header__text${this.state.selectServiceVisible ? '' : '--highlighted'}`}>

                          <div className="experimental-class__select-service__header__text--undelined">

                            {this.state.serviceSelected !== null ? 'Serviço selecionado' : 'Selecione o serviço desejado'}:

                          </div>

                          {this.state.serviceSelected !== null &&
                            <span className="experimental-class__select-service__header__service-text">{this.state.serviceSelected}</span>
                          }

                        </h2>

                      </header>

                      <VerticalAccordionContainer
                        className="vertical-accordion-container experimental-class__select-service"
                        pose={this.state.selectServiceVisible ? 'verticalOpen' : 'verticalClosed'}
                      >

                        <div className="experimental-class__select-service__wrapper">

                          {this.getServiceOptions()}

                        </div>

                      </VerticalAccordionContainer>

                    </section>

                    {(this.state.registrationCompleted || this.serviceSelectedIsUnavailable()) ?
                      <div className="experimental-class__main-message">
                        {this.getMainMessage()}
                      </div>:
                      <section
                        className="experimental-class__form"
                      >

                        <VerticalAccordionContainer
                          className="vertical-accordion-container experimental-class__form"
                          pose={this.state.formVisible ? 'verticalOpen' : 'verticalClosed'}
                        >

                          <header
                            className="experimental-class__form__header"
                          >

                            <h2 className="experimental-class__form__header__text">

                              Informe os dados para realizar o agendamento:

                            </h2>

                          </header>

                          <div className="experimental-class__form__wrapper">

                            {this.state.screenWidth > 970 ? (
                              <HalfWrapper>

                                <DefaultInput
                                  name="name"
                                  isHighlighted={this.isHighlighted("name")}
                                  label="Nome"
                                  type="text"
                                  placeholder="Informe seu nome"
                                  maxLength="128"
                                  handleInputChange={(event) => this.handleInputChange(event)}
                                  value={this.state.experimental_class.name}
                                  autoComplete="name"
                                />

                                <HalfWrapper>

                                  <DefaultInput
                                    name="date"
                                    isHighlighted={this.isHighlighted("date")}
                                    label="Dia"
                                    type="select"
                                    handleInputChange={(event) => this.handleInputChange(event)}
                                    value={this.state.experimental_class.date || ''}
                                    options={this.getAvailableDates()}
                                  />

                                  <DefaultInput
                                    name="hour"
                                    isHighlighted={this.isHighlighted("hour")}
                                    label="Horário"
                                    type="select"
                                    handleInputChange={(event) => this.handleInputChange(event)}
                                    value={this.state.experimental_class.hour}
                                    options={this.getAvailableTimes()}
                                  />

                                </HalfWrapper>

                              </HalfWrapper>
                            ) : (
                              <React.Fragment>

                                <DefaultInput
                                  name="name"
                                  isHighlighted={this.isHighlighted("name")}
                                  label="Nome"
                                  type="text"
                                  placeholder="Informe seu nome"
                                  maxLength="128"
                                  handleInputChange={(event) => this.handleInputChange(event)}
                                  value={this.state.experimental_class.name}
                                  autoComplete="name"
                                />

                                <HalfWrapper>

                                  <DefaultInput
                                    name="date"
                                    isHighlighted={this.isHighlighted("date")}
                                    label="Dia"
                                    type="select"
                                    handleInputChange={(event) => this.handleInputChange(event)}
                                    value={this.state.experimental_class.date || ''}
                                    options={this.getAvailableDates()}
                                  />

                                  <DefaultInput
                                    name="hour"
                                    isHighlighted={this.isHighlighted("hour")}
                                    label="Horário"
                                    type="select"
                                    handleInputChange={(event) => this.handleInputChange(event)}
                                    value={this.state.experimental_class.hour}
                                    options={this.getAvailableTimes()}
                                  />

                                </HalfWrapper>

                              </React.Fragment>
                            )}

                            <HalfWrapper>

                              <DefaultInput
                                name="email"
                                isHighlighted={this.isHighlighted("email")}
                                label="E-mail"
                                type="email"
                                placeholder="Informe seu e-mail"
                                maxLength="128"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={this.state.experimental_class.email}
                                autoComplete="email"
                              />

                              <DefaultInput
                                name="phone"
                                isHighlighted={this.isHighlighted("phone")}
                                label="Telefone"
                                type="tel"
                                placeholder="Informe o seu telefone/Whatsapp com DDD"
                                maxLength="14"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={getPhoneText(this.state.experimental_class.phone)}
                                autoComplete="tel"
                              />

                            </HalfWrapper>

                            <div className="experimental-class__form__checkbox-input-container">

                              <input
                                type="checkbox"
                                id="permission_to_send_email_marketing"
                                name="permission_to_send_email_marketing"
                                onChange={(event) => this.handleInputChange(event)}
                                className="experimental-class__form__checkbox-input"
                                checked={this.state.contractAccpeted}
                              />

                              <label htmlFor="permission_to_send_email_marketing" className="experimental-class__form__checkbox-input-label">

                                Gostaria de ser notificado quando houver promoções ou eventos da FYD Club. Prometemos não encher sua caixa de email! Realizamos envios de emails promocionais poucas vezes no ano.

                              </label>

                            </div>

                            <DefaultInput
                              name="physical_activity"
                              isHighlighted={this.isHighlighted("physical_activity")}
                              label="Pratica ou praticou recentemente alguma atividade física? Isso nos ajudará a adaptar melhor sua primeira aula."
                              type="textarea"
                              placeholder="Ex: caminhada, ciclismo, natação, etc"
                              maxLength="512"
                              rows="3"
                              handleInputChange={(event) => this.handleInputChange(event)}
                              value={this.state.experimental_class.physical_activity || ''}
                              autoComplete="off"
                            />

                            <DefaultInput
                              name="physical_condition"
                              isHighlighted={this.isHighlighted("physical_condition")}
                              label="Você possui alguma restrição física?"
                              type="textarea"
                              placeholder="Ex: hérnia de disco, asma, problema no joelho, etc"
                              maxLength="512"
                              rows="3"
                              handleInputChange={(event) => this.handleInputChange(event)}
                              value={this.state.experimental_class.physical_condition || ''}
                              autoComplete="off"
                            />

                          </div>

                          <WarningMessage
                            className={`experimental-class__form__warning-message`}
                            background="white"
                            color="#ff3d53"
                            message={this.state.warningMessage}
                            onClose={() => {this.setState({highlights: [], showWarningMessage: false})}}
                            visible={this.state.showWarningMessage}
                          />

                          <button
                            className={`experimental-class__form__submit-button${this.inputsAreValid() ? '--enabled' : ''}`}
                            onClick={() => this.submitExperimentalClass()}
                          >

                            Agendar aula experimental

                          </button>

                        </VerticalAccordionContainer>

                      </section>
                    }

                  </div>
              }

            </div>

          </section>

          <MainFooter />

        </div>

        <ChatOverlayButton />

      </React.Fragment>
    );
  }
}

export default ExperimentalClass
