import React from 'react';
import { render } from 'react-snapshot';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './libs/fontawesome-6.5.1-web/scss/fontawesome.scss';
import './libs/fontawesome-6.5.1-web/scss/solid.scss';
import './libs/fontawesome-6.5.1-web/scss/brands.scss';
import './init.scss';
import './general.scss';
import MainPage from './pages/main_page';
import UserRegistrationForm from './forms/user_registration/user_registration_form';
import AcceptContract from './forms/user_registration/accept_contract';
import ScheduleAppointment from './forms/service_appointment/schedule_appointment';
import DietSample from './forms/diet_sample/diet_sample';
import CustomerFeedback from './forms/customer_feedback/customer_feedback';
import ThankYou from './forms/user_registration/thank_you';
import Blank from './pages/blank';
import TrainingCalendarView from './pages/training_calendar/training_calendar_view';
import PollConfirmation from './pages/poll/poll_confirmation';
import UnsubscribeEmail from './pages/email_marketing/unsubscribe_email';
import Services from './pages/services';
import TrainingHours from './pages/training_hours';
import ExperimentalClass from './pages/experimental_class';
// import Promotion from './components/promotion';
import * as serviceWorker from './serviceWorker';
import * as routes from './constants';
import {APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID,
        APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID} from './constants';

render((
  <React.Fragment>

    <BrowserRouter>

      {/* <Route
        path="/"
        component={Promotion}
      /> */}

      <Switch>

        <Route
          path="/cadastro-de-aluno/:userHash"
          component={UserRegistrationForm}
        />

        <Route
          path="/aceite-de-contrato/:userHash"
          component={AcceptContract}
        />

        <Route
          path="/agendar-avaliacao-fisica/:userHash"
          render={(props) => (
            <ScheduleAppointment
              key="schedule_service_appointment_view"
              {...props}
              appointment_type_id={APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID}
            />
          )}
        />
        <Route
          path="/agendar-avaliacao-nutricional/:userHash"
          render={(props) => (
            <ScheduleAppointment
              key="schedule_service_appointment_view"
              {...props}
              appointment_type_id={APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID}
            />
          )}
        />

        <Route
          path="/historico-de-alimentacao/:userHash"
          component={DietSample}
        />

        <Route
          path="/canal-de-comunicacao-do-cliente/:unitIdentifier"
          component={CustomerFeedback}
        />

        <Route
          path="/periodizacao/:authHash"
          render={(props) => (
            <TrainingCalendarView
              {...props}
              key="training_calendar_view"
              loadDefaultPeriod={false}
            />
          )}
        />

        {/* <Route
          path={routes.TRAINING_PERIOD_PATH}
          render={(props) => (
            <TrainingCalendarView
              {...props}
              key="training_calendar_view"
              loadDefaultPeriod={true}
            />
          )}
        /> */}

        <Route
          path="/resposta-de-levantamento/:userHash"
          component={PollConfirmation}
        />

        <Route
          path="/remover-email-de-lista-para-divulgacao/:userHash"
          component={UnsubscribeEmail}
        />

        <Route
          path={routes.THANK_YOU_PATH}
          component={ThankYou}
        />

        <Route
          path={routes.BLANK_PATH}
          component={Blank}
        />

        <Route
          path={routes.SERVICES_PATH}
          component={Services}
        />

        <Route
          path={routes.WORKING_HOURS_PATH}
          component={TrainingHours}
        />

        <Route
          path={routes.EXPERIMENTAL_CLASS_PATH}
          component={ExperimentalClass}
        />

        <Route
          path={routes.MAIN_PATH}
          component={MainPage}
        />

        <Redirect from="/" to={routes.MAIN_PATH} />

      </Switch>

    </BrowserRouter>

  </React.Fragment>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
