import React from 'react';
import './small_loading_icon.scss';

import loadingCircleOuter from '../images/loading_circle_outer.svg';
import loadingCircleInner from '../images/loading_circle_inner.svg';

function SmallLoadingIcon(props) {
  return (
    <div className="small-loading-icon">

      <p className="small-loading-icon__text">{props.text || 'Carregando'}</p>

      <img
        className="small-loading-icon__icon-2"
        src={loadingCircleInner}
        alt=""
      />
      <img
        className="small-loading-icon__icon-1"
        src={loadingCircleOuter}
        alt=""
      />

    </div>
  );
}

export default SmallLoadingIcon;
