import React from 'react';
import './dropdown_menu.scss';

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleBySelection: false,
      screenWidth: window.innerWidth
    };

    this.timeTracked = 0;
    this.timeReference = null;
    this.tracking = false;
    this.mounted = false;
    this.mouseIsOver = false;

    this.ref = null

    this.setRef = (element) => {
      this.ref = element;
    };
  }

  async componentDidMount() {
    this.mounted = true;

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getStyle() {
    if (!this.props.element) {
      return null;
    }

    return {
      top: `${this.props.element.offsetTop + this.props.element.offsetHeight + 2}px`,
      left: `${this.props.element.offsetLeft}px`,
      width: `${this.props.element.offsetWidth}px`,
      maxHeight: `${0.8*(window.innerHeight - (this.props.element.offsetTop + this.props.element.offsetHeight))}px`
    };
  }

  trackElement() {
    if (!this.mounted) {
      return;
    }

    if (this.timeReference === null) {
      this.timeReference = Date.now();
    }
    else {
      this.timeTracked = (Date.now() - this.timeReference)/1000;
    }

    const update = {};

    if (this.props.element) {
      if (this.props.visible && this.props.showWhenSelected) {
        update.visibleBySelection = this.mouseIsOver || (this.props.element.contains(document.activeElement) || (this.ref !== null && this.ref.contains(document.activeElement)));
      }
    }

    this.setState(update);
    requestAnimationFrame(this.trackElement.bind(this));
  }

  getItems() {
    if (this.props.list && this.props.list.length > 0) {
      return this.props.list.map((item) => {
        return (
          <li
            key={`dropdown_menu:item:${item.id}`}
            className="dropdown-menu__list-item"
            onClick={() => {
              if (this.props.onSelectItem) {
                this.props.onSelectItem(item);
              }
            }}
          >
            {item.name}
          </li>
        );
      });
    }

    return (
      <li className="dropdown-menu__list-item--disabled">

        <p className="dropdown-menu__no-item-text">{this.props.noItemText || 'Nenhum item encontrado'}</p>

      </li>
    );
  }

  render() {
    if (!this.props.visible || !this.props.element) {
      return null;
    }

    if (!this.tracking) {
      this.tracking = true;
      requestAnimationFrame(this.trackElement.bind(this));
    }

    if (this.props.showWhenSelected && !this.state.visibleBySelection) {
      return null;
    }

    return (
      <aside
        className="dropdown-menu"
        style={this.getStyle()}
        ref={this.setRef}
        onMouseEnter={() => {
          this.mouseIsOver = true;
        }}
        onMouseLeave={() => {
          this.mouseIsOver = false;
        }}
      >

        <ul className="dropdown-menu__list-container">

          {this.getItems()}

        </ul>

      </aside>
    );
  }
}

export default DropdownMenu;
