import React from 'react';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router'
import MainHeader from '../../components/main_header';
import MainFooter from '../../components/main_footer';
import LoadingIcon from '../../components/loading_icon';
import {RedText} from '../../components/default_elements';
import posed, { PoseGroup } from 'react-pose';
// import FormView from '../form_view';
import FormContent from '../form_content';
// import PreLoader from '../../utils/preloader';
import './user_registration_form.scss';
import {
  Question1,
  Question2,
  Question3,
  Question4,
  Question5,
  Question6,
  Question7,
  Question8,
  Question9,
  Question10,
  Question11,
  Question12,
  Question13,
  Question14,
  Question15,
  Question16,
  BRASIL_STATES} from './user_registration_questions';
import {STATE_API, STUDENT_BY_HASH_API} from '../../constants'

// function setInputCursor(target, position) {
//   if (target.setSelectionRange) {
//     target.setSelectionRange(position, position);
//   }
//   else if (target.createTextRange) {
//     const range = target.createTextRange();
//     range.collapse(true);
//     range.moveEnd('character', position);
//     range.moveStart('character', position);
//     range.select();
//   }
// }

const FadeContainer = posed.div({
  preEnter: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

class UserRegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      apiFailed: false,
      errorMessage: "",
      currentQuestionsId: 0,
      allowNext: false,
      dataSubmitted: false,
      showMetFrom: true,
      showDisc: true,
      showAnamnesia: true,
      showPhysicalEvaluation: true,
      data: {
        name: "",
        email: "",
        phone: "",
        gender: null,
        cpf: "",
        rg: "",
        profession: "",
        marital_status: "",
        nationality: "",
        birthdate: "",
        at_emergency: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        cep: "",
        city: "",
        state: "",
        met_from: "",
        principal_objetivo: "",
        week_frequency: "",
        note: "",
        praticava_atividade: "",
        tempo_que_praticava_atividade: "",
        horas_de_sono: "",
        sono_suficiente: "",
        fuma: "",
        fuma_ha_quanto_tempo: "",
        ingere_alcool: "",
        alteracao_pressao: "",
        anemia: "",
        ansiedade: "",
        crise_respiratoria: "",
        colesterol_elevado: "",
        depressao: "",
        sono_irregular: "",
        diabetes: "",
        gastrite: "",
        insonia: "",
        tensao_ombros_pescoco: "",
        tireoide: "",
        tonturas: "",
        varizes: "",
        taquicardia: "",
        outros_problemas_saude: "",
        usa_medicamento: "",
        medicamentos_usados: "",
        quanto_tempo_usa_medicamento: "",
        fez_cirurgia: "",
        cirurgia_realizada: "",
        ha_quanto_tempo_fez_cirurgia: "",
        disc_questions: null,
      },
    };

    this.accentRegex = new RegExp(/\p{Diacritic}/, 'gu');

    this.title1 = "Qual seu objetivo MAIS IMPORTANTE dentro do treinamento físico hoje?";
    this.title31 = "Quantos dias por semana pretende se exercitar?";
    this.title2 = "Praticava alguma atividade física?";
    this.title3 = "Quando foi a última vez que praticou?";
    this.title4 = "Quantas horas dorme por dia?";
    this.title5 = "Essas horas são suficientes para seu descanso?";
    this.title6 = "Fuma?";
    this.title7 = "Há quantos anos fuma?";
    this.title8 = "Ingere bebidas alcoólicas?";
    this.title9 = "Alteração Pressão";
    this.title10 = "Anemia";
    this.title11 = "Ansiedade";
    this.title12 = "Crise Respiratória";
    this.title13 = "Colesterol Elevado";
    this.title14 = "Depressão";
    this.title15 = "Sono Irregular";
    this.title16 = "Diabetes";
    this.title17 = "Gastrite";
    this.title18 = "Insônia";
    this.title19 = "Tensão ombros e pescoço";
    this.title20 = "Tireóide";
    this.title21 = "Tonturas";
    this.title22 = "Varizes";
    this.title23 = "Taquicardia (Palpitação)";
    this.title24 = "Outros";
    this.title25 = "Usa algum medicamento?";
    this.title26 = "Se usa, quais são os medicamentos (mais importantes)?";
    this.title27 = "Há quanto tempo usa o(s) medicamento(s)?";
    this.title28 = "Fez alguma cirurgia?";
    this.title29 = "Qual cirurgia realizou?";
    this.title30 = "Há quanto tempo realizou a cirurgia?";

    this.stateToken = null;

    this.hasFillledForm = false;

    const questionMap = new Map();
    const questionIsValidMap = new Map();
    const questionOnChangeCallbackMap = new Map();
    const autoProceedCallbackMap = new Map();

    this.metFromSkipIds = [];

    this.generalDataRange = [0, 0];
    this.physicalEvaluationRange = [0, 0];
    this.healthHistoryRange = [0, 0];
    this.discRange = [0, 0];

    questionMap.set(questionMap.size, () => (
      <Question1
        // phone={this.getPhoneText(this.state.data.phone)}
        phone={this.state.data.phone}
        name={this.state.data.name}
        email={this.state.data.email}
        gender={this.state.data.gender}
        email_is_validated={this.state.data.email_is_validated}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.email.match(/.+@.+\..+/) != null &&
             data.phone.replace(/\D/g,"").length >= 10 &&
             data.name.length > 0 &&
             data.gender && data.gender.length > 0;
    });
    this.generalDataRange[0] = questionMap.size - 1;

    questionMap.set(questionMap.size, () => (
      <Question2
        // cpf={this.getCpfText(this.state.data.cpf)}
        // rg={this.getRgText(this.state.data.rg)}
        cpf={this.state.data.cpf}
        rg={this.state.data.rg}
        profession={this.state.data.profession}
        marital_status={this.state.data.marital_status}
        nationality={this.state.data.nationality}
        birthdate={this.state.data.birthdate}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.cpf.replace(/\D/g,"").length >= 11 &&
             data.rg.replace(/\D/g,"").length >= 4 &&
             data.marital_status.length > 0 &&
             data.nationality.length > 0 &&
             data.birthdate.length >= 10;
    });

    questionMap.set(questionMap.size, () => (
      <Question3
        at_emergency={this.state.data.at_emergency}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.at_emergency.length >= 8;
    });

    questionMap.set(questionMap.size, () => (
      <Question4
        street={this.state.data.street}
        number={this.state.data.number}
        complement={this.state.data.complement}
        neighborhood={this.state.data.neighborhood}
        // cep={this.getCepText(this.state.data.cep)}
        cep={this.state.data.cep}
        city={this.state.data.city}
        state={this.state.data.state}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.street.length > 0 &&
             data.number.length > 0 &&
             data.complement.length >= 0 &&
             data.neighborhood.length > 0 &&
             data.cep.replace(/\D/g,"").length >= 8 &&
             data.city.length > 0 &&
             data.state.length >= 2;
    });

    questionMap.set(questionMap.size, () => (
      <Question5
        met_from={this.state.data.met_from}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.met_from.length > 0;
    });

    this.metFromSkipIds.push(questionMap.size - 1);
    this.generalDataRange[1] = questionMap.size - 1;

    questionMap.set(questionMap.size, () => (
      <Question6
        title={this.title1}
        principal_objetivo={this.state.data.principal_objetivo}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.principal_objetivo.length > 0;
    });

    this.metFromSkipIds.push(questionMap.size - 1);
    this.physicalEvaluationRange[0] = questionMap.size - 1;
    autoProceedCallbackMap.set(questionMap.size - 1, (data) => {
      return true;
    });

    questionMap.set(questionMap.size, () => (
      <Question16
        title={this.title31}
        week_frequency={this.state.data.week_frequency}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.week_frequency.length > 0;
    });

    // this.metFromSkipIds.push(questionMap.size - 1);
    autoProceedCallbackMap.set(questionMap.size - 1, (data) => {
      return true;
    });

    questionMap.set(questionMap.size, () => (
      <Question7
        note={this.state.data.note}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.note.length >= 0;
    });

    this.physicalEvaluationMaxId = questionMap.size - 1;

    questionMap.set(questionMap.size, () => (
      <Question8
        titleA={this.title2}
        titleB={this.title3}
        praticava_atividade={this.state.data.praticava_atividade}
        tempo_que_praticava_atividade={this.state.data.tempo_que_praticava_atividade}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return (data.praticava_atividade.length > 0 &&
             data.tempo_que_praticava_atividade.length > 0) ||
             data.praticava_atividade === 'Não';
    });
    questionOnChangeCallbackMap.set(questionMap.size - 1, (name, value) => {
      const additionalChanges = {};

      if(value && name === 'praticava_atividade' && value === 'Não') {
        additionalChanges['tempo_que_praticava_atividade'] = '';
      }

      return additionalChanges;
    });

    this.metFromSkipIds.push(questionMap.size - 1);
    autoProceedCallbackMap.set(questionMap.size - 1, (data) => {
      return true;
    });

    questionMap.set(questionMap.size, () => (
      <Question9
        titleA={this.title4}
        titleB={this.title5}
        horas_de_sono={this.state.data.horas_de_sono}
        sono_suficiente={this.state.data.sono_suficiente}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.horas_de_sono.length > 0 &&
             data.sono_suficiente.length > 0;
    });

    this.metFromSkipIds.push(questionMap.size - 1);
    autoProceedCallbackMap.set(questionMap.size - 1, (data) => {
      return true;
    });

    questionMap.set(questionMap.size, () => (
      <Question10
        titleA={this.title6}
        titleB={this.title7}
        fuma={this.state.data.fuma}
        fuma_ha_quanto_tempo={this.state.data.fuma_ha_quanto_tempo}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return (data.fuma.length > 0 &&
             data.fuma_ha_quanto_tempo.length > 0) ||
             data.fuma === 'Não';
    });
    questionOnChangeCallbackMap.set(questionMap.size - 1, (name, value) => {
      const additionalChanges = {};

      if(value && name === 'fuma' && value === 'Não') {
        additionalChanges['fuma_ha_quanto_tempo'] = '';
      }

      return additionalChanges;
    });

    this.metFromSkipIds.push(questionMap.size - 1);
    autoProceedCallbackMap.set(questionMap.size - 1, (data) => {
      return true;
    });

    questionMap.set(questionMap.size, () => (
      <Question11
        title={this.title8}
        ingere_alcool={this.state.data.ingere_alcool}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.ingere_alcool.length > 0;
    });

    this.physicalEvaluationRange[1] = questionMap.size - 1;
    this.anamnesiaMaxId = questionMap.size - 1;
    this.metFromSkipIds.push(questionMap.size - 1);
    autoProceedCallbackMap.set(questionMap.size - 1, (data) => {
      return true;
    });

    questionMap.set(questionMap.size, () => (
      <Question12
        titleA={this.title9}
        titleB={this.title10}
        titleC={this.title11}
        titleD={this.title12}
        titleE={this.title13}
        titleF={this.title14}
        titleG={this.title15}
        titleH={this.title16}
        titleI={this.title17}
        titleJ={this.title18}
        titleK={this.title19}
        titleL={this.title20}
        titleM={this.title21}
        titleN={this.title22}
        titleO={this.title23}
        titleP={this.title24}
        alteracao_pressao={this.state.data.alteracao_pressao}
        anemia={this.state.data.anemia}
        ansiedade={this.state.data.ansiedade}
        crise_respiratoria={this.state.data.crise_respiratoria}
        colesterol_elevado={this.state.data.colesterol_elevado}
        depressao={this.state.data.depressao}
        sono_irregular={this.state.data.sono_irregular}
        diabetes={this.state.data.diabetes}
        gastrite={this.state.data.gastrite}
        insonia={this.state.data.insonia}
        tensao_ombros_pescoco={this.state.data.tensao_ombros_pescoco}
        tireoide={this.state.data.tireoide}
        tonturas={this.state.data.tonturas}
        varizes={this.state.data.varizes}
        taquicardia={this.state.data.taquicardia}
        outros_problemas_saude={this.state.data.outros_problemas_saude}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return data.alteracao_pressao.length > 0 &&
             data.anemia.length > 0 &&
             data.ansiedade.length > 0 &&
             data.crise_respiratoria.length > 0 &&
             data.colesterol_elevado.length > 0 &&
             data.depressao.length > 0 &&
             data.sono_irregular.length > 0 &&
             data.diabetes.length > 0 &&
             data.gastrite.length > 0 &&
             data.insonia.length > 0 &&
             data.tensao_ombros_pescoco.length > 0 &&
             data.tireoide.length > 0 &&
             data.tonturas.length > 0 &&
             data.varizes.length > 0 &&
             data.taquicardia.length > 0 &&
             data.outros_problemas_saude.length > 0;
    });

    this.healthHistoryRange[0] = questionMap.size - 1;
    autoProceedCallbackMap.set(questionMap.size - 1, (data) => {
      return true;
    });

    questionMap.set(questionMap.size, () => (
      <Question13
        titleA={this.title25}
        titleB={this.title26}
        titleC={this.title27}
        usa_medicamento={this.state.data.usa_medicamento}
        medicamentos_usados={this.state.data.medicamentos_usados}
        quanto_tempo_usa_medicamento={this.state.data.quanto_tempo_usa_medicamento}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return (data.usa_medicamento.length > 0 &&
             data.medicamentos_usados.length > 0 &&
             data.quanto_tempo_usa_medicamento.length > 0) ||
             data.usa_medicamento === 'Não';
    });
    questionOnChangeCallbackMap.set(questionMap.size - 1, (name, value) => {
      const additionalChanges = {};

      if(value && name === 'usa_medicamento' && value === 'Não') {
        additionalChanges['medicamentos_usados'] = '';
        additionalChanges['quanto_tempo_usa_medicamento'] = '';
      }

      return additionalChanges;
    });

    autoProceedCallbackMap.set(questionMap.size - 1, (data) => {
      return data.usa_medicamento === 'Não';
    });

    questionMap.set(questionMap.size, () => (
      <Question14
        titleA={this.title28}
        titleB={this.title29}
        titleC={this.title30}
        fez_cirurgia={this.state.data.fez_cirurgia}
        cirurgia_realizada={this.state.data.cirurgia_realizada}
        ha_quanto_tempo_fez_cirurgia={this.state.data.ha_quanto_tempo_fez_cirurgia}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));
    questionIsValidMap.set(questionMap.size - 1, (data) => {
      return (data.fez_cirurgia.length > 0 &&
             data.cirurgia_realizada.length > 0 &&
             data.ha_quanto_tempo_fez_cirurgia.length > 0) ||
             data.fez_cirurgia === 'Não';
    });
    questionOnChangeCallbackMap.set(questionMap.size - 1, (name, value) => {
      const additionalChanges = {};

      if(value && name === 'fez_cirurgia' && value === 'Não') {
        additionalChanges['cirurgia_realizada'] = '';
        additionalChanges['ha_quanto_tempo_fez_cirurgia'] = '';
      }

      return additionalChanges;
    });
    autoProceedCallbackMap.set(questionMap.size - 1, (data) => {
      return data.fez_cirurgia === 'Não';
    });

    this.healthHistoryRange[1] = questionMap.size - 1;
    this.discMaxId = questionMap.size - 1;

    questionMap.set(questionMap.size, () => (
      <Question15
        onChange={(disc_questions, isCompleted) => {
          const newData = {...this.state.data, disc_questions: disc_questions};

          this.setState({
            data: newData,
            allowNext: isCompleted,
          });
        }}
        handleInputChange={(e) => this.handleInputChange(e)}
      />
    ));

    this.discRange[0] = questionMap.size - 1;
    this.discRange[1] = questionMap.size - 1;

    this.maxId = questionMap.size - 1;

    this.questionMap = questionMap;
    this.questionIsValidMap = questionIsValidMap;
    this.questionOnChangeCallbackMap = questionOnChangeCallbackMap;
    this.autoProceedCallbackMap = autoProceedCallbackMap;
  }

  async componentDidMount() {
    let stateToken = await this.getStateToken();

    let errorMessage = 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.';

    if(stateToken) {
      const headers = new Headers({
        'state': stateToken
      });

      try {
        let response = await fetch(`${STUDENT_BY_HASH_API}${this.props.match.params.userHash}`,
          {
            method: "GET",
            headers: headers,
            cache: 'no-store',
            credentials: 'same-origin'
          });

        if(!response.ok) {
          throw await response.json();
          // throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
        }

        let data = await response.json();

        data = {...this.state.data, ...data, ...data.address};

        delete data.address;

        if (data.state && data.state.length > 0) {
          const state = BRASIL_STATES.find((entry) => (entry.value.toLowerCase() === data.state.toLowerCase() ||
                                                       entry.text.normalize("NFD").replace(this.accentRegex, "").toLowerCase() === data.state.normalize("NFD").replace(this.accentRegex, "").toLowerCase()))

          if (state) {
            data.state = state.value;
          }
          else {
            data.state = "";
          }
        }

        const showMetFrom = data.met_from.length <= 0;
        const showDisc = !data.disc_filled;
        const showAnamnesia = !data.anamnesia_filled;
        const showPhysicalEvaluation = !data.physical_evaluation_filled;

        delete data.anamnesia_filled;
        delete data.anamnesia_filled;
        delete data.physical_evaluation_filled;

        if(!showPhysicalEvaluation) {
          this.maxId = this.physicalEvaluationMaxId;
          this.hasFillledForm = true;
        }
        else if(!showAnamnesia) {
          this.maxId = this.anamnesiaMaxId;
          this.hasFillledForm = true;
        }
        else if(!showDisc) {
          this.maxId = this.discMaxId;
          this.hasFillledForm = true;
        }

        this.setState({
          showDisc: showDisc,
          showAnamnesia: showAnamnesia,
          showPhysicalEvaluation: showPhysicalEvaluation,
          showMetFrom: showMetFrom,
          data: data,
          apiFailed: false,
          loadingData: false,
          allowNext: this.isValidData(data, this.state.currentQuestionsId)
        });

        return data;
      }
      catch(error) {
        if(error.code) {
          switch (error.code) {
            case 203:
              errorMessage = 'URL inválida. Certifique-se de utilizar o link enviado no email. Qualquer dúvida não hesite em nos contactar.';

              break;
            default:
          }
        }
      }
    }

    this.setState({
      loadingData: false,
      apiFailed: true,
      errorMessage
    });
  }

  async getStateToken() {
    try {
      let response = await fetch(STATE_API, {cache: 'no-store', credentials: 'same-origin'});

      if(!response.ok) {
        throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
      }

      let data = await response.json();

      this.stateToken = data.state;

      return this.stateToken;
    }
    catch(error) {
      console.log(error);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    let additionalChanges = {};

    if(this.questionOnChangeCallbackMap.has(this.state.currentQuestionsId)) {
      additionalChanges = this.questionOnChangeCallbackMap.get(this.state.currentQuestionsId)(name, value);
    }

    // if(this.state.currentQuestionsId === 0) {
    //   // if(value && name === 'phone') {
    //   //   const initialPosition = target.selectionStart;
    //   //   const initialSize = value.length;
    //   //
    //   //   value = value.replace(/\D/g,"")
    //   //   target.value = this.getPhoneText(value);
    //   //
    //   //   let newPosition = (target.value.length - initialSize) + initialPosition;
    //   //
    //   //   if(initialPosition < initialSize) {
    //   //     newPosition = initialPosition;
    //   //   }
    //   //
    //   //   if(value.length > 2 &&
    //   //       (newPosition === 4)) {
    //   //     newPosition = 6;
    //   //   }
    //   //
    //   //   setInputCursor(target, newPosition);
    //   // }
    // }
    // else if(this.state.currentQuestionsId === 1) {
    //   // if(value && name === 'cpf') {
    //   //   const initialPosition = target.selectionStart;
    //   //   const initialSize = value.length;
    //   //
    //   //   value = value.replace(/\D/g,"");
    //   //   target.value = this.getCpfText(value);
    //   //
    //   //   let newPosition = (target.value.length - initialSize) + initialPosition;
    //   //
    //   //   if(initialPosition < initialSize) {
    //   //     newPosition = initialPosition;
    //   //   }
    //   //
    //   //   const valueIncreased = this.state.data[name].length < value.length;
    //   //
    //   //   if(value.length > 3 &&
    //   //       (newPosition === 4)) {
    //   //     newPosition = valueIncreased ? 5 : 3;
    //   //   }
    //   //   else if(value.length > 6 &&
    //   //       (newPosition === 8)) {
    //   //     newPosition = valueIncreased ? 9 : 7;
    //   //   }
    //   //   else if(value.length > 9 &&
    //   //       (newPosition === 12)) {
    //   //     newPosition = valueIncreased ? 13 : 11;
    //   //   }
    //   //
    //   //   setInputCursor(target, newPosition);
    //   // }
    //   // else if(value && name === 'rg') {
    //   //   const initialPosition = target.selectionStart;
    //   //   const initialSize = value.length;
    //   //
    //   //   value = value.replace(/\D/g,"");
    //   //   target.value = this.getRgText(value);
    //   //
    //   //   let newPosition = (target.value.length - initialSize) + initialPosition;
    //   //
    //   //   if(initialPosition < initialSize) {
    //   //     newPosition = initialPosition;
    //   //   }
    //   //
    //   //   const valueIncreased = this.state.data[name].length < value.length;
    //   //
    //   //   if(value.length > 2 &&
    //   //       (newPosition === 3)) {
    //   //     newPosition = valueIncreased ? 4 : 2;
    //   //   }
    //   //   else if(value.length > 5 &&
    //   //       (newPosition === 7)) {
    //   //     newPosition = valueIncreased ? 8 : 6;
    //   //   }
    //   //   else if(value.length > 8 &&
    //   //       (newPosition === 11)) {
    //   //     newPosition = valueIncreased ? 12 : 10;
    //   //   }
    //   //
    //   //   setInputCursor(target, newPosition);
    //   // }
    // }
    // else if(this.state.currentQuestionsId === 3) {
    //   // if(value && name === 'cep') {
    //   //   const initialPosition = target.selectionStart;
    //   //   const initialSize = value.length;
    //   //
    //   //   value = value.replace(/\D/g,"");
    //   //   target.value = this.getCepText(value);;
    //   //
    //   //   let newPosition = (target.value.length - initialSize) + initialPosition;
    //   //
    //   //   setInputCursor(target, newPosition);
    //   // }
    // }

    if(target.type === 'checkbox') {
      const currenteValue = new Set(this.state.data[name]);

      if(target.checked) {
        currenteValue.add(value);
      }
      else {
        currenteValue.delete(value);
      }

      value = [...currenteValue];
    }

    const newData = {...this.state.data, ...additionalChanges, [name]: value};

    const questionIsValid = this.isValidData(newData, this.state.currentQuestionsId);

    let autoProceed = false;

    if(this.autoProceedCallbackMap.has(this.state.currentQuestionsId)) {
      autoProceed = this.autoProceedCallbackMap.get(this.state.currentQuestionsId)(newData);
    }

    this.setState({
      data: newData,
      allowNext: questionIsValid,
    });

    if(questionIsValid && autoProceed) {
      setTimeout(() => {this.onNextClick();}, 100);
    }
  }

  isValidData(data, questionId) {
    let isValid = false;

    if(this.questionIsValidMap.has(questionId)) {
      return this.questionIsValidMap.get(questionId)(data);
    }

    return isValid;
  }

  onNextClick() {
    let nextId = this.state.currentQuestionsId >= this.maxId ? this.maxId : this.state.currentQuestionsId + 1;

    while(!this.state.showMetFrom && this.metFromSkipIds.includes(nextId)) {
      nextId += 1;
    }

    if((nextId >= 11 & nextId <= 13) && !this.state.showAnamnesia) {
      nextId = 14;
    }

    window.scrollTo(0, 0);

    this.setState({
      currentQuestionsId: nextId,
      allowNext: this.isValidData(this.state.data, nextId),
    });
  }

  onPreviousClick() {
    let previousId = this.state.currentQuestionsId > 0 ? this.state.currentQuestionsId - 1 : 0;

    while(!this.state.showMetFrom && previousId > 0 && this.metFromSkipIds.includes(previousId)) {
      previousId -= 1;
    }

    if((previousId >= 11 & previousId <= 13) && !this.state.showAnamnesia) {
      previousId = 10;
    }

    window.scrollTo(0, 0);

    this.setState({
      currentQuestionsId: previousId,
      allowNext: this.isValidData(this.state.data, previousId),
    });
  }

  async onFinishClick() {
    this.setState({
      loadingData: true,
    });

    const data = {
      name: this.state.data.name,
      phone: this.state.data.phone.replace(/\D/g,""),
      email: this.state.data.email,
      birthdate: this.state.data.birthdate,
      cpf: this.state.data.cpf.replace(/\D/g,""),
      rg: this.state.data.rg.replace(/\D/g,""),
      profession: this.state.data.profession,
      marital_status: this.state.data.marital_status,
      nationality: this.state.data.nationality,
      at_emergency: this.state.data.at_emergency,
      note: this.state.data.note,
      address: {
        street: this.state.data.street,
        number: this.state.data.number,
        complement: this.state.data.complement,
        cep: this.state.data.cep.replace(/\D/g,""),
        neighborhood: this.state.data.neighborhood,
        city: this.state.data.city,
        state: this.state.data.state
      }
    };

    if(this.state.showMetFrom) {
      data.met_from = this.state.data.met_from.join(', ');
    }

    if(this.state.showPhysicalEvaluation) {
      data.physical_evaluation = [
        {
          question_id: 1,
          question_text: this.title1,
          value: this.state.data.principal_objetivo
        },
        {
          question_id: 2,
          question_text: this.title2,
          value: this.state.data.praticava_atividade
        },
        {
          question_id: 3,
          question_text: this.title3,
          value: this.state.data.tempo_que_praticava_atividade
        },
        {
          question_id: 4,
          question_text: this.title4,
          value: this.state.data.horas_de_sono
        },
        {
          question_id: 5,
          question_text: this.title5,
          value: this.state.data.sono_suficiente
        },
        {
          question_id: 6,
          question_text: this.title6,
          value: this.state.data.fuma
        },
        {
          question_id: 7,
          question_text: this.title7,
          value: this.state.data.fuma_ha_quanto_tempo
        },
        {
          question_id: 8,
          question_text: this.title8,
          value: this.state.data.ingere_alcool
        },
        {
          question_id: 9,
          question_text: this.title31,
          value: this.state.data.week_frequency
        }
        // {
        //   question_id: 10,
        //   question_text: "Nível físico",
        //   value: "Iniciante"
        // },
      ];
    }
    else {
      data.physical_evaluation_update = [
        {
          question_id: 9,
          question_text: this.title31,
          value: this.state.data.week_frequency
        }
      ]
    }

    if(this.state.showAnamnesia) {
      data.anamnesia = [
        {
          question_id: 1,
          question_text: this.title9,
          value: this.state.data.alteracao_pressao
        },
        {
          question_id: 2,
          question_text: this.title10,
          value: this.state.data.anemia
        },
        {
          question_id: 3,
          question_text: this.title11,
          value: this.state.data.ansiedade
        },
        {
          question_id: 4,
          question_text: this.title12,
          value: this.state.data.crise_respiratoria
        },
        {
          question_id: 5,
          question_text: this.title13,
          value: this.state.data.colesterol_elevado
        },
        {
          question_id: 6,
          question_text: this.title14,
          value: this.state.data.depressao
        },
        {
          question_id: 7,
          question_text: this.title15,
          value: this.state.data.sono_irregular
        },
        {
          question_id: 8,
          question_text: this.title16,
          value: this.state.data.diabetes
        },
        {
          question_id: 9,
          question_text: this.title17,
          value: this.state.data.gastrite
        },
        {
          question_id: 10,
          question_text: this.title18,
          value: this.state.data.insonia
        },
        {
          question_id: 11,
          question_text: this.title19,
          value: this.state.data.tensao_ombros_pescoco
        },
        {
          question_id: 12,
          question_text: this.title20,
          value: this.state.data.tireoide
        },
        {
          question_id: 13,
          question_text: this.title21,
          value: this.state.data.tonturas
        },
        {
          question_id: 14,
          question_text: this.title22,
          value: this.state.data.varizes
        },
        {
          question_id: 15,
          question_text: this.title23,
          value: this.state.data.taquicardia
        },
        {
          question_id: 16,
          question_text: this.title24,
          value: this.state.data.outros_problemas_saude
        },
        {
          question_id: 17,
          question_text: this.title25,
          value: this.state.data.usa_medicamento
        },
        {
          question_id: 18,
          question_text: this.title26,
          value: this.state.data.medicamentos_usados
        },
        {
          question_id: 19,
          question_text: this.title27,
          value: this.state.data.quanto_tempo_usa_medicamento
        },
        {
          question_id: 20,
          question_text: this.title28,
          value: this.state.data.fez_cirurgia
        },
        {
          question_id: 21,
          question_text: this.title29,
          value: this.state.data.cirurgia_realizada
        },
        {
          question_id: 22,
          question_text: this.title30,
          value: this.state.data.ha_quanto_tempo_fez_cirurgia
        },
      ];
    }

    if(this.state.showDisc) {
      data.disc = {
        answers: this.state.data.disc_questions
      };
    }

    let stateToken = await this.getStateToken();

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken,
      });

      const body = JSON.stringify(data);

      try {
        let response = await fetch(`${STUDENT_BY_HASH_API}${this.props.match.params.userHash}`, {
          method: "PATCH",
          headers: headers,
          body: body,
          cache: 'no-store',
          credentials: 'same-origin'
        });

        if(!response.ok) {
          throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
        }

        let data = await response.json();

        const stateUpdate = {
          dataSubmitted: true,
          loadingData: false
        }

        if(data.redirect_to) {
          stateUpdate.redirect_to = data.redirect_to
        }

        this.setState(stateUpdate);

        return;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      apiFailed: true,
      loadingData: false
    });
  }

  getPhoneText(input) {
    const inputSize = input.length;

    if(inputSize > 2) {
      return `(${input.slice(0, 2)}) ${input.slice(2)}`;
    }
    else if(inputSize > 0) {
      return '(' + input;
    }

    return input;
  }

  getCpfText(input) {
    input = input.replace(/(\d{3})(\d)/,"$1.$2");
    input = input.replace(/(\d{3})(\d)/,"$1.$2");
    input = input.replace(/(\d{3})(\d{1,2})$/,"$1-$2");

    return input;
  }

  getRgText(input) {
    input = input.replace(/(\d{2})(\d)/,"$1.$2");
    input = input.replace(/(\d{3})(\d)/,"$1.$2");
    input = input.replace(/(\d{3})(\d+)$/,"$1-$2");

    return input;
  }

  getCepText(input) {
    input = input.replace(/(\d{5})(\d+)/,"$1-$2");

    return input;
  }

  getQuestions() {
    if(this.state.dataSubmitted || this.state.apiFailed) {
      return null;
    }

    if(this.questionMap.has(this.state.currentQuestionsId)) {
      return this.questionMap.get(this.state.currentQuestionsId)();
    }

    return null;
  }

  getFormTitle() {
    if(this.state.apiFailed) {
      return (
        <FadeContainer key="falha_de_api">

          <RedText>OOOPS!</RedText>

        </FadeContainer>
      );
    }
    else if(this.state.dataSubmitted) {
      return (
        <FadeContainer key="dados_enviados">

          <RedText>DADOS</RedText> ENVIADOS COM SUCESSO

        </FadeContainer>
      );
    }
    else if(this.state.currentQuestionsId >= this.generalDataRange[0] && this.state.currentQuestionsId <= this.generalDataRange[1]) {
      return (
        <FadeContainer key="dados_gerais">

          CADASTRO DO ALUNO - <RedText>DADOS GERAIS</RedText>

        </FadeContainer>
      );
    }
    else if(this.state.currentQuestionsId >= this.physicalEvaluationRange[0] && this.state.currentQuestionsId <= this.physicalEvaluationRange[1]) {
      return (
        <FadeContainer key="avaliacao_fisica">

          CADASTRO DO ALUNO - <RedText>AVALIAÇÃO FÍSICA</RedText>

        </FadeContainer>
      );
    }
    else if(this.state.currentQuestionsId >= this.healthHistoryRange[0] && this.state.currentQuestionsId <= this.healthHistoryRange[1]) {
      return (
        <FadeContainer key="historico_de_saude">

          CADASTRO DO ALUNO - <RedText>HISTÓRICO DE SAÚDE</RedText>

        </FadeContainer>
      );
    }
    else if(this.state.currentQuestionsId >= this.discRange[0] && this.state.currentQuestionsId <= this.discRange[1]) {
      return (
        <FadeContainer key="formulario_disc">

          CADASTRO DO ALUNO - <RedText>ANÁLISE DISC</RedText>

        </FadeContainer>
      );
    }

    return (
      <FadeContainer key="formulario_disc">

        CADASTRO DO ALUNO

      </FadeContainer>
    );
  }

  getFormText() {
    const refillText = 'Por favor, confira seus dados cadastrais e atualize o que for necessário.';

    if(this.state.apiFailed) {
      return this.state.errorMessage;
    }
    else if(this.state.dataSubmitted) {
      return 'Obrigado pelo sua avaliação, todos os dados são sigilosos e sua ' +
             'utilização é exclusivamente para melhoria de nossos serviços.';
    }
    else if(this.state.currentQuestionsId >= this.generalDataRange[0] && this.state.currentQuestionsId <= this.generalDataRange[1]) {
      if(this.hasFillledForm) {
        return refillText;
      }
      else {
        return 'Informe os dados abaixo, todas as informações serão utilizadas ' +
               'para o registro do aluno em nosso sistema.';
      }
    }
    else if((this.state.currentQuestionsId >= this.physicalEvaluationRange[0] && this.state.currentQuestionsId <= this.physicalEvaluationRange[1]) || (this.state.currentQuestionsId >= this.healthHistoryRange[0] && this.state.currentQuestionsId <= this.healthHistoryRange[1])) {
      if(this.hasFillledForm) {
        return refillText;
      }
      else {
        return 'Quanto mais soubermos sobre você, melhor será seus resultados! ' +
               'Não levará mais que alguns minutos.';
      }
    }
    else {
      if(this.hasFillledForm) {
        return refillText;
      }
      else {
        return 'Estamos quase terminando.';
      }
    }
  }

  render() {
    if(this.state.redirect_to) {
      return (
        <Redirect push to={this.state.redirect_to} />
      );
    }

    return (
      <React.Fragment>

        <Helmet>
          <title>Cadastro de aluno - FYD Club</title>
        </Helmet>

        <MainHeader
          location={this.props.location}
          collapse={true}
        />

        <div className="user-registration-form__wrapper">

          <section className="user-registration-form">

            <div className="user-registration-form__content">

              {this.state.loadingData ?
                <LoadingIcon />:
                <FormContent
                  contentTitle={
                    <PoseGroup>

                      {this.getFormTitle()}

                    </PoseGroup>
                  }
                  contentText={this.getFormText()}
                  questionsId={this.state.currentQuestionsId}
                  questions={this.getQuestions()}
                  onNextClick={() => this.onNextClick()}
                  onPreviousClick={() => this.onPreviousClick()}
                  onFinishClick={() => this.onFinishClick()}
                  maxId={this.maxId}
                  showControllers={!this.state.dataSubmitted && !this.state.apiFailed}
                  allowNext={this.state.allowNext}
                  finishText="Próximo"
                  finishButtonIsIdentical={true}
                />
              }

            </div>

          </section>

          <MainFooter
            collapse={true}
          />

        </div>

      </React.Fragment>
    );

    // if(this.state.loadingData) {
    //   return (
    //     <PoseGroup>
    //       <FadeContainer key="preloader">
    //         <PreLoader />
    //       </FadeContainer>
    //     </PoseGroup>
    //   );
    // }
    // else {
    //   return this.state.redirect_to ? (
    //     <Redirect to={this.state.redirect_to} />
    //   ):
    //   (
    //     <FormView
    //       title="Cadastro do Aluno"
    //       contentTitle={
    //         <PoseGroup>
    //
    //           {this.getFormTitle()}
    //
    //         </PoseGroup>
    //       }
    //       contentText={this.getFormText()}
    //       questionsId={this.state.currentQuestionsId}
    //       questions={this.getQuestions()}
    //       onNextClick={() => this.onNextClick()}
    //       onPreviousClick={() => this.onPreviousClick()}
    //       onFinishClick={() => this.onFinishClick()}
    //       maxId={this.maxId}
    //       showControllers={!this.state.dataSubmitted && !this.state.apiFailed}
    //       allowNext={this.state.allowNext}
    //       finishText="Próximo"
    //       finishButtonIsIdentical={true}
    //     />
    //   );
    // }
  }
}

export default UserRegistrationForm
