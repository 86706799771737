import React from 'react';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router'
import MainHeader from '../../components/main_header';
import MainFooter from '../../components/main_footer';
import ChatOverlayButton from '../../components/chat_overlay_button';
import LoadingIcon from '../../components/loading_icon';
import {RedText} from '../../components/default_elements';
// import posed, { PoseGroup } from 'react-pose';
// import PreLoader from '../../utils/preloader';
// import FormView from '../../forms/form_view';
import {TRAINING_CALENDAR_GET_API,
        TRAINING_EXECUTION_METHOD_FIXED_REPS,
        TRAINING_EXECUTION_METHOD_PIRAMIDAL,
        TRAINING_EXECUTION_METHOD_QRP,
        STUDENT_APP_SUBDOMAIN,
        TRAINING_CALENDAR_DEFAULT_GET_API} from '../../constants';
import {getModel, getLocalDateIsoString, parseTextForIcons} from '../../utils/functions';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import './training_calendar_view.scss';

const DEFAULT_SEQUENTIAL_COLOR_PALLET = [
  '#367dc4',
  '#da5a1b',
  '#0e8c62',
  '#e32b62',
  '#8746ce',
  '#0e8f9d',
  '#3945e4',
  '#a92689',
  '#547582',
  '#e24c4c',
  '#245a8e',
  '#74abe2',
  '#ef8d5d',
  '#3fb68e',
  '#f06a93',
  '#a97dd8',
  '#3ab5c2',
  '#6973f6',
  '#cd59b1',
  '#8ca2ab',
  '#f38787',
  '#4d78a2',
];

const QRP_CYCLES_VISIBLE_NUMBER = 3;

// const FadeContainer = posed.div({
//   preEnter: {
//     opacity: 0
//   },
//   enter: {
//     opacity: 1
//   },
//   exit: {
//     opacity: 0
//   }
// });

function getFormatedTime(minutes) {
  const secondsRemaining = Math.floor((minutes*60) % 60);

  return `${Math.floor(minutes)}'` + (secondsRemaining > 0 ? ` ${secondsRemaining}''` : '');
}

class TrainingCalendarView extends React.Component {
  constructor(props) {
    super(props);

    const today = getLocalDateIsoString(new Date());

    const promotionStartDate = '2022-09-01';
    // const promotionEndDate = '2021-11-30';

    this.state = {
      enablePromotion: today >= promotionStartDate, //&& today <= promotionEndDate
      loadingData: true,
      apiFailed: false,
      training_day_map: new Map(),
      training_periods: [],
      trainingDayVisibilityMap: new Map(),
      target_service: null,
      screenWidth: window.innerWidth,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "Agendamento cancelado com sucesso!",
    };

    this.stateToken = null;
  }

  async componentDidMount() {
    this.setState({
      loadingData: true,
    });

    const update = {loadingData: false};

    let training_day_map;

    if(this.props.loadDefaultPeriod) {
      if (process.env.NODE_ENV === 'production') {
        window.gtag('config', 'G-8YRPC28XN5', {'page_path': this.props.location.pathname});
      }

      training_day_map = await getModel(TRAINING_CALENDAR_DEFAULT_GET_API);
    }
    else {
      training_day_map = await getModel(`${TRAINING_CALENDAR_GET_API}${this.props.match.params.authHash}`);
    }

    if(training_day_map) {
      update.training_day_map = new Map();

      // .filter((entry) => entry.has_training)
      for(let entry of training_day_map.trainings) {
        if(!update.training_day_map.has(entry.date)) {
          update.training_day_map.set(entry.date, entry);
        }
      }

      update.training_periods = training_day_map.periods;
      update.target_service = training_day_map.target_service;
    }
    else {
      this.setState({
        loadingData: false,
        apiFailed: true,
      });

      return;
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getContentTitle() {
    // if(this.state.apiFailed) {
    //   return (
    //     <FadeContainer key="falha_de_api">
    //
    //       <RedText>OOOPS!</RedText>
    //
    //     </FadeContainer>
    //   );
    // }

    return (
      <React.Fragment>

        Periodização - <RedText>{this.state.apiFailed ? 'Oops' : this.state.target_service}</RedText>

      </React.Fragment>
    );
  }

  // getContentText() {
  //   if(this.state.apiFailed) {
  //     return 'Infelizmente estamos com problemas temporários ' +
  //            'em nosso serviço. Por favor, tente novamente mais tarde.';
  //   }
  //
  //   return null;
  // }

  toggleTrainingDayVisibility(isoDate) {
    let visible = false;

    if(this.state.trainingDayVisibilityMap.has(isoDate)) {
      visible = this.state.trainingDayVisibilityMap.get(isoDate);
    }

    const trainingDayVisibilityMap = new Map(this.state.trainingDayVisibilityMap);
    trainingDayVisibilityMap.set(isoDate, !visible);

    this.setState({trainingDayVisibilityMap});
  }

  getTrainingExercises(executionMethod, repetitionIndex, exercises, isoDate, groupColor, cycleNumber, exerciseGroup=null) {
    if(exercises.length <= 0) {
      return (
        <p className="training-calendar-view__training__exercise__not-configured">TREINO NÃO CONFIGURADO</p>
      );
    }

    let intensities = [];
    const difficulties = [];
    const intermediateDifficulties = [];
    const advancedDifficulties = [];
    const exerciseNames = [];
    const easierOptions = [];
    const references = [];

    let showDificulties = false;
    let showIntermediateDificulties = false;
    let showAdvancedDificulties = false;
    let showEasierOptions = false;
    let showReferences = false;
    let showCycleNumber = true;

    let hasAdditionalHeader = false;

    let cycleNumberText = `${cycleNumber}x`;

    if(executionMethod === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
      showCycleNumber = false;
      hasAdditionalHeader = true;

      for(let i = 0; i < cycleNumber; ++i) {
        intensities.push([(
          <p
            className="training-calendar-view__training__exercise__header"
            key={`training_calendar_view:${isoDate}:repetition:${repetitionIndex}:intensity_header:cycle:${i}`}
            style={{background: groupColor}}
          >

            {i + 1}

          </p>
        )]);
      }
    }
    else if(executionMethod === TRAINING_EXECUTION_METHOD_QRP) {
      cycleNumberText = (
        <React.Fragment>

          <span className="training-calendar-view__training__exercise__reps-text-span">
            QRP
          </span>

          {exerciseGroup.clock_time_limit ?
            <span className="training-calendar-view__training__exercise__reps-text-span">
              {getFormatedTime(exerciseGroup.clock_time_limit)}
            </span>:
            null
          }

        </React.Fragment>
      );
      hasAdditionalHeader = true;

      for(let i = 0; i < QRP_CYCLES_VISIBLE_NUMBER + 1; ++i) {
        intensities.push([(
          <p
            className="training-calendar-view__training__exercise__header"
            key={`training_calendar_view:${isoDate}:repetition:${repetitionIndex}:intensity_header:cycle:${i}`}
            style={{background: groupColor}}
          >

            {i === QRP_CYCLES_VISIBLE_NUMBER ? 'N' : (i + 1)}

          </p>
        )]);
      }
    }

    for(let exercise of exercises) {
      if(!showDificulties && exercise.difficult_value[repetitionIndex-1]) {
        showDificulties = true;
      }

      if(!showIntermediateDificulties && exercise.difficult_intermediate_value[repetitionIndex-1]) {
        showIntermediateDificulties = true;
      }

      if(!showAdvancedDificulties && exercise.difficult_advanced_value[repetitionIndex-1]) {
        showAdvancedDificulties = true;
      }

      if(!showEasierOptions && exercise.easier_option && exercise.easier_option.length > 0) {
        showEasierOptions = true;
      }

      if(!showReferences && exercise.exercise.reference_url && exercise.exercise.reference_url.length > 0) {
        showReferences = true;
      }

      if(executionMethod === TRAINING_EXECUTION_METHOD_FIXED_REPS) {
        let intensityStyle = {};
        let intensityText;

        if(exercise.intensity_value[repetitionIndex-1] && exercise.intensity_value[repetitionIndex-1].trim().length > 0) {
          intensityText = exercise.intensity_value[repetitionIndex-1];
          intensityStyle = {};
        }
        else {
          intensityStyle.color = 'transparent';
          intensityText = '-';
        }

        intensities.push(
          <p
            className="training-calendar-view__training__exercise__intensity-text"
            key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:intensity`}
            style={intensityStyle}
          >

            {intensityText}

          </p>
        );
      }
      else if(executionMethod === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
        for(let i = 0; i < cycleNumber; ++i) {
          let intensityStyle = {};
          let intensityText;

          if(exercise.intensity_value[repetitionIndex-1] && exercise.intensity_value[repetitionIndex-1][i] && exercise.intensity_value[repetitionIndex-1][i].trim().length > 0) {
            intensityText = exercise.intensity_value[repetitionIndex-1][i];
          }
          else {
            intensityStyle.color = 'transparent';
            intensityText = '-';
          }

          intensities[i].push(
            <p
              className="training-calendar-view__training__exercise__intensity-cycle-text"
              key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:intensity:cycle:${i}`}
              style={intensityStyle}
            >

              {intensityText}

            </p>
          );
        }
      }
      else if(executionMethod === TRAINING_EXECUTION_METHOD_QRP) {
        let showElipsis = true;

        for(let i = 0; i < QRP_CYCLES_VISIBLE_NUMBER; ++i) {
          let intensity_value = exercise.intensity_value[repetitionIndex-1] + (exercise.intensity_value_step[repetitionIndex-1]*i);

          if(intensity_value <= 0) {
            intensity_value = '-';
            showElipsis = false;
          }
          else {
            intensity_value = `${intensity_value}${exercise.intensity_unit ? ' ' + exercise.intensity_unit : ''}`;
          }

          intensities[i].push(
            <p
              className="training-calendar-view__training__exercise__intensity-cycle-text"
              key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:intensity:cycle:${i}`}
            >

              {intensity_value}

            </p>
          );
        }

        intensities[QRP_CYCLES_VISIBLE_NUMBER].push(
          <p
            className="training-calendar-view__training__exercise__intensity-cycle-text"
            key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:intensity:cycle:${QRP_CYCLES_VISIBLE_NUMBER}`}
          >

            {showElipsis ?
              <i className="fas fa-ellipsis-h"></i>:
              '-'
            }

          </p>
        );
      }

      let difficultyStyle = {};
      let difficultyText;

      if(exercise.difficult_value[repetitionIndex-1]) {
        difficultyText = `${exercise.difficult_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        difficultyStyle.color = 'transparent';
        difficultyText = '-';
      }

      difficulties.push(
        <p
          className="training-calendar-view__training__exercise__difficult-text"
          key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:difficulty`}
          style={difficultyStyle}
        >

          {parseTextForIcons(difficultyText, `training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:dificult_text`, 'training-calendar-view__parsed-text', true)}

        </p>
      );

      let intermediateDifficultyStyle = {};
      let intermediateDifficultyText;

      if(exercise.difficult_intermediate_value[repetitionIndex-1]) {
        intermediateDifficultyText = `${exercise.difficult_intermediate_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        intermediateDifficultyStyle.color = 'transparent';
        intermediateDifficultyText = '-';
      }

      intermediateDifficulties.push(
        <p
          className="training-calendar-view__training__exercise__difficult-text"
          key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:intermediate_difficulty`}
          style={intermediateDifficultyStyle}
        >

          {parseTextForIcons(intermediateDifficultyText, `training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:dificult_text`, 'training-calendar-view__parsed-text', true)}

        </p>
      );

      let advancedDifficultyStyle = {};
      let advancedDifficultyText;

      if(exercise.difficult_advanced_value[repetitionIndex-1]) {
        advancedDifficultyText = `${exercise.difficult_advanced_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        advancedDifficultyStyle.color = 'transparent';
        advancedDifficultyText = '-';
      }

      advancedDifficulties.push(
        <p
          className="training-calendar-view__training__exercise__difficult-text"
          key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:advanced_difficulty`}
          style={advancedDifficultyStyle}
        >

          {parseTextForIcons(advancedDifficultyText, `training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:dificult_text`, 'training-calendar-view__parsed-text', true)}

        </p>
      );

      exerciseNames.push(
        <p
          className="training-calendar-view__training__exercise__name-text"
          key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:name`}
        >

          {exercise.exercise_name}

        </p>
      );

      let easierOptionStyle = {};
      let easierOptionText;

      if(exercise.easier_option) {
        easierOptionText = exercise.easier_option;
      }
      else {
        easierOptionStyle.color = 'transparent';
        easierOptionText = '-';
      }

      easierOptions.push(
        <p
          className="training-calendar-view__training__exercise__easier-option-text"
          key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:easier_option`}
          style={easierOptionStyle}
        >

          {easierOptionText}

        </p>
      );

      if(exercise.exercise.reference_url && exercise.exercise.reference_url.length > 0) {
        references.push(
          <a
            className="training-calendar-view__training__exercise__reference-link"
            href={exercise.exercise.reference_url}
            target="_blank"
            rel="noopener noreferrer"
            key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:reference`}
          >

            <i className="fas fa-link"></i>

          </a>
        );
      }
      else {
        references.push(
          <p
            className="training-calendar-view__training__exercise__reference-link"
            key={`training_calendar_view:${isoDate}:exercise:${exercise.id}:repetition:${repetitionIndex}:reference`}
            style={{color: 'transparent'}}
          >
            -
          </p>
        );
      }
    }

    if(executionMethod !== TRAINING_EXECUTION_METHOD_FIXED_REPS) {
      intensities = intensities.map((entry, index) => (
        <div
          className="training-calendar-view__training__exercise__intensities"
          key={`training_calendar_view:${isoDate}:repetition:${repetitionIndex}:intensity_cycle_column:${index}`}
        >

          {entry}

        </div>
      ));
    }

    return (
      <React.Fragment>

        <div
          className={`training-calendar-view__training__exercise__first-column${executionMethod === TRAINING_EXECUTION_METHOD_PIRAMIDAL ? '--expanded' : ''}`}
          style={{background: groupColor}}
        >

          <p
            className="training-calendar-view__training__exercise__header"
          >

          </p>

          {hasAdditionalHeader &&
            <p
              className="training-calendar-view__training__exercise__header"
              style={{background: groupColor}}
            >

              {executionMethod === TRAINING_EXECUTION_METHOD_PIRAMIDAL ? 'Série' : ''}

            </p>
          }

        </div>

        {showCycleNumber &&
          <div className="training-calendar-view__training__exercise__reps">

            <p
              className="training-calendar-view__training__exercise__header"
              style={{background: groupColor}}
            >

            </p>

            {hasAdditionalHeader &&
              <p
                className="training-calendar-view__training__exercise__header"
                style={{background: groupColor}}
              >

                Série

              </p>
            }

            <p className="training-calendar-view__training__exercise__reps-text">

              {cycleNumberText}

            </p>

          </div>
        }

        <div className={`training-calendar-view__training__exercise__intensities${hasAdditionalHeader ? '--collapsed' : ''}`}>

          <p
            className={`training-calendar-view__training__exercise__header${hasAdditionalHeader ? '--centered' : ''}`}
            style={{background: groupColor}}
          >

            Reps

          </p>

          <div className={`training-calendar-view__training__exercise__intensities-wrapper${executionMethod === TRAINING_EXECUTION_METHOD_FIXED_REPS ? '--vertical' : '--horizontal'}`}>

            {intensities}

          </div>

        </div>

        <div className="training-calendar-view__training__exercise__names">

          {hasAdditionalHeader &&
            <p
              className="training-calendar-view__training__exercise__header"
              style={{background: groupColor}}
            >

            </p>
          }

          <p
            className="training-calendar-view__training__exercise__header"
            style={{background: groupColor}}
          >

            Exercícios

          </p>

          {exerciseNames}

        </div>

        {showDificulties &&
          <div className="training-calendar-view__training__exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="training-calendar-view__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="training-calendar-view__training__exercise__header"
              style={{background: groupColor}}
            >

              Dificuldade

            </p>

            {difficulties}

          </div>
        }

        {showIntermediateDificulties &&
          <div className="training-calendar-view__training__exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="training-calendar-view__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="training-calendar-view__training__exercise__header"
              style={{background: groupColor}}
            >

              *

            </p>

            {intermediateDifficulties}

          </div>
        }

        {showAdvancedDificulties &&
          <div className="training-calendar-view__training__exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="training-calendar-view__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="training-calendar-view__training__exercise__header"
              style={{background: groupColor}}
            >

              AV

            </p>

            {advancedDifficulties}

          </div>
        }

        {showEasierOptions &&
          <div className="training-calendar-view__training__exercise__easier-options">

            {hasAdditionalHeader &&
              <p
                className="training-calendar-view__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="training-calendar-view__training__exercise__header"
              style={{background: groupColor}}
            >

              Variações

            </p>

            {easierOptions}

          </div>
        }

        {showReferences &&
          <div className="training-calendar-view__training__exercise__references">

            {hasAdditionalHeader &&
              <p
                className="training-calendar-view__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="training-calendar-view__training__exercise__header"
              style={{background: groupColor}}
            >

              Links

            </p>

            {references}

          </div>
        }

        {/* <div className="training-calendar-view__training__exercise__last-column">

          <p
            className="training-calendar-view__training__exercise__header"
            style={{background: groupColor}}
          >

          </p>

        </div> */}

      </React.Fragment>
    );
  }

  getTraingGroups(repetitionIndex, trainingData, isoDate) {
    const groupInfo = [];

    for(let exerciseGroup of trainingData.group_associations) {
      let groupColor = '#000000';

      const groupOrderIndex = exerciseGroup.order - 1;

      if(groupOrderIndex < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
          groupColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[groupOrderIndex];
      }

      groupInfo.push(
        <div
          className="training-calendar-view__training__exercise-group"
          style={{borderColor: groupColor, background: groupColor}}
          key={`training_calendar_view:${isoDate}:exercise_group:${exerciseGroup.id}:repetition:${repetitionIndex}`}
        >

          <h4
            className="training-calendar-view__training__exercise-group__title"
            style={{background: groupColor}}
          >

            {exerciseGroup.name}

          </h4>

          <div className="training-calendar-view__training__exercise-group__exercises-container">

            {this.getTrainingExercises(exerciseGroup.execution_method, exerciseGroup.group_is_phase_constant ? 1 : repetitionIndex, exerciseGroup.exercise_associations, isoDate, groupColor, exerciseGroup.cycle_number, exerciseGroup)}

          </div>

          {exerciseGroup.note ?
            <div className="training-calendar-view__training__exercise-group__note-container">

              <p className="training-calendar-view__training__exercise-group__note-label">
                OBS:
              </p>

              <p
                className="training-calendar-view__training__exercise-group__note-text"
                style={{color: groupColor}}
              >
                {exerciseGroup.note}
              </p>

            </div>:
            null
          }

        </div>
      );
    }

    return groupInfo;
  }

  getTrainings() {
    const currentDate = new Date();

    const trainings = [];

    for(let i = 0; i < 7; ++i) {
      const isoString =  getLocalDateIsoString(currentDate);

      const dayNameFormat = new Intl.DateTimeFormat('pt-BR', {
        weekday: this.state.screenWidth > 680 ? 'long' : 'short',
        day: 'numeric'
      });

      if(this.state.training_day_map.has(isoString)) {
        const training = this.state.training_day_map.get(isoString);

        if(training.has_training) {
          const trainingData = this.state.training_periods.find((period) => period.id === training.period_id).training_days[training.training_index];

          trainings.push(
            <section
              className="training-calendar-view__training"
              key={`training_calendar_view:${isoString}`}
            >

              <header className="training-calendar-view__training__header">

                <h3 className="training-calendar-view__training__header__date">

                  <span className="training-calendar-view__training__header__date-text">

                    {dayNameFormat.format(currentDate).replace('.', '')}

                  </span>

                  <div className="training-calendar-view__training__header__separator">

                  </div>

                </h3>

                <h3 className="training-calendar-view__training__header__name">

                  {this.state.screenWidth > 680 ? 'Treino' : ''}
                  <span className="training-calendar-view__training__header__main-name">

                    {training.training_day_name}

                  </span>
                  {`(FASE ${training.repetition_index})`}

                </h3>

              </header>

              <div className="training-calendar-view__training__content-container">

                <VerticalAccordionContainer
                  className="vertical-accordion-container training-calendar-view__training__content-wrapper"
                  pose={this.state.trainingDayVisibilityMap.get(isoString) ? 'verticalOpen' : 'verticalClosed'}>

                  <div className="training-calendar-view__link-wrapper">

                    {i < 2 &&
                      <a
                        className="training-calendar-view__student-app-link"
                        href={`${window.location.protocol}//${STUDENT_APP_SUBDOMAIN}.${window.location.host.replace(':3000', ':5000')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >

                          <p className="training-calendar-view__student-app-link__text">
                            <i className="fas fa-check"></i>
                            {' '}Confirmar presença
                          </p>

                      </a>
                    }

                  </div>

                  <div className="training-calendar-view__training__content">

                    {(trainingData.note && trainingData.note.length > 0) &&
                      <div className="training-calendar-view__training__note">

                        <i className="fas fa-exclamation training-calendar-view__training__note-icon"></i>

                        <p className="training-calendar-view__training__note-text">

                          {trainingData.note}

                        </p>

                      </div>
                    }

                    {this.getTraingGroups(training.repetition_index, trainingData, isoString)}

                  </div>

                </VerticalAccordionContainer>

                <button
                  className="training-calendar-view__training__toggle-button"
                  onClick={() => this.toggleTrainingDayVisibility(isoString)}
                >

                  {this.state.trainingDayVisibilityMap.get(isoString) ? 'Esconder' : 'Mostrar treino'}

                </button>

              </div>

            </section>
          );
        }
        else {
          trainings.push(
            <section
              className="training-calendar-view__training"
              key={`training_calendar_view:${isoString}`}
            >

              <header className="training-calendar-view__training__header">

                <h3 className="training-calendar-view__training__header__date">

                  <span className="training-calendar-view__training__header__date-text">

                    {dayNameFormat.format(currentDate).replace('.', '')}

                  </span>

                  <div className="training-calendar-view__training__header__separator">

                  </div>

                </h3>

                <h3 className="training-calendar-view__training__header__not-today">

                  Não haverá treino

                </h3>

              </header>

            </section>
          );
        }
      }
      else {
        trainings.push(
          <section
            className="training-calendar-view__training"
            key={`training_calendar_view:${isoString}`}
          >

            <header className="training-calendar-view__training__header">

              <h3 className="training-calendar-view__training__header__date">

                <span className="training-calendar-view__training__header__date-text">

                  {dayNameFormat.format(currentDate).replace('.', '')}

                </span>

                <div className="training-calendar-view__training__header__separator">

                </div>

              </h3>

              <h3 className="training-calendar-view__training__header__not-today">

                Não definido

              </h3>

            </header>

          </section>
        );
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return trainings;
  }

  getWarningMessage() {
    let message = 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.';

    return (
      <div className="training-calendar-view__warning-message">

        <i className="fas fa-exclamation training-calendar-view__warning-message__icon"></i>
        <p className="training-calendar-view__warning-message__text">{message}</p>

      </div>
    );
  }

  render() {
    if(this.state.redirect_to) {
      return (
        <Redirect push to={this.state.redirect_to} />
      );
    }

    return (
      <React.Fragment>

        <Helmet>
          <title>Treinos de CROSS da semana - FYD Club</title>
          <meta name="description" content="Treinos montados priorizando sua saúde e segurança." />
        </Helmet>

        <MainHeader
          location={this.props.location}
          enablePromotion={this.state.enablePromotion}
          invertPromotionColors={true}
        />

        <div className="training-calendar-view__wrapper">

          <section className={`training-calendar-view${this.state.enablePromotion ? '--promotion-enalbed' : ''}`}>

            <div className="training-calendar-view__content">

              <h1 className="training-calendar-view__title">{this.getContentTitle()}</h1>

              {this.state.loadingData ?
                <LoadingIcon />:
                this.state.apiFailed ?
                  this.getWarningMessage():
                  <div className="training-calendar-view__trainings-container">

                    {this.getTrainings()}

                  </div>
              }

            </div>

          </section>

          <MainFooter />

        </div>

        <ChatOverlayButton />

      </React.Fragment>
    );

    // if(this.state.loadingData) {
    //   return (
    //     <PoseGroup>
    //       <FadeContainer key="preloader">
    //         <PreLoader />
    //       </FadeContainer>
    //     </PoseGroup>
    //   );
    // }
    // else {
    //   return this.state.redirect_to ? (
    //     <Redirect push to={this.state.redirect_to} />
    //   ):
    //   (
    //     <React.Fragment>
    //
    //       <FormView
    //         title="Periodização"
    //         contentTitle={this.getContentTitle()}
    //         contentText={this.getContentText()}
    //         questionsId={0}
    //         questions={
    //           this.getTrainings()
    //         }
    //         maxId={0}
    //         showControllers={false}
    //       />
    //
    //     </React.Fragment>
    //
    //   );
    // }
  }
}

export default TrainingCalendarView
