import React from 'react';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router'
import MainHeader from '../../components/main_header';
import MainFooter from '../../components/main_footer';
import LoadingIcon from '../../components/loading_icon';
import {RedText} from '../../components/default_elements';
import posed from 'react-pose';
// import FormView from '../form_view';
import FormContent from '../form_content';
// import PreLoader from '../../utils/preloader';
import {PHYSICAL_EVALUATION_AVAILABLE_PERIODS_GET_API,
        FOOD_PRESCRIPTION_AVAILABLE_PERIODS_BY_HASH,
        SCHEDULE_SERVICE_APPOINTMENT_POST_API,
        THANK_YOU_PATH,
        CANCEL_SERVICE_APPOINTMENT_POST_API,
        APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID,
        APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID} from '../../constants';
import {getModels, postModel, getAsLocalDateString, getAsLocalDate} from '../../utils/functions';
import './schedule_appointment.scss';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../../utils/warning_message';
import ConfirmationWindow from '../../utils/confirmation_window';

const FadeContainer = posed.div({
  preEnter: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

class ScheduleAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      apiFailed: false,
      data: {
        may_schedule_appointment: false,
        enabled: false,
        appointment_scheduled: null,
        available_dates: []
      },
      scheduled_date: "",
      scheduled_time: "",
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "Agendamento cancelado com sucesso!",
    };

    this.stateToken = null;
  }

  async componentDidMount() {
    await this.reloadAvailableDates();
  }

  async reloadAvailableDates() {
    this.setState({
      loadingData: true,
    });

    let availability = null;

    if (this.props.appointment_type_id === APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID) {
      availability = await getModels(`${PHYSICAL_EVALUATION_AVAILABLE_PERIODS_GET_API}${this.props.match.params.userHash}`);
    }
    else if (this.props.appointment_type_id === APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID) {
      availability = await getModels(`${FOOD_PRESCRIPTION_AVAILABLE_PERIODS_BY_HASH}${this.props.match.params.userHash}`);
    }

    if(availability === null) {
      this.setState({
        loadingData: false,
        apiFailed: true,
      });

      return;
    }

    this.setState({
      loadingData: false,
      data: availability,
      scheduled_date: "",
      scheduled_time: "",
    });
  }

  async onFinishClick() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
    });

    const selectedDate = this.state.data.available_dates.find((entry) => entry.date === this.state.scheduled_date);
    const selectedTime = selectedDate.available_times.find((entry) => entry.time === this.state.scheduled_time);

    const data = {
      appointment_type_id: this.props.appointment_type_id,
      scheduled_at: `${this.state.scheduled_date}T${this.state.scheduled_time}`,
      appointment_schedule_id: selectedTime.appointment_schedule_id,
    };

    try {
      if(await postModel(`${SCHEDULE_SERVICE_APPOINTMENT_POST_API}${this.props.match.params.userHash}`, data) == null) {
        this.setState({
          apiFailed: true
        });
      }
      else {
        this.setState({
          redirect_to: THANK_YOU_PATH
        });
      }
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 106:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'Another appointment is already scheduled for selected period':
                    warningMessages.push(
                      'Infelizmente o dia e horário selecionado acabou ' +
                      'de ser agendado por outro aluno. Por favor, ' +
                      'selecione outro dia e horário'
                    );

                    break;
                  default:
                }
              }

              break;
            case 208:
              if (error.message.includes('User already have an appointment scheduled')) {
                warningMessages.push(
                  'Encontramos um atendimento já agendado para você para os próximos dias. ' +
                  'Caso tenha interesse, sinta-se à vontade para desmarcar este para tentar reagendar um novo dia e horário'
                );
              }

              break;

              default:
          }
        }

        this.reloadAvailableDates();

        this.setState({
          highlights: highlights,
          showWarningMessage: true,
          warningMessage: `${warningMessages.join('; ')}.`,
        });
      }
    }
  }

  isHighlighted(propertyName) {
    return this.state.highlights.includes(propertyName);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const update = {};

    if(name === 'scheduled_date' && value !== this.state.scheduled_date) {
      update.scheduled_time = '';
    }

    this.setState({[name]: value, ...update});
  }

  getContentTitle() {
    if(this.state.apiFailed) {
      return (
        <FadeContainer key="falha_de_api">

          <RedText>OOOPS!</RedText>

        </FadeContainer>
      );
    }
    else if(!this.state.data.may_schedule_appointment) {
      if(!this.state.data.enabled) {
        return (
          <React.Fragment>

            AGENDAMENTO <RedText>DESABILITADO</RedText>

          </React.Fragment>
        );
      }
      else if(this.state.data.appointment_scheduled) {
        return (
          <React.Fragment>

            AVALIAÇÃO <RedText>AGENDADA</RedText>

          </React.Fragment>
        );
      }
      else {
        return (
          <React.Fragment>

            FORA DO PERÍODO DE <RedText>REAVALIAÇÃO</RedText>

          </React.Fragment>
        );
      }
    }

    return (
      <React.Fragment>

        AGENDAMENTO DE <RedText>AVALIAÇÃO</RedText>

      </React.Fragment>
    );
  }

  getContentText() {
    if(this.state.apiFailed) {
      return 'Infelizmente estamos com problemas temporários ' +
             'em nosso serviço. Por favor, tente novamente mais tarde.';
    }
    else if(!this.state.data.may_schedule_appointment) {
      if(!this.state.data.enabled) {
        if (this.props.appointment_type_id === APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID) {
          return 'Infelizmente você não está habilitado para realizar o agendamento ' +
                 'de avaliações físicas. Caso tenha interesse, por favor, entre em ' +
                 'contato conosco para liberarmos seu acesso.';
        }
        else if (this.props.appointment_type_id === APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID) {
          return 'Infelizmente você não está habilitado para realizar o agendamento ' +
                 'de avaliações nutricionais. Caso tenha interesse, por favor, entre em ' +
                 'contato conosco para liberarmos seu acesso.';
        }
      }
      else if(this.state.data.appointment_scheduled) {
        const scheduled_date = this.state.data.appointment_scheduled.scheduled_at;
        const scheduled_time = this.state.data.appointment_scheduled.scheduled_at.slice(11, 16);
        const weekday = new Intl.DateTimeFormat('pt-BR', {weekday: 'long'}).format(getAsLocalDate(scheduled_date, false));

        return (
          <React.Fragment>

            Sua avaliação está marcado para
            {' '}<RedText>{weekday}</RedText>, dia
            {' '}<RedText>{getAsLocalDateString(scheduled_date)}</RedText>
            {' '}as
            {' '}<RedText>{scheduled_time}</RedText>.
            {' '}Caso deseje desmarcar este atendimento, seja por motivos adversos, ou para tentar
            {' '}remarcá-lo em outra data, clique no botão abaixo.

          </React.Fragment>
        );
      }
      else {
        if (this.props.appointment_type_id === APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID) {
          return 'Fora do período de reavaliação física. Fique tranquilo ' +
                 'que estaremos te notificando quando der a hora de sua reavaliação.';
        }
        else if (this.props.appointment_type_id === APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID) {
          return 'Fora do período de reavaliação nutricional. Fique tranquilo ' +
                 'que estaremos te notificando quando der a hora de sua reavaliação.';
        }
      }
    }
    else if(this.state.data.available_dates.length <= 0) {
      if (this.props.appointment_type_id === APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID) {
        return (
          <React.Fragment>

            Infelizmente todas as <RedText>vagas</RedText> para avaliação física foram <RedText>preenchidas</RedText>. Mas
            fique tranquilo, assim que marcarmos outro dia de avaliação, você receberá novamente um email para realizar o agendamento.
            Caso esteja tendo dificuldades para marcar sua avaliação, pedimos um pouco de paciência pois estamos trabalhando bastante para atender
            a todos os alunos. Obrigado pela compreenção!

          </React.Fragment>
        );
      }
      else if (this.props.appointment_type_id === APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID) {
        return (
          <React.Fragment>

            Infelizmente todas as <RedText>vagas</RedText> para avaliação nutricional foram <RedText>preenchidas</RedText>. Mas
            fique tranquilo, assim que marcarmos outro dia de avaliação, você receberá novamente um email para realizar o agendamento.
            Caso esteja tendo dificuldades para marcar sua avaliação, pedimos um pouco de paciência pois estamos trabalhando bastante para atender
            a todos os alunos. Obrigado pela compreenção!

          </React.Fragment>
        );
      }
    }

    if (this.props.appointment_type_id === APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID) {
      return 'Por favor, selecione o dia e horário desejado para realizar sua ' +
             'avaliação física';
    }
    else if (this.props.appointment_type_id === APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID) {
      return 'Por favor, selecione o dia e horário desejado para realizar sua ' +
             'avaliação nutricional';
    }
  }

  getAvailableDates() {
    if (this.state.scheduled_date.length > 0) {
      return this.state.data.available_dates.map((entry) => SelectOption(entry.date, getAsLocalDateString(entry.date)));
    }

    return [
      SelectOption('', 'Selecione uma data disponível'),
      ...this.state.data.available_dates.map((entry) => SelectOption(entry.date, getAsLocalDateString(entry.date)))
    ];
  }

  getAvailableTimes() {
    const availableDate = this.state.data.available_dates.find((entry) => entry.date === this.state.scheduled_date);

    if(availableDate) {
      if (this.state.scheduled_time.length > 0) {
        return availableDate.available_times.map((entry) => SelectOption(entry.time));
      }

      return [
        SelectOption('', 'Selecione um horário disponível'),
        ...availableDate.available_times.map((entry) => SelectOption(entry.time))
      ];
    }

    return [
      SelectOption('', 'Selecione um horário disponível'),
    ];
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Cancelando agendamento';
    }
    else if(this.state.confirmFailed) {
      return 'Cancelado';
    }

    return 'Cancelar agendamento';
  }

  async cancelScheduledAppointment() {
    this.setState({
      confirmInProgress: true
    });

    try{
      if(await postModel(`${CANCEL_SERVICE_APPOINTMENT_POST_API}${this.props.match.params.userHash}`, {appointment_id: this.state.deleteId})) {
        this.setState({
          confirmInProgress: false,
          confirmFailed: true,
        });

        return
      }
      else {
        this.setState({
          deleteId: null,
          apiFailed: true
        });

        return;
      }
    }
    catch(errors) {
      this.setState({
        deleteId: null,
        apiFailed: true
      });

      return;
    }
  }

  onCancelConfirmation() {
    if(this.state.confirmFailed) {
      window.location.reload();

      return;
    }

    this.setState({
      deleteId: null
    });
  }

  render() {
    if(this.state.redirect_to) {
      return (
        <Redirect push to={this.state.redirect_to} />
      );
    }

    return (
      <React.Fragment>

        <Helmet>
          <title>Agendamento de avaliação - FYD Club</title>
        </Helmet>

        <MainHeader
          location={this.props.location}
          collapse={true}
        />

        <div className="schedule-appointment__wrapper">

          <section className="schedule-appointment">

            <div className="schedule-appointment__content">

              {this.state.loadingData ?
                <LoadingIcon />:
                <FormContent
                  contentTitle={this.getContentTitle()}
                  contentText={this.getContentText()}
                  questionsId={0}
                  questions={(
                    <React.Fragment>

                      <div className="schedule-appointment__warning-container">

                        <WarningMessage
                          message={this.state.warningMessage}
                          onClose={() => {this.setState({highlights: [], showWarningMessage: false})}}
                          visible={this.state.showWarningMessage}
                        />

                      </div>

                      {this.state.apiFailed || this.state.data.available_dates.length <= 0 ?

                      !this.state.data.may_schedule_appointment && this.state.data.appointment_scheduled && !this.state.apiFailed &&
                      <button
                        className="schedule-appointment__cancel-button"
                        onClick={() => this.setState({
                          deleteId: this.state.data.appointment_scheduled.id,
                          confirmInProgress: false,
                          confirmFailed: false
                        })}
                      >

                        Desmarcar agendamento

                      </button>:

                      <React.Fragment>



                        <div className="schedule-appointment__input-container">

                          <HalfWrapper>

                            <DefaultInput
                              name="scheduled_date"
                              isHighlighted={this.isHighlighted("scheduled_date")}
                              label="Dia da avaliação"
                              type="select"
                              handleInputChange={(event) => this.handleInputChange(event)}
                              value={this.state.scheduled_date}
                              options={this.getAvailableDates()}
                            />

                            {this.state.scheduled_date.length > 0 ?
                              <DefaultInput
                                name="scheduled_time"
                                isHighlighted={this.isHighlighted("scheduled_time")}
                                label="Horário da avaliação"
                                type="select"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={this.state.scheduled_time}
                                options={this.getAvailableTimes()}
                              />:
                              null
                            }

                          </HalfWrapper>

                        </div>

                      </React.Fragment>}

                    </React.Fragment>
                  )}
                  onFinishClick={() => this.onFinishClick()}
                  maxId={0}
                  showControllers={!this.state.apiFailed && this.state.data.may_schedule_appointment && this.state.data.available_dates.length > 0}
                  allowNext={this.state.scheduled_date.length > 0 && this.state.scheduled_time.length > 0}
                  finishText="Confirmar agendamento"
                  uploadingText="Confirmando..."
                />
              }

            </div>

          </section>

          <MainFooter
            collapse={true}
          />

        </div>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : 'Uma vez confirmada, o dia e horário de seu agendamento serão liberados para outros alunos.'}
          confirmText="Confirmar cancelamento"
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.cancelScheduledAppointment()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
          errorIcon={<i className="fas fa-check schedule-appointment__canceld-icon"></i>}
        />

      </React.Fragment>
    );
  }
}

export default ScheduleAppointment
